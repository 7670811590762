import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import focusBearApi from 'services/axios-config';
import Endpoints from 'constants/endpoints';
import { updateIsYouTubeURLMetadataFetching } from './slice';

export const fetchYoutubeURLMetadata = createAsyncThunk(
  'setting/fetchYoutubeURLMetadata',
  async (video_urls: string[], { dispatch }) => {
    try {
      dispatch(updateIsYouTubeURLMetadataFetching(true));
      const result = await focusBearApi.post(Endpoints.VIDEO_METADATA, {
        video_urls,
      });
      return result?.data ?? null;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
