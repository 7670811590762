import ModalHeader from 'components/shared/ModalHeader';
import ModalOverlay from 'components/shared/ModalOverlay';
import { THEME_OPTION } from 'constants/enum';
import { DELETE_COUNT, MODAL_TYPES } from 'constants/general';
import { ReactNode, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateTodoSubTasks } from 'store/reducer/modal/slice';
import ListOfSubTasks from './ListOfSubTasks';
import SubTasksModalActions from './SubTasksModalActions';

const Wrapper = ({ children }: { readonly children: ReactNode }) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`relative top-[10vh] w-[95%] sm:w-[80%] xl:w-[70%] 2xl:w-[60%] h-fit shadow-lg rounded-md ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-600' : 'bg-gray-200'
      } flex flex-col mx-auto`}
    >
      {children}
    </div>
  );
};

const SubTasksModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { toDoSubTasks } = useAppSelector((state) => state.modal);

  const handleDrop = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const updateData = Array.from(toDoSubTasks.subTasks ?? []);
      const [removed] = updateData.splice(result.source.index, DELETE_COUNT);
      updateData.splice(result.destination.index, 0, removed);
      dispatch(
        updateTodoSubTasks({
          subTasks: updateData,
          isNewTask: toDoSubTasks.isNewTask
        })
      );
    },
    [toDoSubTasks.subTasks]
  );

  return (
    <ModalOverlay
      styles='w-full h-full bg-gray-600 bg-opacity-50'
      zIndex='z-[70]'
    >
      <Wrapper>
        <ModalHeader
          title={`${t('to_do_procrastinate.create_subtasks')}: ${
            toDoSubTasks?.task ?? ''
          }`}
          modalType={MODAL_TYPES.TO_DO_SUB_TASK_MODAL}
        />
        <DragDropContext onDragEnd={handleDrop}>
          <ListOfSubTasks />
        </DragDropContext>
        <SubTasksModalActions />
      </Wrapper>
    </ModalOverlay>
  );
};

export default SubTasksModal;
