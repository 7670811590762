import { IconProps } from 'interfaces';
import COLOR from 'constants/color';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';

const Matrix = ({
  styles = 'w-7 md:w-5 h-auto',
  fill = COLOR.GRAY_5OO
}: IconProps) => {
  return (
    <svg
      className={styles}
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
      fill={
        !isDarkModeActivated(useAppSelector(themeSelector)) ? fill : COLOR.WHITE
      }
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path d='M0 0h48v48H0z' fill='none'></path>
        <g id='Shopicon'>
          <rect x='4' y='4' width='10' height='10'></rect>
          <rect x='19' y='4' width='10' height='10'></rect>
          <rect x='34' y='4' width='10' height='10'></rect>
          <rect x='4' y='19' width='10' height='10'></rect>
          <rect x='19' y='19' width='10' height='10'></rect>
          <rect x='34' y='19' width='10' height='10'></rect>
          <rect x='4' y='34' width='10' height='10'></rect>
          <rect x='19' y='34' width='10' height='10'></rect>
          <rect x='34' y='34' width='10' height='10'></rect>
        </g>
      </g>
    </svg>
  );
};

export default Matrix;
