import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';
import {
  DEFAULT_TODO_TASK_DURATION,
  MAC_APP_DATA_LOCATION
} from 'constants/general';
import { t } from 'i18next';
import { ToDoFocusEndContext } from 'pages/to-do/toDoFocusEnd';
import { useContext, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsTaskSubmitting } from 'store/reducer/to-do/slice';
import { toDoFocusEndTasksSelector } from 'store/reducer/user/selectors';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { areAllDurationsValid } from 'utils/todoUtil';

const TodoFocusEndSaveBtn = () => {
  const dispatch = useAppDispatch();
  const { incomingTasks } = useContext(ToDoFocusEndContext);
  const { isTaskSubmitting, platform } = useAppSelector(
    toDoFocusEndTasksSelector
  );

  const hasValidDurations = useMemo(
    () => areAllDurationsValid(incomingTasks ?? []),
    [incomingTasks]
  );

  return (
    <ButtonFocusBear
      title={t('save')}
      size={BTN_FB_SIZE.SMALL}
      disabled={!hasValidDurations || isTaskSubmitting}
      onClick={() => {
        dispatch(updateIsTaskSubmitting(true));
        sendDataToPlatform(
          platform,
          (incomingTasks ?? []).map((task) => {
            const duration = parseInt(
              String(task?.focusedDuration ?? DEFAULT_TODO_TASK_DURATION)
            );
            return {
              ...task,
              duration: duration,
              focusedDuration: duration
            };
          }),
          MAC_APP_DATA_LOCATION.END_FOCUS_MODE
        );
      }}
      additionalStyles={classNames('self-end my-4 mr-3', {
        'animate-pulse cursor-not-allowed': isTaskSubmitting,
        'animate-none cursor-pointer': !isTaskSubmitting
      })}
    />
  );
};

export default TodoFocusEndSaveBtn;
