import { useCallback } from 'react';
import { DAYS_OF_WEEK, DAY } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionDaysOfWeek } from 'store/reducer/modal/slice';
import { DaysOfWeek } from 'constants/enum';
import DropDown from 'components/common/DropDown';
import { DropDownSelectedValue } from 'interfaces';
import { t } from 'i18next';
import { decrement } from 'utils/support';

const options = [
  ...DAYS_OF_WEEK.map(({ label, value }) => ({
    label: t(label),
    value: value
  }))
];

const DayOfWeek = () => {
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const daysOfWeek: { label: string; value: DaysOfWeek }[] =
    DAYS_OF_WEEK.filter((days) =>
      activity?.days_of_week?.includes(days.value)
    ).map(({ label, value }) => ({ label: t(label), value }));

  const handleChange = useCallback(
    (data) => {
      const result = data as DropDownSelectedValue[];
      if (result.length) {
        let days: DropDownSelectedValue[] = [];
        const selectedDay = result[decrement(result.length)]
          .value as DaysOfWeek;
        if (selectedDay === DaysOfWeek.ALL) {
          days = [{ label: t(DAY.ALL), value: DaysOfWeek.ALL }];
        } else if ([DaysOfWeek.SAT, DaysOfWeek.SUN].includes(selectedDay)) {
          days = result.filter(
            ({ value }) =>
              ![DaysOfWeek.WEEKEND, DaysOfWeek.ALL].includes(
                value as DaysOfWeek
              )
          );
        } else if (selectedDay === DaysOfWeek.WEEKEND) {
          days = result.filter(
            ({ value }) =>
              ![DaysOfWeek.ALL, DaysOfWeek.SAT, DaysOfWeek.SUN].includes(
                value as DaysOfWeek
              )
          );
        } else if (selectedDay === DaysOfWeek.WEEKDAYS) {
          days = result.filter(
            ({ value }) =>
              ![
                DaysOfWeek.MON,
                DaysOfWeek.TUE,
                DaysOfWeek.WED,
                DaysOfWeek.THU,
                DaysOfWeek.FRI
              ].includes(value as DaysOfWeek)
          );
        } else {
          days = result.filter((item) => item.value !== DaysOfWeek.ALL);
        }
        dispatch(
          updateMoreOptionDaysOfWeek(
            days.map((item) => item.value as DaysOfWeek)
          )
        );
      }
    },
    [activity?.days_of_week]
  );

  return (
    <DropDown
      value={daysOfWeek}
      isMulti
      handleChange={handleChange}
      options={options}
      title={t('which_days_of_the_week_this_activity_should_be_used')}
      containerStyles='w-fit text-xs lg:text-sm font-semibold text-black'
    />
  );
};

export default DayOfWeek;
