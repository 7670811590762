import { useContext, useEffect } from 'react';
import Search from 'assets/icons/Search';
import COLOR from 'constants/color';
import { COMPLETED_TAB, DEBOUNCE_DELAY } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TasksContext } from '..';
import { t } from 'i18next';
import { searchToDosSelector } from 'store/reducer/to-do/selectors';
import TextInput from 'components/common/inputs/TextInput';
import { useDebounce } from 'usehooks-ts';
import { searchToDos } from 'store/reducer/to-do/extra';
import { updatePlayerSearchResults } from 'store/reducer/to-do/slice';

const SearchToDos = () => {
  const dispatch = useAppDispatch();
  const { activeTab, isDeletingMultipleToDo } =
    useAppSelector(searchToDosSelector);
  const { filterData, setFilterData, shouldActivateDarkMode } =
    useContext(TasksContext);
  const shouldDisableFilters =
    activeTab === COMPLETED_TAB || isDeletingMultipleToDo;

  const searchedTitle = useDebounce(
    filterData.taskName,
    DEBOUNCE_DELAY.MARKET_PLACE_SEARCH
  );

  useEffect(() => {
    searchedTitle && dispatch(searchToDos({ title: searchedTitle }));
  }, [searchedTitle]);

  return (
    <div className='w-full relative'>
      <TextInput
        disabled={shouldDisableFilters}
        type='text'
        value={filterData.taskName}
        onChange={({ target: { value } }) => {
          setFilterData?.((prev) => ({ ...prev, taskName: value }));
          !value && dispatch(updatePlayerSearchResults(null));
        }}
        className={`w-full pl-2 pr-6 py-1 lg:py-1.5 rounded text-xs md:text-sm outline-none border shadow leading-relaxed ${
          shouldActivateDarkMode
            ? 'bg-gray-400 border-gray-500'
            : 'bg-white border-gray-200'
        }`}
        placeholder={t('search')}
      />
      <span className='absolute top-1/2 -translate-y-1/2 right-2 lg:right-4'>
        <Search fill={COLOR.BLACK} />
      </span>
    </div>
  );
};

export default SearchToDos;
