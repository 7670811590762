import Spinner from 'components/common/Spinner';
import { createAppSelector, useAppSelector } from 'store/hooks';
import ListOfCourses from './list-of-courses/ListOfCourses';

const OtherCourses = () => {
  const { isFetchingOtherCourses } = useAppSelector(
    createAppSelector(
      [(state) => state.course.isFetchingOtherCourses],
      (isFetchingOtherCourses) => ({
        isFetchingOtherCourses
      })
    )
  );

  return isFetchingOtherCourses ? <Spinner /> : <ListOfCourses />;
};

export default OtherCourses;
