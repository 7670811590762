import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { courseHighlightsSelector } from 'store/reducer/course/selectors';
import { updateShowCourseLessonsModal } from 'store/reducer/course/slice';

const CourseHighlights = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { name, description, lessons } = useAppSelector(
    courseHighlightsSelector
  );

  return (
    <div className='w-full flex flex-col gap-3'>
      <h3 className='line-clamp-2 text-xl md:text-2xl font-semibold'>{name}</h3>
      <p className='h-fit max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 bg-gray-200 px-4 py-2 rounded-md shadow-md text-sm md:text-base'>
        {description}
      </p>
      {lessons?.length ? (
        <ButtonFocusBear
          title={t('course.learn_more')}
          onClick={() => dispatch(updateShowCourseLessonsModal(true))}
          size={BTN_FB_SIZE.SMALL}
          additionalStyles='self-center my-4'
        />
      ) : (
        <p className='w-fit text-xs sm:text-sm mx-auto py-4'>
          {t('course.no_lesson_found')}
        </p>
      )}
    </div>
  );
};

export default CourseHighlights;
