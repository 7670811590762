import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateValidationErrors } from 'store/reducer/setting/slice';
import { Mode } from 'constants/enum';
import { getSettingsValidationErrors } from 'utils/settingsUtil';
import { useEffect, useMemo, useState } from 'react';
import { isDarkModeActivated } from 'utils/validation';
import COLOR from 'constants/color';
import CloseCircle from 'assets/icons/CloseCircle';
import { t } from 'i18next';
import { EMPTY_STRING } from 'constants/general';
import { increment } from 'utils/support';
import { validationErrorsSelector } from 'store/reducer/setting/selectors';

const ValidationErrors = () => {
  const dispatch = useAppDispatch();
  const [showErrors, setShowErrors] = useState(false);
  const {
    currentSettings,
    validationErrors,
    mode,
    themeMode,
    currentViewedFocusTemplate
  } = useAppSelector(validationErrorsSelector);

  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  useEffect(() => {
    const isFocusModeActive = mode === Mode.FOCUS_MODE;
    dispatch(
      updateValidationErrors(
        getSettingsValidationErrors(
          isFocusModeActive ? null : currentSettings,
          mode,
          isFocusModeActive ? currentViewedFocusTemplate : null
        )
      )
    );
  }, [currentSettings, currentViewedFocusTemplate, mode]);

  return validationErrors.length ? (
    <div className='relative'>
      <button
        onClick={() => setShowErrors(true)}
        className='text-xs lg:text-sm bg-red-400 text-white rounded-lg px-2.5 py-1 capitalize'
      >
        {t('validation.view_errors')}
      </button>
      {showErrors && (
        <div className='absolute bottom-0 right-0 flex flex-col text-black'>
          <button
            onClick={() => setShowErrors(false)}
            className='absolute -top-2 -right-2 border border-focusBear rounded-full bg-focusBear'
          >
            <CloseCircle styles='w-5 h-auto' fill={COLOR.RED} />
          </button>
          <ul
            className={`w-[50vw] md:w-[35vw] lg:w-[30vw] xl:w-[25vw] h-fit max-h-[50vh] px-2.5 py-1 rounded-lg overflow-y-auto scrollbar-thin scrollbar-thumb-red-400 border border-red-300 ${shouldActivateDarkMode ? 'bg-red-300' : 'bg-red-100'}`}
          >
            {validationErrors?.map((error) => (
              <li
                key={error.position}
                className={`w-full flex flex-col gap-0.5 text-[0.5rem] sm:text-xs py-1.5 px-2 rounded-md my-2 shadow-md ${shouldActivateDarkMode ? 'bg-gray-300' : 'bg-focusBear'}`}
              >
                <p className='w-full line-clamp-2'>
                  {t(`validation.tab_routine`, { routine: t(error.tab.title) })}
                </p>
                <p>
                  {error?.position
                    ? `${t('validation.habit_position', {
                        position: increment(error.position)
                      })} ${error.tab.habit ?? EMPTY_STRING}`
                    : t('validation.feature_time_name', {
                        name: error.feature
                      })}
                </p>
                <p className='underline'>
                  {t('validation.error_message', { message: error.message })}
                </p>
                {error.invalid_value && (
                  <p className='underline'>
                    {t('validation.error_invalid_value', {
                      value: error.invalid_value
                    })}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  ) : null;
};

export default ValidationErrors;
