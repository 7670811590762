import Switch from 'react-switch';
import { createAppSelector, useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionMicroBreak } from 'store/reducer/modal/slice';
import { ACTIVITY_TYPES } from 'constants/general';
import { SWITCH } from 'constants/tag';
import { t } from 'i18next';
import Tooltip from 'components/common/Tooltip';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full flex flex-col md:flex-row gap-6 md:gap-10 py-2'>
    {children}
  </div>
);

const MicroBreak = () => {
  const dispatch = useAppDispatch();
  const { type, activity } = useAppSelector(
    createAppSelector(
      [
        (state) => state.setting.moreOptions.type,
        (state) => state.modal.moreOption.activity
      ],
      (type, activity) => ({ type, activity })
    )
  );

  const shouldRunMicroBreaksSwitchBeVisible =
    (!activity?.allowed_apps?.length ||
      activity?.allowed_urls?.length ||
      activity?.allowed_focus_mode_id) &&
    type === ACTIVITY_TYPES.BREAKING;

  return (
    <Wrapper>
      <div className='flex items-center gap-2 py-1'>
        <Switch
          width={SWITCH.WIDTH.MICRO_BREAK}
          height={SWITCH.HEIGHT.MICRO_BREAK}
          onChange={(value) => {
            dispatch(updateMoreOptionMicroBreak(value));
          }}
          checked={activity?.run_micro_breaks ?? false}
          disabled={!shouldRunMicroBreaksSwitchBeVisible}
        />
        <p className='text-xs lg:text-sm font-medium'>
          {t('run_microbreaks_during_activity')}
        </p>
        <Tooltip message={t('allowed_urls_or_apps_need_to_be_set')} />
      </div>
    </Wrapper>
  );
};

export default MicroBreak;
