import { THEME_OPTION } from 'constants/enum';
import { createAppSelector } from 'store/hooks';

export const todoSelector = createAppSelector(
  [
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.toDo.showEmbeddedCreateToDoModal,
    (state) => state.toDo.showTodoSubTasksModal
  ],
  (
    isTokenAddedToInterceptor,
    showEmbeddedCreateToDoModal,
    showTodoSubTasksModal
  ) => ({
    isTokenAddedToInterceptor,
    showEmbeddedCreateToDoModal,
    showTodoSubTasksModal
  })
);

export const taskContainerSelector = createAppSelector(
  [
    (state) => state.modal.eisenhowerMatrix.isEisenhowerMatrixModalOpened,
    (state) => state.toDo.tasks.slice(),
    (state) => ({ ...state.pagination }),
    (state) => state.general.tabLayout.activeTab,
    (state) => state.toDo.completedTasks
  ],
  (
    isEisenhowerMatrixModalOpened,
    tasks,
    pagination,
    activeTab,
    completedTasks
  ) => ({
    isEisenhowerMatrixModalOpened,
    tasks,
    pagination,
    activeTab,
    completedTasks
  })
);

export const pageActionsSelector = createAppSelector(
  [
    (state) => state.toDo.isTaskSubmitting,
    (state) => state.toDo.tasks.slice(),
    (state) => state.toDo.selectedTaskIDs.slice(),
    (state) => state.setting.platform
  ],
  (isTaskSubmitting, tasks, selectedTaskIDs, platform) => ({
    isTaskSubmitting,
    tasks,
    selectedTaskIDs,
    platform
  })
);

export const todoActiveTabIndexSelector = createAppSelector(
  [(state) => state.toDo.activeTabIndex],
  (activeTabIndex) => activeTabIndex
);

export const listOfProjectsSelector = createAppSelector(
  [
    (state) => state.toDo.tasks.slice(),
    (state) => ({ ...state.pagination }),
    (state) => state.general.tabLayout.activeTab,
    (state) => state.toDo.player.searchResults?.slice(),
    (state) => state.toDo.completedTasks.slice()
  ],
  (tasks, pagination, activeTab, searchResults, completedTasks) => ({
    tasks,
    pagination,
    activeTab,
    searchResults,
    completedTasks
  })
);

export const taskSelector = createAppSelector(
  [
    (state) => state.toDo.selectedTaskIDs.slice(),
    (state) => state.general.tabLayout.activeTab,
    (state) => state.toDo.tasks?.slice()
  ],
  (selectedTaskIDs, activeTab, tasks) => ({ selectedTaskIDs, activeTab, tasks })
);

export const filterActionsSelector = createAppSelector(
  [
    (state) => state.general.tabLayout.activeTab,
    (state) => state.toDo.selectedTaskIDs.slice(),
    (state) => state.toDo.isDeletingMultipleToDo,
    (state) => state.toDo.tasks.slice(),
    (state) => state.setting.platform,
    (state) => state.toDo.completedTasks.slice(),
    (state) => state.toDo.isFetchingToDo
  ],
  (
    activeTab,
    selectedTaskIDs,
    isDeletingMultipleToDo,
    tasks,
    platform,
    completedTasks,
    isFetchingToDo
  ) => ({
    activeTab,
    selectedTaskIDs,
    isDeletingMultipleToDo,
    tasks,
    platform,
    completedTasks,
    isFetchingToDo
  })
);

export const searchToDosSelector = createAppSelector(
  [
    (state) => state.general.tabLayout.activeTab,
    (state) => state.toDo.isDeletingMultipleToDo
  ],
  (activeTab, isDeletingMultipleToDo) => ({
    activeTab,
    isDeletingMultipleToDo
  })
);

export const taskItemHeaderSelector = createAppSelector(
  [
    (state) => state.toDo.isUpdatingStatus,
    (state) => state.toDo.selectedTaskIDs.slice()
  ],
  (isUpdatingStatus, selectedTaskIDs) => ({
    isUpdatingStatus,
    selectedTaskIDs
  })
);

export const selectTasksBtnSelector = createAppSelector(
  [
    (state) => state.toDo.selectedTaskIDs.slice(),
    (state) => state.toDo.tasks.slice(),
    (state) => state.toDo.isTaskSubmitting,
    (state) => state.setting.platform,
    (state) => state.setting.themeMode
  ],
  (selectedTaskIDs, tasks, isTaskSubmitting, platform, themeMode) => ({
    selectedTaskIDs,
    tasks,
    isTaskSubmitting,
    platform,
    themeMode
  })
);

export const tasksToolbarSelector = createAppSelector(
  [
    (state) => state.focusMode.tags?.slice(),
    (state) => state.user.details,
    (state) => state.toDo.tasks.slice()
  ],
  (tags, details, tasks) => ({
    tags,
    details,
    tasks
  })
);

export const tasksSelector = createAppSelector(
  [
    (state) => state.toDo.isFetchingToDo,
    (state) => state.toDo.tasks.slice(),
    (state) => state.toDo.player.isSearchingToDos,
    (state) => state.toDo.player.searchResults,
    (state) => state.toDo.completedTasks
  ],
  (isFetchingToDo, tasks, isSearchingToDos, searchResults, completedTasks) => ({
    isFetchingToDo,
    tasks,
    isSearchingToDos,
    searchResults,
    completedTasks
  })
);

export const searchResultsSelector = createAppSelector(
  [
    (state) => state.toDo.player.searchResults?.slice(),
    (state) => state.toDo.isCreatingToDo,
    (state) => state.toDo.player.isSearchingToDos
  ],
  (searchResults, isCreatingToDo, isSearchingToDos) => ({
    searchResults,
    isCreatingToDo,
    isSearchingToDos
  })
);

export const searchResultSelector = createAppSelector(
  [
    (state) => ({ ...state.toDo.player }),
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (player, userId, userEmail) => ({ player, userId, userEmail })
);

export const todoPlayerSelector = createAppSelector(
  [
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.setting.platform,
    (state) => state.toDo.player,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (isTokenAddedToInterceptor, platform, player, userId, userEmail) => ({
    isTokenAddedToInterceptor,
    platform,
    player,
    userId,
    userEmail
  })
);

export const playerContentsSelector = createAppSelector(
  [
    (state) => state.toDo.showTodoSubTasksModal,
    (state) => ({ ...state.toDo.player }),
    (state) => state.setting.themeMode,
    (state) => state.setting.isUserOnboarding,
    (state) => state.modal.confirmAction.showConfirmModal,
    (state) => state.toDo.player.showPlayerNewTaskModal
  ],
  (
    showTodoSubTasksModal,
    player,
    themeMode,
    isUserOnboarding,
    showConfirmModal,
    showPlayerNewTaskModal
  ) => ({
    showTodoSubTasksModal,
    player,
    themeMode,
    isUserOnboarding,
    showConfirmModal,
    showPlayerNewTaskModal
  })
);

export const playerSelectedTaskSelector = createAppSelector(
  [(state) => ({ ...state.toDo.player }), (state) => state.setting.themeMode],
  (player, themeMode) => ({ player, themeMode })
);

export const playerAddTaskSelector = createAppSelector(
  [
    (state) => state.toDo.newTodoPlayerTask,
    (state) => state.toDo.player,
    (state) => state.toDo.isCreatingToDo
  ],
  (newTodoPlayerTask, player, isCreatingToDo) => ({
    newTodoPlayerTask,
    player,
    isCreatingToDo
  })
);

export const playerAddTaskModalBodySelector = createAppSelector(
  [
    (state) => state.toDo.isCreatingToDo,
    (state) => state.setting.isUserOnboarding,
    (state) => state.setting.themeMode
  ],
  (isCreatingToDo, isUserOnboarding, themeMode) => ({
    isCreatingToDo,
    isUserOnboarding,
    themeMode
  })
);

export const brainDumpSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.toDo.brainDumpTasks.slice(),
    (state) => state.toDo.isConvertingBrianDump,
    (state) => state.toDo.showBrainDumpTasksModal,
    (state) => state.setting.themeMode,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (
    platform,
    brainDumpTasks,
    isConvertingBrianDump,
    showBrainDumpTasksModal,
    themeMode,
    userId,
    userEmail
  ) => ({
    platform,
    brainDumpTasks,
    isConvertingBrianDump,
    showBrainDumpTasksModal,
    themeMode,
    userId,
    userEmail
  })
);

export const brainDumpConvertedTasksModalSelector = createAppSelector(
  [
    (state) => state.toDo.brainDumpTasks.slice(),
    (state) => state.toDo.isAddingConvertedTasksToPlayer,
    (state) => state.toDo.player.tasks.slice()
  ],
  (brainDumpTasks, isAddingConvertedTasksToPlayer, playerTasks) => ({
    brainDumpTasks,
    isAddingConvertedTasksToPlayer,
    playerTasks
  })
);

export const brainDumpItemSelector = createAppSelector(
  [
    (state) => state.toDo.isAddingConvertedTasksToPlayer,
    (state) => state.setting.themeMode
  ],
  (isAddingConvertedTasksToPlayer, themeMode) => ({
    isAddingConvertedTasksToPlayer,
    themeMode
  })
);

export const playerTaskItemSelector = createAppSelector(
  [(state) => state.toDo.player],
  (player) => ({
    ...player
  })
);

export const searchedTasksSelector = createAppSelector(
  [
    (state) => state.toDo.player.isSearchingToDos,
    (state) => state.toDo.player.searchResults?.slice(),
    (state) => state.toDo.player.recentToDos?.slice(),
    (state) => state.setting.themeMode
  ],
  (isSearchingToDos, searchResults, recentToDos, themeMode) => ({
    isSearchingToDos,
    searchResults,
    recentToDos,
    themeMode
  })
);

export const recentTasksSelector = createAppSelector(
  [
    (state) => state.toDo.player.recentToDos?.slice(),
    (state) => state.setting.themeMode,
    (state) => state.toDo.player.tasks?.slice()
  ],
  (recentToDos, themeMode, tasks) => ({ recentToDos, themeMode, tasks })
);

export const addTaskBtnSelector = createAppSelector(
  [
    (state) => state.toDo.player.tasks?.slice(),
    (state) => state.setting.themeMode,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (tasks, themeMode, userId, userEmail) => ({
    tasks,
    themeMode,
    userId,
    userEmail
  })
);

export const selectedTaskActionsSelector = createAppSelector(
  [(state) => state.toDo.player],
  (player) => player
);

export const playerTasksListSelector = createAppSelector(
  [(state) => state.toDo.player],
  (player) => player
);

export const playerTaskItem = createAppSelector(
  [(state) => state.toDo.player],
  (player) => ({
    ...player
  })
);

export const playerTaskStatusSelector = createAppSelector(
  [
    (state) => state.toDo.player.selectedTask,
    (state) => state.toDo.player.tasksPlayedIds?.slice(),
    (state) => state.toDo.player.tasks?.slice()
  ],
  (selectedTask, tasksPlayedIds, tasks) => ({
    selectedTask,
    tasksPlayedIds,
    tasks
  })
);

export const newTaskSelector = createAppSelector(
  [
    (state) => state.focusMode.tags,
    (state) => state.toDo.isCreatingToDo,
    (state) => state.toDo.shouldResetNewTaskForm,
    (state) => state.toDo.editing.active,
    (state) => state.toDo.editing.task,
    (state) => state.toDo.isUpdatingToDo,
    (state) => state.toDo.newTask,
    (state) => state.toDo.showTodoSubTasksModal,
    (state) => state.modal.toDoSubTasks.subTasks?.slice(),
    (state) => state.setting.themeMode.slice() as THEME_OPTION,
    (state) => state.focusMode.areTagsFetching
  ],
  (
    tags,
    isCreatingToDo,
    shouldResetNewTaskForm,
    active,
    task,
    isUpdatingToDo,
    newTask,
    showTodoSubTasksModal,
    subTasks,
    themeMode,
    areTagsFetching
  ) => ({
    tags,
    isCreatingToDo,
    shouldResetNewTaskForm,
    active,
    task,
    isUpdatingToDo,
    newTask,
    showTodoSubTasksModal,
    subTasks,
    themeMode,
    areTagsFetching
  })
);

export const playerIntentionSelector = createAppSelector(
  [
    (state) => state.toDo.player.intention.replaceAll('"', ''),
    (state) => state.setting.themeMode,
    (state) => state.setting.platform
  ],
  (intention, themeMode, platform) => ({ intention, themeMode, platform })
);

export const playerTotalDurationSelector = createAppSelector(
  [
    (state) => state.toDo.player.total_duration,
    (state) => state.setting.themeMode
  ],
  (total_duration, themeMode) => ({ total_duration, themeMode })
);

export const playerSelectedTaskCompletionPopupSelector = createAppSelector(
  [(state) => state.toDo.player],
  (player) => player
);
