import React, {
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
  memo
} from 'react';
import { TabBodyRowProps } from 'interfaces';
import { Mode } from 'constants/enum';
import { ACTIVITY_TYPES, EMPTY_STRING, NUMBERS } from 'constants/general';
import { useAppSelector } from 'store/hooks';
import { getRoutineActivitiesOfCurrentSettings } from 'utils/support';
import TabBodyRowDuration from './tabBodyRowDuration';
import TabBodyRowActions from './tabBodyRowActions';
import TabBodyRowCompletionRequirements from './tabBodyRowCompletionRequirements';
import TabBodyRowName from './tabBodyRowName';
import { ActivityType } from 'interfaces/commonInterface';
import { hasCompletionRequirement } from 'utils/validation';

interface WrapperProps {
  children: React.ReactNode;
  type: string;
  position: number;
  mode?: Mode;
}

export const TabBodyRowContext = React.createContext<{
  type: string;
  position: number;
  currentActivity?: ActivityType;
  shouldUseTimer: boolean;
  setShouldUseTimer?: Dispatch<SetStateAction<boolean>>;
  isActivityFromHabitPack: boolean;
  isEditingAllowed: boolean;
}>({
  type: EMPTY_STRING,
  position: NUMBERS.ZERO,
  shouldUseTimer: true,
  isActivityFromHabitPack: false,
  isEditingAllowed: false
});

const Wrapper = ({ children, type, position, mode }: WrapperProps) => {
  const { currentSettings, activityLibrary } = useAppSelector(
    (state) => state.setting
  );
  const routineActivities = getRoutineActivitiesOfCurrentSettings(
    type,
    currentSettings
  );
  const currentActivity =
    mode && mode === Mode.LIBRARY
      ? activityLibrary[position]
      : routineActivities[position];

  const [shouldUseTimer, setShouldUseTimer] = useState(
    !hasCompletionRequirement(currentActivity)
  );

  const isActivityFromHabitPack = Boolean(
    currentActivity?.activity_template_id
  );

  const isEditingAllowed = useMemo(() => {
    if ((mode ?? Mode.DEFAULT) === Mode.DEFAULT) {
      return !isActivityFromHabitPack;
    } else if (mode === Mode.LIBRARY) {
      return currentActivity?.activity_type === ACTIVITY_TYPES.LIBRARY;
    } else {
      return true;
    }
  }, [mode, currentActivity]);

  return (
    <div
      className={`w-full flex flex-col lg:flex-row items-start justify-start md:justify-between ${isEditingAllowed ? 'gap-2.5' : 'gap-3'} xl:gap-4 cursor-auto ${
        type === ACTIVITY_TYPES.LIBRARY && 'py-2'
      } relative`}
    >
      <TabBodyRowContext.Provider
        value={useMemo(
          () => ({
            type,
            position,
            currentActivity,
            shouldUseTimer,
            setShouldUseTimer,
            isActivityFromHabitPack,
            isEditingAllowed
          }),
          [
            position,
            shouldUseTimer,
            currentActivity,
            isEditingAllowed,
            isActivityFromHabitPack,
            currentSettings
          ]
        )}
      >
        {children}
      </TabBodyRowContext.Provider>
    </div>
  );
};

const TabBodyRowContent = () => {
  const { shouldUseTimer } = useContext(TabBodyRowContext);

  return (
    <div className='w-full lg:w-3/5 xl:w-[45%] flex flex-col md:flex-row gap-5 items-center'>
      {shouldUseTimer ? (
        <TabBodyRowDuration />
      ) : (
        <TabBodyRowCompletionRequirements />
      )}
      <TabBodyRowActions />
    </div>
  );
};

const TabBodyRow = ({ type, position, mode }: TabBodyRowProps) => (
  <Wrapper type={type} position={position} mode={mode}>
    <TabBodyRowName />
    <TabBodyRowContent />
  </Wrapper>
);

export default memo(TabBodyRow);
