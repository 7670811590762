import React from 'react';
import { useAppSelector } from 'store/hooks';
import { ACTIVITY_TYPES } from 'constants/general';
import DayOfWeek from './DayOfWeek';
import Priority from './Priority';
import IncludeInEveryBreak from './IncludeInEveryBreak';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='w-full flex flex-row flex-wrap gap-4 md:gap-10 py-2'>
    {children}
  </div>
);

const Timing = () => {
  const { type } = useAppSelector((state) => state.setting.moreOptions);

  return (
    <Wrapper>
      {type !== ACTIVITY_TYPES.BREAKING ? (
        <>
          <Priority />
          <DayOfWeek />
        </>
      ) : (
        <IncludeInEveryBreak />
      )}
    </Wrapper>
  );
};

export default Timing;
