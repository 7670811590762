import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_VARIANT } from 'constants/enum';
import { POST_HOG_EVENT } from 'constants/events';
import { ROUTINE_SUGGESTION_WIZARD_STEP } from 'constants/general';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { routineSuggestionsWizardSelector } from 'store/reducer/setting/selectors';
import { captureEvents } from 'utils/events';

type WizardNavigationProps = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
};

const WizardNavigation = ({ step, setStep }: WizardNavigationProps) => {
  const {
    routineSuggestionPayload: { routines, user_goals },
    platform,
    userEmail,
    userId
  } = useAppSelector(routineSuggestionsWizardSelector);

  const shouldDisableContinue = useMemo(() => {
    if (step === ROUTINE_SUGGESTION_WIZARD_STEP.GOALS) {
      return !user_goals.length;
    } else if (step === ROUTINE_SUGGESTION_WIZARD_STEP.ROUTINES) {
      return !routines.some((routine) => routine.value);
    } else {
      return true;
    }
  }, [step, routines, user_goals]);

  const handleContinue = () => {
    let eventName =
      POST_HOG_EVENT.ROUTINE_SUGGESTIONS_ROUTINE_DURATION_CONTINUE_BUTTON_CLICKED;
    if (step === ROUTINE_SUGGESTION_WIZARD_STEP.GOALS) {
      eventName =
        POST_HOG_EVENT.ROUTINE_SUGGESTIONS_GOALS_SELECTION_CONTINUE_BUTTON_CLICKED;
    } else if (step === ROUTINE_SUGGESTION_WIZARD_STEP.ROUTINES) {
      eventName =
        POST_HOG_EVENT.ROUTINE_SUGGESTIONS_ROUTINE_SELECTION_CONTINUE_BUTTON_CLICKED;
    }
    captureEvents(eventName, userEmail, {
      platform,
      userId
    });
    setStep((prev) => ++prev);
  };

  return (
    <div className='w-fit flex items-center justify-center gap-12 sm:gap-14 md:gap-16 pt-6'>
      <ButtonFocusBear
        disabled={step === ROUTINE_SUGGESTION_WIZARD_STEP.GOALS}
        title={t('back')}
        onClick={() => setStep((prev) => --prev)}
        variant={BTN_FB_VARIANT.LINK}
        disableResize
        additionalStyles={`${step !== ROUTINE_SUGGESTION_WIZARD_STEP.GOALS ? 'inline-flex' : 'hidden'} animate-fadeIn text-xl`}
      />
      <ButtonFocusBear
        key={step}
        disabled={shouldDisableContinue}
        title={t('continue')}
        onClick={handleContinue}
        variant={BTN_FB_VARIANT.PRIMARY}
        disableResize
        additionalStyles={`${step < ROUTINE_SUGGESTION_WIZARD_STEP.DURATION ? 'inline-flex' : 'hidden'} animate-fadeIn text-xl`}
      />
    </div>
  );
};

export default WizardNavigation;
