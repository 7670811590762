import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';
import { MAXIMUM_ALLOWED_MINUTES } from 'constants/general';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getRoutineSuggestionActivities } from 'store/reducer/setting/extra';
import { routineOptionsSelector } from 'store/reducer/setting/selectors';
import { updateRoutineSuggestionPayload } from 'store/reducer/setting/slice';
import { getRoutineValue } from 'utils/support';
import { WizardSectionWrapper } from '.';
import { captureEvents } from 'utils/events';
import { POST_HOG_EVENT } from 'constants/events';

const RoutineOptions = ({
  setStep
}: {
  setStep: Dispatch<SetStateAction<number>>;
}) => {
  const dispatch = useAppDispatch();
  const {
    isFetchingRoutineSuggestions,
    routineSuggestionPayload,
    userId,
    userEmail,
    platform
  } = useAppSelector(routineOptionsSelector);

  const handleSuggestions = async () => {
    captureEvents(
      POST_HOG_EVENT.ROUTINE_SUGGESTIONS_SUGGEST_HABITS_FOR_ME_BUTTON_CLICKED,
      userEmail,
      { platform, userId }
    );
    const routine = getRoutineValue(routineSuggestionPayload.routines);
    const { routines, user_goals, ...rest } = routineSuggestionPayload;
    await dispatch(
      getRoutineSuggestionActivities({
        ...rest,
        routine: routines.every((routine) => routine.value)
          ? undefined
          : routine,
        user_goals
      })
    );
    setStep((prev) => ++prev);
  };

  return (
    <WizardSectionWrapper>
      <div className='w-full flex flex-col gap-6 text-lg sm:text-xl md:text-2xl'>
        <p className='text-lg sm:text-xl md:text-2xl text-center'>
          {t('routine_suggestion.how_much_time_do_you_have')}
        </p>
        <div className='w-11/12 sm:w-2/12 self-center relative'>
          <input
            max={MAXIMUM_ALLOWED_MINUTES}
            type='number'
            value={routineSuggestionPayload?.routine_duration}
            onChange={({ target: { value } }) => {
              const duration = parseInt(value);
              (isNaN(duration) || duration <= MAXIMUM_ALLOWED_MINUTES) &&
                dispatch(
                  updateRoutineSuggestionPayload({
                    ...routineSuggestionPayload,
                    routine_duration: parseInt(value)
                  })
                );
            }}
            className='w-full text-center  accent-sunRayFirst rounded-md shadow text-black outline-none'
            step={1}
            list='routineSuggestedMinutes'
            disabled={isFetchingRoutineSuggestions}
            min={1}
          />
          <p className='absolute right-0 -bottom-6 min-w-max text-base sm:text-lg'>
            {t('minutes')}
          </p>
        </div>
      </div>
      <div className='w-full flex flex-col items-center gap-3 mt-10'>
        <p className='text-sm sm:text-base'>
          {t('routine_suggestion.focus_bear_will_suggest_habits')}
        </p>
        {isFetchingRoutineSuggestions ? (
          <div className='w-7 sm:w-8 h-7 sm:h-8 rounded-full border-4 border-t-sunRayFirst animate-spin my-2'></div>
        ) : (
          <ButtonFocusBear
            disabled={!routineSuggestionPayload.routine_duration}
            title={t('routine_suggestion.suggest_habits_for_me')}
            onClick={handleSuggestions}
            size={BTN_FB_SIZE.LARGE}
          />
        )}
      </div>
    </WizardSectionWrapper>
  );
};

export default RoutineOptions;
