import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import {
  ALL_APPS_POST_MESSAGE_DATA,
  MAC_APP_DATA_LOCATION
} from 'constants/general';
import { t } from 'i18next';
import { HabitPackType } from 'interfaces/commonInterface';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { installHabitPack } from 'store/reducer/habit-pack/extra';
import { updateIsSendingInstalledHabitPackId } from 'store/reducer/habit-pack/slice';
import { routineHabitPackDetailsSelector } from 'store/reducer/setting/selectors';
import { useWindowSize } from 'usehooks-ts';
import {
  createHtml,
  getYouTubePlayerHeight,
  getYoutubeEmbeddedURL
} from 'utils/support';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { captureEvents } from 'utils/events';
import { MOBILE_APP_EVENT, POST_HOG_EVENT } from 'constants/events';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import { isRouteEmbedded } from 'utils/validation';

const SelectedHabitPackDetails = ({
  onboardingHabitPack
}: {
  onboardingHabitPack: HabitPackType;
}) => {
  const dispatch = useAppDispatch();
  const {
    installingPack,
    platform,
    isSendingInstalledHabitPackId,
    userId,
    userEmail
  } = useAppSelector(routineHabitPackDetailsSelector);
  const { width } = useWindowSize();
  const height = useMemo(() => getYouTubePlayerHeight(width), [width]);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className='w-full flex flex-col items-center gap-0 p-5 shadow-md rounded-md'>
      <p className='text-sm sm:text-base md:text-lg mb-1 md:mb-2'>
        {onboardingHabitPack?.pack_name}
      </p>
      <div className='flex flex-col items-center gap-2'>
        <div
          className={`text-xs sm:text-sm md:text-base ${isExpanded ? 'line-clamp-none' : 'line-clamp-2'}`}
        >
          {createHtml(onboardingHabitPack?.description)}
        </div>
        {isRouteEmbedded ? (
          <ButtonFocusBear
            onClick={() => setIsExpanded(true)}
            title={t('read_more')}
            size={BTN_FB_SIZE.SMALL}
            variant={BTN_FB_VARIANT.SECONDARY}
            additionalStyles={isExpanded ? 'hidden' : ''}
          />
        ) : null}
      </div>
      {onboardingHabitPack?.description_video_url && (
        <iframe
          width='100%'
          height={height}
          src={getYoutubeEmbeddedURL(onboardingHabitPack.description_video_url)}
          title={onboardingHabitPack.pack_type}
          className='self-center mt-6'
        />
      )}
      {onboardingHabitPack && (
        <div className='flex items-center gap-4 p-2 mt-2'>
          {installingPack ? (
            <div className='w-5 h-5 rounded-full border-t-2 border-black animate-spin'></div>
          ) : (
            <ButtonFocusBear
              disabled={isSendingInstalledHabitPackId}
              title={t('routine_suggestion.install_habit_pack')}
              onClick={async () => {
                captureEvents(
                  POST_HOG_EVENT.ROUTINE_SUGGESTIONS_INSTALL_HABIT_PACK_BUTTON_CLICKED,
                  userEmail,
                  { platform, userId }
                );
                const response = await dispatch(
                  installHabitPack(onboardingHabitPack?.id)
                );
                if (response.meta.requestStatus === 'fulfilled') {
                  dispatch(updateIsSendingInstalledHabitPackId(true));
                  sendDataToPlatform(
                    platform,
                    ALL_APPS_POST_MESSAGE_DATA.ROUTINE_SUGGESTIONS_INSTALLED_SUCCESSFULLY,
                    MAC_APP_DATA_LOCATION.SEND_SELECTED_HABIT_PACK_ID,
                    MOBILE_APP_EVENT.SEND_SELECTED_HABIT_PACK_ID
                  );
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SelectedHabitPackDetails;
