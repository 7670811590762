const quotes = [
  {
    quote: 'Your uniqueness is your superpower.',
    author: 'Chris Packham (naturalist/presenter - autism)',
  },
  {
    quote:
      'Do not judge me by my successes, judge me by how many times I fell down and got back up again.',
    author: 'Richard Branson (Virgin Group founder - dyslexia)',
  },
  {
    quote: "It's not a disability, it's a different ability.",
    author: 'Stuart Duncan ("AutismFather" developer - autism)',
  },
  {
    quote:
      "Normal is not something to aspire to, it's something to get away from.",
    author: "Jodie Foster (actress - asperger's syndrome)",
  },
  {
    quote:
      "Embrace your uniqueness. Time is much too short to be living someone else's life.",
    author: 'Keri Smith (artist - dyslexia)',
  },
  {
    quote: "You don't have to be 'normal' to be happy and successful.",
    author: "John Elder Robison (writer - Asperger's syndrome)",
  },
  {
    quote:
      'The more I give myself permission to live in the moment and enjoy it without feeling guilty or judgmental about any other time, the better I feel.',
    author: 'Ellen Forney (cartoonist and author - bipolar disorder)',
  },
  {
    quote: 'I want to encourage people to be who they are.',
    author: 'Michael McCreary (comedian actor - autism)',
  },
  {
    quote: 'Being different is a badge of honor.',
    author: 'Sara Westbrook (author - dyslexia)',
  },
  {
    quote:
      "I'm not different for the sake of being different, only for the desperate sake of being myself.",
    author: "Vivian Stanshall (artist - Asperger's syndrome)",
  },
  {
    quote:
      'No matter what challenges you face, let your passion guide you to success.',
    author: "Adam Young (singer - Asperger's syndrome)",
  },
  {
    quote:
      "Don't let anyone put limitations on your dreams. Sing your heart out and defy the odds.",
    author: "Clay Aiken (singer - Asperger's syndrome)",
  },
  {
    quote:
      "Embrace your quirks and let them shine. That's what makes you special.",
    author: 'Billie Eilish (Singer - Tourettes)',
  },
  {
    quote:
      'I may have ADHD, but my creativity knows no bounds. Let your imagination run wild.',
    author: 'Justin Timberlake (singer - ADHD)',
  },
  {
    quote:
      "Autism is not a barrier to success. It's a unique perspective that can fuel your creativity.",
    author: 'Matt Savage (musician - autism)',
  },
  {
    quote: 'Your talent and determination will speak louder than any words.',
    author: 'Cher (singer - dyslexia)',
  },
  {
    quote:
      'ADHD may bring chaos, but it also brings an incredible ability to hyperfocus and create magic.',
    author: 'will.i.am (musician - ADHD)',
  },
  {
    quote:
      'The key to success is embracing your uniqueness and leveraging it to make a difference.',
    author: 'Daniel Tammet (writer - autism)',
  },
  {
    quote:
      "Embrace your atypical mind. It's a wellspring of ideas waiting to be unleashed.",
    author: 'Dr. Laurent Mottron (psychiatrist - autism)',
  },
  {
    quote:
      'When I was younger I was looking for this magic meaning of life. It’s very simple now. Making the lives of others better, doing something of lasting value. That’s the meaning of life, it’s that simple.',
    author: 'Dr. Temple Grandin (animal scientist - autism)',
  },
  {
    quote:
      'Recognizing and respecting differences in others, and treating everyone like you want them to treat you, will help make our world a better place for everyone.',
    author: 'Kim Peek (mega-savant - savant syndrome)',
  },
  {
    quote:
      'My philosophy is: It’s none of my business what people say of me and think of me. I am what I am, and I do what I do. I expect nothing and accept everything. And it makes life so much easier.',
    author: "Sir Anthony Hopkins (actor - Asperger's syndrome)",
  },
  {
    quote:
      'It does not matter what sixty-six percent of people do in any particular situation. All that matters is what you do.',
    author: 'John Elder Robinson (author - autism)',
  },
  {
    quote: 'The best way to predict the future is to create it.',
    author: 'Bill Gates (Microsoft cofounder - dyslexia &ADHD)',
  },
  {
    quote:
      "Don't compare yourself with anyone in this world. If you do so, you are insulting yourself.",
    author: 'Bill Gates (Microsoft cofounder - dyslexia &ADHD)',
  },
  {
    quote:
      "Don't be afraid to take a big step if one is indicated. You can't cross a chasm in two small jumps.",
    author: 'Bill Gates (Microsoft cofounder - dyslexia &ADHD)',
  },
  {
    quote:
      "I don't want other people to decide who I am. I want to decide that for myself.",
    author: 'Emma Watson (Actress - ADHD)',
  },
  {
    quote: 'If not me, who? If not now, when?',
    author: 'Emma Watson (Actress - ADHD)',
  },
  {
    quote:
      "It's not the absence of fear, it's overcoming it. Sometimes you've got to blast through and have faith.",
    author: 'Emma Watson (Actress - ADHD)',
  },
  {
    quote:
      "Don't let anyone tell you what you can and can't do or achieve. Do what you want to do and be who you want to be.",
    author: 'Emma Watson (Actress - ADHD)',
  },
  {
    quote:
      "I'm very interested in finding truth, in finding ways to be messy and unsure and flawed and incredible and great and my fullest self, all wrapped into one.",
    author: 'Emma Watson (Actress - ADHD)',
  },
  {
    quote:
      'I truly, truly believe that beauty is something that comes from within.',
    author: 'Emma Watson (Actress - ADHD)',
  },
  {
    quote: 'Be yourself, because an original is worth more than a copy.',
    author: 'Tim Burton (Filmmaker - Autism)',
  },
  {
    quote:
      "Visions are worth fighting for. Why spend your life making someone else's dreams?",
    author: 'Tim Burton (Filmmaker - Autism)',
  },
  {
    quote:
      'You have to keep the faith and trust that the unique idea you have is worth following.',
    author: 'Tim Burton (Filmmaker - Autism)',
  },
  {
    quote: 'Imagination is more important than knowledge.',
    author: 'Albert Einstein (Physicist - ADHD, Dyslexia & Autism)',
  },
  {
    quote:
      'Try not to become a man of success, but rather try to become a man of value.',
    author: 'Albert Einstein (Physicist - ADHD, Dyslexia & Autism)',
  },
  {
    quote: 'In the middle of difficulty lies opportunity.',
    author: 'Albert Einstein (Physicist - ADHD, Dyslexia & Autism)',
  },
  {
    quote:
      'Learn from yesterday, live for today, hope for tomorrow. The important thing is not to stop questioning.',
    author: 'Albert Einstein (Physicist - ADHD, Dyslexia & Autism)',
  },
  {
    quote:
      'Life is like riding a bicycle. To keep your balance, you must keep moving.',
    author: 'Albert Einstein (Physicist - ADHD, Dyslexia & Autism)',
  },
  {
    quote: "Don't be afraid to fail. Be afraid not to try.",
    author: 'Simone Biles (Olympic gymnast - ADHD)',
  },
  {
    quote: "Hard days are the best because that's when champions are made.",
    author: 'Simone Biles (Olympic gymnast - ADHD)',
  },
  {
    quote: 'Practice creates confidence. Confidence empowers you.',
    author: 'Simone Biles (Olympic gymnast - ADHD)',
  },
  {
    quote:
      "Luck is not chance, it's toil; fortune's expensive smile is earned.",
    author: 'Emily Dickinson (Poet - Autism)',
  },
  {
    quote: 'The brain is wider than the sky.',
    author: 'Emily Dickinson (Poet - Autism)',
  },
  {
    quote: 'The only way to do great work is to love what you do.',
    author: 'Steve Jobs (Apple founder - Dyslexia)',
  },
  {
    quote:
      'The people who are crazy enough to think they can change the world are the ones who do.',
    author: 'Steve Jobs (Apple founder - Dyslexia)',
  },
  {
    quote:
      "Don't let the noise of others' opinions drown out your own inner voice.",
    author: 'Steve Jobs (Apple founder - Dyslexia)',
  },
  {
    quote:
      'You are stronger than you think, and you have the power to overcome any challenge.',
    author: 'Cara Delevingne (Model - Dyspraxia & ADHD)',
  },
  {
    quote:
      "Remember to take care of yourself, both physically and mentally. Self-care is not selfish, it's necessary.",
    author: 'Cara Delevingne (Model - Dyspraxia & ADHD)',
  },
];

export default quotes;
