import { Mode } from 'constants/enum';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';

const TabBodyRowNotice = ({ message }: { readonly message: string }) => {
  const { themeMode, mode } = useAppSelector((state) => state.setting);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  return (
    <p
      className={`w-5/6 h-fit max-h-4 absolute ${
        mode === Mode.LIBRARY ? '-bottom-5' : '-bottom-1'
      } left-0 text-[0.65em] font-medium ${
        !shouldActivateDarkMode && 'text-blue-600'
      } self-end truncate`}
    >
      {message}
    </p>
  );
};

export default TabBodyRowNotice;
