import { FormEvent, useRef, MutableRefObject } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import {
  FOCUS_MODE_ALLOWED_TYPE,
  FOCUS_TEMPLATE_FEATURE
} from 'constants/general';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import { updateCurrentViewedFocusTemplate } from 'store/reducer/focus-mode/slice';

interface AllowedTypeProps {
  allowedItemsType: string;
  title: string;
}

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='flex md:flex-row flex-col justify-around items-center gap-2 mt-4'>
    {children}
  </div>
);

const AllowedItems = ({ allowedItemsType, title }: AllowedTypeProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    focusMode: { currentViewedFocusTemplate }
  } = useAppSelector((state) => state);
  const isAllowedURLsExists = allowedItemsType === FOCUS_MODE_ALLOWED_TYPE.URLS;
  const inputValue =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const items = isAllowedURLsExists
    ? currentViewedFocusTemplate?.allowed_urls
    : currentViewedFocusTemplate?.allowed_apps;

  const handleAddItem = (event: FormEvent) => {
    event.preventDefault();
    if (inputValue.current?.value !== '') {
      dispatch(
        updateCurrentViewedFocusTemplate({
          focusTemplateFeature: isAllowedURLsExists
            ? FOCUS_TEMPLATE_FEATURE.ALLOWED_URLS
            : FOCUS_TEMPLATE_FEATURE.ALLOWED_APPS,
          value: [...(items ?? []), inputValue.current.value]
        })
      );
      inputValue.current.value = '';
    }
  };

  const handleDeleteItem = (id: number) => {
    const res = items?.filter((_: string, index: number) => index !== id);
    dispatch(
      updateCurrentViewedFocusTemplate({
        focusTemplateFeature: isAllowedURLsExists
          ? FOCUS_TEMPLATE_FEATURE.ALLOWED_URLS
          : FOCUS_TEMPLATE_FEATURE.ALLOWED_APPS,
        value: res ?? []
      })
    );
  };

  return (
    <div className='md:w-[45%] w-[90%] px-2'>
      <h3 className='text-lg'>{title}</h3>
      <form
        className='w-full] mb-2 flex justify-between'
        onSubmit={handleAddItem}
      >
        <input
          ref={inputValue}
          className='w-[87%] outline-none px-2 py-1 rounded-sm'
          placeholder={t('focus_mode.enter_url')}
        />
        <button className='w-[10%] bg-gray-400 text-white text-xl rounded-sm cursor-pointer'>
          +
        </button>
      </form>
      <div className='px-1 border-2 py-2 rounded-sm bg-gray-200 h-[280px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 overflow-y-scroll'>
        {items
          ?.map((url: string, index: number) => ({ position: index, url }))
          ?.map(({ url, position }) => (
            <div
              key={position}
              className='flex justify-between border-b-[1px] border-gray-500 py-1'
            >
              <p className='w-[90%] text-sm break-all'>{url}</p>
              <button onClick={() => handleDeleteItem(position)}>
                <CloseCircle fill={COLOR.RED} />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default function FocusModeAllowedAppsAndURLs() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <AllowedItems
        title={t('title_allowed_url')}
        allowedItemsType={FOCUS_MODE_ALLOWED_TYPE.URLS}
      />
      <AllowedItems
        title={t('focus_mode.allowed_apps')}
        allowedItemsType={FOCUS_MODE_ALLOWED_TYPE.APPS}
      />
    </Wrapper>
  );
}
