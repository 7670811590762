import { isYoutubeURL } from 'utils/validation';
import HelpFilled from 'assets/icons/HelpFilled';

interface InputWithLabelProps {
  label: string;
  tooltip?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type?: string;
  required?: boolean;
}

export default function InputWithLabel({
  label,
  tooltip,
  handleChange,
  value,
  type = 'URL',
  required = false
}: InputWithLabelProps) {
  const isRequiredButEmpty = required && value;
  const hasUrlThatsInvalid = value === '' ? true : isYoutubeURL(value);

  const determineBorderColor = () => {
    if (type !== 'URL' && !isRequiredButEmpty) {
      return 'border border-red-400';
    } else if (type === 'URL' && !hasUrlThatsInvalid) {
      return 'border border-red-400';
    } else {
      return '';
    }
  };

  return (
    <div className='flex flex-col'>
      <div className='flex'>
        <h6 className='text-xs 2xl:text-sm 4xl:text-base pb-0.5 text-gray-600 font-semibold mr-2'>
          {label}
        </h6>
        {tooltip && (
          <div className='group relative'>
            <HelpFilled />
            <span className='rounded absolute hidden bg-black text-white mt-1 p-1 group-hover:block w-56 text-xs'>
              <p>{tooltip}</p>
            </span>
          </div>
        )}
      </div>
      <input
        className={`py-2 px-3 mb-2 rounded text-sm 2xl:text-base 4xl:text-lg font-semibold outline-none ${determineBorderColor()}`}
        type='text'
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}
