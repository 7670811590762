import {
  useState,
  useContext,
  createContext,
  useRef,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import InputURL from './InputURL';
import { ModalProps } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES, EMPTY_STRING } from 'constants/general';
import { getModalUrls } from 'utils/support';
import { createAppSelector, useAppDispatch, useAppSelector } from 'store/hooks';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalHeader from 'components/shared/ModalHeader';
import {
  updateMoreOptionShowYouTubeURLModal,
  updateMoreOptionShowAllowedURLModal,
  updateMoreOptionURLs,
  updateChoicesVideoURLs,
  updateChoicesShowYouTubeURLModal
} from 'store/reducer/modal/slice';
import { ATTRIB } from 'constants/test';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import _ from 'lodash';
import { THEME_OPTION } from 'constants/enum';
import { isYoutubeURL } from 'utils/validation';

interface WrapperProps {
  children: React.ReactNode;
  urls: string[];
  modalType: string;
}

export const URLModalContext = createContext<{
  habitUrls: string[];
  modalType: string;
  setHabitUrls?: Dispatch<SetStateAction<string[]>>;
}>({ habitUrls: [], modalType: EMPTY_STRING });

const Wrapper = ({ children, urls, modalType }: WrapperProps) => {
  const [habitUrls, setHabitUrls] = useState(urls);
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`relative top-[40%] -translate-y-[40%] mx-auto w-[95%] sm:w-3/5 md:w-2/5 shadow-lg ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-600' : 'bg-gray-200'
      } flex flex-col gap-2 rounded-md`}
    >
      <URLModalContext.Provider
        value={useMemo(
          () => ({ habitUrls, modalType, setHabitUrls }),
          [habitUrls, modalType]
        )}
      >
        {children}
      </URLModalContext.Provider>
    </div>
  );
};

const EmptyURLs = () => {
  const { t } = useTranslation();
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`w-full flex justify-center text-xs font-semibold ${
        themeMode === THEME_OPTION.DARK ? 'text-blue-200' : 'text-blue-600'
      } '`}
    >
      {t('no_urls_found')}
    </div>
  );
};

const URLModalActions = () => {
  const { t } = useTranslation();
  const { habitUrls, modalType, setHabitUrls } = useContext(URLModalContext);
  const isModalEitherYoutubeOrChoiceYoutubeUrls =
    modalType === MODAL_TYPES.YOUTUBE_URLS ||
    modalType === MODAL_TYPES.CHOICE_YOUTUBE;
  const dispatch = useAppDispatch();
  const { activity, choices, selectedPosition } = useAppSelector(
    createAppSelector(
      [
        (state) => state.modal.moreOption.activity,
        (state) => state.modal.choice.choices,
        (state) => state.modal.choice.selectedPosition
      ],
      (activity, choices, selectedPosition) => ({
        activity,
        choices,
        selectedPosition
      })
    )
  );

  const handleSave = () => {
    const checkInvalidUrls = habitUrls.some(
      (item) =>
        item === '' ||
        ((modalType === MODAL_TYPES.YOUTUBE_URLS ||
          modalType === MODAL_TYPES.CHOICE_YOUTUBE) &&
          !isYoutubeURL(item))
    );
    if (!checkInvalidUrls) {
      if (modalType === MODAL_TYPES.YOUTUBE_URLS) {
        dispatch(
          updateMoreOptionURLs({
            modal_type: MODAL_TYPES.YOUTUBE_URLS,
            urls: habitUrls
          })
        );
        dispatch(updateMoreOptionShowYouTubeURLModal(false));
      } else if (modalType === MODAL_TYPES.ALLOWED_URLS) {
        dispatch(
          updateMoreOptionURLs({
            modal_type: MODAL_TYPES.ALLOWED_URLS,
            urls: habitUrls
          })
        );
        dispatch(updateMoreOptionShowAllowedURLModal(false));
      } else if (
        modalType === MODAL_TYPES.CHOICE_YOUTUBE &&
        selectedPosition !== null
      ) {
        dispatch(
          updateChoicesVideoURLs({
            position: selectedPosition,
            value: habitUrls
          })
        );
        dispatch(updateChoicesShowYouTubeURLModal(false));
      }
    }
  };

  const shouldURLsEdited = () => {
    let oldURLs: string[] = [];
    if (modalType === MODAL_TYPES.YOUTUBE_URLS) {
      oldURLs = activity.video_urls ?? [];
    } else if (modalType === MODAL_TYPES.CHOICE_YOUTUBE) {
      oldURLs = choices[selectedPosition]?.video_urls ?? [];
    } else {
      oldURLs = activity?.allowed_urls ?? [];
    }
    return !_.isEqual(oldURLs, habitUrls);
  };

  const shouldURLsNotValid = useMemo(() => {
    const isValid =
      modalType === MODAL_TYPES.ALLOWED_URLS
        ? habitUrls.some((url) => url === '')
        : habitUrls.some((url) => !isYoutubeURL(url));
    const doURLsDuplicated = habitUrls.some(
      (url, index, arr) => arr.indexOf(url) !== index
    );
    return isValid || doURLsDuplicated;
  }, [habitUrls]);

  return (
    <div className='w-fit h-fit flex items-center gap-2 self-end pr-3 pb-2'>
      <button
        disabled={shouldURLsNotValid}
        data-test={
          isModalEitherYoutubeOrChoiceYoutubeUrls
            ? ATTRIB.TEST.ADD_YOUTUBE_URL
            : ATTRIB.TEST.ADD_ALLOWED_URL
        }
        onClick={() => setHabitUrls?.((prev) => [...prev, ''])}
        className='w-24 h-fit text-xs font-semibold tracking-wider py-1.5 self-end rounded-md text-white mr-2 mb-2 bg-gray-500 hover:bg-gray-700 cursor-pointer transition ease-in-out hover:scale-110 duration-200 disabled:text-gray-500 disabled:bg-gray-300 disabled:hover:bg-gray-300 disabled:hover:scale-100 disabled:cursor-default'
      >
        {t('add_url')}
      </button>
      {shouldURLsEdited() && (
        <button
          disabled={shouldURLsNotValid}
          data-test={
            isModalEitherYoutubeOrChoiceYoutubeUrls
              ? ATTRIB.TEST.SAVE_YOUTUBE_URLS
              : ATTRIB.TEST.SAVE_ALLOWED_URLS
          }
          onClick={handleSave}
          className='w-24 h-fit text-xs font-semibold tracking-wider rounded-md mr-2 mb-2 py-1.5 text-white bg-blue-600 hover:bg-blue-800 cursor-pointer transition ease-in-out hover:scale-110 duration-200 disabled:text-gray-500 disabled:bg-gray-300 disabled:hover:bg-gray-300 disabled:hover:scale-100 disabled:cursor-default'
        >
          {t('save')}
        </button>
      )}
    </div>
  );
};

const URLModalBody = () => {
  const { habitUrls, setHabitUrls, modalType } = useContext(URLModalContext);
  const dummyDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dummyDiv.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  }, [habitUrls?.length]);

  const handleDelete = (id: number): void => {
    setHabitUrls?.(
      habitUrls.filter((_: string, index: number) => index !== id)
    );
  };

  return (
    <div className='w-full max-h-80 sm:max-h-72 flex flex-col gap-1 px-5 py-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
      {habitUrls?.length ? (
        habitUrls
          .map((url, index) => ({ id: index, url }))
          .map(({ url, id }) => (
            <div key={id} className='flex items-center justify-center gap-5'>
              <InputURL id={id} url={url} modalType={modalType} />
              <button onClick={() => handleDelete(id)}>
                <CloseCircle fill={COLOR.RED} />
              </button>
            </div>
          ))
      ) : (
        <EmptyURLs />
      )}
      <div className='float-left clear-both' ref={dummyDiv}></div>
    </div>
  );
};

const URLModal = ({ modalType }: ModalProps) => {
  const { t } = useTranslation();
  const {
    modal: {
      moreOption: { activity },
      choice: { choices, selectedPosition }
    }
  } = useAppSelector((state) => state);
  const currentUrls = getModalUrls(
    modalType,
    activity,
    selectedPosition,
    choices
  );

  return (
    <ModalOverlay zIndex='z-[100]'>
      <Wrapper urls={currentUrls} modalType={modalType}>
        <ModalHeader
          title={
            modalType === MODAL_TYPES.YOUTUBE_URLS ||
            modalType === MODAL_TYPES.CHOICE_YOUTUBE
              ? t('title_youtube_url')
              : t('title_allowed_url')
          }
          modalType={modalType}
        />
        <URLModalBody />
        <URLModalActions />
        {modalType === MODAL_TYPES.ALLOWED_URLS && (
          <div className='w-full text-[11px] text-justify px-4 pb-3'>
            {t('allowed_url_description')}
          </div>
        )}
      </Wrapper>
    </ModalOverlay>
  );
};

export default URLModal;
