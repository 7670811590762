import { WeeklySummaryStatsTypes } from 'interfaces/userInterface';
import moment from 'moment';
import WeeklySummaryHoverDetails from './WeeklySummaryHoverDetails';

const WeeklySummaryRow = ({ stats }: { stats: WeeklySummaryStatsTypes }) => {
  return (
    <div className='flex items-center py-2 hover:bg-orange-200 cursor-pointer rounded-md px-2 group relative'>
      <p className='text-xs sm:text-sm lg:text-base min-w-[100px]'>
        {moment(stats.logged_date).format('ddd')}
      </p>
      <p className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px]'>
        {`${stats.morning_percentage}%`}
      </p>
      <p className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px]'>
        {`${stats.evening_percentage}%`}
      </p>
      <p className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px]'>
        {stats.completed_activities}
      </p>
      <p className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px]'>
        {stats.completed_focus_modes}
      </p>
      <WeeklySummaryHoverDetails stats={stats} />
    </div>
  );
};

export default WeeklySummaryRow;
