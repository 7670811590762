import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { ActivityType } from 'interfaces/commonInterface';
import { getActivityDuration, increment } from 'utils/support';
import { hasCompletionRequirement } from 'utils/validation';

const PageContent = ({ activities }: { activities: ActivityType[] }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.activityContent}>
      {(activities ?? []).map((habit, index: number) => {
        const duration = hasCompletionRequirement(habit)
          ? t('completion_requirements')
          : getActivityDuration(habit);
        return (
          <View key={habit.id} style={styles.activityContainer}>
            <View style={styles.activityItem} wrap={false}>
              <Text style={styles.activityItemText}>
                {increment(index)}. {habit.name}
              </Text>
              <Text style={styles.activityItemText}>{duration}</Text>
            </View>
            {habit.text_instructions && (
              <Text style={styles.activityInstructions}>
                {habit.text_instructions}
              </Text>
            )}
            {habit.image_urls?.length && (
              <View style={styles.activityImagesContainer}>
                {habit.image_urls.map((image, index) => (
                  <Image
                    style={styles.activityImage}
                    src={image.url}
                    key={`${image.url}${index}`}
                  />
                ))}
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  activityContainer: {
    borderBottom: '1px solid #000',
    paddingVertical: '5px'
  },
  activityContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'center'
  },
  activityItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '4px'
  },
  activityItemText: {
    fontSize: '14px',
    marginTop: '3px'
  },
  activityImagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '5px 0px'
  },
  activityImage: {
    width: '150px',
    paddingRight: '10px'
  },
  activityInstructions: {
    fontSize: '12px',
    width: '100%',
    textAlign: 'left'
  }
});

export default PageContent;
