import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import * as Sentry from '@sentry/react';
import focusBearApi from 'services/axios-config';
import Endpoints from 'constants/endpoints';
import {
  updateIsCreatingLessonCompletion,
  updateIsFetchingOtherCourses,
  updateIsFetchingEnrolledCourses,
  updateIsFetchingTutorials,
  updateIsLessonCompletionSuccess,
  updateIsLoadingCourseDetails,
  updateIsCreatingCourseEnrolment,
  updateIsUpdatingCourseCompletion,
  updateShowCourseLessonsModal
} from './slice';
import { HTTP_STATS_CODE } from 'constants/general';

export const getCourseDetails = createAsyncThunk(
  'course/getCourseDetails',
  async (course_id: string, { dispatch }) => {
    try {
      const { data } = await focusBearApi.get(
        Endpoints.COURSE_DETAILS(course_id)
      );
      return data ?? null;
    } catch (error) {
      toast.error(i18n.t('course.could_not_fetch_course_details'));
      dispatch(updateIsLoadingCourseDetails(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const createLessonCompletion = createAsyncThunk(
  'course/createLessonCompletion',
  async (
    { course_id, lesson_id }: { course_id: string; lesson_id: string },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsLessonCompletionSuccess(false));
      dispatch(updateIsCreatingLessonCompletion(true));
      const { status } = await focusBearApi.post(Endpoints.LESSON_COMPLETE, {
        course_id,
        lesson_id
      });
      status === HTTP_STATS_CODE.CREATED && dispatch(getEnrolledCourses());
      dispatch(updateIsCreatingLessonCompletion(false));
    } catch (error) {
      toast.error(i18n.t('course.could_not_update_user_progress'));
      dispatch(updateIsCreatingLessonCompletion(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getTutorials = createAsyncThunk(
  'course/get_tutorials',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsFetchingTutorials(true));
      const { data } = await focusBearApi.get(Endpoints.USER_TUTORIALS);
      return data ?? [];
    } catch (error) {
      dispatch(updateIsFetchingTutorials(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getEnrolledCourses = createAsyncThunk(
  'course/get_enrolled_courses',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsFetchingEnrolledCourses(true));
      const { data } = await focusBearApi.get(Endpoints.ENROLLED_COURSES);
      return data ?? [];
    } catch (error) {
      dispatch(updateIsFetchingEnrolledCourses(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getOtherCourses = createAsyncThunk(
  'course/get_other_courses',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsFetchingOtherCourses(true));
      const { data } = await focusBearApi.get(
        Endpoints.USER_NOT_ENROLLED_COURSES
      );
      return data ?? [];
    } catch (error) {
      dispatch(updateIsFetchingOtherCourses(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const createCourseEnrolment = createAsyncThunk(
  'course/create_course_enrolment',
  async (course_id: string, { dispatch }) => {
    try {
      dispatch(updateIsCreatingCourseEnrolment(true));
      const { status } = await focusBearApi.post(Endpoints.ENROLLED_COURSES, {
        course_id
      });
      if (status === HTTP_STATS_CODE.CREATED) {
        dispatch(updateIsCreatingCourseEnrolment(false));
        await Promise.allSettled([
          dispatch(getOtherCourses()),
          dispatch(getEnrolledCourses())
        ]);
      } else {
        toast.error(i18n.t('course.couldnt_process_the_request'));
      }
    } catch (error) {
      toast.error(i18n.t('course.couldnt_process_the_request'));
      dispatch(updateIsCreatingCourseEnrolment(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateCourseCompletion = createAsyncThunk(
  'course/create_course_enrolment',
  async (course_id: string, { dispatch }) => {
    try {
      dispatch(updateIsUpdatingCourseCompletion(true));
      const { status } = await focusBearApi.patch(Endpoints.ENROLLED_COURSES, {
        course_id,
        finished: true
      });
      if (status === HTTP_STATS_CODE.SUCCESS) {
        dispatch(updateIsUpdatingCourseCompletion(false));
        dispatch(getEnrolledCourses());
        dispatch(updateShowCourseLessonsModal(false));
      } else {
        toast.error(i18n.t('course.couldnt_process_the_request'));
      }
    } catch (error) {
      toast.error(i18n.t('course.couldnt_process_the_request'));
      dispatch(updateIsUpdatingCourseCompletion(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
