import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialPlatformIntegrationState } from 'store/initial-states';
import { getProjectTasks, getProjects } from './extra';
import { ExternalStatus } from 'interfaces';
import { NO_ELEMENT_FOUND } from 'constants/general';
import { Project } from 'interfaces/commonInterface';

const platformIntegration = createSlice({
  name: 'platform-integration',
  initialState: initialPlatformIntegrationState,
  reducers: {
    updateAreProjectsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.areProjectsLoading = payload;
    },
    updateIsProjectSynching: (state, { payload }: PayloadAction<boolean>) => {
      state.isProjectSynching = payload;
    },
    updateAreExternalStatusesSyncing: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.areExternalStatusesSyncing = payload;
    },
    updateShowPlatformProjects: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPlatformProjects = payload;
    },
    updateStatusToCountAsDone: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.statusToCountAsDone = payload;
    },
    updateCurrentProjectId: (state, { payload }: PayloadAction<string>) => {
      state.currentProjectId = payload;
    },
    updateCurrentPlatform: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.currentPlatform = payload;
    },
    updateProjectExternalStatuses: (
      state,
      {
        payload: { position, external_statuses }
      }: PayloadAction<{
        position: number;
        external_statuses: ExternalStatus[];
      }>
    ) => {
      if (position !== NO_ELEMENT_FOUND) {
        state.projects[position].external_statuses = external_statuses;
      }
    },
    updateProjectsToBeSynced: (state, { payload }: PayloadAction<string[]>) => {
      state.projectsToBeSynced = payload;
    },
    updateShowProjectTasksModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showProjectTasksModal = payload;
    },
    updateProjectTasksModal: (
      state,
      { payload }: PayloadAction<{ project?: Project; platform?: string }>
    ) => {
      state.projectTasksModal.project = payload.project;
      state.projectTasksModal.platform = payload.platform;
    },
    updateIsSynchingProjectTasks: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSynchingProjectTasks = payload;
    },
    updateIsLoadingProjectTasks: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingProjectTasks = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.projects = payload ?? [];
        state.areProjectsLoading = false;
        state.showPlatformProjects = true;
      })
      .addCase(getProjects.rejected, (state) => {
        state.projects = [];
        state.areProjectsLoading = false;
        state.showPlatformProjects = true;
      })
      .addCase(getProjectTasks.fulfilled, (state, { payload }) => {
        state.projectTasksModal.tasks = payload;
        state.isLoadingProjectTasks = false;
      });
  }
});

export const {
  updateAreProjectsLoading,
  updateIsProjectSynching,
  updateAreExternalStatusesSyncing,
  updateShowPlatformProjects,
  updateStatusToCountAsDone,
  updateCurrentProjectId,
  updateCurrentPlatform,
  updateProjectExternalStatuses,
  updateShowProjectTasksModal,
  updateIsSynchingProjectTasks,
  updateIsLoadingProjectTasks,
  updateProjectTasksModal,
  updateProjectsToBeSynced
} = platformIntegration.actions;
export default platformIntegration.reducer;
