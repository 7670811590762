import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateChoicesAreLogQuestionsInvalid,
  updateChoicesLogQuantity,
  updateChoicesLogQuantityQuestions,
  updateMoreOptionAreLogQuestionsInvalid,
  updateMoreOptionLogQuantity,
  updateMoreOptionLogQuantityQuestions
} from 'store/reducer/modal/slice';
import { ICONS, SWITCH } from 'constants/tag';
import LogQuestionInput from './LogQuestionInput';
import PlusCircle from 'assets/icons/PlusCircle';
import COLOR from 'constants/color';
import { DEFAULT_LOG_QUESTION } from 'assets/data';
import {
  EMPTY_STRING,
  HABIT_CHOICE_TYPE,
  MODAL_TYPES,
  NUMBERS,
  SCREEN
} from 'constants/general';
import HelpFilled from 'assets/icons/HelpFilled';
import { ChoiceItemContext } from 'components/setting-generator/tabs/ChoicesModal/ChoiceItem';
import { ATTRIB } from 'constants/test';
import LogQuantityQuestionDetails from './LogQuantityQuestionDetails';
import Tooltip from 'components/common/Tooltip';
import { SettingsPageContext } from 'components/setting-generator';
import { useWindowSize } from 'usehooks-ts';

interface WrapperProps {
  children: React.ReactNode;
  modalType: string;
}

interface LogQuantityQuestionsProps {
  modalType?: string;
}

export const LogQuantityQuestionContext = React.createContext({
  modalType: MODAL_TYPES.MORE_OPTION
});

const Wrapper = ({ children, modalType }: WrapperProps) => (
  <div
    className={`${
      modalType === MODAL_TYPES.MORE_OPTION
        ? 'w-[95%] md:w-[40%] lg:w-1/2 pl-2'
        : 'w-[95%]'
    } flex flex-col gap-2.5 py-2 border-[0.5px] border-gray-100 rounded-md`}
  >
    <LogQuantityQuestionContext.Provider
      value={useMemo(() => ({ modalType }), [])}
    >
      {children}
    </LogQuantityQuestionContext.Provider>
  </div>
);

const LogQuantityQuestionHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    moreOption: { activity },
    choice: { choices }
  } = useAppSelector((state) => state.modal);
  const { modalType } = useContext(LogQuantityQuestionContext);
  const { position } = useContext(ChoiceItemContext);
  const { width } = useWindowSize();
  const currentActivity =
    modalType === MODAL_TYPES.MORE_OPTION ? activity : choices[position];
  const switch_width =
    modalType === MODAL_TYPES.MORE_OPTION
      ? SWITCH.WIDTH.MORE_OPTION_LOG_QUANTITY_QUESTION
      : SWITCH.WIDTH.CHOICE_LOG_QUANTITY_QUESTION;
  const switch_height =
    modalType === MODAL_TYPES.MORE_OPTION
      ? SWITCH.HEIGHT.MORE_OPTION_LOG_QUANTITY_QUESTION
      : SWITCH.HEIGHT.CHOICE_LOG_QUANTITY_QUESTION;
  const settingPageContext = useContext(SettingsPageContext);
  const shouldActiveMobileView =
    (settingPageContext.width ?? width) <= SCREEN.WIDTH.SMALL;

  useEffect(() => {
    const shouldShowLogQuantityData =
      activity.choice_type === HABIT_CHOICE_TYPE.NONE ||
      activity.choice_type?.length === NUMBERS.ZERO;
    shouldShowLogQuantityData &&
      dispatch(updateMoreOptionLogQuantityQuestions([]));
  }, [activity.choice_type]);

  return (
    <div className='w-fit flex items-center gap-2 relative px-2'>
      <label
        className='w-fit h-fit flex gap-2 text-xs lg:text-sm font-medium'
        data-test={ATTRIB.TEST.SWITCH_LOG_QUANTITY}
      >
        <Switch
          width={switch_width}
          height={switch_height}
          onChange={(value) => {
            if (modalType === MODAL_TYPES.MORE_OPTION) {
              dispatch(updateMoreOptionLogQuantity(value));
              !value && dispatch(updateMoreOptionLogQuantityQuestions([]));
            } else {
              dispatch(updateChoicesLogQuantity({ position, value }));
              !value &&
                dispatch(
                  updateChoicesLogQuantityQuestions({ position, data: [] })
                );
            }
          }}
          checked={currentActivity?.log_quantity ?? false}
        />
        {t('log_quantity_data')}
      </label>
      <Tooltip
        icon={<HelpFilled />}
        message={t(
          'useful_if_you_want_to_record_data_in_your_activity_history'
        )}
        place={shouldActiveMobileView ? 'right' : 'left'}
      />
      {currentActivity?.log_quantity && <LogQuantityQuestionActions />}
    </div>
  );
};

const LogQuantityQuestionBody = () => {
  const dispatch = useAppDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const {
    moreOption: { activity },
    choice: { choices }
  } = useAppSelector((state) => state.modal);
  const { modalType } = useContext(LogQuantityQuestionContext);
  const { position } = useContext(ChoiceItemContext);
  const questions =
    modalType === MODAL_TYPES.MORE_OPTION
      ? activity?.log_quantity_questions ?? []
      : choices[position]?.log_quantity_questions ?? [];

  useEffect(() => {
    divRef.current?.scrollTo({
      top: divRef.current?.scrollHeight,
      behavior: 'smooth'
    });
  }, [questions.length]);

  useEffect(() => {
    validateLogQuestionsChanges();
  }, [questions]);

  const handleDeleteQuestion = useCallback(
    (logQuestionId: string) => {
      const logQuestions = questions.filter(
        (logQuestion) => logQuestion.id !== logQuestionId
      );
      modalType === MODAL_TYPES.MORE_OPTION
        ? dispatch(updateMoreOptionLogQuantityQuestions(logQuestions))
        : dispatch(
            updateChoicesLogQuantityQuestions({ position, data: logQuestions })
          );
    },
    [questions.length]
  );

  const validateLogQuestionsChanges = () => {
    const areQuestionsInvalid = Boolean(
      questions.filter((item) => item.question === EMPTY_STRING)?.length
    );
    if (modalType === MODAL_TYPES.MORE_OPTION) {
      dispatch(
        updateMoreOptionAreLogQuestionsInvalid(
          questions.length ? areQuestionsInvalid : false
        )
      );
    } else {
      dispatch(
        updateChoicesAreLogQuestionsInvalid(
          questions.length ? areQuestionsInvalid : false
        )
      );
    }
  };

  return questions.length ? (
    <div
      ref={divRef}
      className='w-full h-fit max-h-44 flex flex-col items-center gap-2 py-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-50 pr-4'
    >
      {questions.map((logQuestion, index) => (
        <LogQuestionInput
          key={logQuestion.id}
          position={index}
          logQuestion={logQuestion}
          handleDeleteQuestion={handleDeleteQuestion}
        />
      ))}
    </div>
  ) : (
    <></>
  );
};

const LogQuantityQuestionActions = () => {
  const dispatch = useAppDispatch();
  const {
    moreOption: { activity },
    choice: { choices }
  } = useAppSelector((state) => state.modal);
  const { modalType } = useContext(LogQuantityQuestionContext);
  const { position } = useContext(ChoiceItemContext);
  const questions =
    modalType === MODAL_TYPES.MORE_OPTION
      ? activity?.log_quantity_questions ?? []
      : choices[position]?.log_quantity_questions ?? [];
  const isCompetencyBasedActivity =
    activity?.choice_type === HABIT_CHOICE_TYPE.COMPETENCY_BASED;
  const data = [...questions, DEFAULT_LOG_QUESTION(isCompetencyBasedActivity)];

  return (
    <button
      data-test={ATTRIB.TEST.ADD_LOG_QUANTITY_QUESTION}
      onClick={() => {
        modalType === MODAL_TYPES.MORE_OPTION
          ? dispatch(updateMoreOptionLogQuantityQuestions(data))
          : dispatch(updateChoicesLogQuantityQuestions({ position, data }));
        dispatch(updateMoreOptionAreLogQuestionsInvalid(true));
      }}
      className='pt-[1px]'
    >
      <PlusCircle
        width={ICONS.PLUS_CIRCLE.LOG_QUESTION.WIDTH}
        height={ICONS.PLUS_CIRCLE.LOG_QUESTION.HEIGHT}
        fill={COLOR.BLACK}
      />
    </button>
  );
};

const LogQuantityQuestions = ({
  modalType = MODAL_TYPES.MORE_OPTION
}: LogQuantityQuestionsProps) => {
  const {
    moreOption: { activity },
    choice: { choices },
    logQuantity: { showQuestionDetailsModal }
  } = useAppSelector((state) => state.modal);
  const { position } = useContext(ChoiceItemContext);
  const currentActivity =
    modalType === MODAL_TYPES.MORE_OPTION ? activity : choices[position];

  return (
    <Wrapper modalType={modalType}>
      <LogQuantityQuestionHeader />
      {currentActivity?.log_quantity && <LogQuantityQuestionBody />}
      {showQuestionDetailsModal && <LogQuantityQuestionDetails />}
    </Wrapper>
  );
};

export default React.memo(LogQuantityQuestions);
