import DraggableIcon from 'assets/icons/Draggable';
import classNames from 'classnames';
import { TO_DO_STATUS } from 'constants/enum';
import { decode } from 'html-entities';
import { t } from 'i18next';
import moment from 'moment';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { useContext } from 'react';
import { useAppSelector } from 'store/hooks';
import { playerTaskItemSelector } from 'store/reducer/to-do/selectors';
import { PlayerTaskItemContext } from '.';
import PlayerTaskItemDeleteBtn from './PlayerTaskItemDeleteBtn';
import PlayerTaskItemDurationActions from './PlayerTaskItemDurationActions';
import PlayerTaskItemPlayActions from './PlayerTaskItemPlayActions';

const PlayerTaskItem = () => {
  const { task, provided } = useContext(PlayerTaskItemContext);
  const { playerData } = useContext(ToDoPlayerContext);
  const player = useAppSelector(playerTaskItemSelector);
  const { selectedTask } = player;
  const [shouldTaskSelected, shouldDisableTaskSelection] = [
    selectedTask?.id === task?.id,
    task?.status === TO_DO_STATUS.COMPLETED || playerData.isPlaying
  ];

  return (
    <div
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      className='w-full h-fit flex gap-1 items-start relative mt-5'
    >
      <button {...provided?.dragHandleProps} className='my-0.5'>
        {!shouldDisableTaskSelection && <DraggableIcon styles='w-5 h-auto' />}
      </button>
      <div
        className={classNames(
          'w-full flex flex-col gap-1 p-2 md:p-3 rounded-md shadow-md',
          {
            'bg-orange-100': shouldTaskSelected,
            'bg-green-100': task?.status === TO_DO_STATUS.COMPLETED,
            'bg-yellow-100':
              !shouldTaskSelected && task?.status === TO_DO_STATUS.IN_PROGRESS,
            'bg-gray-100':
              !shouldTaskSelected &&
              ![TO_DO_STATUS.COMPLETED, TO_DO_STATUS.IN_PROGRESS].includes(
                (task?.status ?? '') as TO_DO_STATUS
              )
          },
          ' time_to_do_the_task'
        )}
      >
        <div className='flex flex-wrap gap-1 items-center justify-between'>
          <PlayerTaskItemPlayActions />
          <PlayerTaskItemDurationActions />
        </div>
        <p className='w-full text-sm lg:text-base line-clamp-4 select-none'>
          {decode(task?.title)}
        </p>
        <p className='min-w-max w-fit text-center text-xs select-none rounded self-end'>{`${t('to_do_player.start')}: ${moment(task?.startTime, 'h:mm a').format('h:mm a')} - ${t('to_do_player.end')}:${moment(task?.endTime, 'h:mm a').format('h:mm a')}`}</p>
      </div>
      <PlayerTaskItemDeleteBtn />
    </div>
  );
};

export default PlayerTaskItem;
