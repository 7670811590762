import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import {
  Attributes,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useImperativeHandle,
  useRef
} from 'react';
interface TextInputProps
  extends DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    Attributes {
  readonly type?: 'text';
  readonly additionalStyles?: string;
}

const TextInput = forwardRef(
  (
    { type = 'text', className, additionalStyles, ...rest }: TextInputProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      getValue: () => inputRef.current?.value
    }));

    return (
      <input
        ref={inputRef}
        type={type}
        {...rest}
        className={`
        ${
          className ??
          'border-orange-300 border outline-none rounded-md px-2 py-1 min-w-[30vw] text-sm md:text-base'
        } ${additionalStyles}`}
        {...DEFAULT_HTML_ATTRIBUTES}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
