import Switch from 'react-switch';

interface Props {
  value: boolean;
  handleChange: (value: boolean) => void;
  label: string;
}

export default function SwitchWithLabel({ value, handleChange, label }: Props) {
  return (
    <div className='flex items-center gap-2 w-fit'>
      <Switch width={50} height={20} onChange={handleChange} checked={value} />
      <label className='text-xs 2xl:text-sm text-gray-600 font-semibold'>
        {label}
      </label>
    </div>
  );
}
