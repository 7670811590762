import { UploadButton } from 'react-uploader';
import { uploaderOptions } from 'constants/general';
import { UploadWidgetResult } from 'uploader';
import { useTranslation } from 'react-i18next';
import { uploader } from 'services/upload-io';

interface UploadButtonProps {
  setUrls: (files: UploadWidgetResult[]) => void;
  folderPath: string;
}

export default function UploadFileButton({
  setUrls,
  folderPath,
}: UploadButtonProps) {
  const { t } = useTranslation();
  return (
    <UploadButton
      uploader={uploader}
      options={{
        ...uploaderOptions,
        path: {
          fileName: '{ORIGINAL_FILE_NAME}{ORIGINAL_FILE_EXT}',
          folderPath,
        },
      }}
      onComplete={setUrls}
    >
      {({ onClick }) => (
        <button
          className='w-24 h-fit text-xs font-semibold tracking-wider py-1.5 rounded-md text-white mr-2 mb-2 bg-gray-500 hover:bg-gray-700 cursor-pointer transition ease-in-out delay-150 hover:scale-110  duration-300'
          onClick={onClick}
        >
          {t('add_image')}
        </button>
      )}
    </UploadButton>
  );
}
