import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ATTRIB } from 'constants/test';
import { useAppDispatch } from 'store/hooks';
import { updateIsUserLoggingOut } from 'store/reducer/user/slice';
import { useRef } from 'react';

function LoginButton() {
  const dispatch = useAppDispatch();
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  const logInBtn = useRef<HTMLButtonElement>(null);

  return (
    <button
      ref={logInBtn}
      data-test={ATTRIB.TEST.LOGIN}
      onClick={() => {
        logInBtn.current!.disabled = true;
        dispatch(updateIsUserLoggingOut(false));
        loginWithRedirect();
      }}
      className='login-button px-5 py-2 rounded-md text-xl border-0 text-orange-400 bg-orange-100 hover:bg-orange-400 hover:text-white disabled:border-4 disabled:bg-gray-100 disabled:hover:bg-gray-100 disabled:border-gray-300 disabled:text-gray-400 disabled:hover:text-gray-400 disabled:cursor-default disabled:after:w-full disabled:after:h-full disabled:after:top-0 disabled:after:left-0'
    >
      {t('login')}
    </button>
  );
}

export default LoginButton;
