import LogSummary from './LogSummary';
import TakeNote from './TakeNote';
import LogQuantityQuestions from './LogQuantityQuestions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import {
  updateMoreOptionLogQuantity,
  updateMoreOptionLogSummaryType,
} from 'store/reducer/modal/slice';
import { LOG_SUMMARY_OPTION } from 'constants/general';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='w-full flex flex-col md:flex-row flex-wrap justify-start lg:justify-between py-2 gap-2'>
    {children}
  </div>
);

const LogData = () => {
  /**
   * https://github.com/Focus-Bear/backend/blob/main/apps/api-server/src/modules/activity/dto/update-activity.dto.ts
   */
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const choices = activity?.choices ?? [];
  const dispatch = useAppDispatch();
  const isLogQuantityAllowed = activity?.log_quantity ?? false;

  useEffect(() => {
    if (choices.length > 0) {
      dispatch(updateMoreOptionLogQuantity(false));
      dispatch(updateMoreOptionLogSummaryType(LOG_SUMMARY_OPTION.SUMMATION));
    }
  }, [activity]);

  return (
    <Wrapper>
      {!choices.length && (
        <>
          <LogQuantityQuestions />
          {isLogQuantityAllowed && <LogSummary />}
        </>
      )}
      <TakeNote />
    </Wrapper>
  );
};

export default LogData;
