import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'constants/general';
import { ATTRIB } from 'constants/test';
import { useAppDispatch } from 'store/hooks';
import { updateConfirmActionModal } from 'store/reducer/modal/slice';

export default function LogoutButton() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isDesktop = window.innerWidth >= 1024;
  const buttonStyle = isDesktop
    ? `w-full text-gray-500 hover:bg-gray-100 bg-orange-50 w-full h-fit p-1 xl:px-2 lg:px-1 py-2 rounded`
    : 'text-gray-800 block p-4 py-1.5 lg:py-1 font-extrabold hover:bg-gray-50 rounded w-full text-left hover:text-orange-400';

  return (
    <button
      data-test={ATTRIB.TEST.LOGOUT}
      onClick={() => {
        dispatch(
          updateConfirmActionModal({
            modalType: MODAL_TYPES.CONFIRM_ACTION_LOGOUT,
            confirmTitle: t('logout'),
            description: t('are_you_sure_you_want_to_logout'),
            showConfirmModal: true,
          })
        );
      }}
      className={buttonStyle}
    >
      {t('logout')}
    </button>
  );
}
