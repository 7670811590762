import { POST_HOG_API_HOST } from 'constants/routes';
import postHog from 'posthog-js';

postHog.init(process.env.REACT_APP_POST_HOG_API_KEY!, {
  api_host: POST_HOG_API_HOST,
  autocapture: false,
  secure_cookie: true,
  capture_pageview: false
});

export default postHog;
