import logo from 'assets/images/logo.png';
import { Mode } from 'constants/enum';
import { ActivityImagesDataType } from 'interfaces';
import { t } from 'i18next';
import { useAppSelector } from 'store/hooks';
import { getActivityDuration } from 'utils/support';
import { hasCompletionRequirement } from 'utils/validation';

const ActivityImages = ({
  images,
  altName
}: {
  readonly images?: ActivityImagesDataType[];
  readonly altName: string;
}) => {
  return images?.length ? (
    <div className='flex flex-row justify-start y-[5px]'>
      {images
        .map((image, idx) => ({ image, idx }))
        .map(({ image, idx }) => (
          <img
            key={`${image.url}${idx}`}
            className='w-[150px] p-[10px]'
            src={image.url}
            alt={t('image_showing_how_to_do_habit', {
              habit_name: altName
            })}
          />
        ))}
    </div>
  ) : null;
};

const Preview = () => {
  const { mode, currentSettings: settings } = useAppSelector(
    (state) => state.setting
  );

  return (
    <table className='w-[90%] lg:w-[51rem] h-full table table-auto flex-col items-center content-center'>
      <thead className='table-header-group'>
        <tr>
          <td>
            <img
              src={logo}
              alt='Focus Bear'
              className='w-[200px] h-[33px] mb-6 mt-8 m-auto'
            />
            <div className='w-full grid grid-cols-2 font-semibold text-base mb-6 border-y py-2 border-black'>
              <p>{t('starting_time', { time: settings.startup_time })}</p>
              <p>{t('ending_time', { time: settings.shutdown_time })}</p>
              <p>{t('break_every', { time: settings.break_after_minutes })}</p>
              {settings.cutoff_time_for_non_high_priority_activities && (
                <p className='min-w-max'>
                  {t('cutoff_time_for_standard_priority_activities')}:
                  {settings.cutoff_time_for_non_high_priority_activities}
                </p>
              )}
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr className='flex break-inside-avoid'>
          <td className='w-full h-fit max-h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
            {mode !== Mode.FREESTYLE && (
              <>
                <div
                  className={`border-2 border-black rounded-lg w-[100%] m-auto py-4 px-4 break-after-page
            `}
                >
                  <h3 className='text-lg font-bold mb-1 text-left'>
                    {t('morning_habits')}
                  </h3>
                  <div className='flex flex-col w-full justify-between text-base rounded-xl'>
                    {(settings?.morning_activities ?? []).map((habit) => {
                      const duration = hasCompletionRequirement(habit)
                        ? t('completion_requirements')
                        : getActivityDuration(habit);
                      return (
                        <div
                          key={habit.id}
                          className='flex flex-col py-2 [&:not(:last-child)]:border-b-[1px] border-black'
                        >
                          <div className='flex text-base font-semibold w-full justify-between'>
                            <p>{habit.name}</p>
                            <p>{duration}</p>
                          </div>
                          {habit.text_instructions && (
                            <p className='text-left w-[100%]'>
                              {habit.text_instructions}
                            </p>
                          )}
                          <ActivityImages
                            images={habit.image_urls}
                            altName={habit.name}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={`border-2 border-black rounded-lg w-[100%] m-auto py-4 mt-8 px-4 break-after-page
            `}
                >
                  <h3 className='text-lg font-bold mb-1 text-left'>
                    {t('evening_habits')}
                  </h3>
                  <div className='flex flex-col w-full justify-between text-base rounded-xl '>
                    {(settings?.evening_activities ?? []).map((habit) => {
                      const duration = hasCompletionRequirement(habit)
                        ? t('completion_requirements')
                        : getActivityDuration(habit);
                      return (
                        <div
                          key={habit.id}
                          className='flex flex-col py-2 [&:not(:last-child)]:border-b-[1px] border-black'
                        >
                          <div className='flex text-base w-full justify-between'>
                            <p>{habit.name}</p>
                            <p>{duration}</p>
                          </div>
                          {habit.text_instructions && (
                            <p className='text-left w-[100%]'>
                              {habit.text_instructions}
                            </p>
                          )}
                          <ActivityImages
                            images={habit.image_urls}
                            altName={habit.name}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div
              className={`border-2 border-black rounded-lg w-[100%] m-auto py-4 mt-8 px-4 break-after-page break-inside-avoid`}
            >
              <h3 className='text-lg font-bold mb-1 text-left'>
                {t('break_activities')}
              </h3>
              <div className='flex flex-col w-full justify-between text-base rounded-xl '>
                {(settings?.break_activities ?? []).map((activity) => {
                  const duration = hasCompletionRequirement(activity)
                    ? t('completion_requirements')
                    : t('time_seconds', {
                        count: activity.duration_seconds
                      });

                  return (
                    <div
                      key={activity.id}
                      className='flex flex-col py-2 [&:not(:last-child)]:border-b-[1px] border-black'
                    >
                      <div className='flex text-base w-full justify-between'>
                        <p>{activity.name}</p>
                        <p>{duration}</p>
                      </div>
                      {activity.text_instructions && (
                        <p className='text-left w-[100%]'>
                          {activity.text_instructions}
                        </p>
                      )}
                      <ActivityImages
                        images={activity.image_urls}
                        altName={activity.name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Preview;
