import { createAppSelector } from 'store/hooks';

export const teamPageSelector = createAppSelector(
  [
    (state) => state.team.areTeamsLoading,
    (state) => state.team.showTeamOwnerActionModal,
    (state) => state.team.teamOwnerAction,
    (state) => state.team.showTeamOwnerMassUpdateModal,
    (state) => state.user.subscriptionInfo
  ],
  (
    areTeamsLoading,
    showTeamOwnerActionModal,
    teamOwnerAction,
    showTeamOwnerMassUpdateModal,
    subscriptionInfo
  ) => ({
    areTeamsLoading,
    showTeamOwnerActionModal,
    teamOwnerAction,
    showTeamOwnerMassUpdateModal,
    subscriptionInfo
  })
);

export const listOfMembersSelector = createAppSelector(
  [
    (state) => state.team.teamMembers.members?.slice(),
    (state) => state.team.areMembersLoading,
    (state) => state.team.searchMember
  ],
  (members, areMembersLoading, searchMember) => ({
    members,
    areMembersLoading,
    searchMember
  })
);

export const teamOwnerActionsSelector = createAppSelector(
  [
    (state) => state.team.selectedMembersId,
    (state) => state.team.searchMember,
    (state) => state.team.teamMembers.members?.slice(),
    (state) => state.team.teams?.slice(),
    (state) => state.team.areMembersLoading,
    (state) => state.team.selectedTeam,
    (state) => state.user.subscriptionInfo?.activeEntitlements?.slice()
  ],
  (
    selectedMembersId,
    searchMember,
    members,
    teams,
    areMembersLoading,
    selectedTeam,
    activeEntitlements
  ) => ({
    selectedMembersId,
    searchMember,
    members,
    teams,
    areMembersLoading,
    selectedTeam,
    activeEntitlements
  })
);

export const memberSelector = createAppSelector(
  [
    (state) => state.team.teamMembers.admins?.slice(),
    (state) => state.team.selectedMembersId,
    (state) => state.team.selectedTeam,
    (state) => state.team.isAssigningAdmin,
    (state) => state.team.isRemovingAdmin,
    (state) => state.team.selectedMemberId,
    (state) => state.team.isRemovingMember,
    (state) => state.team.teamOwnerAction,
    (state) => state.user.details
  ],
  (
    admins,
    selectedMembersId,
    selectedTeam,
    isAssigningAdmin,
    isRemovingAdmin,
    selectedMemberId,
    isRemovingMember,
    teamOwnerAction,
    userInfo
  ) => ({
    admins,
    selectedMembersId,
    selectedTeam,
    isAssigningAdmin,
    isRemovingAdmin,
    selectedMemberId,
    isRemovingMember,
    teamOwnerAction,
    userInfo
  })
);

export const teamMembersSelector = createAppSelector(
  [(state) => state.team.selectedTeam, (state) => state.team.areMembersLoading],
  (selectedTeam, areMembersLoading) => ({
    selectedTeam,
    areMembersLoading
  })
);

export const invitationFormSelector = createAppSelector(
  [(state) => state.team.teamMembers, (state) => state.user.details],
  (teamMembers, userInfo) => ({
    teamMembers,
    userInfo
  })
);

export const teamOwnerActionModalSelector = createAppSelector(
  [
    (state) => state.team.teamOwnerAction,
    (state) => state.team.selectedTeam,
    (state) => state.team.isUpdatingTeamName,
    (state) => state.team.teams?.slice(),
    (state) => state.team.isInvitingUser,
    (state) => state.user.availableStripePlans?.slice(),
    (state) => state.user.details
  ],
  (
    teamOwnerAction,
    selectedTeam,
    isUpdatingTeamName,
    teams,
    isInvitingUser,
    availableStripePlans,
    userInfo
  ) => ({
    teamOwnerAction,
    selectedTeam,
    isUpdatingTeamName,
    teams,
    isInvitingUser,
    availableStripePlans,
    userInfo
  })
);
