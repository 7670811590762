import { useTranslation } from 'react-i18next';

const WeeklySummaryColumns = () => {
  const { t } = useTranslation();
  return (
    <div className='w-fit flex items-center text-orange-400 rounded p-2 bg-gray-100 shadow-md'>
      <h5 className='text-xs sm:text-sm lg:text-base min-w-[100px] text-center'>
        {t('dashboard_stat.day')}
      </h5>
      <h5 className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px] text-center'>
        {t('dashboard_stat.morning_routine')}
      </h5>
      <h5 className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px] text-center'>
        {t('dashboard_stat.evening_routine')}
      </h5>
      <h5 className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px] text-center'>
        {t('dashboard_stat.activities_completed')}
      </h5>
      <h5 className='text-xs sm:text-sm lg:text-base min-w-[150px] md:min-w-[250px] text-center'>
        {t('dashboard_stat.focus_blocks_completed')}
      </h5>
    </div>
  );
};

export default WeeklySummaryColumns;
