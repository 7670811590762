import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getHabitPackById, getUserInstalledHabitPacks } from './extra';
import { initialHabitPackState } from 'store/initial-states';
import { HabitPackType } from 'interfaces/commonInterface';
import { SettingsType } from 'interfaces/settingInterface';

const habitPack = createSlice({
  name: 'habit-pack',
  initialState: initialHabitPackState,
  reducers: {
    updateAreHabitPacksLoading: (state, action: PayloadAction<boolean>) => {
      state.areHabitPacksLoading = action.payload;
    },
    setMarketplaceApprovedPacks: (
      state,
      action: PayloadAction<HabitPackType[]>
    ) => {
      state.marketplaceApprovedPacks = action.payload;
    },
    setUserInstalledHabitPacks: (
      state,
      action: PayloadAction<HabitPackType[]>
    ) => {
      state.installedHabitPacks = action.payload;
    },
    setUserCreatedHabitPacks: (
      state,
      action: PayloadAction<HabitPackType[]>
    ) => {
      state.userCreatedHabitPacks = action.payload;
    },
    setFeaturedHabitPacks: (state, action: PayloadAction<HabitPackType[]>) => {
      state.featuredHabitPacks = action.payload;
    },
    setIsOnHabitPacksTab: (state, { payload }: PayloadAction<boolean>) => {
      state.isOnHabitPacksTab = payload;
    },
    setCurrentViewedPack: (
      state,
      { payload }: PayloadAction<HabitPackType | null>
    ) => {
      state.currentViewedPack = payload;
    },
    updateIsCurrentHabitPackFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCurrentHabitPackFetching = payload;
    },
    updateIsSharedHabitPackFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSharedHabitPackFetching = payload;
    },
    updateSharedViewedPack: (
      state,
      { payload }: PayloadAction<HabitPackType>
    ) => {
      state.sharedViewedPack = payload;
    },
    updateIsUserInstalledPacksFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUserInstalledPacksFetching = payload;
    },
    updateInstallingPack: (state, { payload }: PayloadAction<boolean>) => {
      state.installingPack = payload;
    },
    updateUninstallingPack: (state, { payload }: PayloadAction<boolean>) => {
      state.uninstallingPack = payload;
    },
    updateIsUserUpsertingPack: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserUpsertingPack = payload;
    },
    updateIsHabitPackAllowedToBeEdited: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isHabitPackAllowedToBeEdited = payload;
    },
    updateIsHabitPackPdfShowed: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isHabitPackPdfShowed = payload;
    },
    updateInitialCurrentViewedPack: (
      state,
      { payload }: PayloadAction<SettingsType | null>
    ) => {
      state.initialCurrentViewedPack = payload;
    },
    updateIsHabitPacksDeleting: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isHabitPacksDeleting = payload;
    },
    updateOnboardingHabitPacks: (
      state,
      { payload }: PayloadAction<HabitPackType[]>
    ) => {
      state.onboardingHabitPacks = payload;
    },
    updateIsSendingInstalledHabitPackId: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSendingInstalledHabitPackId = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInstalledHabitPacks.fulfilled, (state, action) => {
        state.installedHabitPacks = action.payload ?? [];
        state.isUserInstalledPacksFetching = false;
      })
      .addCase(getUserInstalledHabitPacks.rejected, (state) => {
        state.isUserInstalledPacksFetching = false;
      })
      .addCase(getHabitPackById.fulfilled, (state, { payload }) => {
        state.currentViewedPack = payload;
        state.isCurrentHabitPackFetching = false;
      })
      .addCase(getHabitPackById.rejected, (state) => {
        state.isCurrentHabitPackFetching = false;
      });
  }
});

export const {
  setUserInstalledHabitPacks,
  setFeaturedHabitPacks,
  setMarketplaceApprovedPacks,
  updateAreHabitPacksLoading,
  setIsOnHabitPacksTab,
  setUserCreatedHabitPacks,
  setCurrentViewedPack,
  updateIsCurrentHabitPackFetching,
  updateIsSharedHabitPackFetching,
  updateSharedViewedPack,
  updateIsUserInstalledPacksFetching,
  updateInstallingPack,
  updateUninstallingPack,
  updateIsUserUpsertingPack,
  updateIsHabitPackAllowedToBeEdited,
  updateIsHabitPackPdfShowed,
  updateInitialCurrentViewedPack,
  updateIsHabitPacksDeleting,
  updateOnboardingHabitPacks,
  updateIsSendingInstalledHabitPackId
} = habitPack.actions;

export default habitPack.reducer;
