import { FC, ReactNode } from 'react';
import ModalOverlay from 'components/shared/ModalOverlay';
import { MODAL_TYPES } from 'constants/general';
import CourseData from '../CourseData';
import ModalHeader from 'components/shared/ModalHeader';

interface WrapperProps {
  readonly children: ReactNode;
}

const Wrapper: FC<WrapperProps> = ({ children }: WrapperProps) => (
  <div className='relative top-1/2 -translate-y-1/2 mx-auto w-11/12 sm:w-9/12 md:w-7/12 lg:w-6/12 xl:w-5/12 flex flex-col bg-whiteLace'>
    {children}
  </div>
);

const EnrolledCourseDetailsModal = () => {
  return (
    <ModalOverlay>
      <Wrapper>
        <ModalHeader modalType={MODAL_TYPES.COURSE_DETAILS_MODAL} />
        <div className='w-full flex p-4'>
          <CourseData />
        </div>
      </Wrapper>
    </ModalOverlay>
  );
};

export default EnrolledCourseDetailsModal;
