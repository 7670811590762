import Clone from 'assets/icons/Clone';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cloneHabit } from 'store/reducer/setting/slice';

interface Props {
  readonly activityPosition: number;
  readonly type: string;
}

export default function CloneLibraryActivityButton({
  activityPosition,
  type,
}: Props) {
  const { activityLibrary } = useAppSelector((state) => state.setting);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const templateActivity = activityLibrary[activityPosition];

  const handleCloneHabit = () => {
    dispatch(
      cloneHabit({
        templateActivity,
        newActivityPosition: activityPosition + 1,
        type,
      }),
    );
  };

  return (
    <div className='group relative'>
      <div className='rounded absolute hidden bottom-0 bg-black text-white mb-10 p-1 group-hover:block w-40 text-xs z-20'>
        <p>{t('clone_activity_to_below')}</p>
      </div>
      <button
        onClick={handleCloneHabit}
        className='text-blue-400 hover:text-blue-500 h-fit w-fit rounded-sm'
      >
        <Clone />
      </button>
    </div>
  );
}
