import { DOWNLOAD_LINK } from 'constants/routes';
import { AppDownloadButtonLabels } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import AppleLogo from 'assets/icons/AppleLogo';
import GooglePlayLogo from 'assets/icons/GooglePlayLogo';
import WindowsLogo from 'assets/icons/WindowsLogo';
import AppStore from 'assets/icons/AppStore';

interface WrapperProps {
  children: React.ReactNode;
}

interface AppLinkProps {
  label: AppDownloadButtonLabels;
  link: string;
  icon: JSX.Element;
  disabled: boolean;
}

interface AppCategoryProps {
  platform: {
    title: string;
    applications: {
      label: AppDownloadButtonLabels;
      icon: JSX.Element;
      disabled: boolean;
      link: string;
    }[];
    isLaunched: boolean;
  };
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='w-full flex items-center justify-evenly flex-wrap gap-4'>
    {children}
  </div>
);

const ComingSoonNotice = () => {
  const { t } = useTranslation();
  return (
    <div className='absolute top-1/2 -translate-y-1/2 left-5 font-semibold text-orange-600/40 text-lg -rotate-12'>
      {t('inquiry.coming_soon')}
    </div>
  );
};

const AppLinkButton = ({ label, link, icon, disabled }: AppLinkProps) => {
  return (
    <a href={link}>
      <button
        disabled={disabled}
        className={`flex items-center gap-2 w-fit h-fit p-5 py-1.5 ${
          disabled ? 'bg-gray-400' : 'bg-gray-500 hover:bg-gray-600'
        } text-white rounded-md text-xs lg:text-sm`}
      >
        {label} {icon}
      </button>
    </a>
  );
};

const ApplicationCategory = ({ platform }: AppCategoryProps) => {
  return (
    <div className='w-fit flex flex-col gap-1 relative'>
      <p className='w-fit font-semibold text-sm lg:text-lg truncate pb-0.5'>
        {platform.title}
      </p>
      <div className='flex items-center w-full gap-6'>
        {platform.applications.map((app, idx) => (
          <AppLinkButton
            key={idx}
            label={app.label}
            icon={app.icon}
            disabled={app.disabled}
            link={app.link}
          />
        ))}
      </div>
      {!platform.isLaunched && <ComingSoonNotice />}
    </div>
  );
};

export default function AppInstallPrompt() {
  const { t } = useTranslation();
  const platforms = {
    desktop: {
      title: t('inquiry.desktop_apps'),
      applications: [
        {
          label: AppDownloadButtonLabels.MAC,
          icon: <AppleLogo />,
          disabled: false,
          link: DOWNLOAD_LINK.MAC_APP
        },
        {
          label: AppDownloadButtonLabels.WINDOWS,
          icon: <WindowsLogo />,
          disabled: false,
          link: DOWNLOAD_LINK.WINDOWS_APP
        }
      ],
      isLaunched: true
    },
    mobile: {
      title: t('inquiry.mobile_apps'),
      applications: [
        {
          label: AppDownloadButtonLabels.IOS,
          icon: <AppStore />,
          disabled: false,
          link: DOWNLOAD_LINK.MOBILE.IOS
        },
        {
          label: AppDownloadButtonLabels.GOOGLE_PLAY,
          icon: <GooglePlayLogo />,
          disabled: false,
          link: DOWNLOAD_LINK.MOBILE.ANDROID
        }
      ],
      isLaunched: true
    }
  };
  return (
    <Wrapper>
      <ApplicationCategory platform={platforms.desktop} />
      <ApplicationCategory platform={platforms.mobile} />
    </Wrapper>
  );
}
