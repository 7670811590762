import { IconProps } from 'interfaces';
import COLOR from 'constants/color';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';

const Draggable = ({
  fill = COLOR.BLACK,
  styles = 'w-6 h-6',
  fillDarkMode = COLOR.WHITE
}: IconProps) => {
  return (
    <svg
      fill={
        !isDarkModeActivated(useAppSelector(themeSelector))
          ? fill
          : fillDarkMode
      }
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
      className={styles}
    >
      <g id='SVGRepo_iconCarrier'>
        <rect x='10' y='6' width='4' height='4'></rect>
        <rect x='18' y='6' width='4' height='4'></rect>
        <rect x='10' y='14' width='4' height='4'></rect>
        <rect x='18' y='14' width='4' height='4'></rect>
        <rect x='10' y='22' width='4' height='4'></rect>
        <rect x='18' y='22' width='4' height='4'></rect>
      </g>
    </svg>
  );
};

export default Draggable;
