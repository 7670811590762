import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { SCREEN } from 'constants/general';
import { LayoutContext } from '../layout';
import { ATTRIB } from 'constants/test';
import { ROUTES } from 'constants/routes';
import LogoutButton from '../../common/buttons/LogoutButton';

interface SubProps {
  path: string;
  text?: string;
  attribAction?: string;
}

interface Props {
  closeMenu?: () => void;
  data: {
    parent: SubProps;
    children: SubProps[];
  };
}

const NavDropDown = ({ data, closeMenu }: Props) => {
  const [isParentHovered, setIsParentHovered] = useState(false);
  const [isParentContentHovered, setIsParentContentHovered] = useState(false);
  const { width } = useContext(LayoutContext);
  const parentButtonStyle =
    width > SCREEN.WIDTH.LARGE
      ? `hover:text-gray-800 h-fit p-1 lg:px-1 xl:px-2 ml-1 rounded-t-md hover:bg-gray-50 hover:text-orange-400`
      : `block p-1 hover:bg-gray-50 rounded w-full text-left hover:text-orange-400`;

  const childButtonStyle = (path: string) => {
    const childPath = window.location.href.includes(path);
    return width > SCREEN.WIDTH.LARGE
      ? `w-full text-gray-500 hover:bg-gray-100 bg-orange-50 w-full h-fit p-1 xl:px-2 lg:px-1 py-2 rounded ${
          childPath && 'bg-gray-100 text-orange-400'
        }`
      : `text-gray-800 block p-4 py-1.5 lg:py-1 font-extrabold hover:bg-gray-50 rounded w-full text-left hover:text-orange-400  ${
          childPath && 'bg-gray-100 text-orange-400'
        }`;
  };

  return (
    <div className='w-full lg:w-fit relative inline-block'>
      <button
        data-test={ATTRIB.TEST.STORE}
        onMouseEnter={() => {
          setIsParentHovered(true);
        }}
        onMouseLeave={() => {
          setIsParentHovered(false);
        }}
        className={`${parentButtonStyle} text-gray-500`}
      >
        {data.parent}
      </button>
      <div
        onMouseEnter={() => {
          setIsParentContentHovered(true);
        }}
        onMouseLeave={() => {
          setIsParentContentHovered(false);
        }}
        className={`w-full lg:w-[170px] absolute md:top-7 xl:top-9 left-0 lg:left-1/2 lg:-translate-x-1/2 z-50 flex flex-col gap-1 ${
          isParentHovered || isParentContentHovered ? 'block' : 'hidden'
        } bg-orange-200/80 py-1.5 px-1 rounded-b shadow text-sm`}
      >
        {data.children.map((child: SubProps, index) =>
          child.path === ROUTES.LOG_OUT ? (
            <LogoutButton key={child.path} />
          ) : (
            <Link key={child.path} to={child.path}>
              <button
                data-action={child.attribAction}
                onClick={() => {
                  setIsParentContentHovered(false);
                  closeMenu?.();
                }}
                className={childButtonStyle(child.path)}
              >
                {child.text}
              </button>
            </Link>
          )
        )}
      </div>
    </div>
  );
};

export default NavDropDown;
