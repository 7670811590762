import { TEAM_OWNER_ACTION } from 'constants/enum';
import { EMPTY_STRING } from 'constants/general';
import { TeamMember } from 'interfaces';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assignAdmin, removeAdmin } from 'store/reducer/team/extra';
import {
  updateSelectedMemberId,
  updateSelectedMembersId,
  updateTeamOwnerAction
} from 'store/reducer/team/slice';
import MemberActions from './MemberActions';
import { memberSelector } from 'store/reducer/team/selectors';

const Loading = () => (
  <div className='w-4 h-4 rounded-full border-t border-gray-600 animate-spin'></div>
);

const Member = ({ member }: { member: TeamMember }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    admins,
    selectedMembersId,
    selectedTeam,
    isAssigningAdmin,
    isRemovingAdmin,
    selectedMemberId,
    isRemovingMember,
    teamOwnerAction,
    userInfo
  } = useAppSelector(memberSelector);

  const isOwner = admins.some(
    (admin) =>
      admin.email === userInfo?.email && member.email === userInfo?.email
  );
  const isAdmin = admins?.some(
    (admin) => admin.id === member.id && admin.email !== userInfo.email
  );

  const memberType = useMemo(() => {
    if (isOwner) {
      return t('admin_team.owner');
    } else if (isAdmin) {
      return t('admin_team.admin');
    }
    return t('admin_team.standard');
  }, [admins]);

  const handlePrivileges = () => {
    dispatch(updateSelectedMemberId(member.id));
    dispatch(updateTeamOwnerAction('' as TEAM_OWNER_ACTION));
    if (isAdmin) {
      dispatch(
        removeAdmin({
          member_id: member.id ?? EMPTY_STRING,
          team_id: selectedTeam?.id ?? EMPTY_STRING
        })
      );
    } else {
      dispatch(
        assignAdmin({
          member_id: member.id,
          team_id: selectedTeam?.id ?? EMPTY_STRING
        })
      );
    }
    dispatch(updateSelectedMembersId([]));
  };

  const shouldInitRemoveOrAssignAdminRoles = [
    TEAM_OWNER_ACTION.REMOVE_ADMIN,
    TEAM_OWNER_ACTION.ASSIGN_ADMIN
  ].includes(teamOwnerAction ?? ('' as TEAM_OWNER_ACTION));
  const isEitherAssigningOrRemovingAdminRoles =
    isAssigningAdmin || isRemovingAdmin;

  return (
    <div className='min-w-max w-full flex items-center justify-between gap-2 px-3 py-1.5 text-xs md:text-sm xl:text-base border-b-2 border-gray-300'>
      {isOwner ? (
        <div className='w-[20px]'></div>
      ) : (
        <div className='w-[20px] flex items-center justify-center'>
          {shouldInitRemoveOrAssignAdminRoles &&
          isEitherAssigningOrRemovingAdminRoles &&
          selectedMembersId?.includes(member.id) ? (
            <Loading />
          ) : (
            <input
              disabled={isRemovingMember}
              type='checkbox'
              className='w-5 h-5 cursor-pointer disabled:cursor-not-allowed'
              checked={selectedMembersId?.includes(member.id)}
              onChange={({ target: { checked } }) => {
                dispatch(
                  updateSelectedMembersId(
                    checked
                      ? [...selectedMembersId, member.id]
                      : selectedMembersId.filter((id) => id !== member.id)
                  )
                );
              }}
            />
          )}
        </div>
      )}

      <p className='w-[250px] px-3 py-1 truncate'>{member.email}</p>
      <p className='w-[220px] px-3 py-1 text-center truncate'>
        {member.first_name ?? '--------'}
      </p>
      <p className='w-[220px] px-3 py-1 text-center truncate'>
        {member.last_name ?? '--------'}
      </p>
      <p className='w-[200px]  px-3 py-1 text-center truncate'>
        {moment(member.last_active_date).format('MMM DD, YYYY')}
      </p>
      <div className='w-[300px]  px-3 py-1 flex items-center justify-center gap-1'>
        {isOwner ? '--------' : <MemberActions member={member} />}
      </div>
      <div className='w-[220px] flex items-center justify-center'>
        {!shouldInitRemoveOrAssignAdminRoles &&
        isEitherAssigningOrRemovingAdminRoles &&
        selectedMemberId === member.id ? (
          <Loading />
        ) : (
          <select
            disabled={isOwner}
            value={memberType}
            className='text-xs sm:text-sm px-1.5 py-1 rounded-md bg-gray-200 cursor-pointer outline-none disabled:cursor-not-allowed'
            onChange={handlePrivileges}
          >
            <option value={t('admin_team.admin')}>
              {t('admin_team.admin')}
            </option>
            <option value={t('admin_team.standard')}>
              {t('admin_team.standard')}
            </option>
            <option hidden value={t('admin_team.owner')}>
              {t('admin_team.owner')}
            </option>
          </select>
        )}
      </div>
      <p className='min-w-[250px] px-3 py-1 text-center truncate'>
        {member.morning_routines_streak ?? '--------'}
      </p>
      <p className='min-w-[250px] px-3 py-1 text-center truncate'>
        {member.evening_routines_streak ?? '--------'}
      </p>
      <p className='min-w-[250px] px-3 py-1 text-center truncate'>
        {member.focus_modes_streak ?? '--------'}
      </p>
      <p className='min-w-[280px] px-3 py-1 text-center'>
        {member?.focus_modes_percent_number_day_of_stats_completed ?? 0}
      </p>
      <p className='min-w-[250px] px-3 py-1 text-center'>
        {member.morning_percent_number_day_of_stats_completed ?? 0}
      </p>
      <p className='min-w-[280px] px-3 py-1 text-center'>
        {member?.micro_percent_number_day_of_stats_completed ?? 0}
      </p>
      <p className='min-w-[250px] px-3 py-1 text-center'>
        {member?.evening_percent_number_day_of_stats_completed ?? 0}
      </p>
    </div>
  );
};

export default Member;
