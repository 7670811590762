import MicroBreak from './MicroBreak';
import Applications from './Applications';
import FocusMode from './FocusMode';
import Websites from './Websites';
import { createAppSelector, useAppSelector } from 'store/hooks';
import { ACTIVITY_TYPES } from 'constants/general';
import GuiltFreePlay from './GuiltFreePlay';
import { PropsWithChildren } from 'react';

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full flex flex-col md:flex-row md:flex-wrap gap-6 md:gap-6 py-2'>
    {children}
  </div>
);

const AllowedApps = () => {
  const { activity, type } = useAppSelector(
    createAppSelector(
      [
        (state) => state.modal.moreOption.activity,
        (state) => state.setting.moreOptions.type
      ],
      (activity, type) => ({ activity, type })
    )
  );
  const shouldRunMicroBreaksSwitchBeVisible = Boolean(
    (activity?.allowed_apps?.length ||
      activity?.allowed_urls?.length ||
      activity?.allowed_focus_mode_id) &&
      type === ACTIVITY_TYPES.BREAKING
  );

  return (
    <Wrapper>
      <Applications />
      <Websites />
      <FocusMode />
      {!shouldRunMicroBreaksSwitchBeVisible && <MicroBreak />}
      <GuiltFreePlay />
    </Wrapper>
  );
};

export default AllowedApps;
