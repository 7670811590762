import { useState } from 'react';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalWrapper from 'components/shared/ModalWrapper';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateIsExpiryDateMassUpdating,
  updateIsMemberTypeMassUpdating,
  updateShowTeamOwnerMassUpdateModal,
} from 'store/reducer/team/slice';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  assignAdmin,
  removeAdmin,
  updateMemberExpiryDate,
} from 'store/reducer/team/extra';
import { EMPTY_STRING } from 'constants/general';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';

const TeamOwnerMassUpdateModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    selectedTeam,
    isExpiryDateMassUpdating,
    isMemberTypeMassUpdating,
    selectedMembersId,
  } = useAppSelector((state) => state.team);
  const [bulkData, setBulkData] = useState({
    memberType: t('admin_team.admin'),
    expiryDate: moment().format('YYYY-MM-DD'),
  });
  const shouldAllowMassUpdate =
    !isExpiryDateMassUpdating && !isMemberTypeMassUpdating;

  const handleChangeMemberType = async () => {
    dispatch(updateIsMemberTypeMassUpdating(true));
    const results = await Promise.allSettled(
      selectedMembersId?.map((member_id) =>
        bulkData.memberType === t('admin_team.admin')
          ? dispatch(
              assignAdmin({
                member_id: member_id,
                team_id: selectedTeam?.id ?? EMPTY_STRING,
              }),
            )
          : dispatch(
              removeAdmin({
                member_id: member_id ?? EMPTY_STRING,
                team_id: selectedTeam?.id ?? EMPTY_STRING,
              }),
            ),
      ),
    );
    const successful = results.filter(
      (result) => result.status === 'fulfilled',
    );
    dispatch(updateIsMemberTypeMassUpdating(false));
    if (successful?.length) {
      dispatch(updateShowTeamOwnerMassUpdateModal(false));
    } else {
      toast.error(t('could_not_process_the_request'));
    }
  };

  const handleChangeExpiryDate = async () => {
    dispatch(updateIsExpiryDateMassUpdating(true));
    const results = await Promise.allSettled(
      selectedMembersId?.map((member_id) =>
        dispatch(
          updateMemberExpiryDate({
            member_id: member_id,
            team_id: selectedTeam?.id ?? '',
            expiry_date: bulkData.expiryDate,
          }),
        ),
      ),
    );
    const successful = results.filter(
      (result) => result.status === 'fulfilled',
    );
    dispatch(updateIsExpiryDateMassUpdating(false));
    if (successful?.length) {
      dispatch(updateShowTeamOwnerMassUpdateModal(false));
    } else {
      toast.error(t('could_not_process_the_request'));
    }
  };

  return (
    <ModalOverlay zIndex='z-50'>
      <ModalWrapper additionalStyles='gap-y-2'>
        {isExpiryDateMassUpdating || isMemberTypeMassUpdating ? (
          <div className='w-6 h-6 rounded-full border-r-2 border-gray-500 animate-spin my-2'></div>
        ) : (
          <div className='w-fit flex items-end justify-center gap-10 text-xs md:text-sm my-4'>
            <div className='w-fit flex flex-col gap-3'>
              <input
                disabled={!shouldAllowMassUpdate}
                type='date'
                value={bulkData.expiryDate}
                onChange={({ target: { value } }) =>
                  setBulkData((prev) => ({ ...prev, expiryDate: value }))
                }
                className='text-xs sm:text-sm px-1.5 py-1 rounded-md bg-gray-200 cursor-pointer outline-none disabled:cursor-not-allowed'
                min={moment().format('YYYY-MM-DD')}
              />
              <button
                disabled={!shouldAllowMassUpdate}
                className='bg-black text-white rounded-md px-3 py-1 outline-none shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] disabled:bg-gray-400'
                onClick={handleChangeExpiryDate}
              >
                {t('admin_team.change_expiry_date')}
              </button>
            </div>
            <div className='w-fit flex flex-col gap-3'>
              <select
                disabled={!shouldAllowMassUpdate}
                value={bulkData.memberType}
                onChange={({ target: { value } }) =>
                  setBulkData((prev) => ({ ...prev, memberType: value }))
                }
                className='text-xs sm:text-sm px-1.5 py-1 rounded-md bg-gray-200 cursor-pointer outline-none disabled:cursor-not-allowed'
              >
                <option value={t('admin_team.admin')}>
                  {t('admin_team.admin')}
                </option>
                <option value={t('admin_team.standard')}>
                  {t('admin_team.standard')}
                </option>
              </select>
              <button
                disabled={!shouldAllowMassUpdate}
                className='bg-black text-white rounded-md px-3 py-1 outline-none shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] disabled:bg-gray-400'
                onClick={handleChangeMemberType}
              >
                {t('admin_team.change_member_type')}
              </button>
            </div>
          </div>
        )}
        <button
          onClick={() => dispatch(updateShowTeamOwnerMassUpdateModal(false))}
          className='absolute top-0 right-0'
        >
          <CloseCircle fill={COLOR.BLACK} />
        </button>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default TeamOwnerMassUpdateModal;
