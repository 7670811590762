import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { Mode } from 'constants/enum';
import { MORNING_ROUTINE_TAB_INDEX } from 'constants/general';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsShowRoutineModal } from 'store/reducer/setting/slice';

const AdvancedBtn = () => {
  const dispatch = useAppDispatch();
  const { mode, activeTabIndex } = useAppSelector((state) => state.setting);
  return mode === Mode.ROUTINE &&
    activeTabIndex === MORNING_ROUTINE_TAB_INDEX ? (
    <ButtonFocusBear
      title={t('advanced')}
      onClick={() => {
        dispatch(updateIsShowRoutineModal(true));
      }}
    />
  ) : null;
};

export default AdvancedBtn;
