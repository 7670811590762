import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { updateMoreOptionShowImageURLModal } from 'store/reducer/modal/slice';

const ActivityImages = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <div className='w-fit h-fit flex justify-evenly items-end gap-2 bg-gray-200 shadow-md px-3 py-2 rounded-md'>
      <p className='text-left text-xs sm:text-sm md:text-base font-medium text-black'>
        {t('images')}
      </p>
      <button
        className='w-32 py-1 text-[10px] ml-1 bg-gray-500 text-white rounded-md  transition ease-in-out delay-150 hover:scale-110 hover:bg-gray-600 duration-300'
        onClick={() => {
          dispatch(updateMoreOptionShowImageURLModal(true));
        }}
      >
        {t('manage_image_urls')}
      </button>
    </div>
  );
};

export default React.memo(ActivityImages);
