import { useContext, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import Preview from './Preview';
import Pdf from './Pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDF_FILENAME, SCREEN } from 'constants/general';
import PdfDocument from './Pdf/PdfDocument';
import { nextLevel } from 'utils/support';
import { LayoutContext } from 'components/dashboard/layout';

const Output = () => {
  const {
    setting: { currentSettings },
    user: {
      details: { metadata }
    }
  } = useAppSelector((state) => state);
  const [jsonCounter, setJsonCounter] = useState(0);
  const showJSON = jsonCounter >= 8;
  const { t } = useTranslation();
  const [isPdfShown, setIsPdfShown] = useState(false);
  const [hasDownloadedPdf, setHasDownloadedPdf] = useState(false);
  const { height } = useContext(LayoutContext);

  const pdfButtonText = (loading: boolean, hasDownloadedPdf: boolean) => {
    if (loading) {
      return t('loading_document');
    } else if (hasDownloadedPdf) {
      return t('downloaded');
    } else {
      return t('download_pdf');
    }
  };

  return (
    <>
      <div className='w-full h-full flex flex-col items-center overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400'>
        <div
          className={`w-full ${
            showJSON ? 'h-full' : 'h-fit'
          } py-2 flex flex-col gap-4 items-center justify-center relative`}
        >
          <button
            className='absolute top-0 left-0 w-full h-5 bg-transparent cursor-default'
            onClick={() => {
              setJsonCounter((prevState) => nextLevel(prevState));
            }}
          ></button>
          {showJSON && (
            <textarea
              readOnly={true}
              className='w-full sm:w-2/3 h-full resize-none outline-none text-sm px-4 py-1 font-mono rounded-lg bg-blue-200 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'
              value={JSON.stringify(currentSettings, null, 4)}
            ></textarea>
          )}
          {height < SCREEN.WIDTH.LARGE ? (
            <PDFDownloadLink
              className='mt-4 text-base sm:text-xl font-semibold px-8 sm:px-10 py-2 bg-blue-700 text-white rounded-md'
              onClick={() => setHasDownloadedPdf(true)}
              document={
                <PdfDocument settings={currentSettings} metadata={metadata} />
              }
              fileName={PDF_FILENAME.SETTINGS}
            >
              {({ loading }) => pdfButtonText(loading, hasDownloadedPdf)}
            </PDFDownloadLink>
          ) : (
            <button
              onClick={() => {
                setIsPdfShown(true);
              }}
              className='mt-4 text-base sm:text-xl font-semibold px-8 sm:px-10 py-2 bg-blue-700 text-white rounded-md'
            >
              {t('generate_pdf')}
            </button>
          )}
        </div>
        {!showJSON && <Preview />}
      </div>
      {isPdfShown && <Pdf setIsPdfShown={setIsPdfShown} />}
    </>
  );
};

export default Output;
