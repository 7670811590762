import classNames from 'classnames';
import Tooltip from 'components/common/Tooltip';
import { t } from 'i18next';
import { useAppSelector } from 'store/hooks';
import { timingSummarySelector } from 'store/reducer/setting/selectors';
import {
  formatTimeTo12Hour,
  getRoutineDurationAsSecond
} from 'utils/settingsUtil';
import { createHtml } from 'utils/support';

const TimingSummary = () => {
  const {
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities,
    morning_activities,
    evening_activities
  } = useAppSelector(timingSummarySelector);

  const duration_of_morning_routine =
    getRoutineDurationAsSecond(morning_activities);
  const duration_of_evening_routine =
    getRoutineDurationAsSecond(evening_activities);

  const start_time = formatTimeTo12Hour(startup_time);
  const end_time = formatTimeTo12Hour(shutdown_time);
  const cutOffTime = formatTimeTo12Hour(
    cutoff_time_for_non_high_priority_activities
  );
  const morningRoutineEndTime = formatTimeTo12Hour(
    startup_time,
    duration_of_morning_routine
  );
  const eveningRoutineEndTime = formatTimeTo12Hour(
    cutoff_time_for_non_high_priority_activities ?? end_time,
    duration_of_evening_routine
  );

  const summaries = [
    {
      title: t('summary.morning_routine_duration', {
        start_time,
        morningRoutineEndTime
      }),
      tooltip: t('summary.tool_tip_morning_routine_duration')
    },
    {
      title: t('summary.work_time', { morningRoutineEndTime, end_time }),
      tooltip: t('summary.tool_tip_work_time')
    },

    {
      title: t('summary.end_of_day_sleep_time', {
        eveningRoutineEndTime,
        startup_time
      }),
      tooltip: t('summary.tool_tip_end_of_day_sleep_time')
    }
  ];

  if (cutOffTime) {
    summaries.splice(
      2,
      0,
      {
        title: t('summary.free_time', { end_time, cutOffTime }),
        tooltip: t('summary.tool_tip_free_time')
      },
      {
        title: t('summary.evening_routine_duration', {
          cutOffTime,
          eveningRoutineEndTime
        }),
        tooltip: t('summary.tool_tip_evening_routine_duration')
      }
    );
  }

  return (
    <div className='w-full max-w-full text-sm flex flex-col gap-4 mt-3 md:mt-6 lg:mt-8 shadow-md py-2 sm:py-3 md:py-4 px-2 sm:px-4 md:px-6 rounded-md'>
      {summaries.map(({ title, tooltip }) => (
        <p
          key={tooltip}
          className={classNames('text-sm sm:text-base', {
            'font-semibold': tooltip === t('summary.tool_tip_free_time')
          })}
        >
          {createHtml(title)}
          <Tooltip
            message={tooltip}
            iconStyles='w-4 sm:w-5 h-4 sm:h-5 inline-flex ml-1.5'
            additionalWrapperStyles='w-fit'
          />
        </p>
      ))}
    </div>
  );
};

export default TimingSummary;
