import { REACT_SELECT_DROPDOWN } from 'constants/general';
import Select, {
  CSSObjectWithLabel,
  MultiValue,
  SingleValue
} from 'react-select';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated, isEmbeddedTodoList } from 'utils/validation';
import COLOR from 'constants/color';
import { DropDownSelectedValue } from 'interfaces';
import { themeSelector } from 'store/reducer/setting/selectors';

interface DropDownProps {
  title?: string;
  value?: DropDownSelectedValue | DropDownSelectedValue[];
  options: DropDownSelectedValue[];
  handleChange: (
    data: SingleValue<DropDownSelectedValue> | MultiValue<DropDownSelectedValue>
  ) => void;
  isClearable?: boolean;
  containerStyles?: string;
  labelStyles?: string;
  hasError?: boolean;
  ErrorMessage?: JSX.Element;
  isDisabled?: boolean;
  zIndex?: number;
  placeholder?: string;
  isMulti?: boolean;
  isLoading?: boolean;
  defaultMenuIsOpen?: boolean;
}

const getDropDownStyles = (
  zIndex: number,
  isMulti: boolean,
  shouldActivateDarkMode: boolean
) => ({
  menuPortal: (base: CSSObjectWithLabel) => ({
    ...base,
    zIndex,
    fontSize: REACT_SELECT_DROPDOWN.FONT_SIZE,
    color: COLOR.BLACK
  }),
  option: (base: CSSObjectWithLabel) => ({
    ...base,
    cursor: 'pointer'
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE
  }),
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.GRAY_700 : COLOR.GRAY_4OO
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE,
    color: isMulti && shouldActivateDarkMode ? COLOR.WHITE : COLOR.BLACK
  }),
  singleValueLabel: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.GRAY_800 : COLOR.GRAY_4OO
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_700 : COLOR.GRAY_3OO
  }),
  multiValueLabel: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.WHITE : COLOR.BLACK
  }),
  clearIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.WHITE,
    color: shouldActivateDarkMode ? COLOR.GRAY_3OO : COLOR.GRAY_4OO,
    cursor: 'pointer',
    ':hover': {
      color: shouldActivateDarkMode ? COLOR.GRAY_600 : COLOR.GRAY_5OO
    }
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    cursor: 'pointer',
    ':hover': {
      color: shouldActivateDarkMode ? COLOR.GRAY_600 : COLOR.GRAY_5OO
    }
  }),
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    color: shouldActivateDarkMode ? COLOR.GRAY_4OO : COLOR.GRAY_600,
    ':hover': {
      backgroundColor: shouldActivateDarkMode ? COLOR.GRAY_600 : COLOR.GRAY_4OO,
      color: shouldActivateDarkMode ? COLOR.WHITE : COLOR.GRAY_800
    }
  })
});

const DropDown = ({
  title,
  value,
  options,
  handleChange,
  isClearable = false,
  containerStyles,
  labelStyles,
  hasError,
  ErrorMessage,
  isDisabled = false,
  zIndex = REACT_SELECT_DROPDOWN.Z_INDEX.DEFAULT,
  placeholder,
  isMulti = false,
  isLoading = false,
  defaultMenuIsOpen = false
}: DropDownProps) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  const shouldEmbeddedFocusIU = isEmbeddedTodoList(window.location.pathname);
  const getContainerStyles =
    containerStyles ??
    `${
      shouldEmbeddedFocusIU ? 'w-full md:w-[20vw]' : 'w-full md:w-1/2'
    } flex flex-col ${shouldActivateDarkMode ? 'text-white' : 'text-black'}`;

  const styles = getDropDownStyles(zIndex, isMulti, shouldActivateDarkMode);

  return (
    <div className={getContainerStyles}>
      {title ? (
        <p
          className={`${
            labelStyles ??
            'w-full truncate text-xs sm:text-sm font-medium mb-0.5'
          } ${shouldActivateDarkMode ? 'text-white' : 'text-black'}`}
        >
          {title}
          {hasError ? ErrorMessage : null}
        </p>
      ) : null}
      <Select
        isDisabled={isDisabled}
        className='w-full text-xs rounded shadow'
        value={value}
        menuPortalTarget={document.body}
        styles={styles}
        options={options}
        onChange={handleChange}
        isClearable={isClearable}
        menuPosition='fixed'
        isSearchable={false}
        placeholder={placeholder}
        isMulti={isMulti}
        isLoading={isLoading}
        openMenuOnClick={true}
        defaultMenuIsOpen={defaultMenuIsOpen}
      />
    </div>
  );
};

export default DropDown;
