import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionCategory } from 'store/reducer/modal/slice';

const Category = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { activity } = useAppSelector((state) => state.modal.moreOption);

  return (
    <div className='w-fit'>
      <p className='text-left text-sm pb-0.5 text-gray-500 font-medium'>
        {t('category')}
      </p>
      <input
        value={activity?.category ?? ''}
        onChange={({
          target: { value }
        }: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(updateMoreOptionCategory(value));
        }}
        className='w-full py-1.5 px-2 text-xs font-medium tracking-wide outline-none border border-gray-400 rounded-md focus:bg-gray-100'
      />
    </div>
  );
};

export default React.memo(Category);
