import React from 'react';
import ModalHeader from 'components/shared/ModalHeader';
import ModalOverlay from 'components/shared/ModalOverlay';
import { MODAL_TYPES } from 'constants/general';
import { useTranslation } from 'react-i18next';
import NewTask from './NewTask';

const Wrapper = ({ children }: { readonly children: React.ReactNode }) => (
  <div className='relative top-[40%] -translate-y-[40%] mx-auto w-[95%] sm:w-3/4 xl:w-3/5 2xl:w-1/2 h-fit max-h-[90vh] shadow-lg rounded-md bg-gray-200 flex flex-col'>
    {children}
  </div>
);

const EmbeddedCreateToDoModal = () => {
  const { t } = useTranslation();
  return (
    <ModalOverlay
      styles='w-full h-full bg-gray-600 bg-opacity-50 overflow-x-auto scrollbar-thin scrollbar-gray-200'
      zIndex='z-[70]'
    >
      <Wrapper>
        <ModalHeader
          title={t('to_do_procrastinate.new_task')}
          modalType={MODAL_TYPES.EMBEDDED_CREATE_TO_DO_MODAL}
        />
        <NewTask />
      </Wrapper>
    </ModalOverlay>
  );
};

export default EmbeddedCreateToDoModal;
