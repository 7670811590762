import AngleDoubleRight from 'assets/icons/AngleDoubleRight';
import AngleRight from 'assets/icons/AngleRight';
import CloseCircle from 'assets/icons/CloseCircle';
import Edit from 'assets/icons/Edit';
import Button from 'components/common/buttons/Button';
import COLOR from 'constants/color';
import {
  DEFAULT_TO_DO_PLAYER_DURATION,
  DEFAULT_TODO_TASK_DURATION,
  TO_DO_PLAYER_TASK_BTN_DURATION
} from 'constants/general';
import moment from 'moment';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { useContext, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { playerTaskItemSelector } from 'store/reducer/to-do/selectors';
import {
  convertToMinutes,
  convertToSeconds,
  getDurationFormat
} from 'utils/support';
import { PlayerTaskItemContext } from '.';

const PlayerTaskItemDurationActions = () => {
  const [isDurationEditable, setIsDurationEditable] = useState(false);
  const { task, handleDuration } = useContext(PlayerTaskItemContext);
  const { playerData } = useContext(ToDoPlayerContext);
  const player = useAppSelector(playerTaskItemSelector);
  const shouldTaskSelected = player.selectedTask?.id === task?.id;

  const [shouldDisableDecrementMinuteBtn, shouldDisableDecrementTenMinuteBtn] =
    useMemo(
      () => [
        !task?.duration || task.duration - DEFAULT_TODO_TASK_DURATION < 1,
        !task?.duration || task.duration - DEFAULT_TODO_TASK_DURATION * 10 < 1
      ],
      [playerData]
    );

  return (
    <div className='flex items-center relative px-2 gap-1'>
      {shouldTaskSelected && (
        <Button
          icon={<AngleDoubleRight styles='w-3 rotate-180' />}
          onClick={() => {
            handleDuration?.(
              TO_DO_PLAYER_TASK_BTN_DURATION.DECREMENT_TEN_MINUTE,
              task?.id
            );
          }}
          className='bg-gray-500 hover:bg-gray-600 rounded-md shadow p-0.5 disabled:bg-gray-400 disabled:hover:bg-gray-400'
          disabled={shouldDisableDecrementTenMinuteBtn}
        />
      )}
      <Button
        icon={<AngleRight styles='w-3 rotate-180' />}
        onClick={() => {
          handleDuration?.(
            TO_DO_PLAYER_TASK_BTN_DURATION.DECREMENT_A_MINUTE,
            task?.id
          );
        }}
        className='bg-gray-500 hover:bg-gray-600 rounded-md shadow p-0.5 disabled:bg-gray-400 disabled:hover:bg-gray-400'
        disabled={shouldDisableDecrementMinuteBtn}
      />
      {isDurationEditable ? (
        <input
          type='number'
          value={convertToMinutes(task?.duration ?? 0)}
          onChange={({ target: { value } }) => {
            task &&
              handleDuration?.(
                TO_DO_PLAYER_TASK_BTN_DURATION.MANUAL,
                task?.id,
                convertToSeconds(value ? parseInt(value) : 0)
              );
          }}
          className='w-16 rounded-md outline-none p-0.5 text-xs text-center disabled:bg-gray-400 disabled:hover:bg-gray-400'
          min={1}
          onBlur={() => setIsDurationEditable((prev) => !prev)}
        />
      ) : (
        <p className='text-xs md:text-sm lg:text-base relative px-1'>
          {moment()
            .startOf('date')
            .add(task?.duration, 'seconds')
            .format(
              getDurationFormat(task?.duration ?? DEFAULT_TO_DO_PLAYER_DURATION)
            )}
        </p>
      )}
      <Button
        icon={
          isDurationEditable ? (
            <CloseCircle styles='w-3 h-auto' fill={COLOR.WHITE} />
          ) : (
            <Edit styles='w-3 h-auto' fill={COLOR.WHITE} />
          )
        }
        onClick={() => {
          setIsDurationEditable((prev) => !prev);
        }}
        className='bg-gray-500 hover:bg-gray-600 rounded-md shadow p-0.5 disabled:bg-gray-400 disabled:hover:bg-gray-400'
      />
      <Button
        icon={<AngleRight />}
        onClick={() => {
          handleDuration?.(
            TO_DO_PLAYER_TASK_BTN_DURATION.INCREMENT_A_MINUTE,
            task?.id
          );
        }}
        className='bg-gray-500 hover:bg-gray-600 rounded-md shadow p-0.5 disabled:bg-gray-400 disabled:hover:bg-gray-400'
      />
      {shouldTaskSelected && (
        <Button
          icon={<AngleDoubleRight />}
          onClick={() => {
            handleDuration?.(
              TO_DO_PLAYER_TASK_BTN_DURATION.INCREMENT_TEN_MINUTE,
              task?.id
            );
          }}
          className='bg-gray-500 hover:bg-gray-600 rounded-md shadow p-0.5 disabled:bg-gray-400 disabled:hover:bg-gray-400'
        />
      )}
    </div>
  );
};

export default PlayerTaskItemDurationActions;
