import React from 'react';
import CloseCircle from 'assets/icons/CloseCircle';
import { EMPTY_STRING, TAB_INDEX } from 'constants/general';
import COLOR from 'constants/color';
import { useAppSelector } from 'store/hooks';
import { THEME_OPTION } from 'constants/enum';

interface InputCheckItemProps {
  position: number;
  item: string;
  handleInputCheckItem: (position: number, arg: string) => void;
  handleDelete: (position: number) => void;
}

const InputCheckItem = ({
  item,
  position,
  handleInputCheckItem,
  handleDelete
}: InputCheckItemProps) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div className='w-full flex items-center gap-3'>
      <input
        tabIndex={TAB_INDEX.CHECK_LIST_INPUT}
        className={`w-full rounded-md px-2 py-1 outline-none text-sm text-black ${
          item === EMPTY_STRING && 'border-2 border-red-400'
        } ${themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-white'}`}
        type='text'
        value={item}
        onChange={({ target: { value } }) => {
          handleInputCheckItem(position, value);
        }}
      />
      <button
        onClick={() => {
          handleDelete(position);
        }}
      >
        <CloseCircle styles='w-4 md:w-6 h-4 md:h-6' fill={COLOR.RED} />
      </button>
    </div>
  );
};

export default React.memo(InputCheckItem);
