import React from 'react';

interface Props {
  children: React.ReactNode;
  styles?: string;
}

const DetailsPageListWrapper = ({ children, styles = 'gap-8' }: Props) => {
  return (
    <div className={`w-full md:w-[80%] lg:w-3/4 flex flex-col ${styles}`}>
      {children}
    </div>
  );
};

export default DetailsPageListWrapper;
