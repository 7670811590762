import { useEffect } from 'react';
import { COURSE_ID_PARAM } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCourseDetails } from 'store/reducer/course/extra';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Spinner from 'components/common/Spinner';
import CourseLessonsModal from './course-lessons-modal';
import OverlaySpinner from 'components/OverlaySpinner';
import COLOR from 'constants/color';
import { t } from 'i18next';
import CourseData from './CourseData';
import { courseDetailsSelector } from 'store/reducer/course/selectors';

const CourseDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const course_id = new URLSearchParams(window.location.search).get(
    COURSE_ID_PARAM
  );
  const {
    showCourseLessonsModal,
    isLoadingCourseDetails,
    isCreatingLessonCompletion
  } = useAppSelector(courseDetailsSelector);

  useEffect(() => {
    course_id
      ? dispatch(getCourseDetails(course_id))
      : navigate(ROUTES.NOT_FOUND);
  }, []);

  return (
    <section className='w-full min-h-screen flex flex-col md:flex-row gap-8 md:gap-10 overflow-y-auto p-5 md:p-10 bg-main bg-focusBear'>
      {isLoadingCourseDetails ? (
        <Spinner />
      ) : (
        <div className='w-full md:w-1/2 h-fit'>
          <CourseData />
        </div>
      )}
      {showCourseLessonsModal && <CourseLessonsModal />}
      {isCreatingLessonCompletion && (
        <OverlaySpinner
          title={t('course.updating_user_progress')}
          messageStyles='text-black'
          fillSpinner={COLOR.BLACK}
        />
      )}
    </section>
  );
};

export default CourseDetails;
