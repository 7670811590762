import classNames from 'classnames';
import { EMPTY_STRING, PAGE } from 'constants/general';
import { t } from 'i18next';
import { useMemo, PropsWithChildren, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { paginationSelector } from 'store/reducer/pagination/selectors';
import {
  updatePaginationCurrentPage,
  updatePaginationTotalPages
} from 'store/reducer/pagination/slice';
import { decrement, increment } from 'utils/support';

type PaginationBtnProps = {
  label: string;
  styles?: string;
};

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full flex items-start justify-center text-sm rounded-lg gap-0.5'>
    {children}
  </div>
);

const PaginationBtn = ({ label, styles }: PaginationBtnProps) => {
  const dispatch = useAppDispatch();
  const { currentPage, totalPages, hasNextPage } =
    useAppSelector(paginationSelector);

  const { isPageBtnDisabled, currentPageValue } = useMemo(() => {
    if (label === t('admin.previous')) {
      return {
        isPageBtnDisabled: currentPage === PAGE.FIRST,
        currentPageValue: decrement(currentPage)
      };
    } else if (label === t('next')) {
      return {
        isPageBtnDisabled: !hasNextPage,
        currentPageValue: increment(currentPage)
      };
    }
    return {
      isPageBtnDisabled: currentPage === parseInt(label),
      currentPageValue: parseInt(label)
    };
  }, [currentPage, label, hasNextPage]);

  const handleClick = useCallback(() => {
    dispatch(updatePaginationCurrentPage(currentPageValue));
    const shouldIncrementTotalPages =
      label === t('next') && currentPage >= totalPages;
    shouldIncrementTotalPages &&
      dispatch(updatePaginationTotalPages(currentPageValue));
  }, [currentPage, label]);

  return (
    <button
      disabled={isPageBtnDisabled}
      onClick={handleClick}
      className={classNames(
        'w-fit h-fit px-3 py-1 text-center lowercase text-white rounded outline-none shadow',
        {
          'bg-gray-800 scale-105 duration-300': label === String(currentPage),
          'bg-gray-300 text-gray-400': isPageBtnDisabled,
          'bg-gray-500 hover:bg-gray-500/80 cursor-pointer':
            label !== String(currentPage) && !isPageBtnDisabled,
          [styles ?? EMPTY_STRING]: true
        }
      )}
    >
      {label}
    </button>
  );
};

const Pagination = () => {
  const { totalPages } = useAppSelector(paginationSelector);

  return (
    <Wrapper>
      <PaginationBtn label={t('admin.previous')} styles='rounded-l-md' />
      <div className='w-fit h-fit flex items-center justify-center overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 px-1 pb-0.5'>
        {Array.from({ length: totalPages }).map((_, index) => (
          <PaginationBtn
            key={String(++index)}
            label={String(++index)}
            styles='border-x border-white'
          />
        ))}
      </div>
      <PaginationBtn label={t('next')} styles='rounded-r-md' />
    </Wrapper>
  );
};

export default Pagination;
