import { useCallback, useContext } from 'react';
import { NewTaskModalContext } from '.';
import Spinner from 'components/common/Spinner';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { searchedTasksSelector } from 'store/reducer/to-do/selectors';
import { t } from 'i18next';
import SearchResults from './SearchResults';
import { createOrUpdateToDo } from 'store/reducer/to-do/extra';
import { TO_DO_STATUS } from 'constants/enum';
import { v4 as uuid } from 'uuid';
import {
  DEFAULT_NEW_TODO_TASK_DURATION,
  DEFAULT_TO_DO_TASK_EMOJI,
  EISENHOWER_QUADRANT,
  EMPTY_STRING
} from 'constants/general';
import moment from 'moment';
import classNames from 'classnames';
import { isDarkModeActivated } from 'utils/validation';

const initNewTask = (searchedTitle: string) => ({
  id: uuid(),
  status: TO_DO_STATUS.NOT_STARTED,
  title: searchedTitle,
  details: EMPTY_STRING,
  objective: EMPTY_STRING,
  due_date: moment().endOf('day').toISOString(),
  eisenhower_quadrant: parseInt(EISENHOWER_QUADRANT.DO),
  subtasks: [],
  tags: [],
  duration: DEFAULT_NEW_TODO_TASK_DURATION,
  icon: DEFAULT_TO_DO_TASK_EMOJI
});

const SearchedTasks = ({ searchedTitle }: { searchedTitle: string }) => {
  const dispatch = useAppDispatch();
  const { newTaskModal } = useContext(NewTaskModalContext);
  const { isSearchingToDos, searchResults, recentToDos, themeMode } =
    useAppSelector(searchedTasksSelector);

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  const handleCreateTask = useCallback(() => {
    dispatch(
      createOrUpdateToDo({
        payload: initNewTask(searchedTitle),
        shouldFetchTodo: false
      })
    );
  }, [searchedTitle]);

  return (
    <>
      {!searchedTitle &&
        newTaskModal.isSearchTitleFocused &&
        !isSearchingToDos && (
          <p className='text-xs md:text-sm mx-auto my-4'>
            {t(
              'to_do_player.type_the_task_name_above_to_add_a_new_task_or_search_for_an_existing_task'
            )}
          </p>
        )}
      {searchResults?.length ? (
        <p
          className={classNames(
            'text-xs px-4 py-2 text-white mt-1 rounded-t-md',
            {
              'bg-gray-500': shouldActivateDarkMode,
              'bg-gray-600': !shouldActivateDarkMode
            }
          )}
        >
          {t('to_do_player.search_results')}
        </p>
      ) : null}
      {isSearchingToDos && (
        <Spinner
          loaderStyles='w-5 h-5'
          minHeight='h-full py-5'
          borderColor='border-black'
        />
      )}
      {searchResults === null && !recentToDos?.length && (
        <p className='m-auto text-sm'>
          {t('to_do_player.time_to_create_your_first_task')}
        </p>
      )}
      <SearchResults handleCreateTask={handleCreateTask} />
    </>
  );
};

export default SearchedTasks;
