import { createAppSelector } from 'store/hooks';

export const marketplaceHabitPacksTabPanelSelector = createAppSelector(
  [
    (state) => state.habitPack.areHabitPacksLoading,
    (state) => state.setting.language
  ],
  (areHabitPacksLoading, language) => ({
    areHabitPacksLoading,
    language
  })
);

export const detailsPageWrapperSelector = createAppSelector(
  [
    (state) => state.habitPack.isOnHabitPacksTab,
    (state) => state.setting.themeMode
  ],
  (isOnHabitPacksTab, themeMode) => ({
    isOnHabitPacksTab,
    themeMode
  })
);
