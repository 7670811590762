import { useTranslation } from 'react-i18next';

const CallbackComponent = ({platform}: {platform: string}) => {
  const { t } = useTranslation();

  return (
    <div className='flex justify-center items-center h-screen'>
      <h1 className='text-4xl animate-pulse'>
        {t('to_do_procrastinate.connecting', { platform })}
      </h1>
    </div>
  );
};

export default CallbackComponent;
