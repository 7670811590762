import { useMemo } from 'react';
import DropDown from 'components/common/DropDown';
import { IMPACT_MEASUREMENT_OPTIONS } from 'constants/general';
import { DropDownSelectedValue } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionImpactCategory } from 'store/reducer/modal/slice';

const ImpactMeasurement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.modal.moreOption);

  const measurementOptions: DropDownSelectedValue[] = useMemo(
    () =>
      IMPACT_MEASUREMENT_OPTIONS.map((measurement) => ({
        label: measurement.label,
        value: measurement.value
      })),
    []
  );

  return (
    <DropDown
      title={t('impact_measurement.impact_category')}
      value={
        activity.impact_category
          ? {
              label: t(`impact_measurement.${activity.impact_category}`),
              value: activity.impact_category
            }
          : undefined
      }
      options={measurementOptions}
      handleChange={(data) => {
        const result = data as DropDownSelectedValue;
        data && dispatch(updateMoreOptionImpactCategory(result.value));
      }}
      isClearable={false}
      containerStyles='min-w-max w-60 flex flex-col gap-1 text-sm lg:text-base font-medium'
    />
  );
};

export default ImpactMeasurement;
