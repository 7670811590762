import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_TYPES,
  DEFAULT_CHOICE_OPTIONS,
  HABIT_CHOICE_TYPE,
  ROUTINE_CHOICE_OPTIONS,
  EMPTY_STRING
} from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateChoices,
  updateMoreOptionChoices,
  updateMoreOptionChoiceType,
  updateMoreOptionShowCheckListModal,
  updateMoreOptionShowChoicesModal
} from 'store/reducer/modal/slice';
import { ATTRIB } from 'constants/test';
import DropDown from 'components/common/DropDown';
import { DropDownSelectedValue } from 'interfaces';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full sm:w-fit flex flex-col items-center gap-2'>
    {children}
  </div>
);

const ChoiceType = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    modal: {
      moreOption: { activity }
    },
    setting: {
      moreOptions: { type }
    }
  } = useAppSelector((state) => state);

  const optionsForSelect =
    type === ACTIVITY_TYPES.BREAKING
      ? DEFAULT_CHOICE_OPTIONS
      : ROUTINE_CHOICE_OPTIONS;

  const handleChoiceTypeChange = (value: string) => {
    // clear choices array and choice type if choice type is set to 'none'
    if (value === HABIT_CHOICE_TYPE.NONE) {
      dispatch(updateMoreOptionChoiceType(undefined));
      dispatch(updateMoreOptionChoices([]));
    } else {
      dispatch(updateMoreOptionChoiceType(value));
    }
  };

  const shouldShowEditChoicesButton = [
    HABIT_CHOICE_TYPE.RANDOM,
    HABIT_CHOICE_TYPE.COMPETENCY_BASED,
    HABIT_CHOICE_TYPE.CHECK_LIST
  ].includes(activity?.choice_type ?? EMPTY_STRING);
  const isChecklistType =
    activity?.choice_type === HABIT_CHOICE_TYPE.CHECK_LIST;

  return (
    <Wrapper>
      <DropDown
        title={t('choice_type')}
        value={{
          label: String(
            activity.choice_type
              ? t(activity.choice_type)
              : t(HABIT_CHOICE_TYPE.NONE)
          ).toUpperCase(),
          value: activity?.choice_type ?? HABIT_CHOICE_TYPE.NONE
        }}
        options={optionsForSelect}
        handleChange={(data) => {
          const result = data as DropDownSelectedValue;
          result && handleChoiceTypeChange(result.value);
        }}
        containerStyles='w-full min-w-[120px] sm:min-w-[220px] text-xs sm:text-sm outline-none cursor-pointer font-medium rounded-md'
        isClearable={false}
      />
      {shouldShowEditChoicesButton ? (
        <button
          data-test={ATTRIB.TEST.EDIT_ACTIVITY_CHOICE}
          onClick={() => {
            if (isChecklistType) {
              dispatch(updateMoreOptionShowCheckListModal(true));
            } else {
              dispatch(updateChoices(activity?.choices ?? []));
              dispatch(updateMoreOptionShowChoicesModal(true));
            }
          }}
          className='min-w-max w-fit h-fit text-[10px] font-medium tracking-wider px-3 py-1 rounded-md text-white bg-gray-500 hover:bg-gray-600 self-end'
        >
          {isChecklistType ? t('edit_check_lists') : t('edit_choices')}
        </button>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(ChoiceType);
