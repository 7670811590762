import {
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmButton from 'components/common/buttons/ConfirmButton';
import {
  EMPTY_STRING,
  SUBSCRIPTION_PLAN,
  TEAM_NAME_PLACEHOLDER
} from 'constants/general';
import CloseFilled from 'assets/icons/CloseFilled';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalWrapper from 'components/shared/ModalWrapper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TEAM_OWNER_ACTION } from 'constants/enum';
import { updateShowTeamOwnerActionModal } from 'store/reducer/team/slice';
import { inviteMember, modifyTeamName } from 'store/reducer/team/extra';
import { DropDownSelectedValue, InviteMemberPayload } from 'interfaces';
import { updateShowTeamNameModal } from 'store/reducer/user/slice';
import { saveMarkerIndicatingUserOpenedStripe } from 'utils/support';
import { getStripeCheckoutURL } from 'services/subscription';
import { updateError } from 'store/reducer/setting/slice';
import * as Sentry from '@sentry/react';
import InvitationForm from './InvitationForm';
import { teamOwnerActionModalSelector } from 'store/reducer/team/selectors';

export interface WidgetData {
  email: string;
  teamName: string;
  isValidEmail: boolean;
  isEmailExists: boolean;
  user?: DropDownSelectedValue;
  first_name?: string;
  last_name?: string;
  member_expiry_date?: string;
  is_admin: boolean;
  is_member: boolean;
}

const ActionWidget = ({
  widgetData,
  setWidgetData
}: {
  widgetData: WidgetData;
  setWidgetData: Dispatch<SetStateAction<WidgetData>>;
}) => {
  const { teamOwnerAction, isUpdatingTeamName } = useAppSelector(
    (state) => state.team
  );

  return teamOwnerAction === TEAM_OWNER_ACTION.INVITE_MEMBER ? (
    <InvitationForm widgetData={widgetData} setWidgetData={setWidgetData} />
  ) : (
    <input
      disabled={isUpdatingTeamName}
      value={widgetData.teamName}
      onChange={({ target: { value } }) =>
        setWidgetData((prev) => ({ ...prev, teamName: value }))
      }
      className='outline-none px-2 py-1 border-2 border-gray-200 focus:bg-gray-200 rounded-md'
    />
  );
};

export default function TeamOwnerActionModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    teamOwnerAction,
    selectedTeam,
    isUpdatingTeamName,
    teams,
    isInvitingUser,
    availableStripePlans,
    userInfo
  } = useAppSelector(teamOwnerActionModalSelector);

  const [widgetData, setWidgetData] = useState<WidgetData>({
    email: EMPTY_STRING,
    teamName: selectedTeam?.name ?? TEAM_NAME_PLACEHOLDER,
    isValidEmail: false,
    isEmailExists: false,
    is_admin: false,
    is_member: true
  });

  const getActionWidgetInfo = useCallback(() => {
    switch (teamOwnerAction) {
      case TEAM_OWNER_ACTION.INVITE_MEMBER:
        return {
          title: t('admin_team.invite_member'),
          label: t('invite'),
          description: t('enter_the_email_of_the_member_you_want_to_invite')
        };
      case TEAM_OWNER_ACTION.UPDATE_NAME:
        return {
          title: t('admin_team.update_team_name'),
          label: t('update'),
          description: EMPTY_STRING
        };
      default:
        return {
          title: t('enter_the_name_for_your_team'),
          label: t('accept'),
          description: EMPTY_STRING
        };
    }
  }, [teamOwnerAction]);

  const { title, label, description } = useMemo(
    () => getActionWidgetInfo(),
    [teamOwnerAction, teams]
  );

  const handleConfirmButton = useCallback(() => {
    if (teamOwnerAction === TEAM_OWNER_ACTION.UPDATE_NAME) {
      dispatch(
        modifyTeamName({
          name: widgetData.teamName,
          team_id: selectedTeam?.id ?? EMPTY_STRING
        })
      );
    } else if (teamOwnerAction === TEAM_OWNER_ACTION.INVITE_MEMBER) {
      let payload: InviteMemberPayload = {
        email: widgetData.email.trim(),
        team_id: selectedTeam?.id ?? EMPTY_STRING,
        is_admin: widgetData.is_admin,
        is_member: widgetData.is_member
      };
      if (widgetData.first_name) {
        payload = { ...payload, first_name: widgetData.first_name };
      }
      if (widgetData.last_name) {
        payload = { ...payload, last_name: widgetData.last_name };
      }
      if (widgetData.member_expiry_date) {
        payload = {
          ...payload,
          member_expiry_date: widgetData.member_expiry_date
        };
      }
      dispatch(inviteMember(payload));
    } else if (teamOwnerAction === TEAM_OWNER_ACTION.CREATE_NAME) {
      try {
        const plan = availableStripePlans.find(
          (plan) => plan.id === SUBSCRIPTION_PLAN.TEAM
        );
        if (plan) {
          saveMarkerIndicatingUserOpenedStripe();
          getStripeCheckoutURL(plan.default_price, widgetData.teamName);
          dispatch(updateShowTeamNameModal(false));
        } else {
          throw new Error("couldn't find a team plan");
        }
      } catch (error) {
        Sentry.captureException(error);
        dispatch(
          updateError({ message: t('errors.couldnt_fetch_stripe_checkout') })
        );
      }
    }
  }, [widgetData]);

  const isButtonDisabled = () => {
    switch (teamOwnerAction) {
      case TEAM_OWNER_ACTION.INVITE_MEMBER:
        return (
          !widgetData.isValidEmail ||
          widgetData.isEmailExists ||
          widgetData.email === userInfo.email
        );
      case TEAM_OWNER_ACTION.UPDATE_NAME:
      case TEAM_OWNER_ACTION.CREATE_NAME:
        return !widgetData.teamName;
      default:
        return Boolean(!widgetData.user);
    }
  };

  return (
    <ModalOverlay zIndex='z-[99]'>
      <ModalWrapper>
        <button
          className='self-end'
          onClick={() => {
            dispatch(updateShowTeamNameModal(false));
            dispatch(updateShowTeamOwnerActionModal(false));
          }}
        >
          <CloseFilled />
        </button>
        <h1 className='text-2xl 2xl:text-4xl font-bold'>{title}</h1>
        <h3 className='px-5 sm:px-10 text-sm sm:text-lg md:text-2xl'>
          {description}
        </h3>
        <ActionWidget widgetData={widgetData} setWidgetData={setWidgetData} />
        {isUpdatingTeamName || isInvitingUser ? (
          <div className='w-6 h-6 rounded-full border-r-2 border-gray-500 animate-spin my-2'></div>
        ) : (
          <ConfirmButton
            isButtonDisabled={isButtonDisabled()}
            label={label}
            onClickButton={handleConfirmButton}
          />
        )}
      </ModalWrapper>
    </ModalOverlay>
  );
}
