import { Entitlement, SORT_OPTION } from 'constants/enum';
import { t } from 'i18next';
import { Team, TeamMember } from 'interfaces';

export const getRemainingTeams = (team: Team | null) =>
  team ? team.team_size_limit - team.team_size : 0;

export const isTeamOwnerOrAdmin = (activeEntitlements: string[]) =>
  activeEntitlements.some((activeEntitlement) =>
    [Entitlement.TEAM_OWNER, Entitlement.TEAM_ADMIN].includes(
      activeEntitlement as Entitlement
    )
  );

export const teamMembersColumns = [
  { title: '', styles: 'w-[20px]', shouldSortAllowed: false },
  {
    title: t('email'),
    styles: 'w-[250px]',
    shouldSortAllowed: false
  },
  {
    title: t('admin_team.first_name'),
    styles: 'w-[220px]',
    shouldSortAllowed: false
  },
  {
    title: t('admin_team.last_name'),
    styles: 'w-[220px]',
    shouldSortAllowed: false
  },
  {
    title: t('admin_team.last_active'),
    styles: 'w-[200px]',
    shouldSortAllowed: false
  },
  {
    title: t('admin_team.has_access'),
    styles: 'w-[300px]',
    shouldSortAllowed: false
  },
  {
    title: t('admin_team.member_type'),
    styles: 'w-[220px]',
    shouldSortAllowed: false
  },
  {
    title: t('admin_team.morning_streak'),
    styles: 'w-[250px]',
    shouldSortAllowed: true
  },
  {
    title: t('admin_team.evening_streak'),
    styles: 'w-[250px]',
    shouldSortAllowed: true
  },
  {
    title: t('admin_team.focus_mode_streak'),
    styles: 'w-[250px]',
    shouldSortAllowed: true
  },
  {
    title: t('avg_focus_modes_90_days'),
    styles: 'w-[280px]',
    shouldSortAllowed: true
  },
  {
    title: t('avg_morning_90_days'),
    styles: 'w-[250px]',
    shouldSortAllowed: true
  },
  {
    title: t('avg_micro_break_90_days'),
    styles: 'w-[280px]',
    shouldSortAllowed: true
  },
  {
    title: t('avg_evening_90_days'),
    styles: 'w-[250px]',
    shouldSortAllowed: true
  }
];

export const getSortedMembers = (
  members: TeamMember[],
  column?: string,
  sortType: SORT_OPTION = SORT_OPTION.ASC
) => {
  const compare = (a: number, b: number) =>
    sortType === SORT_OPTION.DESC ? b - a : a - b;

  const columnMapping: { [key: string]: (member: TeamMember) => number } = {
    [t('admin_team.morning_streak')]: (member) =>
      member.morning_routines_streak,
    [t('admin_team.evening_streak')]: (member) =>
      member.evening_routines_streak,
    [t('admin_team.focus_mode_streak')]: (member) => member.focus_modes_streak,
    [t('avg_focus_modes_90_days')]: (member) =>
      member.focus_modes_percent_number_day_of_stats_completed,
    [t('avg_morning_90_days')]: (member) =>
      member.morning_percent_number_day_of_stats_completed,
    [t('avg_micro_break_90_days')]: (member) =>
      member.micro_percent_number_day_of_stats_completed,
    [t('avg_evening_90_days')]: (member) =>
      member.evening_percent_number_day_of_stats_completed
  };

  if (!column) {
    return members;
  }
  const getColumnValue = columnMapping[column];
  return members.sort((a, b) => compare(getColumnValue(a), getColumnValue(b)));
};
