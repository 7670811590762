import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PAGINATE } from 'constants/enum';
import { PAGE } from 'constants/general';
import { initialPaginationState } from 'store/initial-states';

const pagination = createSlice({
  name: 'pagination',
  initialState: initialPaginationState,
  reducers: {
    initPagination: (
      state,
      {
        payload: { type, itemsPerPage }
      }: PayloadAction<{ type: PAGINATE | null; itemsPerPage: number }>
    ) => {
      state.paginationType = type;
      state.itemsPerPage = itemsPerPage;
      state.currentPage = PAGE.FIRST;
      state.totalPages = PAGE.INIT_TOTAL;
    },
    updatePaginationCurrentPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.currentPage = payload;
    },
    updatePaginationTotalPages: (state, { payload }: PayloadAction<number>) => {
      state.totalPages = payload;
    },
    updatePaginationNavigation: (
      state,
      {
        payload: { hasNextPage, hasPreviousPage }
      }: PayloadAction<{ hasPreviousPage: boolean; hasNextPage: boolean }>
    ) => {
      state.hasPreviousPage = hasPreviousPage;
      state.hasNextPage = hasNextPage;
    }
  }
});

export const {
  initPagination,
  updatePaginationCurrentPage,
  updatePaginationTotalPages,
  updatePaginationNavigation
} = pagination.actions;
export default pagination.reducer;
