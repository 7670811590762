import { THEME_OPTION } from 'constants/enum';
import React from 'react';
import { useAppSelector } from 'store/hooks';

interface Props {
  title: string;
  duration: string;
}

const YouTubeMetaData = ({ title, duration }: Props) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div className='w-full flex items-center gap-2'>
      <div className='w-fit text-xs truncate'>{title}</div>
      <div
        className={`w-fit text-xs ${themeMode === THEME_OPTION.DARK ? 'text-focusBearText' : 'text-blue-700'}`}
      >{`(${duration})`}</div>
    </div>
  );
};

export default YouTubeMetaData;
