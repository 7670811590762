import { EMPTY_STRING } from 'constants/general';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChangeEmail?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  emailValue: string;
  hasError?: boolean;
}

const EmailInput = ({ onChangeEmail, emailValue, hasError = false }: Props) => {
  const { t } = useTranslation();
  const isInValidInput = hasError && emailValue !== EMPTY_STRING;
  return (
    <input
      onChange={onChangeEmail}
      value={emailValue}
      type='text'
      placeholder={t('email')}
      className={`w-full border ${
        isInValidInput ? 'border-rose-400' : 'border-black'
      } rounded-lg px-4 py-2 outline-none shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] text-sm sm:text-base`}
    />
  );
};

export default EmailInput;
