const Endpoints = {
  USER_SETTINGS: '/user-settings',
  USER_DETAILS: '/user/details',
  USER_DETAILS_CURRENT_ACTIVITY_PROPS: '/user/details/current-activity-props',
  USER_AVAILABLE_PLANS: '/subscription/stripe/products',
  COMPLETED_ACTIVITY: '/completed-activity',
  COMPLETED_ACTIVITY_SEQUENCE_STATS:
    '/completed-activity-sequence/{activity_sequence_id}/stats?days_number=30&timezone=UTC',
  FORCE_COMPLETE_CURRENT_SEQUENCE:
    '/completed-activity-sequence/{activity_sequence_id}/force-complete-current-sequence',
  WEEKLY_FOCUS_BLOCK_SUMMARY: 'user/weekly-focus-block-summary',
  WEEKLY_COMPLETED_ACTIVITY_SUMMARY: 'user/weekly-completed-activity-summary',
  USER_INSTALLED_HABIT_PACKS: '/habit-packs/user-packs',
  HABIT_PACKS: '/habit-packs',
  INSTALL_HABIT_PACK: '/habit-packs/{pack_id}/install',
  INSTALL_HABIT_PACK_AS_DEFAULT_SETTINGS:
    '/habit-packs/default?pack_id={pack_id}',
  UNINSTALL_HABIT_PACK: '/habit-packs/{pack_id}/uninstall',
  GET_USER_CREATED_PACKS: '/habit-packs?user_id={user_id}',
  ACCESS_HABIT_PACK_BY_ID: '/habit-packs/{pack_id}',
  USER_LIST: '/user/user-list',
  USER_INFO_AND_FOCUS_MODES: '/user',
  USER_SIGN_UP_TEMPLATE: '/user/sign-up-template',
  VIDEO_METADATA: '/video-metadata',
  UPSERT_FOCUS_MODE_TEMPLATE: '/focus-mode-template',
  GET_USER_CREATED_FOCUS_TEMPLATES: '/focus-mode-template?author_id={user_id}',
  GET_MARKETPLACE_APPROVED_FOCUS_TEMPLATES:
    '/focus-mode-template?marketplace_approval_status=true',
  GET_USER_INSTALLED_FOCUS_TEMPLATES: '/focus-mode-template/user-installed',
  DELETE_FOCUS_TEMPLATE: '/focus-mode-template/{template_id}',
  INSTALL_FOCUS_TEMPLATE: '/focus-mode-template/{template_id}/install',
  UNINSTALL_FOCUS_TEMPLATE: '/focus-mode?id={focus_mode_id}',
  GET_FOCUS_TEMPLATE_BY_ID: '/focus-mode-template/{template_id}',
  ACTIVITY_LIBRARY: '/activity-library',
  USER_METADATA: '/user/metadata',
  DELETE_ACTIVITY_IMAGE: '/activity/images?path_file={path_file}',
  DEVICE: '/device',
  USER_ONBOARDING_STATS: '/user/stats/onboarding',
  MOTIVATIONAL_SUMMARY: '/user/motivational-summary',
  CHAT: '/user/chat',
  USER_CONSENT: '/user/consent',
  ACTIVITY: '/activity',
  STRIPE: {
    CREATE_CHECKOUT_SESSION: 'subscription/stripe/create-checkout-session',
    CREATE_PORTAL_SESSION: 'subscription/stripe/create-portal-session',
    CANCEL_SUBSCRIPTION_SESSION:
      'subscription/stripe/cancel-subscription-session'
  },
  REVENUE_CAT: `https://api.revenuecat.com/v1/subscribers/`,
  USER_ACCESS_REQUEST: 'user/access-request',
  COMPLETED_ACTIVITY_SKIP: '/completed-activity/skip',
  USER_STATS_LEADER_BOARD: 'user-stats/leaderboard',
  COMPLETED_ACTIVITY_DAY_SUMMARY: 'completed-activity/day-summary',
  USER_LONG_TERM_GOALS: 'user/long-term-goals',
  USER_WEEKLY_FOCUS_BLOCK_SUMMARY: 'user/weekly-focus-block-summary',
  USER_USERNAME: 'user/username',
  USER_DATA: '/user-data',
  USER: 'user',
  SAVED_WEBSITE: 'saved-website',
  SAVED_WEBSITE_DELETE: 'saved-website/delete',
  TO_DO: 'to-do',
  FOCUS_MODE_TAGS: 'focus-mode/tags',
  USER_STATS_DAILY_SUMMARY: 'user-stats/daily-summary',
  USER_FEEDBACK: '/user-feedback',
  USER_PROJECTS: (platform: string) => `integration/${platform}/user-projects`,
  SYNC_PROJECT: (platform: string) => `integration/${platform}/sync-project`,
  INTEGRATION_AUTH: (platform: string) => `auth/${platform.toLowerCase()}`,
  ZOHO_AUTH: 'auth/zoho',
  MONDAY_AUTH: 'auth/monday',
  JIRA_AUTH: 'auth/jira',
  ASANA_AUTH: 'auth/asana',
  TRELLO_AUTH: 'auth/trello',
  CLICK_UP_AUTH: 'auth/clickup',
  GOOGLE_AUTH: 'auth/google',
  MICROSOFT_AUTH: 'auth/microsoft',
  CALENDAR_FETCH_DATA: (platform: string) =>
    `calendar/${platform.toLowerCase()}/calendar-datas`,
  CALENDAR_UPDATE: 'calendar/calendar-update',
  CALENDAR_KEYWORD_CREATE: 'calendar/keyword-create',
  CALENDAR_KEYWORD_UPDATE: 'calendar/keyword-update',
  CALENDAR_KEYWORD_DELETE: 'calendar/keyword-delete',
  USER_LOCAL_DEVICE_SETTINGS: 'user-local-device-settings',
  SYNCED_PROJECTS_EXTERNAL_STATUS: 'synced-projects/external-statuses',
  TEAM_MANAGEMENT: 'team-management',
  COMPLETED_ACTIVITY_LOG_QUESTION_STATS: (question_id: string) =>
    `completed-activity/log-question/${question_id}/stats`,
  TO_DO_GENERATE_SUBTASKS: 'to-do/generate-subtasks',
  ADMIN_USER_DEVICE: '/device/admin',
  USER_HABIT_PACKS: 'habit-packs/user',
  AI_CHAT: '/ai/chat',
  SYNC_TASKS: (platform: string) => `integration/${platform}/sync-tasks`,
  GET_TASKS: (platform: string, portalId: number, projectId: string) =>
    `integration/${platform}/${portalId}/projects/${projectId}/tasks`,
  UPDATE_ASSIGNEE_FILTER_STATUS: (platform: string) =>
    `integration/${platform.toLowerCase()}/assignee-filter-status`,
  ASSIGNEE_FILTER_STATUS: 'integration/assignee',
  COURSE_DETAILS: (course_id: string) => `course/${course_id}`,
  LESSON_COMPLETE: 'lesson/complete',
  SYNC_PLATFORM_COURSES: 'course/sync-platform-courses',
  USER_TUTORIALS: 'course/user-tutorials',
  TO_DO_SEARCH: 'to-do/search',
  TO_DO_RECENT: 'to-do/recent',
  ACTIVITY_LIBRARY_ROUTINE_SUGGESTIONS: 'activity-library/routine-suggestions',
  ENROLLED_COURSES: 'course/enrolled',
  USER_NOT_ENROLLED_COURSES: 'course/user-not-enrolled',
  COURSE_ENROLLED: 'course/enrolled',
  SURVEY: 'survey',
  TO_DO_BRIAN_DUMP: 'to-do/convert-brain-dump'
};

export default Endpoints;
