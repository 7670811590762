import { useEffect } from 'react';
import { Entitlement } from 'constants/enum';
import { SUBSCRIPTION_PLAN } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Plan from './Plan';
import TabLayout from 'components/common/TabLayout';
import { updateTabLayout } from 'store/reducer/general/slice';
import { t } from 'i18next';
import { isRouteEmbedded } from 'utils/validation';

const AvailablePlans = () => {
  const dispatch = useAppDispatch();
  const { availableStripePlans, subscriptionInfo } = useAppSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(
      updateTabLayout(
        isRouteEmbedded ? [t('personal')] : [t('personal'), t('team')] // @Description: Hide team subscription from popup as per Jeremy's request
      )
    );
  }, []);
  const personalMonthlyPlan = availableStripePlans.find(
    (plan) => plan?.id === SUBSCRIPTION_PLAN.PERSONAL_MONTHLY
  )!;
  const teamPlan = availableStripePlans.find(
    (plan) => plan?.id === SUBSCRIPTION_PLAN.TEAM
  )!;

  return (
    <div className='w-full h-fit flex flex-col items-center px-2 gap-5 mx-auto'>
      <div className='w-full lg:w-3/4 xl:8/12 h-fit flex flex-col items-center justify-center gap-6'>
        <h1 className='text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
          {t('subscription_plan.plans_and')}
          <span className='italic'>{t('subscription_plan.pricing')}</span>
        </h1>
        <p className='text-sm sm:text-base md:text-lg text-gray-500'>
          {t('subscribe_message')}
        </p>
        {subscriptionInfo?.planType === Entitlement.TRIAL && (
          <p className='text-orange-400 text-base sm:text-lg md:text-xl'>
            {t('day', { count: subscriptionInfo?.daysLeftInTrial })}
          </p>
        )}
      </div>
      <TabLayout
        tabs={
          isRouteEmbedded // @Description: Hide team subscription from popup as per Jeremy's request
            ? [
                <Plan
                  key={personalMonthlyPlan?.id}
                  plan={personalMonthlyPlan}
                />
              ]
            : [
                <Plan
                  key={personalMonthlyPlan?.id}
                  plan={personalMonthlyPlan}
                />,
                <Plan key={teamPlan?.id} plan={teamPlan} />
              ]
        }
        selectedTabColor='bg-gray-900/60'
        navigation_styles='bg-whiteLace'
      />
    </div>
  );
};

export default AvailablePlans;
