import React, { useMemo } from 'react';
import InputURL from '../URLModal/InputURL';
import { ActivityImagesDataType } from 'interfaces';
import { useTranslation } from 'react-i18next';
import {
  EMPTY_STRING,
  INDEX,
  MAXIMUM_IMAGE_URLS,
  MODAL_TYPES
} from 'constants/general';
import { v4 as uuid } from 'uuid';
import UploadFileButton from 'components/file-uploader/UploadFileButton';
import { UploadWidgetResult } from 'uploader';
import { deleteActivityImage } from 'services/upload-io';
import ModalOverlay from 'components/shared/ModalOverlay';
import {
  updateMoreOptionImageURLs,
  updateMoreOptionShowImageURLModal
} from 'store/reducer/modal/slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ModalHeader from 'components/shared/ModalHeader';
import COLOR from 'constants/color';
import CloseCircle from 'assets/icons/CloseCircle';
import { THEME_OPTION } from 'constants/enum';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`relative top-[40%] -translate-y-[40%] mx-auto w-[95%] sm:w-3/5 md:1/2 lg:w-1/2 shadow-lg rounded-md ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-700' : 'bg-gray-200'
      } flex flex-col gap-2`}
    >
      {children}
    </div>
  );
};

const NoImageFound = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full flex justify-center text-xs font-semibold text-blue-600'>
      {t('no_images_found')}
    </div>
  );
};

const ImagesModalActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const habitUrls = useMemo(
    () => activity?.image_urls ?? [],
    [activity.image_urls]
  );
  const isMaxImageUrlAmountReached = habitUrls.length >= MAXIMUM_IMAGE_URLS;

  const handleSave = () => {
    const checkInvalidUrls = habitUrls.some(
      (item) => item.url === EMPTY_STRING
    );
    if (!checkInvalidUrls) {
      dispatch(updateMoreOptionImageURLs(habitUrls));
      dispatch(updateMoreOptionShowImageURLModal(false));
    }
  };

  return (
    <div className='w-fit h-fit flex flex-col items-center self-end pr-3 pb-2'>
      <UploadFileButton
        setUrls={(files: UploadWidgetResult[]) => {
          if (files.length) {
            const { fileUrl, filePath } = files[INDEX.ZERO];
            dispatch(
              updateMoreOptionImageURLs([
                ...habitUrls,
                { url: fileUrl, file_path: filePath }
              ])
            );
          }
        }}
        folderPath={`/uploads/activity_images/${activity?.id}`}
      />
      {isMaxImageUrlAmountReached ? (
        <div className='group relative'>
          <button className='w-24 h-fit text-xs font-semibold tracking-wider py-1.5 self-end rounded-md text-white mr-2 mb-2 bg-gray-500 hover:bg-gray-700 cursor-pointer transition ease-in-out delay-150 hover:scale-110  duration-300 disabled:cursor-not-allowed'>
            {t('add_url')}
          </button>
          <div className='rounded absolute hidden bg-black text-white mt-1 p-1 group-hover:block w-40 text-xs'>
            <p>{t('max_image_urls_tooltip')}</p>
          </div>
        </div>
      ) : (
        <button
          onClick={() => {
            dispatch(
              updateMoreOptionImageURLs([...habitUrls, { url: EMPTY_STRING }])
            );
          }}
          className='w-24 h-fit text-xs font-semibold tracking-wider py-1.5 self-end rounded-md text-white mr-2 mb-2 bg-gray-500 hover:bg-gray-700 cursor-pointer transition ease-in-out delay-150 hover:scale-110  duration-300'
        >
          {t('add_url')}
        </button>
      )}
      <button
        onClick={handleSave}
        className='w-24 h-fit text-xs font-semibold tracking-wider py-1.5 self-end rounded-md text-white mr-2 mb-2 bg-blue-600 hover:bg-blue-800 cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300'
      >
        {t('save')}
      </button>
    </div>
  );
};

const ImagesModal = () => {
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const { t } = useTranslation();
  const habitUrls = activity?.image_urls ?? [];

  const handleDelete = async (
    imageIndex: number,
    filePath?: string
  ): Promise<void> => {
    const urls = habitUrls.filter(
      (_: ActivityImagesDataType, index: number) => index !== imageIndex
    );
    dispatch(updateMoreOptionImageURLs(urls));
    if (filePath) {
      await deleteActivityImage(filePath);
    }
  };

  return (
    <ModalOverlay zIndex='z-[70]'>
      <Wrapper>
        <ModalHeader
          title={t('title_image_url')}
          modalType={MODAL_TYPES.IMAGE_URLS}
        />
        <div className='w-full max-h-80 sm:max-h-72 flex flex-col gap-1 px-4 py-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
          {!habitUrls.length ? (
            <NoImageFound />
          ) : (
            habitUrls.map((image, index: number) => (
              <>
                {!image.file_path ? (
                  <div key={uuid()} className='flex items-center gap-3'>
                    <InputURL
                      modalType={MODAL_TYPES.IMAGE_URLS}
                      id={index}
                      url={image.url}
                    />
                    <button
                      onClick={() => handleDelete(index, image.file_path)}
                    >
                      <CloseCircle fill={COLOR.RED} />
                    </button>
                  </div>
                ) : (
                  <div
                    key={uuid()}
                    className='flex flex-col items-left justify-between'
                  >
                    <div className='flex items-center justify-between mb-2'>
                      <p>{image.file_path.split('/').pop()}</p>
                      <button
                        onClick={() => handleDelete(index, image.file_path)}
                      >
                        <CloseCircle fill={COLOR.RED} />
                      </button>
                    </div>
                  </div>
                )}
                {image.url && (
                  <img
                    src={image.url}
                    alt={`url-${index}`}
                    className='w-[200px]'
                  />
                )}
              </>
            ))
          )}
        </div>
        <ImagesModalActions />
      </Wrapper>
    </ModalOverlay>
  );
};

export default React.memo(ImagesModal);
