import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateChoicesLogSummary,
  updateMoreOptionLogSummaryType
} from 'store/reducer/modal/slice';
import {
  EMPTY_STRING,
  LOG_SUMMARY_OPTION,
  MODAL_TYPES,
  REACT_SELECT_DROPDOWN
} from 'constants/general';
import HelpFilled from 'assets/icons/HelpFilled';
import { ChoiceItemContext } from 'components/setting-generator/tabs/ChoicesModal/ChoiceItem';
import DropDown from 'components/common/DropDown';
import { SingleValue } from 'react-select';
import { DropDownSelectedValue } from 'interfaces';

interface WrapperProps {
  children: React.ReactNode;
  modalType: string;
}

interface LogSummaryProps {
  modalType?: string;
}

export const LogSummaryContext = React.createContext({
  modalType: MODAL_TYPES.MORE_OPTION
});

const Wrapper = ({ children, modalType }: WrapperProps) => (
  <div
    className={`h-fit flex ${
      modalType === MODAL_TYPES.MORE_OPTION
        ? 'w-[95%] md:w-[28%] lg:w-fit py-3 px-4'
        : 'w-full'
    } flex-col gap-1 border-[0.5px] border-gray-100 rounded-md`}
  >
    <LogSummaryContext.Provider value={useMemo(() => ({ modalType }), [])}>
      {children}
    </LogSummaryContext.Provider>
  </div>
);

const LogSummaryHeader = () => {
  const { t } = useTranslation();
  const { modalType } = useContext(LogSummaryContext);

  return (
    <div className='w-fit flex pb-0.5  gap-2 relative z-30'>
      <div className='w-full text-xs lg:text-sm flex items-center font-medium'>
        {t('log_summary_type')}
      </div>
      <span className='group'>
        <HelpFilled />
        <div
          className={`w-[25rem] absolute ${
            modalType === MODAL_TYPES.MORE_OPTION
              ? 'top-1/2 -translate-y-1/2 right-5'
              : 'bottom-0 left-0 lg:left-40'
          } items-center hidden mb-6 group-hover:flex`}
        >
          <p className='w-full h-fit relative z-10 p-2 text-xs leading-4 text-white bg-black shadow-lg rounded-lg'>
            {t('use_average_if_you_are_tracking_something_like_mode')}
          </p>
        </div>
      </span>
    </div>
  );
};

const LogSummary = ({
  modalType = MODAL_TYPES.MORE_OPTION
}: LogSummaryProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    moreOption: { activity },
    choice: { choices }
  } = useAppSelector((state) => state.modal);
  const { position } = useContext(ChoiceItemContext);

  const options: { label: string; value: string }[] = useMemo(
    () => [
      { label: t('average'), value: LOG_SUMMARY_OPTION.AVERAGE },
      { label: t('total'), value: LOG_SUMMARY_OPTION.SUMMATION }
    ],
    []
  );

  const logSummaryType = () => {
    const activityLogSummary =
      modalType === MODAL_TYPES.MORE_OPTION
        ? activity?.log_summary_type
        : choices[position]?.log_summary_type;
    if (activityLogSummary === LOG_SUMMARY_OPTION.AVERAGE) {
      return { label: t('average'), value: LOG_SUMMARY_OPTION.AVERAGE };
    } else if (activityLogSummary === LOG_SUMMARY_OPTION.SUMMATION) {
      return { label: t('total'), value: LOG_SUMMARY_OPTION.SUMMATION };
    } else {
      return { label: EMPTY_STRING, value: EMPTY_STRING };
    }
  };

  return (
    <Wrapper modalType={modalType}>
      <LogSummaryHeader />
      <DropDown
        value={logSummaryType()}
        options={options}
        handleChange={(data) => {
          const result = data as SingleValue<DropDownSelectedValue>;
          if (modalType === MODAL_TYPES.MORE_OPTION) {
            result && dispatch(updateMoreOptionLogSummaryType(result.value));
          } else {
            result &&
              dispatch(
                updateChoicesLogSummary({ position, data: result.value })
              );
          }
        }}
        containerStyles={`w-full ${
          modalType === MODAL_TYPES.MORE_OPTION
            ? 'text-xs lg:text-sm'
            : 'text-xs'
        } font-medium`}
        zIndex={REACT_SELECT_DROPDOWN.Z_INDEX.CHOICE}
      />
    </Wrapper>
  );
};

export default LogSummary;
