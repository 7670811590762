import { useState } from 'react';
import { BrainDumpTask } from 'interfaces/commonInterface';
import Button from 'components/common/buttons/Button';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import { t } from 'i18next';
import CheckBox from 'components/common/CheckBox';
import TextInput from 'components/common/inputs/TextInput';
import { useAppSelector } from 'store/hooks';
import { brainDumpItemSelector } from 'store/reducer/to-do/selectors';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import classNames from 'classnames';
import { SECOND_SUBTASK_POSITION } from 'constants/general';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';

interface BrainDumpItemProps {
  task: BrainDumpTask;
  position: number;
  handleBrainDumpActions: (position: number, value?: BrainDumpTask) => void;
}

const BrainDumpItemSubTasks = ({
  subtasks,
  position
}: {
  subtasks: string[];
  position: number;
}) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  return (
    <div
      className={classNames(
        'hidden group-hover:flex flex-col gap-2.5 absolute right-[101%] min-w-1/2 w-[50vw] md:w-[35vw] lg:w-[25vw] h-fit max-h-[20vh] p-2 rounded shadow-md overflow-y-auto scrollbar-thin z-50',
        {
          'top-0': position < SECOND_SUBTASK_POSITION,
          'bottom-0': position >= SECOND_SUBTASK_POSITION,
          'bg-gray-700 text-white': shouldActivateDarkMode,
          'bg-sunRayFifth text-black': !shouldActivateDarkMode
        }
      )}
    >
      {subtasks.map((subtask, idx) => (
        <p
          key={`${subtask}${idx}`}
          className='w-full text-xs px-2 py-1 rounded shadow text-left'
        >
          {subtask}
        </p>
      ))}
    </div>
  );
};

const BrainDumpItem = ({
  task,
  position,
  handleBrainDumpActions
}: BrainDumpItemProps) => {
  const [convertedTask, setConvertedTask] = useState(task);
  const { isAddingConvertedTasksToPlayer, themeMode } = useAppSelector(
    brainDumpItemSelector
  );
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  return (
    <div className='w-full flex items-start gap-2'>
      <CheckBox
        checked={convertedTask.selected}
        onChange={({ target: { checked: selected } }) => {
          setConvertedTask((prev) => ({ ...prev, selected }));
          handleBrainDumpActions(position, { ...convertedTask, selected });
        }}
        className='w-4 h-4 cursor-pointer'
        disabled={isAddingConvertedTasksToPlayer}
      />
      <div
        className={classNames(
          'w-full flex flex-col justify-between gap-1.5 shadow-md rounded py-2.5 px-4 relative',
          {
            'bg-gray-600': shouldActivateDarkMode,
            'bg-whiteLace': !shouldActivateDarkMode
          }
        )}
      >
        <TextInput
          value={convertedTask.task_name}
          onChange={({ target: { value: task_name } }) => {
            setConvertedTask((prev) => ({ ...prev, task_name }));
            handleBrainDumpActions(position, {
              ...convertedTask,
              task_name
            });
          }}
          className={classNames(
            'w-full text-sm outline-none rounded-md shadow px-2 py-1.5 text-black',
            {
              'bg-gray-300': shouldActivateDarkMode,
              'bg-white': !shouldActivateDarkMode
            }
          )}
          disabled={isAddingConvertedTasksToPlayer}
        />
        <div className='w-full flex items-center gap-2'>
          <input
            type='number'
            value={convertedTask.estimated_duration_minutes?.toString()}
            onChange={({ target: { value } }) => {
              setConvertedTask((prev) => ({
                ...prev,
                estimated_duration_minutes: parseInt(value)
              }));
              handleBrainDumpActions(position, {
                ...convertedTask,
                estimated_duration_minutes: parseInt(value)
              });
            }}
            className={classNames(
              'w-10/12 xs:w-8/12 sm:w-6/12 md:w-4/12 text-xs outline-none rounded p-1 text-center shadow text-black',
              {
                'bg-gray-300': shouldActivateDarkMode,
                'bg-white': !shouldActivateDarkMode
              }
            )}
            disabled={isAddingConvertedTasksToPlayer}
          />
          <small className='text-xs self-end'>{t('minutes')}</small>
        </div>
        {convertedTask.subtasks?.length && (
          <ButtonFocusBear
            variant={BTN_FB_VARIANT.SECONDARY}
            size={BTN_FB_SIZE.SMALL}
            title={t('to_do_procrastinate.sub_tasks')}
            additionalStyles='group relative self-end'
          >
            <BrainDumpItemSubTasks
              subtasks={convertedTask.subtasks}
              position={position}
            />
          </ButtonFocusBear>
        )}
        <Button
          icon={
            <CloseCircle
              styles='w-4 h-auto'
              fill={COLOR.RED}
              fillDarkMode={COLOR.RED}
            />
          }
          onClick={() => handleBrainDumpActions(position)}
          className='self-center absolute -top-1.5 -right-1.5'
          disabled={isAddingConvertedTasksToPlayer}
        />
      </div>
    </div>
  );
};

export default BrainDumpItem;
