import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NUMBERS, SCREEN } from 'constants/general';
import { ROUTES } from 'constants/routes';
import { LayoutContext } from '../layout';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  configureTabs,
  setGeneratorMode,
  updateIsSettingEditingFinished,
  updatePreviousSettings,
} from 'store/reducer/setting/slice';
import { Mode } from 'constants/enum';

interface Props {
  path: string;
  text: string;
  closeMenu?: React.MouseEventHandler;
  attribAction?: string;
}

function NavButton({ path, text, closeMenu, attribAction }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isCurrentPage = window.location.href.includes(path);
  const screen = useContext(LayoutContext);
  const buttonStyle =
    screen?.width ?? NUMBERS.ZERO > SCREEN.WIDTH.LARGE
      ? `text-gray-500 hover:text-gray-800 h-fit p-1 lg:px-1 xl:px-2 rounded-md ${
          isCurrentPage && 'bg-gray-100 text-orange-400'
        } hover:bg-gray-50 hover:text-orange-400`
      : `text-gray-800 block p-1 text-lg font-extrabold hover:bg-gray-50 rounded w-[100%] text-left hover:text-orange-400 ${
          isCurrentPage && 'bg-gray-100 text-orange-400'
        }`;
  const { currentSettings } = useAppSelector((state) => state.setting);

  return (
    <button
      // closeMenu function is for when buttons are used in mobile navigation
      onClick={(event) => {
        navigate(path);
        closeMenu && closeMenu(event);
        // it will reset the current settings of the user
        if (path.includes(ROUTES.SETTINGS)) {
          dispatch(setGeneratorMode(Mode.DEFAULT));
          dispatch(configureTabs({ mode: Mode.DEFAULT }));
          dispatch(updatePreviousSettings(currentSettings)); // it helps to reset the validation process and "finish" button become active
          dispatch(updateIsSettingEditingFinished(false)); // it navigates back to the UI where user settings can be seen
        }
      }}
      className={buttonStyle}
      data-test={attribAction}
    >
      {text}
    </button>
  );
}

export default React.memo(NavButton);
