import COLOR from 'constants/color';
import { IconProps } from 'interfaces';

const AngleRight = ({
  fill = COLOR.WHITE,
  styles = 'w-3 h-auto'
}: IconProps) => {
  return (
    <svg
      className={styles}
      fill={fill}
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path d='M 12.96875 4.28125 L 11.53125 5.71875 L 21.8125 16 L 11.53125 26.28125 L 12.96875 27.71875 L 23.96875 16.71875 L 24.65625 16 L 23.96875 15.28125 Z'></path>
      </g>
    </svg>
  );
};

export default AngleRight;
