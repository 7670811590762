import { useContext } from 'react';
import { TasksContext } from '.';
import CheckBox from 'components/common/CheckBox';
import { DEFAULT_DUE_DATE_FILTERS } from 'assets/data';
import { t } from 'i18next';

const TaskDueDateFilters = () => {
  const {
    dueDateFilters: { dueToday, dueThisWeek, dueNextWeek },
    setDueDateFilters
  } = useContext(TasksContext);

  return (
    <div className='w-full flex items-center gap-2 justify-between px-2.5 py-1 border border-gray-200 shadow-md rounded-md'>
      <CheckBox
        label={t('to_do_procrastinate.due_today')}
        checked={dueToday}
        onChange={({
          target: { checked }
        }: React.ChangeEvent<HTMLInputElement>) =>
          setDueDateFilters?.(() => ({
            ...DEFAULT_DUE_DATE_FILTERS,
            dueToday: checked
          }))
        }
      />
      <CheckBox
        label={t('to_do_procrastinate.due_this_week')}
        checked={dueThisWeek}
        onChange={({
          target: { checked }
        }: React.ChangeEvent<HTMLInputElement>) =>
          setDueDateFilters?.(() => ({
            ...DEFAULT_DUE_DATE_FILTERS,
            dueThisWeek: checked
          }))
        }
      />
      <CheckBox
        label={t('to_do_procrastinate.due_next_week')}
        checked={dueNextWeek}
        onChange={({
          target: { checked }
        }: React.ChangeEvent<HTMLInputElement>) =>
          setDueDateFilters?.(() => ({
            ...DEFAULT_DUE_DATE_FILTERS,
            dueNextWeek: checked
          }))
        }
      />
    </div>
  );
};

export default TaskDueDateFilters;
