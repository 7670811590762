import { WeeklySummaryStatsTypes } from 'interfaces/userInterface';
import { useTranslation } from 'react-i18next';

const WeeklySummaryHoverDetails = ({
  stats
}: {
  stats: WeeklySummaryStatsTypes;
}) => {
  const { t } = useTranslation();
  return (
    <div className='hidden group-hover:inline-flex absolute bottom-10 md:bottom-11 lg:bottom-12 left-[27.5%] md:left-1/4 -translate-x-1/4 bg-orange-400 text-white w-[240px] md:w-[340px] p-1.5 md:p-2 rounded-xl text-[10px] md:text-xs items-center justify-end gap-5'>
      <p className='text-left'>
        {t('dashboard_stat.description', {
          minute: stats.morning_minutes?.toFixed(),
          totalMinute: stats.morning_total_minutes
        })}
      </p>
      <p className='text-right'>
        {t('dashboard_stat.description', {
          minute: stats.evening_minutes?.toFixed(),
          totalMinute: stats.evening_total_minutes
        })}
      </p>
      <div className='absolute -bottom-2 left-[14%] md:left-[10%] -translate-x-[10%] w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-orange-400'></div>
      <div className='absolute -bottom-2 right-[14%] md:right-[10%] -translate-x-[10%] w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-orange-400'></div>
    </div>
  );
};

export default WeeklySummaryHoverDetails;
