import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialFilterState } from 'store/initial-states';

const filter = createSlice({
  name: 'filter',
  initialState: initialFilterState,
  reducers: {
    updateFilterSearchWord: (state, { payload }: PayloadAction<string>) => {
      state.searchWord = payload;
    },
    updateFilterPriority: (state, { payload }: PayloadAction<string>) => {
      state.priority = payload;
    },
    updateFilterDaysOfWeek: (state, { payload }: PayloadAction<string[]>) => {
      state.daysOfWeek = payload;
    },
    updateFilterIsSettingToolbarShow: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSettingToolbarShown = payload;
    },
    resetFilters: (state) => {
      state.searchWord = '';
      state.priority = '';
      state.daysOfWeek = [];
    },
    updateFilterIsAdminToolbarShow: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAdminToolbarShown = payload;
    },
  },
});

export const {
  updateFilterSearchWord,
  updateFilterDaysOfWeek,
  updateFilterPriority,
  updateFilterIsSettingToolbarShow,
  resetFilters,
  updateFilterIsAdminToolbarShow,
} = filter.actions;

export default filter.reducer;
