import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { courseReviewsSelector } from 'store/reducer/course/selectors';

const CourseReviews = () => {
  const { t } = useTranslation();
  const ratings = useAppSelector(courseReviewsSelector);

  return (
    <div className='w-full flex flex-col gap-3'>
      <h5>{t('course.reviews')}</h5>
      {ratings?.length ? (
        ratings?.map((rate) => (
          <p
            key={rate.id}
            className='relative bg-gray-200 px-2 pt-1.5 pb-6 rounded-md text-sm shadow-md'
          >
            {rate.review}
            <span className='absolute bottom-0.5 right-2 text-xs'>
              {moment(rate.created_at).format('MMM DD, YYYY')}
            </span>
          </p>
        ))
      ) : (
        <p className='w-fit text-xs sm:text-sm py-4 self-center'>
          {t('course.no_reviews_found')}
        </p>
      )}
    </div>
  );
};

export default CourseReviews;
