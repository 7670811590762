import HabitPackCard from 'components/common/HabitPackCard';
import { useAppSelector } from 'store/hooks';
import { getPackIds } from 'utils/support';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HABIT_PACK_OPTION, PLATFORMS } from 'constants/general';
import { MARKETPLACE_URL } from 'constants/routes';
import { useCallback } from 'react';
import { HabitPackType } from 'interfaces/commonInterface';

interface PackItemsProps {
  title: string;
  habitPacks: HabitPackType[];
  packType: string;
}

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full h-full flex flex-col md:flex-row gap-4 md:gap-2 py-3 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 animate-fadeIn'>
    {children}
  </div>
);

const PackItems = ({ title, habitPacks, packType }: PackItemsProps) => {
  const { t } = useTranslation();

  return (
    <div className='w-full md:w-[40%] h-fit flex flex-col items-center'>
      <div className='w-full px-4 py-1 text-base font-semibold'>{title}</div>
      {habitPacks.length ? (
        <div className='w-full h-fit flex flex-col items-center py-2 px-4 gap-4 md:gap-6 overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100'>
          {habitPacks
            .map((pack, idx) => ({ pack, idx }))
            .map(({ pack, idx }) => (
              <HabitPackCard key={idx} habitPack={pack} />
            ))}
        </div>
      ) : (
        <p className='py-4'>
          {packType === HABIT_PACK_OPTION.INSTALLED
            ? t('packs.no_packs_installed_message')
            : t('packs.no_featured_packs_message')}
        </p>
      )}
    </div>
  );
};

const BrowseMorePacks = () => {
  const { t } = useTranslation();
  const currentUrl = window.location.href;
  const { platform } = useAppSelector((state) => state).setting;

  const openMarketplaceInBrowserForMacApp = useCallback(() => {
    platform === PLATFORMS.MAC &&
      window.webkit.messageHandlers.open_marketplace.postMessage(
        MARKETPLACE_URL
      );
  }, [platform]);

  return (
    <div className='w-[95%] md:w-[20%] h-full flex flex-col justify-center items-center text-center gap-1 md:gap-5 self-center'>
      <p className='text-sm md:text-md lg:text-lg xl:text-xl'>
        {t('market.browse_more_packs')}
      </p>
      {currentUrl.includes('dashboard') ? (
        <Link to='/marketplace'>
          <p className='text-orange-500 underline'>
            {t('market.go_to_marketplace')}
          </p>
        </Link>
      ) : (
        <a
          href={MARKETPLACE_URL}
          target='_blank'
          rel='noreferrer'
          className='underline text-orange-500'
          onClick={openMarketplaceInBrowserForMacApp}
        >
          {t('market.go_to_marketplace')}
        </a>
      )}
    </div>
  );
};

export default function PackLists() {
  const { t } = useTranslation();
  const {
    habitPack: { installedHabitPacks, featuredHabitPacks, areHabitPacksLoading }
  } = useAppSelector((state) => state);
  const installedPackIds = getPackIds(installedHabitPacks);
  const featuredHabitPacksToDisplay = featuredHabitPacks.filter(
    ({ id }) => !installedPackIds.includes(id)
  );

  return (
    <Wrapper>
      <PackItems
        title={t('market.installed_packs')}
        habitPacks={installedHabitPacks}
        packType={HABIT_PACK_OPTION.INSTALLED}
      />
      <PackItems
        title={t('market.featured_packs')}
        habitPacks={areHabitPacksLoading ? [] : featuredHabitPacksToDisplay}
        packType={HABIT_PACK_OPTION.FEATURED}
      />
      <BrowseMorePacks />
    </Wrapper>
  );
}
