import classNames from 'classnames';
import { SUGGESTED_PACK } from 'constants/enum';
import { t } from 'i18next';
import moment from 'moment';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { isDarkModeActivated } from 'utils/validation';

export interface Suggestion {
  where: SUGGESTED_PACK;
  name: string;
  duration: number;
  pack_title?: string;
}

const ActivitySuggestion = ({
  suggestion: { name, duration, where, pack_title }
}: {
  readonly suggestion: Suggestion;
}) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );

  return (
    <div
      className={classNames(
        'w-full flex flex-col gap-1 px-2 md:px-5 py-3 cursor-pointer border-b border-gray-400 text-black',
        {
          'bg-gray-300 hover:bg-gray-300/90': shouldActivateDarkMode,
          'bg-gray-100 hover:bg-gray-200': !shouldActivateDarkMode
        },
        'group'
      )}
    >
      <p className='w-fit max-w-full text-xs truncate'>
        {t(
          where === SUGGESTED_PACK.CREATE_NEW_HABIT
            ? 'create_new_habit_name'
            : 'use_habit_template_name_duration',
          {
            name
          }
        )}
      </p>
      <div className='w-fit flex items-center gap-2'>
        <p className='w-full text-[0.64rem] truncate'>
          {t('pack_suggestion_duration', {
            duration: moment()
              .startOf('date')
              .add(duration, 'seconds')
              .format('mm[m]:ss[s]')
          })}
        </p>
        <p
          className={classNames(
            'min-w-max text-[0.45rem] md:text-[0.55rem] px-1 py-0.5 rounded text-white capitalize',
            {
              'bg-gray-600': where === SUGGESTED_PACK.CREATE_NEW_HABIT,
              'bg-blue-500': where === SUGGESTED_PACK.LIBRARY,
              'bg-focusBearText': where === SUGGESTED_PACK.HABIT_PACK
            }
          )}
        >
          {where}
        </p>
      </div>
      {where !== SUGGESTED_PACK.CREATE_NEW_HABIT && pack_title && (
        <p className='absolute top-1.5 right-1 text-[0.55rem] bg-green-600 px-1.5 py-0.5 rounded text-white hidden group-hover:inline-flex'>
          {pack_title}
        </p>
      )}
    </div>
  );
};

export default ActivitySuggestion;
