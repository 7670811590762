import {
  HABIT_SETTING_FEATURES,
  MAXIMUM_ALLOWED_MINUTES,
  NUMBERS
} from 'constants/general';
import { ATTRIB } from 'constants/test';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { tabBodyRowDurationSelector } from 'store/reducer/setting/selectors';
import {
  updateIsUnlockHabitPackModalShown,
  updateSettingFeatures
} from 'store/reducer/setting/slice';
import { checkActivityDurationAgainstRelaxFocusModeDuration } from 'utils/settingsUtil';
import {
  convertToMinutes,
  convertToSeconds,
  getValidNumber,
  validateDuration
} from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';
import { TabBodyRowContext } from '.';

const TabBodyRowDuration = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    activity,
    themeMode,
    evening_activities,
    cutoff_time,
    shutdown_time,
    user_focus_modes
  } = useAppSelector(tabBodyRowDurationSelector);
  const { type, position, currentActivity, isEditingAllowed } =
    useContext(TabBodyRowContext);
  const [second, setSecond] = useState(
    String((currentActivity?.duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY)
  );

  useEffect(() => {
    if (activity?.id === currentActivity?.id) {
      const incomingSeconds = String(
        (activity?.duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY
      );
      setSecond(incomingSeconds);
    }
  }, []);

  const handleInputMinuteDuration = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const minutes = convertToMinutes(
        getValidNumber(second),
        getValidNumber(value)
      );
      const total_duration = convertToSeconds(minutes);
      validateDuration(total_duration);
      dispatch(
        updateSettingFeatures({
          type,
          position,
          feature: HABIT_SETTING_FEATURES.DURATION,
          value: total_duration
        })
      );
      cutoff_time &&
        checkActivityDurationAgainstRelaxFocusModeDuration(
          total_duration,
          user_focus_modes,
          cutoff_time,
          shutdown_time,
          evening_activities,
          currentActivity
        );
    },
    [currentActivity?.duration_seconds, second, position]
  );

  const handleInputSecondDuration = useCallback(
    (value) => {
      const total_duration = convertToSeconds(
        convertToMinutes(currentActivity?.duration_seconds ?? NUMBERS.ZERO),
        getValidNumber(value)
      );
      validateDuration(total_duration);
      dispatch(
        updateSettingFeatures({
          type: type,
          position: position,
          feature: HABIT_SETTING_FEATURES.DURATION,
          value: total_duration
        })
      );
      setSecond((total_duration % NUMBERS.SIXTY)?.toString());
      cutoff_time &&
        checkActivityDurationAgainstRelaxFocusModeDuration(
          total_duration,
          user_focus_modes,
          cutoff_time,
          shutdown_time,
          evening_activities,
          currentActivity
        );
    },
    [currentActivity?.duration_seconds, position]
  );

  const isDurationValid =
    (currentActivity?.duration_seconds ?? NUMBERS.ZERO) <=
    convertToSeconds(MAXIMUM_ALLOWED_MINUTES);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <div className='w-full md:w-2/5 lg:w-[35%] 2xl:w-1/2 h-fit flex items-end justify-between gap-4'>
      <div className='w-1/2 flex flex-col relative'>
        <p className='text-[0.6rem] md:text-xs pb-0.5 font-medium'>
          {t('duration')}
        </p>
        <input
          data-test={ATTRIB.TEST.INPUT_HABIT_DURATION_MINUTE}
          data-locator={ATTRIB.LOCATOR.HABIT_DURATION_MINUTE}
          readOnly={!isEditingAllowed}
          className={`w-full py-2 rounded text-sm font-medium outline-none text-black text-center ${
            isDurationValid ? 'border-none' : 'border border-red-400'
          } ${shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'}`}
          type='number'
          value={String(
            convertToMinutes(currentActivity?.duration_seconds ?? NUMBERS.ZERO)
          )}
          onChange={handleInputMinuteDuration}
          step={NUMBERS.ONE}
          min={NUMBERS.ZERO}
          placeholder='10'
          onFocus={() => {
            !isEditingAllowed &&
              dispatch(updateIsUnlockHabitPackModalShown(true));
          }}
        />
        <div className='absolute -bottom-3.5 xl:-bottom-4 right-0 text-[9px] lg:text-xs 2xl:text-xs font-medium self-end'>
          {t('minutes')}
        </div>
      </div>
      <div className='w-1/2 flex flex-col relative'>
        <input
          data-test={ATTRIB.TEST.INPUT_HABIT_DURATION_SECOND}
          data-locator={ATTRIB.LOCATOR.HABIT_DURATION_SECOND}
          readOnly={!isEditingAllowed}
          className={`w-full py-2 rounded text-sm font-medium outline-none text-black text-center ${
            isDurationValid ? 'border-none' : 'border border-red-400'
          } ${shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'}`}
          type='number'
          value={second}
          onChange={({
            target: { value }
          }: React.ChangeEvent<HTMLInputElement>) => {
            value ? handleInputSecondDuration(value) : setSecond('');
          }}
          step={NUMBERS.ONE}
          min={NUMBERS.ZERO}
          placeholder='30'
          onFocus={() => {
            !isEditingAllowed &&
              dispatch(updateIsUnlockHabitPackModalShown(true));
          }}
        />
        <div className='absolute -bottom-3.5 xl:-bottom-4 right-0 text-[9px] lg:text-xs 2xl:text-xs font-medium self-end'>
          {`${t('seconds')}`}
        </div>
      </div>
    </div>
  );
};

export default TabBodyRowDuration;
