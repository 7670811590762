import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Mode } from 'constants/enum';
import { initUserSettings } from 'store/reducer/setting/extra';
import {
  updateEveningStatsLoading,
  updateMorningStatsLoading,
  updateWeeklySummaryLoading
} from 'store/reducer/user/slice';
import {
  getMorningActivityStats,
  getEveningActivityStats,
  getUserDetailsCurrentActivityProps,
  getWeeklyStats
} from 'store/reducer/user/extra';
import DocumentMetaData from 'components/DocumentMetaData';
import { PAGES, ROUTES } from 'constants/routes';
import WeeklyStatistics from './WeeklyStatistics';
import RoutineAnalyzer from '../../components/dashboard/routinePlayer/RoutineAnalyzer';
import {
  changeIsSettingLoading,
  updateAiTone
} from 'store/reducer/setting/slice';
import OverlaySpinner from 'components/OverlaySpinner';
import { Link } from 'react-router-dom';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full h-full flex flex-col text-center gap-4 sm:gap-14 2xl:gap-20 justify-center items-center px-6 sm:px-10 md:px-14 py-5'>
    {children}
  </div>
);

export default function Home() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    mode,
    isSettingLoaded,
    isTokenAddedToInterceptor,
    currentSettings,
    isSettingLoading
  } = useAppSelector((state) => state.setting);

  useEffect(() => {
    if (
      isTokenAddedToInterceptor &&
      (mode === Mode.DEFAULT || mode === Mode.FREESTYLE)
    ) {
      updateUserConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenAddedToInterceptor, mode]);

  const updateUserConfiguration = () => {
    if (!isSettingLoaded) {
      dispatch(changeIsSettingLoading(true));
      dispatch(initUserSettings());
    } else {
      const morning_activities = currentSettings?.morning_activities ?? [];
      const evening_activities = currentSettings?.evening_activities ?? [];
      if (
        morning_activities.length > 0 &&
        morning_activities[0].activity_sequence_id
      ) {
        dispatch(updateMorningStatsLoading(true));
        dispatch(
          getMorningActivityStats(morning_activities[0].activity_sequence_id)
        );
      }
      if (
        evening_activities.length > 0 &&
        evening_activities[0].activity_sequence_id
      ) {
        dispatch(updateEveningStatsLoading(true));
        dispatch(
          getEveningActivityStats(evening_activities[0].activity_sequence_id)
        );
      }
      dispatch(updateWeeklySummaryLoading(true));
      dispatch(getWeeklyStats());
      dispatch(getUserDetailsCurrentActivityProps());
      dispatch(updateAiTone());
    }
  };

  return (
    <Wrapper>
      <DocumentMetaData pageName={PAGES.DASHBOARD} />
      <h1 className='text-base sm:text-3xl text-orange-400 w-full sm:w-5/6 md:w-full lg:w-3/4 xl:w-2/3 2xl:w-1/2'>
        {t('welcome_message')}
      </h1>
      <WeeklyStatistics />
      <div className='w-fit flex flex-wrap items-center justify-center gap-x-10 gap-y-4 my-10 sm:my-0'>
        <RoutineAnalyzer />
        <Link
          to={ROUTES.MOTIVATIONAL_SUMMARY}
          className='w-fit h-fit text-sm sm:text-lg font-medium px-4 py-2 rounded-md text-orange-400 bg-orange-100 hover:bg-orange-500 hover:text-white animate-pulse hover:animate-none'
        >
          {t('get_some_inspiration')}
        </Link>
      </div>
      {isSettingLoading && <OverlaySpinner />}
    </Wrapper>
  );
}
