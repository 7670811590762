import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import { loadMetaDataForEnrolledCourses } from 'utils/focusBear_apps';
import {
  getEnrolledCourses,
  getOtherCourses
} from 'store/reducer/course/extra';
import TabLayout from 'components/common/TabLayout';
import { updateTabLayoutTitles } from 'store/reducer/general/slice';
import { ENROLLED_COURSES_TAB, OTHER_COURSES_TAB } from 'constants/general';
import EnrolledCoursesList from 'components/course/enrolled-courses-list';
import OtherCourses from 'components/course/OtherCourses';
import EnrolledCourseDetailsModal from 'components/course/enrolled-courses-list/EnrolledCourseDetailsModal';
import CourseLessonsModal from 'components/course/course-lessons-modal';
import { coursesPageSelectors } from 'store/reducer/course/selectors';
import { getUserDetails } from 'store/reducer/user/extra';
import classNames from 'classnames';
import { isDarkModeActivated } from 'utils/validation';

const AppTutorials = () => {
  const dispatch = useAppDispatch();
  const {
    isTokenAddedToInterceptor,
    showCourseDetailsModal,
    showCourseLessonsModal,
    activeTab,
    themeMode
  } = useAppSelector(coursesPageSelectors);

  useEffect(() => {
    loadMetaDataForEnrolledCourses();
    dispatch(
      updateTabLayoutTitles([
        ENROLLED_COURSES_TAB.title,
        OTHER_COURSES_TAB.title
      ])
    );
  }, []);

  useEffect(() => {
    if (isTokenAddedToInterceptor) {
      activeTab === ENROLLED_COURSES_TAB.index
        ? dispatch(getEnrolledCourses())
        : dispatch(getOtherCourses());
    }
  }, [activeTab, isTokenAddedToInterceptor]);

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  useEffect(() => {
    isTokenAddedToInterceptor && dispatch(getUserDetails());
  }, [isTokenAddedToInterceptor]);
  return isTokenAddedToInterceptor ? (
    <div
      className={classNames('w-screen h-screen flex flex-col px-8 py-4', {
        'bg-transparent text-black': !shouldActivateDarkMode,
        'bg-gray-600 text-white': shouldActivateDarkMode
      })}
    >
      <TabLayout
        tabs={[
          <EnrolledCoursesList key={ENROLLED_COURSES_TAB.index} />,
          <OtherCourses key={OTHER_COURSES_TAB.index} />
        ]}
        navigation_styles={
          shouldActivateDarkMode ? 'bg-gray-500' : 'bg-sunRayFourth'
        }
        selectedTabColor='bg-gray-600/70'
      />
      {showCourseDetailsModal && <EnrolledCourseDetailsModal />}
      {showCourseLessonsModal && <CourseLessonsModal />}
    </div>
  ) : (
    <InitEmbeddedServices />
  );
};

export default AppTutorials;
