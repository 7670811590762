import React from 'react';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalHeader from 'components/shared/ModalHeader';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { updateIsInquiryModalShown } from 'store/reducer/inquiry/slice';
import RoutineCalendarReminders from './RoutineCalendarReminders';
import AppInstallPrompt from './AppInstallPrompt';
import { MODAL_TYPES } from 'constants/general';

interface WrapperProps {
  children: React.ReactNode;
}

interface ModalContentCategoryProps {
  title: string;
  info?: string;
  children: React.ReactNode;
  decor?: string;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='relative top-1/4 -translate-y-1/4 left-1/2 -translate-x-1/2 w-5/6 sm:w-2/3 md:w-3/5 h-fit flex flex-col bg-white rounded-lg'>
    {children}
  </div>
);

const ModalContentCategory = ({
  title,
  info,
  children,
  decor = 'items-center justify-evenly',
}: ModalContentCategoryProps) => (
  <div className='w-full h-fit px-6 md:py-8 py-2 border border-gray-300 rounded-md relative flex flex-col justify-center gap-2'>
    <div className='absolute left-1.5 -top-2.5 w-fit h-fix px-2 text-xs bg-white font-semibold'>
      {title}
    </div>
    {info && (
      <div className='w-full font-semibold md:text-md text-sm'>{info}</div>
    )}
    <div className={`w-full flex ${decor}`}>{children}</div>
  </div>
);

const ModalContent = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full h-fit flex flex-col px-4 pt-2 lg:pt-2 gap-6'>
      <h1 className='font-semibold text-sm md:text-lg'>
        {t('inquiry.how_do_you_want_to_be_reminded_to_do_your_activities')}
      </h1>
      <ModalContentCategory
        title={t('inquiry.installation')}
        info={t('inquiry.get_reminded_from_focusbear_app')}
      >
        <AppInstallPrompt />
      </ModalContentCategory>
      <ModalContentCategory
        title={t('inquiry.reminder')}
        info={t('inquiry.set_calendar_reminder_for_routines')}
        decor='flex-col gap-4'
      >
        <RoutineCalendarReminders />
      </ModalContentCategory>
    </div>
  );
};

const ModalAction = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className='w-full h-fit flex items-center justify-end p-4'>
      <button
        onClick={() => {
          dispatch(updateIsInquiryModalShown(false));
        }}
        className='w-fit h-fit p-4 py-1 bg-gray-500 hover:bg-gray-600 text-white rounded text-sm'
      >
        {t('inquiry.exit')}
      </button>
    </div>
  );
};

const InquiryNoticeModal = () => {
  const { t } = useTranslation();
  return (
    <ModalOverlay zIndex='z-[60]'>
      <Wrapper>
        <ModalHeader title={t('options')} modalType={MODAL_TYPES.INQUIRY} />
        <ModalContent />
        <ModalAction />
      </Wrapper>
    </ModalOverlay>
  );
};

export default InquiryNoticeModal;
