import React, { useContext, useState } from 'react';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalHeader from 'components/shared/ModalHeader';
import { LogQuestionDetailsType } from 'interfaces';
import {
  EMPTY_STRING,
  HABIT_CHOICE_TYPE,
  LOG_QUANTITY_QUESTION_FEATURES,
  MODAL_TYPES
} from 'constants/general';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateChoicesLogQuantityQuestion,
  updateLogQuantityShowQuestionDetailsModal,
  updateMoreOptionLogQuantityQuestionFeatures
} from 'store/reducer/modal/slice';
import { LogQuantityQuestionContext } from './LogQuantityQuestions';
import { ChoiceItemContext } from 'components/setting-generator/tabs/ChoicesModal/ChoiceItem';
import { ATTRIB } from 'constants/test';
import { isDarkModeActivated } from 'utils/validation';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector((state) => state.setting).themeMode
  );
  return (
    <div
      className={`relative top-1/2 -translate-y-1/3 mx-auto w-full sm:w-3/5 md:2/3 lg:w-1/2 2xl:w-[40%] h-fit flex flex-col gap-2 shadow-lg rounded-md ${
        shouldActivateDarkMode ? 'bg-gray-500' : 'bg-white'
      } border-b-2 border-gray-400`}
    >
      {children}
    </div>
  );
};

const LogQuantityQuestionDetails = () => {
  const [isEdited, setIsEdited] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { modalType } = useContext(LogQuantityQuestionContext);
  const { position: choicePosition } = useContext(ChoiceItemContext);
  const {
    modal: {
      moreOption: { activity },
      logQuantityDetails: { position, logQuestion }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const isCompetencyBasedActivity =
    activity?.choice_type === HABIT_CHOICE_TYPE.COMPETENCY_BASED;
  const [questionDetails, setQuestionDetails] =
    useState<LogQuestionDetailsType>({
      min_value: String(logQuestion?.min_value ?? EMPTY_STRING),
      max_value: String(logQuestion?.max_value ?? EMPTY_STRING),
      min_value_description: logQuestion?.min_value_description ?? EMPTY_STRING,
      max_value_description: logQuestion?.max_value_description ?? EMPTY_STRING
    });

  const isMaxValid =
    parseInt(questionDetails.max_value) > parseInt(questionDetails.min_value);
  const isMinValid =
    parseInt(questionDetails.min_value) < parseInt(questionDetails.max_value);

  const areLogQuestionsInvalid =
    [questionDetails.min_value, questionDetails.max_value].some((item) =>
      isNaN(parseInt(item))
    ) ||
    !isMinValid ||
    !isMaxValid;

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  const handleSave = () => {
    if (position !== null) {
      if (modalType === MODAL_TYPES.MORE_OPTION) {
        dispatch(
          updateMoreOptionLogQuantityQuestionFeatures({
            feature: LOG_QUANTITY_QUESTION_FEATURES.QUESTION_DETAILS,
            position,
            value: questionDetails
          })
        );
      } else {
        dispatch(
          updateChoicesLogQuantityQuestion({
            choicePosition,
            questionPosition: position,
            value: questionDetails,
            feature: LOG_QUANTITY_QUESTION_FEATURES.QUESTION_DETAILS
          })
        );
      }
      dispatch(updateLogQuantityShowQuestionDetailsModal(false));
    }
  };

  return (
    <ModalOverlay styles='bg-gray-500/20 -mt-1'>
      <Wrapper>
        <ModalHeader
          title={logQuestion?.question ?? EMPTY_STRING}
          modalType={MODAL_TYPES.LOG_QUESTION_DETAILS}
        />
        <div className='w-full flex flex-col gap-6 px-4 py-4'>
          <div className='w-full flex items-center border border-gray-300 rounded-md p-4 relative gap-4'>
            <label
              className={`absolute -top-2 left-2 ${
                shouldActivateDarkMode ? 'bg-gray-500' : 'bg-white'
              } text-xs px-2 font-medium`}
            >
              {t('minimum')}
            </label>
            <input
              data-test={ATTRIB.TEST.INPUT_LOG_QUESTION_DETAILS_MINIMUM}
              disabled={isCompetencyBasedActivity}
              className={`w-1/4 bg-gray-100 focus:bg-gray-200 outline-none px-2 py-1.5 rounded text-sm font-medium text-center text-black ${
                isEdited &&
                (isNaN(parseInt(questionDetails.min_value)) || !isMinValid) &&
                'border border-red-500'
              }`}
              value={
                isNaN(parseInt(questionDetails.min_value))
                  ? EMPTY_STRING
                  : questionDetails.min_value
              }
              type='text'
              onChange={({ target: { value } }) => {
                setQuestionDetails({
                  ...questionDetails,
                  min_value: value
                });
                setIsEdited(true);
              }}
            />
            <input
              data-test={
                ATTRIB.TEST.INPUT_LOG_QUESTION_DETAILS_MINIMUM_DESCRIPTION
              }
              className='w-3/4 bg-gray-100 focus:bg-gray-200 outline-none px-2 py-1.5 rounded text-sm font-medium text-black'
              value={questionDetails.min_value_description}
              type='text'
              placeholder={t('placeholder.description')}
              onChange={({ target: { value } }) => {
                setQuestionDetails({
                  ...questionDetails,
                  min_value_description: value
                });
              }}
            />
          </div>
          <div className='w-full flex items-center border border-gray-300 rounded-md p-4 relative gap-4'>
            <label
              className={`absolute -top-2 left-2 ${
                shouldActivateDarkMode ? 'bg-gray-500' : 'bg-white'
              } text-xs px-2 font-medium`}
            >
              {t('maximum')}
            </label>
            <input
              data-test={ATTRIB.TEST.INPUT_LOG_QUESTION_DETAILS_MAXIMUM}
              disabled={isCompetencyBasedActivity}
              className={`w-1/4 bg-gray-100 focus:bg-gray-200 outline-none px-2 py-1.5 rounded text-sm font-medium text-center text-black ${
                isEdited &&
                (isNaN(parseInt(questionDetails.max_value)) || !isMaxValid) &&
                'border border-red-500'
              }`}
              value={
                isNaN(parseInt(questionDetails.max_value))
                  ? EMPTY_STRING
                  : questionDetails.max_value
              }
              type='text'
              onChange={({ target: { value } }) => {
                setQuestionDetails({
                  ...questionDetails,
                  max_value: value
                });
                setIsEdited(true);
              }}
            />
            <input
              data-test={
                ATTRIB.TEST.INPUT_LOG_QUESTION_DETAILS_MAXIMUM_DESCRIPTION
              }
              className='w-3/4 bg-gray-100 focus:bg-gray-200 outline-none px-2 py-1.5 rounded text-sm font-medium text-black'
              value={questionDetails.max_value_description}
              type='text'
              placeholder={t('placeholder.description')}
              onChange={({ target: { value } }) => {
                setQuestionDetails({
                  ...questionDetails,
                  max_value_description: value
                });
              }}
            />
          </div>
          <div className='w-fit flex flex-col -my-3'>
            {isEdited && !isMinValid && (
              <p className='text-red-500 text-xs font-medium'>
                {t('min_should_be_less_than_max')}
              </p>
            )}
            {isEdited && !isMaxValid && (
              <p className='text-red-500 text-xs font-medium'>
                {t('max_should_be_greater_than_min')}
              </p>
            )}
          </div>
          <button
            disabled={areLogQuestionsInvalid}
            data-test={ATTRIB.TEST.SAVE_LOG_QUESTION_DETAILS}
            onClick={handleSave}
            className={`w-fit px-5 py-1 rounded-md font-medium text-sm self-end cursor-pointer text-white transition ease-in-out delay-150 hover:scale-110 bg-blue-600 duration-200 disabled:cursor-not-allowed  disabled:text-gray-400 disabled:border-2 disabled:border-gray-400}`}
          >
            {t('save')}
          </button>
        </div>
      </Wrapper>
    </ModalOverlay>
  );
};

export default LogQuantityQuestionDetails;
