import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IChatMessage } from 'interfaces';
import { ChatRoles, ChatBotState } from 'constants/enum';
import i18n from 'services/i18n';
import { sendMessage } from '../user/extra';
import { initialChatBotState } from 'store/initial-states';

const chatBot = createSlice({
  name: 'chat-bot',
  initialState: initialChatBotState,
  reducers: {
    updateMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    updateChatHistory: (state, { payload }: PayloadAction<IChatMessage>) => {
      state.chatHistory.push(payload);
    },
    updateAiGeneratedContent: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      if (payload) {
        state.aiGeneratedContent += payload;
      } else {
        state.aiGeneratedContent = payload;
      }
    },
    updateChatBotState: (state, { payload }: PayloadAction<ChatBotState>) => {
      state.chatBotState = payload;
    },
    updateIsChatBotGenerating: (state, { payload }: PayloadAction<boolean>) => {
      state.isChatBotGenerating = payload;
    },
    resetChatHistory: (state) => {
      state.chatHistory = [
        {
          role: ChatRoles.ASSISTANT,
          content: i18n.t('chatbot.introduction')
        }
      ];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sendMessage.fulfilled, (state) => {
      state.isChatBotGenerating = false;
      state.chatBotState = ChatBotState.IDLE;
    });
  }
});

export const {
  updateAiGeneratedContent,
  updateChatBotState,
  updateChatHistory,
  updateIsChatBotGenerating,
  updateMessage,
  resetChatHistory
} = chatBot.actions;

export default chatBot.reducer;
