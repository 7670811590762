import { useState, useContext } from 'react';
import logo from 'assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import LanguageDropDown from './LanguageDropDown';
import NavButton from '../navBar/NavButton';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import NavDropDown from '../navBar/NavDropDown';
import Hamburger from 'assets/icons/Hamburger';
import { ATTRIB } from 'constants/test';
import COLOR from 'constants/color';
import CloseCircle from 'assets/icons/CloseCircle';
import { NavBarContext } from '../navBar';

export default function MobileNavigation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isUserAdmin } = useAppSelector((state) => state.user);
  const { accountMenuOptions, extraMenuOptions } = useContext(NavBarContext);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <section className='min-h-[1.5rem] w-full sticky top-0 z-[60]'>
      <nav className='flex justify-between items-center py-2 px-4 h-fit w-full bg-transparent shadow-md backdrop-blur-md'>
        <button
          data-action={ATTRIB.TEST.OPEN_SIDE_NAVBAR}
          onClick={() => setIsMenuOpen(true)}
          className='flex w-8 h-8 items-center justify-center focus:outline-none text-orange-400 bg-white hover:bg-orange-100 border-3 rounded'
        >
          <Hamburger />
        </button>
        <LanguageDropDown />
      </nav>
      {/* Popup menu */}
      <div
        className={`w-full h-screen fixed top-0 left-0 ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } duration-200 ease-in-out z-20`}
      >
        <nav className='flex flex-col w-5/6 max-w-sm h-full py-6 px-6 bg-white border-r overflow-y-auto text-lg'>
          <div className='flex items-center my-4 relative'>
            <button
              data-action={ATTRIB.TEST.CLOSE_SIDE_NAVBAR}
              onClick={() => closeMenu()}
              className='navbar-close absolute right-0'
            >
              <CloseCircle styles='w-6 h-6' fill={COLOR.BLACK} />
            </button>
            <button
              data-action={ATTRIB.TEST.HOME}
              onClick={() => {
                closeMenu();
                navigate(ROUTES.HOME);
              }}
              className='w-44 md:w-fit h-fit'
            >
              <img src={logo} alt='focus bear' className='w-full h-full' />
            </button>
          </div>
          <ul className='mt-3'>
            <li className='mb-1'>
              <NavDropDown
                data={{
                  parent: t('account'),
                  children: accountMenuOptions
                }}
                closeMenu={closeMenu}
              />
            </li>
            <li className='mb-1'>
              <NavButton
                path={ROUTES.STATS}
                text={t('stats')}
                closeMenu={closeMenu}
                attribAction={ATTRIB.TEST.STATS}
              />
            </li>
            <li className='mb-1'>
              <NavButton
                path={ROUTES.SETTINGS}
                text={t('settings')}
                closeMenu={closeMenu}
                attribAction={ATTRIB.TEST.SETTINGS}
              />
            </li>
            <li className='mb-1'>
              <NavButton
                path={ROUTES.TODO}
                text={t('to_do_procrastinate.to_do')}
                closeMenu={closeMenu}
                attribAction={ATTRIB.TEST.TODO}
              />
            </li>
            {isUserAdmin && (
              <li className='mb-1'>
                <NavButton
                  path={ROUTES.ADMIN}
                  text={t('admin.admin')}
                  closeMenu={closeMenu}
                  attribAction={ATTRIB.TEST.ADMIN}
                />
              </li>
            )}
            <li className='mb-1'>
              <NavDropDown
                data={{
                  parent: t('extra'),
                  children: extraMenuOptions
                }}
                closeMenu={closeMenu}
              />
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}
