import React from 'react';
import { SWITCH } from 'constants/tag';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionShowSavedDistractingWebsites } from 'store/reducer/modal/slice';

const GuiltFreePlay = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.modal.moreOption);

  return (
    <div className='flex items-center gap-2 py-1'>
      <Switch
        width={SWITCH.WIDTH.MICRO_BREAK}
        height={SWITCH.HEIGHT.MICRO_BREAK}
        onChange={(value) => {
          dispatch(updateMoreOptionShowSavedDistractingWebsites(value));
        }}
        checked={activity?.show_saved_distracting_websites ?? false}
      />
      <div className='text-xs lg:text-sm font-medium'>
        {t('show_guilt_free_play_popup')}
      </div>
    </div>
  );
};

export default React.memo(GuiltFreePlay);
