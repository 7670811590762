import HabitPackActions from 'components/dashboard/marketplace/HabitPackActions';
import InquiryNoticeModal from 'components/dashboard/inquiry/InquiryNoticeModal';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCurrentViewedPack } from 'store/reducer//habit-pack/slice';
import HabitPackActivityList from 'pages/marketplace/HabitPackActivityList';
import { EMPTY_LIST, PLATFORMS } from 'constants/general';
import { PAGES } from 'constants/routes';
import DetailsPageWrapper from 'components/dashboard/wrappers/DetailsPageWrapper';
import DetailsPageHeader from 'components/dashboard/marketplace/DetailsPageHeader';
import DetailsPageBodyWrapper from 'components/dashboard/wrappers/DetailsPageBodyWrapper';
import DetailsPageListWrapper from 'components/dashboard/wrappers/DetailsPageListWrapper';
import Banner from 'components/common/Banner';

export default function PackDetails() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    habitPack: { currentViewedPack, installedHabitPacks },
    inquiry: { is_inquiry_modal_shown },
    setting: { platform }
  } = useAppSelector((state) => state);

  useEffect(() => {
    return () => {
      dispatch(setCurrentViewedPack(null));
    };
  }, []);

  const [isFocusOnlyHabitPack, showBanner] = useMemo(
    () => [
      [
        ...(currentViewedPack?.morning_activities ?? []),
        ...(currentViewedPack?.break_activities ?? []),
        ...(currentViewedPack?.evening_activities ?? [])
      ].length === EMPTY_LIST,
      installedHabitPacks.some((pack) => pack.id === currentViewedPack?.id)
    ],
    [currentViewedPack]
  );

  return (
    <DetailsPageWrapper page={PAGES.HABIT_PACK}>
      <Banner
        message={t('banner_message.this_pack_is_ready_to_user')}
        contentWrapperStyles='w-fit px-5 py-2'
        wrapperStyles='self-center'
        defaultValue={showBanner}
      />
      <DetailsPageHeader />
      <DetailsPageBodyWrapper>
        <HabitPackActions />
        <DetailsPageListWrapper styles='h-fit max-h-[calc(100%-194px)] md:max-h-[calc(100%-64px)] px-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100'>
          {isFocusOnlyHabitPack ? (
            <p className='m-auto sm:py-12 md:py-16 lg:py-20 text-base md:text-lg'>
              {t('market.focus_mode_pack_notice')}
            </p>
          ) : (
            <>
              {(currentViewedPack?.morning_activities ?? []).length ? (
                <HabitPackActivityList
                  title={t('market.morning_habits')}
                  habitActivities={currentViewedPack?.morning_activities ?? []}
                />
              ) : null}

              {(currentViewedPack?.evening_activities ?? []).length ? (
                <HabitPackActivityList
                  title={t('market.evening_habits')}
                  habitActivities={currentViewedPack?.evening_activities ?? []}
                />
              ) : null}

              {(currentViewedPack?.break_activities ?? []).length ? (
                <HabitPackActivityList
                  title={t('market.break_activities')}
                  habitActivities={currentViewedPack?.break_activities ?? []}
                />
              ) : null}
            </>
          )}

          {(currentViewedPack?.standalone_activities ?? []).length ? (
            <HabitPackActivityList
              title={t('market.standalone_activities')}
              habitActivities={currentViewedPack?.standalone_activities ?? []}
            />
          ) : null}
        </DetailsPageListWrapper>
      </DetailsPageBodyWrapper>
      {is_inquiry_modal_shown && platform === PLATFORMS.WEB && (
        <InquiryNoticeModal />
      )}
    </DetailsPageWrapper>
  );
}
