import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setIsOnHabitPacksTab } from 'store/reducer//habit-pack/slice';
import PackLists from '../../tabs/PackList';
import PackDetails from '../../tabs/PackDetails';

export default function PackManager() {
  const dispatch = useAppDispatch();
  const { currentViewedPack } = useAppSelector((state) => state.habitPack);

  useEffect(() => {
    dispatch(setIsOnHabitPacksTab(true));
    return () => {
      dispatch(setIsOnHabitPacksTab(false));
    };
  }, []);

  return currentViewedPack ? <PackDetails /> : <PackLists />;
}
