import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionIsOfficeFriendly } from 'store/reducer/modal/slice';
import COLOR from 'constants/color';
import { SWITCH } from 'constants/tag';
import HelpFilled from 'assets/icons/HelpFilled';
import Tooltip from 'components/common/Tooltip';

const IsOfficeFriendly = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { activity, showChoicesModal } = useAppSelector(
    (state) => state.modal.moreOption
  );
  return (
    <div className='min-w-max w-fit flex items-center gap-2 relative'>
      <Switch
        disabled={showChoicesModal}
        width={SWITCH.WIDTH.IS_OFFICE_FRIENDLY}
        height={SWITCH.HEIGHT.IS_OFFICE_FRIENDLY}
        onChange={(value) => {
          dispatch(updateMoreOptionIsOfficeFriendly(value));
        }}
        checked={activity?.is_office_friendly ?? false}
        offColor={COLOR.SWITCH_OFF}
      />
      <h6 className='text-xs lg:text-sm font-medium'>
        {t('is_office_friendly')}
      </h6>
      <Tooltip
        message={t('is_this_habit_safe_to_do_around_other_people')}
        icon={<HelpFilled />}
      />
    </div>
  );
};

export default IsOfficeFriendly;
