import { createAsyncThunk } from '@reduxjs/toolkit';
import Endpoints from 'constants/endpoints';
import {
  CreateToDoPayload,
  LogsByActivityInTimeRangePayload,
  StatsByActivityPerDayPayload
} from 'interfaces';
import focusBearApi from 'services/axios-config';
import * as Sentry from '@sentry/react';
import {
  updateIsGeneratingSubtasks,
  updateIsUpdatingSubTaskStatus,
  updateRoutineActivitiesStatsIsRoutineStatsLoading,
  updateTodoSubTasks
} from './slice';
import { HTTP_STATS_CODE } from 'constants/general';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import { RootState } from 'store';
import { updateTasks } from '../to-do/slice';

// deprecated
export const getStatsByActivityPerDay = createAsyncThunk(
  'modal/get_stats_by_activity_per_day',
  async (
    { activity_id, days_number, timezone }: StatsByActivityPerDayPayload,
    { dispatch }
  ) => {
    try {
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(true));
      const { data, status } = await focusBearApi.get(
        `${Endpoints.COMPLETED_ACTIVITY}/${activity_id}`,
        {
          params: {
            days_number,
            timezone
          }
        }
      );
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(false));
      if (status !== HTTP_STATS_CODE.SUCCESS) {
        toast(i18n.t('routine_stats_summary.could_not_fetch_summary_stats'));
      }
      return data ?? [];
    } catch (error) {
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getCompletedLogsByActivityInTimeRange = createAsyncThunk(
  'modal/get_completed_logs_by_activity_in_time_range',
  async (
    { activity_id, from_time, to_time }: LogsByActivityInTimeRangePayload,
    { dispatch }
  ) => {
    try {
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(true));
      const { data, status } = await focusBearApi.get(
        `${Endpoints.COMPLETED_ACTIVITY}/${activity_id}/stats`,
        { params: { from_time, to_time } }
      );
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(false));
      if (status !== HTTP_STATS_CODE.SUCCESS) {
        toast(i18n.t('routine_stats_summary.could_not_fetch_summary_stats'));
      }
      return data?.items ?? [];
    } catch (error) {
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getCompleteActivityLogQuestionsAnswers = createAsyncThunk(
  'modal/get_completed_activity_log_questions_answers',
  async (question_id: string, { dispatch }) => {
    try {
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(true));
      const { data, status } = await focusBearApi.get(
        Endpoints.COMPLETED_ACTIVITY_LOG_QUESTION_STATS(question_id)
      );
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(false));
      if (status !== HTTP_STATS_CODE.SUCCESS) {
        toast(i18n.t('routine_stats_summary.could_not_fetch_summary_stats'));
      }
      return data?.items ?? [];
    } catch (error) {
      dispatch(updateRoutineActivitiesStatsIsRoutineStatsLoading(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const generateSubTasks = createAsyncThunk(
  'modal/generate_subtasks',
  async (payload: { task: string; language: string }, { dispatch }) => {
    try {
      dispatch(updateIsGeneratingSubtasks(true));
      const { status, data } = await focusBearApi.post(
        Endpoints.TO_DO_GENERATE_SUBTASKS,
        payload
      );
      if (status === HTTP_STATS_CODE.CREATED) {
        toast.success(
          i18n.t('to_do_procrastinate.subtasks_generate_successfully')
        );
      } else {
        toast.error(i18n.t('to_do_procrastinate.could_not_generate_subtasks'));
      }
      return data;
    } catch (error) {
      dispatch(updateIsGeneratingSubtasks(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const updateSubTaskStatus = createAsyncThunk(
  'modal/update_sub_task_status',
  async (payload: CreateToDoPayload, { dispatch, getState }) => {
    try {
      const { tasks } = (getState() as RootState).toDo;
      dispatch(updateIsUpdatingSubTaskStatus(true));
      const { status } = await focusBearApi.put(Endpoints.TO_DO, payload);
      dispatch(updateIsUpdatingSubTaskStatus(false));
      if (status === HTTP_STATS_CODE.SUCCESS) {
        const updatedTasks = tasks.map((task) =>
          task.id === payload.id ? payload : task
        );
        dispatch(updateTasks(updatedTasks));
      } else {
        const task = tasks.find((task) => task.id === payload.id);
        task && dispatch(updateTodoSubTasks({ subTasks: task.subtasks ?? [] }));
        toast.error(i18n.t('to_do_procrastinate.could_not_update_the_task'));
      }
    } catch (error) {
      dispatch(updateIsUpdatingSubTaskStatus(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
