import DropDown from 'components/common/DropDown';
import Tooltip from 'components/common/Tooltip';
import { DropDownSelectedValue } from 'interfaces';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionTutorial } from 'store/reducer/modal/slice';

const Tutorial = () => {
  const dispatch = useAppDispatch();
  const {
    course: { isFetchingTutorials, tutorials },
    modal: {
      moreOption: {
        activity: { tutorial: tutorial_id }
      }
    }
  } = useAppSelector((state) => state);
  const tutor = tutorials.find((tutor) => tutor.id === tutorial_id);

  return (
    <div className='w-fit flex flex-col gap-1'>
      <div className='w-fit flex items-center gap-1'>
        {t('tutorial')} <Tooltip message={t('tutorial_info_description')} />
      </div>
      <DropDown
        isClearable
        isDisabled={isFetchingTutorials}
        isLoading={isFetchingTutorials}
        value={tutor ? { label: tutor?.name, value: tutor?.id } : undefined}
        options={tutorials.map((tutorial) => ({
          label: tutorial.name,
          value: tutorial.id
        }))}
        handleChange={(data) => {
          const selectedValue = data as DropDownSelectedValue;
          dispatch(updateMoreOptionTutorial(selectedValue?.value));
        }}
        containerStyles='min-w-full md:min-w-[15rem] max-w-[25rem]'
      />
    </div>
  );
};

export default Tutorial;
