import classNames from 'classnames';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { isDarkModeActivated } from 'utils/validation';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title?: string;
  icon?: JSX.Element;
  variant?: BTN_FB_VARIANT;
  additionalStyles?: string;
  size?: string;
  children?: JSX.Element;
  disableDarkMode?: boolean;
  disableResize?: boolean;
}

const ButtonFocusBear = ({
  title,
  icon,
  variant = BTN_FB_VARIANT.PRIMARY,
  additionalStyles,
  size = BTN_FB_SIZE.MEDIUM,
  children,
  disableDarkMode,
  disableResize,
  ...rest
}: Props) => {
  const shouldActivateDarkMode =
    isDarkModeActivated(useAppSelector(themeSelector)) && !disableDarkMode;
  return (
    <button
      {...rest}
      className={classNames(
        'w-fit h-fit px-2.5 py-1 rounded-md shadow flex items-center gap-1',
        {
          'text-white bg-sunRayFirst hover:text-sunRayFirst hover:bg-sunRayFourth active:text-white active:bg-sunRaySecond disabled:bg-sunRayFifth disabled:text-white disabled:hover:bg-sunRayFifth disabled:hover:text-white':
            variant === BTN_FB_VARIANT.PRIMARY && !shouldActivateDarkMode,
          'text-sunRayFirst ring-1 ring-sunRayFirst ring-inset bg-sunRayFifth hover:text-sunRaySecond hover:bg-sunRayFifth hover:ring-sunRayFourth active:text-sunRaySecond active:bg-sunRayFifth active:ring-sunRaySecond disabled:text-sunRayFourth disabled:bg-sunRayFifth disabled:ring-sunRayFourth disabled:hover:bg-sunRayFifth disabled:hover:text-sunRayFourth':
            variant === BTN_FB_VARIANT.SECONDARY && !shouldActivateDarkMode,
          'text-sunRayFirst bg-sunRaySixth hover:text-sunRayFourth hover:bg-sunRaySixth active:text-sunRayFirst active:bg-sunRaySixth disabled:text-sunRaySecond disabled:bg-white disabled:hover:bg-white disabled:hover:text-sunRaySecond':
            variant === BTN_FB_VARIANT.TERTIARY && !shouldActivateDarkMode,
          'text-sunRayFourth hover:text-sunRayFirst hover:underline underline-offset-2':
            variant === BTN_FB_VARIANT.LINK && !shouldActivateDarkMode,
          'text-xs sm:text-sm': !disableResize && size === BTN_FB_SIZE.SMALL,
          'text-xs sm:text-base lg:text-lg':
            !disableResize && size === BTN_FB_SIZE.MEDIUM,
          'text-xs sm:text-lg md:text-xl':
            !disableResize && size === BTN_FB_SIZE.LARGE,
          'text-white bg-gray-700 disabled:bg-gray-400/30 disabled:shadow-none disabled:text-gray-300/30':
            variant !== BTN_FB_VARIANT.LINK && shouldActivateDarkMode,
          'text-gray-300 hover:text-gray-100 hover:underline underline-offset-2':
            variant === BTN_FB_VARIANT.LINK && shouldActivateDarkMode
        },
        additionalStyles
      )}
    >
      {title}
      {icon && <span>{icon}</span>}
      {children}
    </button>
  );
};

export default ButtonFocusBear;
