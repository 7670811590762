import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import { MODAL_TYPES } from 'constants/general';
import { t } from 'i18next';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { useContext } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateConfirmActionModal } from 'store/reducer/modal/slice';
import { PlayerTaskItemContext } from '.';

const PlayerTaskItemDeleteBtn = () => {
  const dispatch = useAppDispatch();
  const { task } = useContext(PlayerTaskItemContext);
  const { playerData } = useContext(ToDoPlayerContext);

  return (
    <button
      disabled={playerData.isPlaying}
      onClick={() => {
        dispatch(
          updateConfirmActionModal({
            cancelTitle: t('undo'),
            confirmTitle: t('confirm_delete'),
            modalType: MODAL_TYPES.CONFIRM_ACTION_DELETE_PLAYER_TASK,
            showConfirmModal: true,
            taskId: task?.id
          })
        );
      }}
      className='absolute -top-2 -right-2 rounded-full bg-white'
    >
      <CloseCircle
        styles='w-5 h-auto'
        fill={COLOR.RED}
        fillDarkMode={COLOR.RED}
      />
    </button>
  );
};

export default PlayerTaskItemDeleteBtn;
