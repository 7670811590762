import { createAsyncThunk } from '@reduxjs/toolkit';
import focusBearApi from 'services/axios-config';
import Endpoints from 'constants/endpoints';
import { UpdateFocusTemplateApprovalStatusProps } from 'interfaces';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import i18n from 'services/i18n';
import { updateAreSettingsSaving, updateError } from '../setting/slice';
import {
  setCurrentViewedFocusTemplate,
  updateIsUserInstalledFocusTemplatesFetching,
  updateIsFocusTemplateFetching,
  updateIsFocusTemplateInstalling,
  updateIsFocusTemplateUninstalling,
  updateIsSharedFocusTemplateFetching,
  updateSharedFocusTemplate,
  updateAreFocusTemplatesLoading,
  updateAreTagsFetching,
  updateIsMarketFocusTemplatesFetching,
  updateIsFocusTemplateDeleting
} from './slice';
import { HTTP_STATS_CODE } from 'constants/general';
import { BASE_URL } from 'constants/routes';
import { AxiosError } from 'axios';
import { FocusModeTemplateType } from 'interfaces/commonInterface';

export const upsertFocusModeTemplate = createAsyncThunk(
  'focus_mode/upsert_focus_mode_template',
  async (focusModeTemplate: FocusModeTemplateType, { dispatch }) => {
    try {
      dispatch(updateAreSettingsSaving(true));
      const { status } = await focusBearApi.put(
        Endpoints.UPSERT_FOCUS_MODE_TEMPLATE,
        focusModeTemplate
      );
      if (status === HTTP_STATS_CODE.SUCCESS) {
        toast.success(i18n.t('focus_mode.focus_mode_saved'));
      } else {
        toast.warning(i18n.t('focus_mode.could_not_save_focus_mode'));
      }
      dispatch(updateAreSettingsSaving(false));
    } catch (error) {
      dispatch(
        updateError({
          message: 'focus_mode.could_not_save_focus_mode',
          status: (error as AxiosError).response?.status
        })
      );
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getFocusTemplateById = createAsyncThunk(
  'focus_mode/get_focus_template_by_id',
  async (
    {
      template_id,
      isUserLoggedIn
    }: { template_id: string; isUserLoggedIn: boolean },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsFocusTemplateFetching(true));
      const { data } = await focusBearApi.get(
        Endpoints.GET_FOCUS_TEMPLATE_BY_ID.replace('{template_id}', template_id)
      );

      if (isUserLoggedIn) {
        dispatch(updateIsUserInstalledFocusTemplatesFetching(true));
        dispatch(getUserInstalledFocusTemplates());
      }
      return data;
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(
        updateError({
          message: 'focus_mode.could_not_fetch_focus_mode',
          status: (error as AxiosError).response?.status
        })
      );
    }
  }
);

export const getUserCreatedFocusTemplates = createAsyncThunk(
  'focus_mode/get_user_created_focus_templates',
  async (userId: string, { dispatch }) => {
    try {
      dispatch(updateAreFocusTemplatesLoading(true));
      const { data } = await focusBearApi.get(
        Endpoints.GET_USER_CREATED_FOCUS_TEMPLATES.replace('{user_id}', userId)
      );
      return data ?? [];
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(
        updateError({
          message: 'focus_mode.could_not_fetch_focus_mode',
          status: (error as AxiosError).response?.status
        })
      );
    }
  }
);

export const getMarketplaceApprovedFocusTemplates = createAsyncThunk(
  'focus_mode/get_marketplace_approved_focus_templates',
  async (
    { searchTerm, language }: { searchTerm: string; language: string },
    { dispatch }
  ) => {
    try {
      dispatch(updateIsMarketFocusTemplatesFetching(true));
      const { data } = await focusBearApi.get(
        Endpoints.GET_MARKETPLACE_APPROVED_FOCUS_TEMPLATES,
        {
          params: {
            marketplace_approval_status: true,
            language
          }
        }
      );
      return (
        data?.filter(
          (focusTemplate: FocusModeTemplateType) =>
            focusTemplate.name?.toLowerCase().includes(searchTerm) ||
            focusTemplate.author_name?.toLowerCase().includes(searchTerm)
        ) ?? []
      );
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(
        updateError({
          message: 'focus_mode.could_not_fetch_focus_mode',
          status: (error as AxiosError).response?.status
        })
      );
    }
  }
);

export const getUserInstalledFocusTemplates = createAsyncThunk(
  'focus_mode/get_user_installed_focus_templates',
  async (_, { dispatch }) => {
    try {
      const { data } = await focusBearApi.get(
        Endpoints.GET_USER_INSTALLED_FOCUS_TEMPLATES
      );
      return data ?? [];
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
      dispatch(
        updateError({
          message: 'focus_mode.could_not_fetch_focus_mode',
          status: (error as AxiosError).response?.status
        })
      );
    }
  }
);

export const updateFocusTemplateMarketPlaceRequestStatus = createAsyncThunk(
  'focus_mode/update_marketplace_approval_status',
  async (
    { focusTemplate, status }: UpdateFocusTemplateApprovalStatusProps,
    { dispatch }
  ) => {
    try {
      const modifiedFocusTemplate: FocusModeTemplateType = {
        ...focusTemplate,
        marketplace_request: status
      };
      dispatch(setCurrentViewedFocusTemplate(modifiedFocusTemplate));
      dispatch(upsertFocusModeTemplate(modifiedFocusTemplate));
    } catch (error) {
      toast.error(i18n.t('market.could_not_update_focus_template'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const removeFocusTemplateFromMarketPlace = createAsyncThunk(
  'focus_mode/update_marketplace_approval_status',
  async (focusTemplate: FocusModeTemplateType, { dispatch }) => {
    try {
      const modifiedFocusTemplate: FocusModeTemplateType = {
        ...focusTemplate,
        marketplace_approval_status: false
      };
      dispatch(setCurrentViewedFocusTemplate(modifiedFocusTemplate));
      dispatch(upsertFocusModeTemplate(modifiedFocusTemplate));
    } catch (error) {
      toast.error(i18n.t('market.could_not_update_focus_template'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const deleteFocusTemplate = createAsyncThunk(
  'focus_mode/delete_focus_template',
  async (focusTemplateId: string, { dispatch }) => {
    try {
      dispatch(updateIsFocusTemplateDeleting(true));
      await focusBearApi.delete(
        Endpoints.DELETE_FOCUS_TEMPLATE.replace(
          '{template_id}',
          focusTemplateId
        )
      );
      dispatch(updateIsFocusTemplateDeleting(false));
    } catch (error) {
      dispatch(updateIsFocusTemplateDeleting(false));
      Sentry.captureException(JSON.stringify(error));
      toast.warning(i18n.t('focus_mode.could_not_save_focus_mode'), {
        position: 'bottom-left',
        autoClose: 3000
      });
    }
  }
);

export const installFocusTemplate = createAsyncThunk(
  'focus_mode/install_focus_template',
  async (focusTemplateId: string, { dispatch }) => {
    try {
      const { status } = await focusBearApi.post(
        Endpoints.INSTALL_FOCUS_TEMPLATE.replace(
          '{template_id}',
          focusTemplateId
        )
      );
      if (status === HTTP_STATS_CODE.CREATED) {
        toast.success(
          i18n.t('focus_mode.focus_template_installed_successfully')
        );
        dispatch(getUserInstalledFocusTemplates());
      } else {
        toast.warning(i18n.t('focus_mode.could_not_save_focus_mode'));
      }
      dispatch(updateIsFocusTemplateInstalling(false));
    } catch (error) {
      toast.error(i18n.t('focus_mode.could_not_save_focus_mode'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const uninstallingFocusTemplate = createAsyncThunk(
  'focus_mode/uninstall_focus_template',
  async (focusModeId: string, { dispatch }) => {
    try {
      dispatch(updateIsFocusTemplateUninstalling(true));
      const { status } = await focusBearApi.delete(
        Endpoints.UNINSTALL_FOCUS_TEMPLATE.replace(
          '{focus_mode_id}',
          focusModeId
        )
      );
      dispatch(updateIsFocusTemplateUninstalling(false));
      if (status === HTTP_STATS_CODE.NO_CONTENT) {
        toast.success(
          i18n.t('focus_mode.focus_template_uninstalled_successfully')
        );
        dispatch(getUserInstalledFocusTemplates());
      } else {
        toast.warning(i18n.t('focus_mode.could_not_uninstall_focus_mode'));
      }
    } catch (error) {
      toast.error(i18n.t('focus_mode.could_not_uninstall_focus_mode'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getSharedFocusTemplateById = createAsyncThunk(
  'focus_mode/get_shared_focus_template_by_id',
  async (templateId: string, { dispatch }) => {
    try {
      dispatch(updateIsSharedFocusTemplateFetching(true));
      const response = await fetch(
        `${BASE_URL}${Endpoints.GET_FOCUS_TEMPLATE_BY_ID.replace(
          '{template_id}',
          templateId
        )}`
      );
      const data = await response.json();
      if (data && data?.statusCode !== 404) {
        dispatch(updateSharedFocusTemplate(data));
      }
    } catch (error) {
      dispatch(
        updateError({
          message: 'market.could_not_fetch_focus_mode',
          status: (error as AxiosError).response?.status
        })
      );
      Sentry.captureException(JSON.stringify(error));
    } finally {
      dispatch(updateIsSharedFocusTemplateFetching(false));
    }
  }
);

export const getFocusModeTags = createAsyncThunk(
  'focus_mode/get_focus_mode_tags',
  async (_, { dispatch }) => {
    try {
      dispatch(updateAreTagsFetching(true));
      const { data } = await focusBearApi.get(Endpoints.FOCUS_MODE_TAGS);
      return data ?? [];
    } catch (error) {
      dispatch(updateIsSharedFocusTemplateFetching(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
