import { ROUTES } from 'constants/routes';
import { useAuth0 } from '@auth0/auth0-react';
import lazyDelayedImport from 'utils/lazyDelayedImport';
import { DYNAMIC_PATH_ID, EMPTY_STRING } from 'constants/general';
import ToDo from './to-do';
import ToDoFocusEnd from 'pages/to-do/toDoFocusEnd';
import TeamAcceptInvitation from '../../pages/team/TeamAcceptInvitation';
import Course from 'pages/course';
import ToDoPlayer from 'pages/to-do/toDoPlayer';
import { isDashboard } from 'utils/support';
import SettingsPage from 'pages/settings';
import Subscription from 'pages/subscription';
import RoutineSuggestions from 'pages/routine-suggestions';
import AppTutorials from 'pages/course/AppsTutorials';
import Navigation from 'navigation';
import GetSupport from 'pages/get-support';

const FocusTemplateDetailsPage = lazyDelayedImport(
  () => import('pages/marketplace/FocusTemplateDetailsPage')
);
const HabitPackDetailsPage = lazyDelayedImport(
  () => import('pages/habit-pack-details-page')
);
const MarketPage = lazyDelayedImport(() => import('pages/marketplace'));
const Motivation = lazyDelayedImport(() => import('pages/motivation'));
const PackLandingPage = lazyDelayedImport(
  () => import('pages/marketplace/PackLandingPage')
);
const FocusTemplateLandingPage = lazyDelayedImport(
  () => import('pages/marketplace/FocusTemplateLandingPage')
);
const StatsPage = lazyDelayedImport(() => import('pages/stats'));
const ChatBot = lazyDelayedImport(() => import('pages/chat-bot'));
const Survey = lazyDelayedImport(() => import('pages/survey'));

// it removes :id sub-string
const getPathWithoutId = (path: string) =>
  path.replace(DYNAMIC_PATH_ID, EMPTY_STRING);

const GetDashboardContext = () => {
  const pathname = window.location.pathname;
  const { isAuthenticated } = useAuth0();

  if (!isDashboard) {
    return <SettingsPage />;
  } else if (pathname.startsWith(getPathWithoutId(ROUTES.HABIT_PACK_SHARE))) {
    return <PackLandingPage />;
  } else if (
    pathname.startsWith(getPathWithoutId(ROUTES.FOCUS_TEMPLATE_SHARE))
  ) {
    return <FocusTemplateLandingPage />;
  } else if (
    [
      ROUTES.WEBVIEW_MOBILE_MOTIVATIONAL_SUMMARY,
      ROUTES.WEBVIEW_MOTIVATIONAL_SUMMARY
    ].includes(pathname)
  ) {
    return <Motivation />;
  } else if (pathname === ROUTES.WEBVIEW_CHAT) {
    return <ChatBot />;
  } else if (pathname === ROUTES.WEBVIEW_STATS) {
    return <StatsPage />;
  } else if (
    [ROUTES.WEBVIEW_TODO_LIST, ROUTES.WEBVIEW_TOOLS_TODO_LIST].includes(
      pathname
    )
  ) {
    return <ToDo />;
  } else if (pathname === ROUTES.WEBVIEW_SURVEY) {
    return <Survey />;
  } else if (pathname === ROUTES.WEBVIEW_FOCUS_END) {
    return <ToDoFocusEnd />;
  } else if (!isAuthenticated && pathname === ROUTES.MARKETPLACE) {
    return <MarketPage />;
  } else if (pathname === ROUTES.WEBVIEW_COURSE) {
    return <Course />;
  } else if (
    !isAuthenticated &&
    pathname.startsWith(getPathWithoutId(ROUTES.HABIT_PACK_DETAILS))
  ) {
    return <HabitPackDetailsPage />;
  } else if (
    !isAuthenticated &&
    pathname.startsWith(getPathWithoutId(ROUTES.FOCUS_TEMPLATE_DETAILS))
  ) {
    return <FocusTemplateDetailsPage />;
  } else if (pathname.startsWith(ROUTES.ACCEPT_INVITE)) {
    return <TeamAcceptInvitation />;
  } else if (pathname === ROUTES.WEBVIEW_TO_DO_PLAYER) {
    return <ToDoPlayer />;
  } else if (ROUTES.WEBVIEW_MANAGE_SUBSCRIPTION === pathname) {
    return <Subscription />;
  } else if (pathname === ROUTES.WEBVIEW_ROUTINE_SUGGESTION) {
    return <RoutineSuggestions />;
  } else if (ROUTES.WEBVIEW_ENROLLED_COURSES === pathname) {
    return <AppTutorials />;
  } else if (ROUTES.WEBVIEW_GET_SUPPORT === pathname) {
    return <GetSupport />;
  } else {
    return <Navigation />;
  }
};
export default GetDashboardContext;
