import { useContext, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { TasksContext } from '.';
import { getFilteredAndOrderedTasks } from 'utils/support';
import { t } from 'i18next';
import { listOfProjectsSelector } from 'store/reducer/to-do/selectors';
import TaskItem from './task-item';
import { COMPLETED_TAB } from 'constants/general';

const ListOfTasks = () => {
  const {
    tasks,
    pagination: { currentPage, itemsPerPage },
    activeTab,
    searchResults,
    completedTasks
  } = useAppSelector(listOfProjectsSelector);

  const { filterData, dueDateFilters } = useContext(TasksContext);

  const filteredAndOrderedTasks = useMemo(
    () =>
      getFilteredAndOrderedTasks(
        activeTab,
        tasks,
        filterData,
        currentPage,
        itemsPerPage,
        dueDateFilters
      ),
    [tasks, filterData, currentPage, itemsPerPage, dueDateFilters]
  );

  const taskLists =
    activeTab === COMPLETED_TAB ? completedTasks : filteredAndOrderedTasks;

  return (
    <div className='w-full h-full flex flex-col gap-4 px-2 items-center overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300'>
      {(filterData.taskName ? searchResults : taskLists)?.map((task) => (
        <TaskItem key={task.id} task={task} />
      ))}
      {!tasks.length && (
        <p className='w-full text-center text-xs md:text-sm py-5'>
          {t('to_do_procrastinate.add_your_first_task')}
        </p>
      )}
      {searchResults && !searchResults?.length && (
        <p className='w-full text-center text-xs md:text-sm py-5'>
          {t('to_do_procrastinate.could_not_find_any_tasks')}
        </p>
      )}
    </div>
  );
};

export default ListOfTasks;
