import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useLocation } from 'react-router-dom';
import { FOCUS_TEMPLATE_FEATURE, TAB } from 'constants/general';
import { ROUTES } from 'constants/routes';
import TabBodyWrapper from '../TabBodyWrapper';
import TabBodyHeader from '../TabBodyHeader';
import PackAndFocusModeInfoContainer from 'components/dashboard/wrappers/PackAndFocusModeInfoContainer';
import PackAndFocusModeInfoLeftContainer from 'components/dashboard/wrappers/PackAndFocusModeInfoLeftContainer';
import PackAndFocusModeInfoRightContainer from 'components/dashboard/wrappers/PackAndFocusModeInfoRightContainer';
import InputWithLabel from '../../../common/inputs/InputWithLabel';
import RichTextEditorWithLabel from 'components/common/RichTextEditorWithLabel';
import AdminWidgets from './AdminWidgets';
import { updateFilterIsAdminToolbarShow } from 'store/reducer/filter/slice';
import {
  changePackDescription,
  changePackDescriptionVideo,
  changePackName,
  changePackWelcomeMessage,
  changePackWelcomeMessageVideo
} from 'store/reducer/setting/slice';
import { updateCurrentViewedFocusTemplate } from 'store/reducer/focus-mode/slice';

const PackAndFocusModeInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    setting: { currentSettings, tabs, activeTabIndex },
    user: { isUserAdmin },
    focusMode: { currentViewedFocusTemplate }
  } = useAppSelector((state) => state);
  const { pathname } = useLocation();
  const isPackInfoTab = tabs[activeTabIndex] === TAB.PACK_INFO;
  const title = isPackInfoTab
    ? t('enter_habit_pack_info')
    : t('focus_mode.enter_focus_mode_info');
  const nameLabel = isPackInfoTab ? t('packs.pack_name') : t('focus_mode.name');
  const descriptionURLLabel = isPackInfoTab
    ? t('packs.pack_description_url')
    : t('focus_mode.description_url');
  const descriptionURLTooltip = isPackInfoTab
    ? t('packs.description_url_tooltip')
    : t('focus_mode.description_url_tooltip');
  const descriptionLabel = isPackInfoTab
    ? t('packs.pack_description')
    : t('focus_mode.description');
  const descriptionTooltip = isPackInfoTab
    ? t('packs.description_tooltip')
    : t('focus_mode.description_tooltip');
  const welcomeURLLabel = isPackInfoTab
    ? t('packs.pack_welcome_video_url')
    : t('focus_mode.welcome_video_url');
  const welcomeURLTooltip = isPackInfoTab
    ? t('packs.welcome_url_tooltip')
    : t('focus_mode.welcome_url_tooltip');
  const welcomeLabel = isPackInfoTab
    ? t('packs.pack_welcome_message')
    : t('focus_mode.welcome_message');
  const welcomeTooltip = isPackInfoTab
    ? t('packs.welcome_message_tooltip')
    : t('focus_mode.welcome_message_tooltip');

  const name = isPackInfoTab
    ? currentSettings?.pack_name
    : currentViewedFocusTemplate?.name;
  const descriptionVideoUrl = isPackInfoTab
    ? currentSettings?.description_video_url
    : currentViewedFocusTemplate?.description_video_url;
  const description = isPackInfoTab
    ? currentSettings?.description
    : currentViewedFocusTemplate?.description;
  const welcomeVideoURL = isPackInfoTab
    ? currentSettings?.welcome_video_url
    : currentViewedFocusTemplate?.welcome_video_url;
  const welcomeMessage = isPackInfoTab
    ? currentSettings?.welcome_message
    : currentViewedFocusTemplate?.welcome_message;

  useEffect(() => {
    isUserAdmin && dispatch(updateFilterIsAdminToolbarShow(true));
  }, []);

  const handleNameChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      isPackInfoTab
        ? dispatch(changePackName(value))
        : dispatch(
            updateCurrentViewedFocusTemplate({
              focusTemplateFeature: FOCUS_TEMPLATE_FEATURE.NAME,
              value
            })
          );
    },
    []
  );

  const handleDescriptionVideoUrlChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      isPackInfoTab
        ? dispatch(changePackDescriptionVideo(value))
        : dispatch(
            updateCurrentViewedFocusTemplate({
              focusTemplateFeature:
                FOCUS_TEMPLATE_FEATURE.DESCRIPTION_VIDEO_URL,
              value
            })
          );
    },
    []
  );

  const handleDescriptionChange = useCallback((value: string) => {
    isPackInfoTab
      ? dispatch(changePackDescription(value))
      : dispatch(
          updateCurrentViewedFocusTemplate({
            focusTemplateFeature: FOCUS_TEMPLATE_FEATURE.DESCRIPTION,
            value
          })
        );
  }, []);

  const handleWelcomeVideoUrlChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      isPackInfoTab
        ? dispatch(changePackWelcomeMessageVideo(value))
        : dispatch(
            updateCurrentViewedFocusTemplate({
              focusTemplateFeature: FOCUS_TEMPLATE_FEATURE.WELCOME_VIDEO_URL,
              value
            })
          );
    },
    []
  );

  const handleWelcomeMessageChange = useCallback((value: string) => {
    isPackInfoTab
      ? dispatch(changePackWelcomeMessage(value))
      : dispatch(
          updateCurrentViewedFocusTemplate({
            focusTemplateFeature: FOCUS_TEMPLATE_FEATURE.WELCOME_MESSAGE,
            value
          })
        );
  }, []);

  return (
    <TabBodyWrapper>
      <TabBodyHeader title={title} />
      <PackAndFocusModeInfoContainer>
        <PackAndFocusModeInfoLeftContainer>
          <InputWithLabel
            label={nameLabel}
            handleChange={handleNameChange}
            value={name ?? ''}
            type={t('name')}
            required
          />
          <InputWithLabel
            label={descriptionURLLabel}
            tooltip={descriptionURLTooltip}
            handleChange={handleDescriptionVideoUrlChange}
            value={descriptionVideoUrl ?? ''}
            required
            type='url'
          />
          <RichTextEditorWithLabel
            label={descriptionLabel}
            tooltip={descriptionTooltip}
            handleChange={handleDescriptionChange}
            value={description ?? ''}
          />
        </PackAndFocusModeInfoLeftContainer>
        <PackAndFocusModeInfoRightContainer>
          <InputWithLabel
            label={welcomeURLLabel}
            tooltip={welcomeURLTooltip}
            handleChange={handleWelcomeVideoUrlChange}
            value={welcomeVideoURL ?? ''}
            type='url'
          />
          <RichTextEditorWithLabel
            label={welcomeLabel}
            tooltip={welcomeTooltip}
            handleChange={handleWelcomeMessageChange}
            value={welcomeMessage ?? ''}
          />
        </PackAndFocusModeInfoRightContainer>
        {pathname.includes(ROUTES.HOME) && isUserAdmin && <AdminWidgets />}
      </PackAndFocusModeInfoContainer>
    </TabBodyWrapper>
  );
};

export default PackAndFocusModeInfo;
