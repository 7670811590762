import COLOR from 'constants/color';
import { ICONS } from 'constants/tag';
import { IconProps } from 'interfaces';

const Hamburger = ({
  width = ICONS.HAMBURGER.DEFAULT.WIDTH,
  height = ICONS.HAMBURGER.DEFAULT.HEIGHT,
  fill = COLOR.BLACK
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g strokeWidth='0'></g>
      <g strokeLinecap='round' strokeLinejoin='round'></g>
      <g id='SVGRepo_iconCarrier'>
        <g>
          <path
            id='Vector'
            d='M5 17H19M5 12H19M5 7H19'
            stroke={fill}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Hamburger;
