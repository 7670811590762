const colors = {
  SWITCH_OFF: '#D03131',
  FINISHED: '#00D100',
  INFO: '#0674db',
  DARK_CERULEAN: '004777',
  YELLOW: 'F7B801',
  DARK_RED: 'A30000',
  BLUE: '#3b82f6',
  BLUE_600: '#2563eb',
  BLACK: '#000',
  WHITE: '#fff',
  RED: '#FF0000',
  GREEN: '#00FF00',
  GREEN_200: '#bbf7d0',
  GREEN_500: '#22c55e',
  GREEN_600: '#16a34a',
  GREEN_700: '#15803d',
  ORANGE: '#f97316',
  ORANGE_100: '#ffedd5',
  ORANGE_200: '#fed7aa',
  ORANGE_300: '#fdba74',
  ORANGE_400: '#fb923c',
  ORANGE_600: '#ea580c',
  GRAY: '#808080',
  GRAY_3OO: '#d4d4d4',
  GRAY_4OO: '#9ca3af',
  GRAY_5OO: '#6b7280',
  GRAY_600: '#475569',
  GRAY_700: '#374151',
  GRAY_800: '#1e293b',
  TRANSPARENT: '#00000000',
  FOCUS_BEAR_LIGHT: '#fff4e9',
  FOCUS_BEAR_DARK: '#dd9542'
};

export default colors;
