import React from 'react';
import ModalHeader from 'components/shared/ModalHeader';
import ModalOverlay from 'components/shared/ModalOverlay';
import { useTranslation } from 'react-i18next';
import {
  EMPTY_STRING,
  LOG_SUMMARY_OPTION,
  MODAL_TYPES,
  MORNING_ROUTINE_ADVANCED_ACTION_TYPE
} from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  createRandomActivityWithChoiceOfMorningRoutine,
  duplicateMorningOnEveningRoutine
} from 'store/reducer/setting/slice';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

interface ModalContentItemProps {
  item: { title: string; action: string };
  actionType: string;
}

const Wrapper = ({ children }: { readonly children: React.ReactNode }) => (
  <div className='relative top-1/4 left-1/2 -translate-x-1/2 w-5/6 sm:w-3/4 md:w-1/2 h-10 border-yellow-300 flex flex-col'>
    {children}
  </div>
);

const ModalContent = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full flex flex-col gap-3 py-6 px-4 bg-white rounded-b-md'>
      <ModalContentItem
        item={{
          title: t('create_random_choice_break_activity_with_these_activities'),
          action: t('create')
        }}
        actionType={MORNING_ROUTINE_ADVANCED_ACTION_TYPE.CREATE}
      />
      <ModalContentItem
        item={{
          title: t('duplicate_activities_in_evening_routine'),
          action: t('duplicate')
        }}
        actionType={MORNING_ROUTINE_ADVANCED_ACTION_TYPE.DUPLICATE}
      />
    </div>
  );
};

const ModalContentItem = ({ item, actionType }: ModalContentItemProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { morning_activities, pack_name } = useAppSelector(
    (state) => state.setting.currentSettings
  );
  const isDuplicationAllowed =
    actionType === MORNING_ROUTINE_ADVANCED_ACTION_TYPE.DUPLICATE &&
    morning_activities &&
    morning_activities.length > 0;
  const isRandomCreationAllowed =
    actionType === MORNING_ROUTINE_ADVANCED_ACTION_TYPE.CREATE;

  const handleAction = () => {
    if (actionType === MORNING_ROUTINE_ADVANCED_ACTION_TYPE.CREATE) {
      const choices =
        morning_activities?.map((activity) => ({
          id: uuid(),
          name: activity.name,
          video_urls: activity.video_urls ?? [],
          log_quantity: activity.log_quantity ?? false,
          log_quantity_question: EMPTY_STRING,
          log_summary_type:
            activity.log_summary_type ?? LOG_SUMMARY_OPTION.SUMMATION
        })) ?? [];
      dispatch(
        createRandomActivityWithChoiceOfMorningRoutine({
          activityName: pack_name ?? '',
          choices
        })
      );
      toast.success(t('random_creation_successful'));
    } else {
      dispatch(duplicateMorningOnEveningRoutine());
      toast.success(t('duplication_successful'));
    }
  };

  return (
    <div className='w-full h-fit flex items-center gap-2'>
      <div className='capitalize'>{item.title}</div>
      <button
        disabled={
          isRandomCreationAllowed
            ? !isRandomCreationAllowed
            : !isDuplicationAllowed
        }
        onClick={handleAction}
        className={`w-fit px-2.5 py-0.5 bg-gray-500 ${
          isDuplicationAllowed || isRandomCreationAllowed
            ? 'hover:bg-gray-600 cursor-pointer'
            : 'cursor-not-allowed'
        } text-white rounded-md text-sm`}
      >
        {item.action}
      </button>
    </div>
  );
};

const MorningAdvancedModal = () => {
  const { t } = useTranslation();
  return (
    <ModalOverlay zIndex='z-[70]'>
      <Wrapper>
        <ModalHeader title={t('advanced')} modalType={MODAL_TYPES.ROUTINE} />
        <ModalContent />
      </Wrapper>
    </ModalOverlay>
  );
};

export default MorningAdvancedModal;
