import React, { useCallback } from 'react';
import InputWithLabel from '../../../common/inputs/InputWithLabel';
import SwitchWithLabel from '../../tabs/SwitchWithLabel';
import { EMPTY_STRING, FOCUS_TEMPLATE_FEATURE, TAB } from 'constants/general';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changePackCreatorName,
  changePackFeaturedForOnboardingStatus,
  changePackIsFeaturedStatus,
  changePackMarketplaceApprovalStatus
} from 'store/reducer/setting/slice';
import { updateFilterIsAdminToolbarShow } from 'store/reducer/filter/slice';
import AdminFilled from 'assets/icons/AdminFilled';
import COLOR from 'constants/color';
import CloseCircle from 'assets/icons/CloseCircle';
import { updateCurrentViewedFocusTemplate } from 'store/reducer/focus-mode/slice';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='w-fit h-fit flex justify-end gap-1 absolute -top-16 right-4'>
    {children}
  </div>
);

const AdminWidgetActions = () => {
  const dispatch = useAppDispatch();
  const { isAdminToolbarShown } = useAppSelector((state) => state.filter);
  return (
    <button
      className={`w-fit h-fit bg-focusBear ${!isAdminToolbarShown && 'p-1'}`}
      onClick={() => {
        dispatch(updateFilterIsAdminToolbarShow(!isAdminToolbarShown));
      }}
    >
      {isAdminToolbarShown ? (
        <CloseCircle fill={COLOR.BLACK} />
      ) : (
        <AdminFilled />
      )}
    </button>
  );
};

const AdminWidgetToolbar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    setting: { tabs, activeTabIndex, currentSettings },
    focusMode: { currentViewedFocusTemplate }
  } = useAppSelector((state) => state);
  const isPackInfo = tabs[activeTabIndex] === TAB.PACK_INFO;
  const authorInputLabel = isPackInfo
    ? t('packs.creator_name')
    : t('focus_mode.author_name');
  const authorName = isPackInfo
    ? currentSettings?.creator_name
    : currentViewedFocusTemplate?.author_name;
  const isFeatured = isPackInfo
    ? currentSettings?.is_featured
    : currentViewedFocusTemplate?.is_featured;
  const featuredForOnboarding = isPackInfo
    ? currentSettings?.featured_for_onboarding
    : currentViewedFocusTemplate?.featured_for_onboarding;
  const marketplaceApprovalStatus = isPackInfo
    ? currentSettings?.marketplace_approval_status
    : currentViewedFocusTemplate?.marketplace_approval_status;

  const handleCreatorNameChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      isPackInfo
        ? dispatch(changePackCreatorName(value))
        : dispatch(
            updateCurrentViewedFocusTemplate({
              focusTemplateFeature: FOCUS_TEMPLATE_FEATURE.AUTHOR_NAME,
              value
            })
          );
    },
    []
  );

  const handleIsFeaturedStatusChange = useCallback((value: boolean) => {
    isPackInfo
      ? dispatch(changePackIsFeaturedStatus(value))
      : dispatch(
          updateCurrentViewedFocusTemplate({
            focusTemplateFeature: FOCUS_TEMPLATE_FEATURE.IS_FEATURED,
            value
          })
        );
  }, []);

  const handleFeaturedForOnboardingStatusChange = useCallback(
    (value: boolean) => {
      isPackInfo
        ? dispatch(changePackFeaturedForOnboardingStatus(value))
        : dispatch(
            updateCurrentViewedFocusTemplate({
              focusTemplateFeature:
                FOCUS_TEMPLATE_FEATURE.FEATURE_FOR_ONBOARDING,
              value
            })
          );
    },
    []
  );

  const handleMarketplaceApprovalStatusChange = useCallback(
    (value: boolean) => {
      isPackInfo
        ? dispatch(changePackMarketplaceApprovalStatus(value))
        : dispatch(
            updateCurrentViewedFocusTemplate({
              focusTemplateFeature:
                FOCUS_TEMPLATE_FEATURE.MARKETPLACE_APPROVAL_STATUS,
              value
            })
          );
    },
    []
  );

  return (
    <div className='w-full h-fit flex items-center gap-4 bg-gray-200 rounded animate-slideIn px-3 py-2'>
      <InputWithLabel
        label={authorInputLabel}
        handleChange={handleCreatorNameChange}
        value={authorName ?? EMPTY_STRING}
        type='author_name'
      />
      <SwitchWithLabel
        value={isFeatured ?? false}
        handleChange={handleIsFeaturedStatusChange}
        label={t('market.is_featured')}
      />
      <SwitchWithLabel
        value={marketplaceApprovalStatus ?? false}
        handleChange={handleMarketplaceApprovalStatusChange}
        label={t('market.marketplace_approval_status')}
      />
      <SwitchWithLabel
        value={featuredForOnboarding ?? false}
        handleChange={handleFeaturedForOnboardingStatusChange}
        label={t('market.featured_for_onboarding')}
      />
    </div>
  );
};

const AdminWidgets = () => {
  const { isAdminToolbarShown } = useAppSelector((state) => state.filter);
  return (
    <Wrapper>
      {isAdminToolbarShown && <AdminWidgetToolbar />}
      <AdminWidgetActions />
    </Wrapper>
  );
};

export default AdminWidgets;
