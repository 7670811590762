import React, { useMemo } from 'react';
import ModalHeader from 'components/shared/ModalHeader';
import ModalOverlay from 'components/shared/ModalOverlay';
import {
  EISENHOWER_PRIORITY_TITLE_ORIENTATION,
  EISENHOWER_QUADRANT,
  MODAL_TYPES,
  SCREEN,
} from 'constants/general';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { TO_DO_STATUS } from 'constants/enum';
import { useWindowSize } from 'usehooks-ts';
import { t } from 'i18next';
import CsvFile from 'assets/icons/CsvFile';
import { downloadEisenhowerMatrixCSVFile } from 'utils/downloadFiles';

interface WrapperProps {
  children: React.ReactNode;
}

interface PriorityTitleProps {
  orientation?: string;
  title: string;
}

interface QuadrantProps {
  background: string;
  quadrant: string;
  subtitle: string;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className='relative top-[40%] -translate-y-[40%] mx-auto w-[95vw] xs:w-[95%] sm:w-3/4 xl:w-3/5 2xl:w-1/2 h-fit shadow-lg rounded-md bg-gray-200 flex flex-col'>
    {children}
  </div>
);

const PriorityTitle = ({
  orientation = EISENHOWER_PRIORITY_TITLE_ORIENTATION.HORIZONTAL,
  title,
}: PriorityTitleProps) => {
  const isHorizontalOrientation =
    orientation === EISENHOWER_PRIORITY_TITLE_ORIENTATION.HORIZONTAL;
  return (
    <div
      className={`${
        isHorizontalOrientation ? 'w-full h-14' : 'w-16 h-[30vh]'
      } text-center bg-gray-300 rounded-md mb-1 flex items-center justify-center`}
    >
      <p
        className={`min-w-max h-fit ${
          isHorizontalOrientation ? 'rotate-0' : '-rotate-90'
        } font-semibold text-sm lg:text-base`}
      >
        {title}
      </p>
    </div>
  );
};

const Quadrant = ({ background, quadrant, subtitle }: QuadrantProps) => {
  const { tasks } = useAppSelector((state) => state.toDo);
  const quadrantTasks = tasks?.filter(
    (task) =>
      task.eisenhower_quadrant === parseInt(quadrant) &&
      task.status !== TO_DO_STATUS.COMPLETED,
  );

  return (
    <div
      className={`w-full h-[30vh] ${background} rounded-md mb-1 p-4 ${
        [EISENHOWER_QUADRANT.DELEGATE, EISENHOWER_QUADRANT.DELETE].includes(
          quadrant,
        )
          ? 'text-black'
          : 'text-white'
      } text-sm flex flex-col`}
    >
      <h6>{subtitle}</h6>
      {quadrantTasks?.length ? (
        <ul
          className={`w-full h-full px-6 py-2 md:py-4 list-disc overflow-y-visible scrollbar-thin ${
            [EISENHOWER_QUADRANT.DELEGATE, EISENHOWER_QUADRANT.DELETE].includes(
              quadrant,
            )
              ? 'scrollbar-thumb-orange-300'
              : 'scrollbar-thumb-gray-300'
          }`}
        >
          {quadrantTasks?.map((task) => (
            <li className='py-1 line-clamp-2' key={task.id}>
              <span>&#9679;</span> {task.title}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

const ModalBody = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isDeviceSmall = useMemo(() => width <= SCREEN.WIDTH.SMALL, [width]);

  return (
    <div className='w-full h-fit max-h-[85vh] overflow-y-auto scrollbar-thin flex flex-col sm:flex-row items-end justify-center px-4 pt-4'>
      {!isDeviceSmall && (
        <div className='w-16 h-fit flex flex-col mr-1'>
          <PriorityTitle
            title={t('to_do_procrastinate.important')}
            orientation={EISENHOWER_PRIORITY_TITLE_ORIENTATION.VERTICAL}
          />
          <PriorityTitle
            title={t('to_do_procrastinate.not_important')}
            orientation={EISENHOWER_PRIORITY_TITLE_ORIENTATION.VERTICAL}
          />
        </div>
      )}
      <div className='w-full sm:w-[45%] h-[37.5vh] sm:h-fit flex flex-col'>
        {!isDeviceSmall && (
          <PriorityTitle title={t('to_do_procrastinate.urgent')} />
        )}
        <Quadrant
          quadrant={EISENHOWER_QUADRANT.DO}
          subtitle={t('to_do_procrastinate.do')}
          background='bg-orange-400'
        />
        <Quadrant
          quadrant={EISENHOWER_QUADRANT.DELEGATE}
          subtitle={t('to_do_procrastinate.delegate')}
          background='bg-orange-200'
        />
      </div>
      <div className='w-full sm:w-[45%] h-[37.5vh] sm:h-fit flex flex-col ml-0 sm:ml-1'>
        {!isDeviceSmall && (
          <PriorityTitle title={t('to_do_procrastinate.not_urgent')} />
        )}
        <Quadrant
          quadrant={EISENHOWER_QUADRANT.DECIDE}
          subtitle={t('to_do_procrastinate.decide')}
          background='bg-orange-400'
        />
        <Quadrant
          quadrant={EISENHOWER_QUADRANT.DELETE}
          subtitle={t('to_do_procrastinate.delete')}
          background='bg-orange-200'
        />
      </div>
    </div>
  );
};

const ModalActions = () => {
  const {
    toDo: { tasks },
    setting: { platform },
  } = useAppSelector((state) => state);
  return (
    <div className='w-full flex items-center justify-end px-4 sm:px-6 py-2 sm:py-4'>
      <button
        onClick={() => downloadEisenhowerMatrixCSVFile(tasks, platform)}
        className='btn-primary flex items-center justify-center gap-1'
      >
        <CsvFile /> {t('to_do_procrastinate.export')}
      </button>
    </div>
  );
};

const EisenhowerMatrixModal = () => {
  const { t } = useTranslation();
  return (
    <ModalOverlay
      styles='w-full h-full bg-gray-600 bg-opacity-50 overflow-x-auto scrollbar-thin scrollbar-gray-200'
      zIndex='z-[70]'
    >
      <Wrapper>
        <ModalHeader
          title={t('to_do_procrastinate.eisenhower_matrix')}
          modalType={MODAL_TYPES.EISENHOWER_MATRIX_MODAL}
        />
        <ModalBody />
        <ModalActions />
      </Wrapper>
    </ModalOverlay>
  );
};

export default EisenhowerMatrixModal;
