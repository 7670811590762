import { INIT_NEW_HABIT } from 'assets/data';
import Warning from 'assets/icons/Warning';
import Tooltip from 'components/common/Tooltip';
import { PRIORITY_OPTION } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { newActivityButtonSelector } from 'store/reducer/setting/selectors';
import { newHabit } from 'store/reducer/setting/slice';
import { getNewHabitProps } from 'utils/support';

const AddFreeTimeHabitButton = () => {
  const dispatch = useAppDispatch();
  const {
    activeTabIndex,
    tabs,
    currentSettings,
    customRoutine,
    user_focus_modes
  } = useAppSelector(newActivityButtonSelector);

  const handleClick = () => {
    const {
      shutdown_time,
      cutoff_time_for_non_high_priority_activities: cutoff_time
    } = currentSettings;
    const duration_seconds = moment(cutoff_time, 'HH:mm').diff(
      moment(shutdown_time, 'HH:mm'),
      'seconds'
    );

    const [activity_type, sequence_id] = getNewHabitProps(
      tabs,
      activeTabIndex,
      currentSettings,
      customRoutine
    );

    const allowed_focus_mode_id = user_focus_modes.find(
      (focus_mode) =>
        focus_mode.name.includes('Relax') || focus_mode?.metadata?.isRelaxFocus
    )?.id;

    const freeTimeHabit = {
      ...INIT_NEW_HABIT(),
      name: t('free_time_no_distraction_blocking'),
      duration_seconds,
      allowed_focus_mode_id,
      show_saved_distracting_websites: true,
      priority: PRIORITY_OPTION.STANDARD
    };
    dispatch(
      newHabit({
        type: activity_type,
        sequence_id,
        isFreeTimeHabit: true,
        freeTimeHabit
      })
    );
  };

  return (
    <div className='w-full md:w-fit flex justify-center items-center gap-2 bg-orange-200 rounded-md px-3 py-1.5 shadow self-center text-[0.6rem] sm:text-xs'>
      <p className='flex items-center gap-1 md:gap-2'>
        <Warning styles='w-3 md:w-4 lg:w-5 h-auto' />{' '}
        {t('free_time_habit_not_set')}
      </p>
      <Tooltip message={t('relax_mode_habit_duration_tooltip')} />
      <button
        onClick={handleClick}
        className='px-2.5 py-0.5 rounded bg-blue-600 hover:bg-blue-700 text-white hover:underline underline-offset-1 animate-pulse hover:animate-none'
      >
        {t('create_one')}
      </button>
    </div>
  );
};

export default AddFreeTimeHabitButton;
