import { t } from 'i18next';
import { ENROLLED_COURSES_TAB } from 'constants/general';
import { useAppSelector } from 'store/hooks';
import CourseItem from './CourseItem';
import { listOfCoursesSelector } from 'store/reducer/course/selectors';

const ListOfCourses = () => {
  const { activeTab, other_courses, enrolled_courses } = useAppSelector(
    listOfCoursesSelector
  );

  const [courses, empty_message] =
    activeTab === ENROLLED_COURSES_TAB.index
      ? [enrolled_courses, t('course.youve_not_enrolled_in_any_course_yet')]
      : [other_courses, t('course.no_remaining_courses_to_enroll_in')];

  return courses?.length ? (
    <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 gap-5 sm:gap-10'>
      {courses.map((course) => (
        <CourseItem key={course.id} course={course} />
      ))}
    </div>
  ) : (
    <p className='w-full text-center my-10'>{empty_message}</p>
  );
};

export default ListOfCourses;
