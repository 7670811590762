import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SubscriptionInfoTypes,
  StripePlanType,
  DeviceType,
  CompletedActivityPayloadType,
  SyncedPlatforms
} from 'interfaces';
import {
  EMPTY_STRING,
  ACTIVITY_TYPES,
  NUMBER_OF_DAYS
} from 'constants/general';
import {
  fetchUserLevelStats,
  fetchUserMotivationalSummary,
  getCompletedActivityDaySummary,
  getEveningActivityStats,
  getMorningActivityStats,
  getUserDetailsCurrentActivityProps,
  getWeeklyFocusBlockSummary,
  updateLongTermGoals,
  updateUserConsent,
  getWeeklyStats,
  getLocalDeviceSettings,
  updateLocalDeviceSettings,
  getUserDetails,
  getLongTermGoals
} from './extra';
import { initialUserState } from 'store/initial-states';
import { initWeeklySummaryStats } from 'utils/support';
import { DEFAULT_LOCAL_DEVICE_SETTING } from 'assets/data';
import {
  FocusMode,
  UserDetailsTypes,
  WeeklyFocusBlockSummary
} from 'interfaces/userInterface';

const user = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateIsSubscriptionChecking: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSubscriptionChecking = payload;
    },
    updateWeeklySummaryLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isWeeklySummaryUpdating = payload;
    },
    updateMorningStatsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isMorningStatsUpdating = payload;
    },
    updateEveningStatsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isEveningStatsUpdating = payload;
    },
    updateUserDetails: (
      state,
      { payload }: PayloadAction<UserDetailsTypes>
    ) => {
      state.details = payload;
    },
    setSubscriptionInfo: (
      state,
      { payload }: PayloadAction<SubscriptionInfoTypes>
    ) => {
      state.subscriptionInfo = payload;
    },
    setStripAvailablePlans: (
      state,
      { payload }: PayloadAction<StripePlanType[]>
    ) => {
      state.availableStripePlans = payload;
    },
    setFocusedModes: (
      state,
      {
        payload
      }: PayloadAction<{
        focusNames: FocusMode[];
      }>
    ) => {
      state.details.focus_modes = payload?.focusNames ?? [];
    },
    setUserId: (state, action) => {
      state.subscriptionInfo.userId = action.payload;
    },
    setIsUserAdmin: (state, action) => {
      state.isUserAdmin = action.payload;
    },
    updateIsCurrentActivityPropsUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCurrentActivityPropsUpdating = payload;
    },
    updateDevice: (state, { payload }: PayloadAction<DeviceType>) => {
      state.details.device = payload;
    },
    updateLastCompletedActivityLog: (
      state,
      { payload }: PayloadAction<CompletedActivityPayloadType | null>
    ) => {
      state.last_completed_activity_log = payload;
    },
    updateIsMorningRoutineCompleted: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isMorningRoutineCompleted = payload;
    },
    updateIsEveningRoutineCompleted: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEveningRoutineCompleted = payload;
    },
    updateIsBreakingRoutineCompleted: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isBreakingRoutineCompleted = payload;
    },
    updateCompletedRoutines: (state, { payload }: PayloadAction<string[]>) => {
      state.completed_routines = payload;
      if (payload.includes(ACTIVITY_TYPES.MORNING)) {
        state.isMorningRoutineCompleted = true;
      }
      if (payload.includes(ACTIVITY_TYPES.EVENING)) {
        state.isBreakingRoutineCompleted = true;
        state.isEveningRoutineCompleted = true;
      }
    },
    toggleAreOnboardingStatsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.areOnboardingStatsLoading = payload;
    },
    toggleAreUserDetailsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.areUserDetailsLoading = payload;
    },
    updateMotivationalSummary: (state, { payload }: PayloadAction<string>) => {
      state.motivationalSummary += payload.replaceAll('"', '');
    },
    updateIsMotivationalSummaryLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isMotivationalSummaryLoading = payload;
    },
    resetMotivationalSummary: (state) => {
      state.motivationalSummary = EMPTY_STRING;
    },
    updateIsMotivationalSummaryStopGenerating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isMotivationalSummaryStopGenerating = payload;
    },
    updateIsUpdatingUserPrivacyPolicy: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingUserPrivacyPolicy = payload;
    },
    updateIsDailySummaryFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDailySummaryFetching = payload;
    },
    updateIsLogTermGoalUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLogTermGoalUpdating = payload;
    },
    updateIsFetchingLogTermGoal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingLogTermGoal = payload;
    },
    updateIsGuestUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isGuestUser = payload;
    },
    updateIsWeeklyFocusBlockSummaryUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isWeeklyFocusBlockSummaryUpdating = payload;
    },
    updateWeeklyFocusBlockSummary: (
      state,
      { payload }: PayloadAction<WeeklyFocusBlockSummary[]>
    ) => {
      state.weeklyFocusBlockSummary = payload;
    },
    updateIsUsernameUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.isUsernameUpdating = payload;
    },
    updateUsername: (state, { payload }: PayloadAction<string>) => {
      state.details.username = payload;
    },
    updateIsUserLoggingOut: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserLoggingOut = payload;
    },
    updateIsWeeklyStatsUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isWeeklyStatsUpdating = payload;
    },
    updateOpenAIPrompt: (state, { payload }: PayloadAction<string>) => {
      state.openAIPrompt = payload;
      state.isMotivationalSummaryStopGenerating = true;
    },
    updateIsSavingUserFeedback: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavingUserFeedback = payload;
    },
    updateShowThankYouModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showThankYouModal = payload;
    },
    updateIsLocalDeviceSettingUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLocalDeviceSettingUpdating = payload;
    },
    updateSyncedPlatforms: (
      state,
      { payload }: PayloadAction<SyncedPlatforms>
    ) => {
      state.details.synced_platforms = payload;
    },
    updateShowTeamNameModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showTeamNameModal = payload;
    },
    updateIsUpdatingUserProfileDescription: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingUserProfileDescription = payload;
    },
    updateIsUpdatingUserProfileImage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingUserProfileImage = payload;
    },
    updateUserMetadata: (
      state,
      {
        payload
      }: PayloadAction<{
        profile_image: {
          url: string;
          file_path?: string;
        };
        description: string;
      }>
    ) => {
      state.details.metadata = payload;
    },
    updateIsCancelingSubscription: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCancelingSubscription = payload;
    },
    updateShowCancelReasonModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showCancelReasonModal = payload;
    },
    updateIsFetchingAvailableStripPlans: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingAvailableStripPlans = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMorningActivityStats.fulfilled, (state, { payload }) => {
        state.morningCompletionPercent =
          payload?.average_completion_percent ?? 0;
        state.isMorningStatsUpdating = false;
      })
      .addCase(getMorningActivityStats.rejected, (state) => {
        state.isMorningStatsUpdating = false;
      })
      .addCase(getEveningActivityStats.fulfilled, (state, { payload }) => {
        state.eveningCompletionPercent =
          payload?.average_completion_percent ?? 0;
        state.isEveningStatsUpdating = false;
      })
      .addCase(getEveningActivityStats.rejected, (state) => {
        state.isEveningStatsUpdating = false;
      })
      .addCase(
        getUserDetailsCurrentActivityProps.fulfilled,
        (state, { payload }) => {
          state.currentActivityProps = payload;
          state.isCurrentActivityPropsUpdating = false;
        }
      )
      .addCase(getUserDetailsCurrentActivityProps.rejected, (state) => {
        state.isCurrentActivityPropsUpdating = false;
      })
      .addCase(fetchUserLevelStats.fulfilled, (state, { payload }) => {
        state.onboardingStats = { ...payload };
        state.areOnboardingStatsLoading = false;
      })
      .addCase(fetchUserLevelStats.rejected, (state) => {
        state.areOnboardingStatsLoading = false;
      })
      .addCase(fetchUserMotivationalSummary.fulfilled, (state) => {
        state.isMotivationalSummaryStopGenerating = true;
      })
      .addCase(fetchUserMotivationalSummary.rejected, (state) => {
        state.isMotivationalSummaryStopGenerating = true;
      })
      .addCase(updateUserConsent.fulfilled, (state, { payload }) => {
        if (payload) {
          state.details.has_consented_to_terms_of_service = true;
        }
        state.isUpdatingUserPrivacyPolicy = false;
      })
      .addCase(updateLongTermGoals.fulfilled, (state, { payload }) => {
        state.details.long_term_goals = payload ?? [];
        state.isLogTermGoalUpdating = false;
      })
      .addCase(
        getCompletedActivityDaySummary.fulfilled,
        (state, { payload }) => {
          state.dailySummary.focusSummary = payload?.focusSummary ?? [];
          state.dailySummary.daySummaryAVG = payload?.daySummaryAVG ?? [];
          state.dailySummary.daySummarySUM = payload?.daySummarySUM ?? [];
          state.dailySummary.daySummaryDuration =
            payload?.daySummaryDuration ?? [];
          state.isDailySummaryFetching = false;
        }
      )
      .addCase(getWeeklyFocusBlockSummary.fulfilled, (state, { payload }) => {
        state.weeklyFocusBlockSummary = payload;
        state.isWeeklyFocusBlockSummaryUpdating = false;
      })
      .addCase(getWeeklyStats.fulfilled, (state, { payload }) => {
        state.weeklySummaryStats = payload?.length
          ? payload
          : initWeeklySummaryStats(NUMBER_OF_DAYS);
        state.isWeeklyStatsUpdating = false;
      })
      .addCase(getLocalDeviceSettings.fulfilled, (state, { payload }) => {
        state.localDeviceSetting = payload ?? DEFAULT_LOCAL_DEVICE_SETTING;
        state.isLocalDeviceSettingUpdating = false;
      })
      .addCase(updateLocalDeviceSettings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.localDeviceSetting = payload;
          state.details.local_device_settings = payload;
        }
        state.isLocalDeviceSettingUpdating = false;
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.details = payload;
      })
      .addCase(getLongTermGoals.fulfilled, (state, { payload }) => {
        state.details.long_term_goals = [
          ...state.details.long_term_goals.filter(
            (goal) => !payload.includes(goal)
          ),
          ...payload
        ];
        state.isFetchingLogTermGoal = false;
      });
  }
});

export const {
  updateUserDetails,
  setSubscriptionInfo,
  setStripAvailablePlans,
  setFocusedModes,
  updateMorningStatsLoading,
  updateEveningStatsLoading,
  updateWeeklySummaryLoading,
  updateIsSubscriptionChecking,
  setUserId,
  setIsUserAdmin,
  updateIsCurrentActivityPropsUpdating,
  updateDevice,
  updateLastCompletedActivityLog,
  updateIsMorningRoutineCompleted,
  updateIsBreakingRoutineCompleted,
  updateIsEveningRoutineCompleted,
  updateCompletedRoutines,
  toggleAreOnboardingStatsLoading,
  toggleAreUserDetailsLoading,
  updateMotivationalSummary,
  updateIsMotivationalSummaryLoading,
  resetMotivationalSummary,
  updateIsMotivationalSummaryStopGenerating,
  updateIsUpdatingUserPrivacyPolicy,
  updateIsDailySummaryFetching,
  updateIsLogTermGoalUpdating,
  updateIsGuestUser,
  updateIsWeeklyFocusBlockSummaryUpdating,
  updateWeeklyFocusBlockSummary,
  updateIsUsernameUpdating,
  updateUsername,
  updateIsUserLoggingOut,
  updateIsWeeklyStatsUpdating,
  updateOpenAIPrompt,
  updateIsSavingUserFeedback,
  updateShowThankYouModal,
  updateIsLocalDeviceSettingUpdating,
  updateSyncedPlatforms,
  updateShowTeamNameModal,
  updateIsUpdatingUserProfileImage,
  updateIsUpdatingUserProfileDescription,
  updateUserMetadata,
  updateIsCancelingSubscription,
  updateShowCancelReasonModal,
  updateIsFetchingAvailableStripPlans,
  updateIsFetchingLogTermGoal
} = user.actions;

export default user.reducer;
