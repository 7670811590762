import {
  useState,
  useMemo,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  ReactNode
} from 'react';
import { EMPTY_STRING } from 'constants/general';
import { useAppSelector } from 'store/hooks';
import Spinner from 'components/common/Spinner';
import { ToDoContext } from '..';
import { ROUTES } from 'constants/routes';
import Pagination from 'components/common/Pagination';
import TabLayout from 'components/common/TabLayout';
import { isDarkModeActivated } from 'utils/validation';
import { DEFAULT_DUE_DATE_FILTERS, DEFAULT_FILTER_DATA } from 'assets/data';
import ListOfTasks from './ListOfTasks';
import TaskDueDateFilters from './TaskDueDateFilters';
import { t } from 'i18next';
import classNames from 'classnames';
import SelectTasksBtn from './SelectTasksBtn';
import TasksToolbar, { FilterDataProps } from './tasks-toolbar';
import { tasksSelector } from 'store/reducer/to-do/selectors';
import { themeSelector } from 'store/reducer/setting/selectors';

export interface DueDateFilters {
  dueToday: boolean;
  dueThisWeek: boolean;
  dueNextWeek: boolean;
}

export const TasksContext = createContext<{
  filterData: FilterDataProps;
  setFilterData?: Dispatch<SetStateAction<FilterDataProps>>;
  shouldActivateDarkMode: boolean;
  dueDateFilters: DueDateFilters;
  setDueDateFilters?: Dispatch<SetStateAction<DueDateFilters>>;
}>({
  filterData: DEFAULT_FILTER_DATA,
  shouldActivateDarkMode: false,
  dueDateFilters: DEFAULT_DUE_DATE_FILTERS
});

const TasksWrapper = ({ children }: { readonly children: ReactNode }) => {
  const { shouldEmbedTodoList } = useContext(ToDoContext);
  const [filterData, setFilterData] = useState<FilterDataProps>({
    taskName: EMPTY_STRING,
    focusMode: null,
    project: null,
    quadrant: null,
    status: null
  });
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  const [dueDateFilters, setDueDateFilters] = useState(
    DEFAULT_DUE_DATE_FILTERS
  );
  const shouldEmbedTodoPlayer = window.location.pathname.includes(
    ROUTES.WEBVIEW_TO_DO_PLAYER
  );

  return (
    <div
      className={`${
        shouldEmbedTodoPlayer || shouldEmbedTodoList
          ? 'w-full h-full'
          : 'w-full xl:w-[55%] h-fit xl:h-[676px]'
      } flex flex-col items-center justify-between flex-grow-0 gap-3 ${
        shouldActivateDarkMode
          ? 'bg-gray-600 border-gray-700'
          : 'bg-gray-100 border-gray-200'
      } shadow-md rounded-md p-4 border relative`}
    >
      <TasksContext.Provider
        value={useMemo(
          () => ({
            filterData,
            setFilterData,
            shouldActivateDarkMode,
            dueDateFilters,
            setDueDateFilters
          }),
          [filterData, shouldActivateDarkMode, dueDateFilters]
        )}
      >
        {children}
      </TasksContext.Provider>
    </div>
  );
};

const TasksHeaderTitle = () => (
  <h6 className='absolute -top-2 left-3 text-xs bg-gradient-to-b from-transparent via-gray-100 to-gray-100 px-2 text-gray-700 rounded-b'>
    {t('to_do_procrastinate.list_of_tasks')}
  </h6>
);

const TasksHeader = () => {
  const shouldFocusStartEmbedTodoList =
    window.location.pathname === ROUTES.WEBVIEW_TODO_LIST;
  const { shouldActivateDarkMode } = useContext(TasksContext);

  return (
    <div className='w-full h-fit flex flex-col relative'>
      {shouldFocusStartEmbedTodoList ? (
        <h6
          className={classNames('w-full truncate leading-10 text-center', {
            'text-white': shouldActivateDarkMode,
            'text-black': !shouldActivateDarkMode
          })}
        >
          {t('to_do_procrastinate.select_tasks_from_the_list')}
        </h6>
      ) : (
        <TasksHeaderTitle />
      )}
      <TasksToolbar />
    </div>
  );
};

const Tasks = () => {
  const {
    isFetchingToDo,
    tasks,
    isSearchingToDos,
    searchResults,
    completedTasks
  } = useAppSelector(tasksSelector);

  const tabs = [
    <>
      <TasksHeader />
      <TaskDueDateFilters />
      {isFetchingToDo || isSearchingToDos ? <Spinner /> : <ListOfTasks />}
      {!searchResults && tasks?.length ? <Pagination /> : null}
      <SelectTasksBtn />
    </>,
    <>
      <TasksHeader />
      {isFetchingToDo ? <Spinner /> : <ListOfTasks />}
      {completedTasks.length ? <Pagination /> : null}
    </>
  ];

  return (
    <TasksWrapper>
      <TabLayout tabs={tabs} />
    </TasksWrapper>
  );
};

export default Tasks;
