import DownArrow from 'assets/icons/DownArrow';
import InfoCircle from 'assets/icons/InfoCircle';
import Tooltip from 'components/common/Tooltip';
import COLOR from 'constants/color';
import { LessonCompletionStatus } from 'constants/enum';
import { FIRST_LESSON_OFFSET, ITEM_INDEX_NOT_FOUND } from 'constants/general';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createLessonCompletion } from 'store/reducer/course/extra';
import { courseModalActionsSelector } from 'store/reducer/course/selectors';
import { decrement, increment } from 'utils/support';
import { CourseLessonsModalContext } from '.';
import {
  updateCourseDetails,
  updateIsLessonCompletionSuccess
} from 'store/reducer/course/slice';

const CourseModalActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    selectedCourse,
    isLessonCompletionSuccess,
    isCreatingLessonCompletion,
    enrolled_courses
  } = useAppSelector(courseModalActionsSelector);
  const lessons = selectedCourse?.lessons ?? [];
  const { lessonInfo, setLessonInfo } = useContext(CourseLessonsModalContext);
  const isPreviousBtnDisabled =
    lessonInfo.currentLesson === FIRST_LESSON_OFFSET ||
    isCreatingLessonCompletion;
  const shouldShowSelfTaughtInfo =
    lessons[lessonInfo.currentLesson]?.status ===
    LessonCompletionStatus.SELF_TAUGHT;

  useEffect(() => {
    if (isLessonCompletionSuccess) {
      setLessonInfo?.((prev) => {
        const lessonIndex = increment(prev.currentLesson);
        return {
          ...prev,
          currentLesson: lessonIndex,
          lastCompletedLesson: lessonIndex,
          isLastLesson: increment(lessonIndex) >= lessons.length
        };
      });
      const updatedCourse = enrolled_courses.find(
        (course) => course.id === selectedCourse?.id
      );
      if (updatedCourse && selectedCourse) {
        dispatch(
          updateCourseDetails({
            ...selectedCourse,
            lessonCompletions: [
              ...(selectedCourse?.lessonCompletions ?? []),
              ...(updatedCourse.lessonCompletions ?? [])
            ]
          })
        );
      }
      dispatch(updateIsLessonCompletionSuccess(false));
    }
  }, [isLessonCompletionSuccess]);

  const handleNextLesson = useCallback(() => {
    const shouldSaveLesson =
      selectedCourse?.lessonCompletions?.findIndex(
        (completion) =>
          completion.lesson_id === lessons[lessonInfo.currentLesson]?.id
      ) === ITEM_INDEX_NOT_FOUND;
    if (
      lessonInfo.currentLesson >= lessonInfo.lastCompletedLesson &&
      shouldSaveLesson &&
      selectedCourse
    ) {
      dispatch(
        createLessonCompletion({
          course_id: selectedCourse?.id,
          lesson_id: lessons[lessonInfo.currentLesson]?.id
        })
      );
    } else {
      setLessonInfo?.((prev) => ({
        ...prev,
        lastCompletedLesson: prev.currentLesson,
        currentLesson: increment(prev.currentLesson),
        isLastLesson: increment(prev.currentLesson) >= lessons.length
      }));
    }
  }, [lessonInfo, selectedCourse]);

  return (
    <div className='w-full flex items-center justify-between gap-3 sm:gap-5 absolute bottom-0 right-1 pl-2'>
      <div className='w-fit flex gap-4 items-center'>
        <p className='text-xs md:text-base font-semibold'>{`${increment(lessonInfo.currentLesson)} / ${lessons.length}`}</p>
        {shouldShowSelfTaughtInfo ? (
          <Tooltip
            icon={<InfoCircle styles='w-5 sm:w-6 h-auto' />}
            message={t('course.looks_like_you_already_figured_out')}
          />
        ) : null}
      </div>
      <div className='w-fit flex gap-4'>
        <button
          disabled={isPreviousBtnDisabled}
          onClick={() =>
            setLessonInfo?.((prev) => ({
              ...prev,
              currentLesson: decrement(prev.currentLesson)
            }))
          }
          className='cursor-pointer rounded-full hover:bg-gray-300 transition-colors ease-in-out duration-200 disabled:text-gray-400 disabled:hover:bg-none'
        >
          <DownArrow
            fill={isPreviousBtnDisabled ? COLOR.GRAY_3OO : COLOR.GRAY_700}
            styles='w-5 sm:w-7 h-auto rotate-90'
          />
        </button>
        <button
          disabled={isCreatingLessonCompletion}
          onClick={handleNextLesson}
          className='cursor-pointer rounded-full hover:bg-gray-300 transition-colors ease-in-out duration-200 disabled:text-gray-400 disabled:hover:bg-none'
        >
          <DownArrow
            fill={isCreatingLessonCompletion ? COLOR.GRAY_3OO : COLOR.GRAY_700}
            styles='w-5 sm:w-7 h-auto -rotate-90'
          />
        </button>
      </div>
    </div>
  );
};

export default CourseModalActions;
