import { THEME_OPTION } from 'constants/enum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionTextInstructions } from 'store/reducer/modal/slice';

const TextInstructions = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    modal: {
      moreOption: { activity }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  return (
    <textarea
      value={activity?.text_instructions ?? ''}
      onChange={({
        target: { value }
      }: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(updateMoreOptionTextInstructions(value));
      }}
      className={`w-full md:w-1/2 min-h-[150px] md:h-full border border-gray-300 rounded-md p-2 outline-none resize-none text-sm lg:text-base px-2 py-1 text-black ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-white'
      }`}
      placeholder={t('instructions')}
    />
  );
};

export default React.memo(TextInstructions);
