import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import YouTubeMetaData from 'components/shared/YouTubeMetaData';
import { updateMoreOptionShowYouTubeURLModal } from 'store/reducer/modal/slice';
import { ATTRIB } from 'constants/test';
import HelpFilled from 'assets/icons/HelpFilled';
import Tooltip from 'components/common/Tooltip';
import { useContext } from 'react';
import { SettingsPageContext } from 'components/setting-generator';
import { SCREEN } from 'constants/general';
import { increment } from 'utils/support';
import { useWindowSize } from 'usehooks-ts';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full min-h-[8rem] flex flex-col py-2'>{children}</div>
);

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const settingPageContext = useContext(SettingsPageContext);
  const shouldActiveMobileView =
    (settingPageContext?.width ?? width) <= SCREEN.WIDTH.SMALL;

  return (
    <div className='w-fit flex gap-2 items-center border-b py-0.5 pl-2 relative'>
      <button
        data-test={ATTRIB.TEST.MANAGE_YOUTUBE_URLS}
        className='w-fit px-2 py-1 text-[10px] bg-gray-500 text-white rounded-md  transition ease-in-out delay-150 hover:scale-110 hover:bg-gray-700 duration-300'
        onClick={() => {
          dispatch(updateMoreOptionShowYouTubeURLModal(true));
        }}
      >
        {t('manage_youtube_url')}
      </button>
      <Tooltip
        icon={<HelpFilled />}
        message={t(
          'useful_if_you_want_to_record_data_in_your_activity_history'
        )}
        place={shouldActiveMobileView ? 'right' : 'left'}
      />
    </div>
  );
};

const EmptyURLs = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full flex justify-center text-xs font-medium'>
      {t('no_urls_found')}
    </div>
  );
};

const YouTubeVideos = () => {
  const {
    cache: {
      isYouTubeURLMetadataUpdating,
      youtube_urls_metadata: { videos_metadata }
    },
    modal: {
      moreOption: { activity }
    }
  } = useAppSelector((state) => state);

  const video_urls = activity?.video_urls ?? [];

  return (
    <Wrapper>
      <Header />
      <div className='w-full max-h-48 py-2 flex flex-col gap-3 items-center justify-start overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
        {video_urls.length ? (
          video_urls.map((url: string, index: number) => {
            let metadata = { title: '', duration: '' };
            if (videos_metadata.length) {
              metadata = videos_metadata.find(
                (meta) => meta.video_url === url
              ) ?? { title: '', duration: '' };
            }
            return (
              <div
                key={`${url}${index}`}
                className='w-full flex flex-col py-0.5'
              >
                <div className='text-xs text-gray-400 font-medium flex items-center'>
                  {isYouTubeURLMetadataUpdating && (
                    <div className='w-3 h-3 rounded-full border-r border-gray-600 animate-spin'></div>
                  )}
                  {!isYouTubeURLMetadataUpdating &&
                    (metadata.title && metadata.duration ? (
                      <YouTubeMetaData
                        title={metadata.title}
                        duration={metadata.duration}
                      />
                    ) : (
                      `URL ${increment(index)}`
                    ))}
                </div>
                <div className='w-full pr-1 text-sm font-medium text-left rounded-md truncate'>
                  {url}
                </div>
              </div>
            );
          })
        ) : (
          <EmptyURLs />
        )}
      </div>
    </Wrapper>
  );
};

export default YouTubeVideos;
