import { useAuth0 } from '@auth0/auth0-react';
import BearPawPrint from 'assets/icons/BearPawPrint';
import GlobeNo from 'assets/icons/GlobeNo';
import ConfirmButton from 'components/common/buttons/ConfirmButton';
import DocumentMetaData from 'components/DocumentMetaData';
import {
  DASHBOARD,
  EMPTY_STRING,
  ERROR_PAGE_RELOADED,
  ERROR_PAGE_TIMEOUT_DELAY,
  HTTP_STATS_CODE,
  LOCAL_STORAGE
} from 'constants/general';
import { PAGES, ROUTES } from 'constants/routes';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  errorMessageSelector,
  errorPageSelector
} from 'store/reducer/setting/selectors';
import { updateError } from 'store/reducer/setting/slice';
import {
  getLocalStorage,
  removeLocalStorage,
  updateLocalStorage
} from 'utils/support';

const NoInternetConnection = () => {
  return (
    <div className='w-fit h-fit flex flex-col items-center gap-4'>
      <BearPawPrint />
      <div className='w-fit flex items-center gap-2'>
        <GlobeNo />
        <p className='text-orange-500 text-xl font-medium'>
          {t('no_internet_connection')}
        </p>
      </div>
      <ConfirmButton
        onClickButton={() => {
          window.history.back();
          window.location.reload();
        }}
        label={t('reload_the_page')}
      />
    </div>
  );
};

const ErrorMessage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    error: { message, url: redirectURL },
    subscriptionInfo
  } = useAppSelector(errorMessageSelector);
  const { logout } = useAuth0();
  const isEmbeddedPageActive =
    Object.values(ROUTES).some((route) => String(route).includes('webview')) &&
    !window.location.href.includes(DASHBOARD);

  const isValidUser = subscriptionInfo?.hasActiveSubscription;
  const isPageReloaded = getLocalStorage(LOCAL_STORAGE.ERROR_PAGE_RELOADED);

  useEffect(() => {
    !isPageReloaded && setTimeout(handleResetPage, ERROR_PAGE_TIMEOUT_DELAY);
  }, []);

  function checkPageReloaded() {
    !isPageReloaded &&
      updateLocalStorage(
        LOCAL_STORAGE.ERROR_PAGE_RELOADED,
        ERROR_PAGE_RELOADED
      );
    window.location.reload();
  }

  const handleResetPage = () => {
    if (isEmbeddedPageActive) {
      checkPageReloaded();
    } else {
      dispatch(
        updateError({
          message: EMPTY_STRING,
          status: undefined,
          url: undefined
        })
      );
      if (isValidUser) {
        redirectURL ? navigate(redirectURL) : navigate(-1);
      } else {
        removeLocalStorage([LOCAL_STORAGE.ERROR_PAGE_RELOADED]);
        // logs user out if there was a problem fetching their subscription info
        logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI });
      }
    }
  };

  return (
    <div className=' container px-4 mx-auto flex flex-col justify-center items-center'>
      <div className='max-w-4xl mx-auto text-center flex flex-col justify-center items-center gap-4 md:gap-10'>
        {!isEmbeddedPageActive ? (
          <h2 className='text-orange-400 text-xl md:text-3xl leading-tight font-bold tracking-tighter flex flex-col items-center gap-1'>
            <span className='w-fit px-3 py-1 text-xs leading-5 text-black bg-orange-200 font-medium rounded-full shadow-sm'>
              {t('error')}
            </span>
            {message ? t(message) : t('encountered_problem')}
          </h2>
        ) : null}
        <p className='text-sm sm:text-lg md:text-xl w-5/6 sm:w-[60%]'>
          {message === 'ChunkLoadError'
            ? t('chunk_error_description')
            : t('error_page_message')}
        </p>
        <ConfirmButton
          onClickButton={handleResetPage}
          label={
            isEmbeddedPageActive ? t('reload_the_page') : t('back_to_homepage')
          }
        />
      </div>
    </div>
  );
};

export default function ErrorPage() {
  const error = useAppSelector(errorPageSelector);

  return (
    <section className='bg-main mt-[80px] h-[calc(100vh-80px)] flex flex-col justify-center items-center'>
      <DocumentMetaData pageName={PAGES.ERROR} />
      {error?.status === HTTP_STATS_CODE.NO_CONNECTION ? (
        <NoInternetConnection />
      ) : (
        <ErrorMessage />
      )}
    </section>
  );
}
