import COLOR from 'constants/color';
import { IconProps } from 'interfaces';

const AngleDoubleRight = ({
  fill = COLOR.WHITE,
  styles = 'w-3 h-auto'
}: IconProps) => {
  return (
    <svg
      className={styles}
      fill={fill}
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path d='M 9.09375 4.78125 L 7.6875 6.21875 L 17.46875 16 L 7.6875 25.78125 L 9.09375 27.21875 L 20.3125 16 Z M 16.09375 4.78125 L 14.6875 6.21875 L 24.46875 16 L 14.6875 25.78125 L 16.09375 27.21875 L 27.3125 16 Z'></path>
      </g>
    </svg>
  );
};

export default AngleDoubleRight;
