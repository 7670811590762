import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateSelectedTaskIDs, updateTasks } from 'store/reducer/to-do/slice';
import { COMPLETED_TAB, EISENHOWER_QUADRANT } from 'constants/general';
import { ToDoTask } from 'interfaces/commonInterface';
import CheckBox from 'components/common/CheckBox';
import { taskSelector } from 'store/reducer/to-do/selectors';
import classNames from 'classnames';
import TaskItemHeader from './TaskItemHeader';
import TaskItemBody from './TaskItemBody';
import { deleteToDo } from 'store/reducer/to-do/extra';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import { t } from 'i18next';
import { toast } from 'react-toastify';

interface TaskItemProps {
  showTaskContents: boolean;
  isTaskDeleting: boolean;
  task?: ToDoTask;
}

export const TaskItemContext = createContext<{
  taskItemState: TaskItemProps;
  setTaskItemState?: Dispatch<SetStateAction<TaskItemProps>>;
}>({ taskItemState: { showTaskContents: false, isTaskDeleting: false } });

const TaskItem = ({ task }: { task: ToDoTask }) => {
  const dispatch = useAppDispatch();
  const [taskItemState, setTaskItemState] = useState<TaskItemProps>({
    showTaskContents: false,
    isTaskDeleting: false,
    task
  });
  const { selectedTaskIDs, activeTab, tasks } = useAppSelector(taskSelector);

  const handleTaskDeletion = async () => {
    setTaskItemState((prev) => ({ ...prev, isTaskDeleting: true }));
    const response = await dispatch(deleteToDo({ task_id: task.id }));
    setTaskItemState((prev) => ({ ...prev, isTaskDeleting: false }));
    if (response.payload) {
      dispatch(
        updateTasks(tasks.filter((prevTask) => prevTask.id !== task.id))
      );
      toast.success(t('to_do_procrastinate.task_deleted_successfully'));
    } else {
      toast.error(t('to_do_procrastinate.could_not_delete_the_task'));
    }
  };

  return (
    <div className='w-full h-fit flex items-center gap-3 pl-1 py-2 relative'>
      {activeTab !== COMPLETED_TAB && (
        <CheckBox
          checked={selectedTaskIDs.includes(task.id)}
          onChange={() => {
            selectedTaskIDs.includes(task.id)
              ? dispatch(
                  updateSelectedTaskIDs(
                    selectedTaskIDs.filter((id) => task.id !== id)
                  )
                )
              : dispatch(updateSelectedTaskIDs([...selectedTaskIDs, task.id]));
          }}
          inputStyles='cursor-pointer outline-none w-3 sm:w-5 h-3 sm:h-5'
        />
      )}
      <TaskItemContext.Provider
        value={useMemo(
          () => ({ taskItemState, setTaskItemState }),
          [taskItemState]
        )}
      >
        <div
          className={classNames(
            'w-full flex flex-col rounded-md shadow-sm relative p-1.5',
            {
              'bg-red-200':
                String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DO,
              'bg-orange-200':
                String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DECIDE,
              'bg-green-200':
                String(task.eisenhower_quadrant) ===
                EISENHOWER_QUADRANT.DELEGATE,
              'bg-blue-200':
                String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELETE
            }
          )}
        >
          <TaskItemHeader />
          <TaskItemBody />
        </div>
      </TaskItemContext.Provider>
      <button
        onClick={handleTaskDeletion}
        className='absolute top-0 -right-2 w-fit h-fit bg-white rounded-full cursor-pointer'
      >
        {taskItemState.isTaskDeleting ? (
          <div className='w-5 h-5 border-red-500 border-s-2 animate-spin rounded-full'></div>
        ) : (
          <CloseCircle styles='w-5 h-auto' fill={COLOR.RED} />
        )}
      </button>
    </div>
  );
};

export default TaskItem;
