import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

const DownArrow = ({ fill = COLOR.WHITE, styles }: IconProps) => {
  return (
    <svg
      className={styles}
      fill={fill}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 493.46 493.46'
      xmlSpace='preserve'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <g>
          <g>
            <path d='M246.732,0C110.684,0,0.004,110.684,0.004,246.744c0,136.04,110.68,246.716,246.728,246.716 s246.724-110.676,246.724-246.716C493.456,110.684,382.78,0,246.732,0z M361.08,220.372L250.936,330.516 c-0.996,0.992-2.328,1.412-3.764,1.412l-0.124,3.744l0.052-4.108h-0.012h-0.156c-1.44,0-2.772-0.304-3.768-1.296L132.848,220.08 c-2.08-2.076-2.08-5.4,0-7.492l8.972-8.948c2.004-1.988,5.556-1.976,7.56,0.016l94.936,94.956c1.444,1.448,4.016,1.448,5.46,0 l94.768-94.776c2.012-2.012,5.56-2.012,7.576,0l8.96,8.98C363.164,214.896,363.164,218.284,361.08,220.372z'></path>{' '}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DownArrow;
