import { PDFViewer } from '@react-pdf/renderer';
import ModalOverlay from 'components/shared/ModalOverlay';
import { useAppSelector } from 'store/hooks';
import PdfDocument from './PdfDocument';

interface PdfProps {
  setIsPdfShown: (value: boolean) => void;
}

const Pdf = ({ setIsPdfShown }: PdfProps) => {
  const {
    setting: { currentSettings },
    user: {
      details: { metadata },
    },
  } = useAppSelector((state) => state);

  return (
    <ModalOverlay zIndex='z-50' setIsPdfShown={setIsPdfShown}>
      <PDFViewer className='relative top-[10%] mx-auto w-[90vw] sm:w-[75vw] xl:w-[50vw] h-[80vh] bg-white shadow-lg rounded-md'>
        <PdfDocument settings={currentSettings} metadata={metadata} />
      </PDFViewer>
    </ModalOverlay>
  );
};

export default Pdf;
