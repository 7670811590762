import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NUMBERS } from 'constants/general';
import { initialGeneralState } from 'store/initial-states';

const general = createSlice({
  name: 'general',
  initialState: initialGeneralState,
  reducers: {
    incrementGeneralCounter: (state) => {
      state.counter = state.counter + NUMBERS.ONE;
    },
    updateShouldStopCounter: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        state.counter = NUMBERS.ZERO;
      }
      state.shouldStopCounter = payload;
    },
    updateTabLayout: (state, { payload }) => {
      state.tabLayout.titles = payload;
    },
    updateTabLayoutActiveTab: (state, { payload }) => {
      state.tabLayout.activeTab = payload;
    },
    updateTabLayoutTitles: (state, { payload }: PayloadAction<string[]>) => {
      state.tabLayout.titles = payload;
    }
  }
});

export const {
  incrementGeneralCounter,
  updateShouldStopCounter,
  updateTabLayout,
  updateTabLayoutActiveTab,
  updateTabLayoutTitles
} = general.actions;
export default general.reducer;
