import { useMemo, createContext, PropsWithChildren } from 'react';
import DroppedContent from './DroppedContent';
import * as Sentry from '@sentry/react';
import { THEME_OPTION } from 'constants/enum';
import Tabs from './tabs';
import { useAppSelector } from 'store/hooks';
import { PLATFORMS } from 'constants/general';
import { getCurrentPlatformFont, isDashboard } from 'utils/support';
import FreeStyle from './FreeStyle';
import Questioner from './Questioner';
import FocusBearLogo from './FocusBearLogo';
import { useWindowSize } from 'usehooks-ts';

export const SettingsPageContext = createContext<{
  width?: number;
  height?: number;
}>({});

const FocusBearFeaturesWrapper = ({ children }: PropsWithChildren<object>) => {
  const { platform, themeMode, font } = useAppSelector(
    (state) => state.setting
  );

  const currentFont = useMemo(
    () => getCurrentPlatformFont(platform, font),
    [platform, font]
  );

  return (
    <div
      className={`${
        platform !== PLATFORMS.WEB
          ? 'w-screen min-h-screen h-screen px-2'
          : 'w-[96%] h-full rounded-3xl px-2 md:px-4 py-2'
      } flex flex-col justify-between ${
        themeMode === THEME_OPTION.DARK
          ? 'text-white bg-gray-600'
          : 'text-black bg-focusBear'
      } overflow-hidden  ${currentFont}`}
    >
      {children}
    </div>
  );
};

const SettingGenerator = () => {
  const { width, height } = useWindowSize();
  const { showChoice, showTabs, showFreeStyle, showDropProcessor, platform } =
    useAppSelector((state) => state.setting);

  return (
    <SettingsPageContext.Provider
      value={useMemo(() => ({ width, height }), [width])}
    >
      {platform === PLATFORMS.WEB && !isDashboard && <FocusBearLogo />}
      <FocusBearFeaturesWrapper>
        {showChoice && <Questioner />}
        {showFreeStyle && <FreeStyle />}
        {showTabs && <Tabs />}
        {showDropProcessor && <DroppedContent />}
      </FocusBearFeaturesWrapper>
    </SettingsPageContext.Provider>
  );
};

export default Sentry.withProfiler(SettingGenerator);
