import InfoCircle from 'assets/icons/InfoCircle';
import { useMemo } from 'react';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import { v4 as uuid } from 'uuid';

interface TooltipProps {
  message?: string;
  place?: PlacesType;
  icon?: JSX.Element;
  iconStyles?: string;
  iconFillDarkMode?: string;
  additionalWrapperStyles?: string;
}

const Tooltip = ({
  message,
  place,
  icon,
  iconStyles,
  iconFillDarkMode,
  additionalWrapperStyles
}: TooltipProps) => {
  const id = useMemo(() => uuid(), []);
  return (
    <span
      className={`text-xs md:text-sm ${additionalWrapperStyles ?? 'flex items-center'}`}
    >
      <span data-tooltip-id={id} className='cursor-pointer'>
        {icon ?? (
          <InfoCircle styles={iconStyles} fillDarkMode={iconFillDarkMode} />
        )}
      </span>
      <ReactTooltip
        id={id}
        place={place}
        content={message}
        className='max-w-[80vw] z-[99999]'
      />
    </span>
  );
};

export default Tooltip;
