import axios, { AxiosRequestConfig } from 'axios';
import { LOCAL_STORAGE } from 'constants/general';
import { BASE_URL } from 'constants/routes';
import { getLocalStorage } from 'utils/support';
import { v1 as uuid } from 'uuid'; //@Description: generate unique id for each request with mac address of each device

//@TODO it needs handle => timeout: AXIOS_TIMEOUT,
const focusBearApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    accept: '*/*',
    'x-context-id': uuid()
  },
  signal: new AbortController().signal
});

export const addAccessTokenToInterceptor = () => {
  focusBearApi.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN);
      if (token) {
        config!.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default focusBearApi;
