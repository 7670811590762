import { TEAM_OWNER_ACTION } from 'constants/enum';
import { EMPTY_STRING } from 'constants/general';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  assignAdmin,
  fetchAllMembers,
  removeAdmin,
  removeMember
} from 'store/reducer/team/extra';
import {
  updateSelectedMembersId,
  updateShowTeamOwnerMassUpdateModal,
  updateTeamOwnerAction
} from 'store/reducer/team/slice';

interface TeamOwnerActionProps {
  title: string;
  action: TEAM_OWNER_ACTION;
  icon: JSX.Element;
  disabled?: boolean;
}

const TeamOwnerAction = ({
  title,
  action,
  icon,
  disabled
}: TeamOwnerActionProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    areMembersLoading,
    selectedMembersId,
    selectedTeam,
    isRemovingMember
  } = useAppSelector((state) => state.team);
  const shouldDisableBtnAction = Boolean(
    areMembersLoading || isRemovingMember || disabled
  );

  const handleBtnAction = async () => {
    const executeAction = async (
      actionFn: (args: {
        member_id: string;
        team_id: string;
      }) => ReturnType<
        typeof assignAdmin | typeof removeAdmin | typeof removeMember
      >,
      errorMessage: string
    ) => {
      const results = await Promise.allSettled(
        selectedMembersId?.map((member_id) =>
          dispatch(
            actionFn({
              member_id,
              team_id: selectedTeam?.id ?? EMPTY_STRING
            })
          )
        )
      );

      const successful = results.filter(
        (result) => result.status === 'fulfilled'
      );

      if (successful?.length && selectedTeam?.id) {
        dispatch(fetchAllMembers(selectedTeam?.id));
        dispatch(updateSelectedMembersId([]));
      } else {
        toast.error(t(errorMessage));
      }
    };

    switch (action) {
      case TEAM_OWNER_ACTION.ASSIGN_ADMIN:
        dispatch(updateTeamOwnerAction(TEAM_OWNER_ACTION.ASSIGN_ADMIN));
        await executeAction(
          assignAdmin,
          'admin_team.could_not_assign_admin_role'
        );
        break;
      case TEAM_OWNER_ACTION.REMOVE_ADMIN:
        dispatch(updateTeamOwnerAction(TEAM_OWNER_ACTION.REMOVE_ADMIN));
        await executeAction(
          removeAdmin,
          'admin_team.could_not_remove_admin_role'
        );
        break;
      case TEAM_OWNER_ACTION.REMOVE_MEMBER:
        await executeAction(removeMember, 'admin_team.could_not_remove_member');
        break;
      case TEAM_OWNER_ACTION.MASS_UPDATES:
        dispatch(updateShowTeamOwnerMassUpdateModal(true));
        break;
      default:
        dispatch(updateTeamOwnerAction(action));
    }
  };

  return (
    <button
      disabled={shouldDisableBtnAction}
      onClick={handleBtnAction}
      className={`min-w-max flex items-center gap-1 ${
        shouldDisableBtnAction
          ? 'bg-gray-100 text-gray-500'
          : 'text-white bg-gray-600  hover:bg-gray-700 cursor-pointer'
      } py-1 px-2.5 rounded-lg text-xs md:text-sm disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed disabled:hover:bg-gray-200 focus:bg-gray-400 animate-fadeIn shadow outline-none`}
    >
      {icon}
      {title}
    </button>
  );
};

export default TeamOwnerAction;
