import { useState } from 'react';
import {
  changeShowDropProcessor,
  changeShowTabs,
} from 'store/reducer/setting/slice';
import { validatePastedSettings } from 'store/reducer/setting/extra';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const DroppedContent = () => {
  const { isValidSettings } = useAppSelector((state) => state.setting);
  const [isEmpty, setIsEmpty] = useState(false);
  const [inputJson, setInputJson] = useState('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleStartScratch = () => {
    dispatch(changeShowDropProcessor(false));
    dispatch(changeShowTabs(true));
  };

  return (
    <div className='w-full h-full flex flex-col justify-center items-center'>
      <textarea
        value={inputJson}
        rows={15}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setInputJson(e.target.value);
          e.target.value === '' ? setIsEmpty(true) : setIsEmpty(false);
        }}
        required={true}
        placeholder={t('paste_your_json_here')}
        className='w-full sm:w-2/3 h-full bg-blue-200 font-mono rounded-3xl resize-none outline-none px-4 py-3 text-xs text-gray-700 font-semibold mt-4'
      ></textarea>
      {isEmpty && (
        <p className='text-red-500 text-xs font-bold w-2/3 pl-2 pt-1'>
          {t('empty_content_detected')}
        </p>
      )}

      {!isValidSettings ? (
        <div className='font-semibold py-2'>
          {t('sorry_settings_json_not_valid')}{' '}
          <button
            className='bg-blue-500 px-2 rounded text-white text-sm font-semibold py-1'
            onClick={handleStartScratch}
          >
            {t('lets_start_from_scratch')}
          </button>
        </div>
      ) : (
        <button
          className='w-fit h-fit bg-blue-600 px-8 py-2 text-white font-semibold text-base rounded cursor-pointer mt-4'
          onClick={async () => {
            if (inputJson !== '') {
              setIsEmpty(false);
              dispatch(validatePastedSettings(inputJson));
            } else {
              setIsEmpty(true);
            }
          }}
        >
          {t('process')}
        </button>
      )}
    </div>
  );
};

export default DroppedContent;
