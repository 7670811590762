import { DEFAULT_SETTINGS } from 'assets/data';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { resetFocusModeState } from 'store/reducer/focus-mode/slice';
import { setCurrentViewedPack } from 'store/reducer//habit-pack/slice';
import {
  changeCurrentSettings,
  configureTabs,
  setGeneratorMode,
} from 'store/reducer/setting/slice';
import { Mode } from 'constants/enum';

export default function SharePack() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(changeCurrentSettings(DEFAULT_SETTINGS));
      dispatch(setGeneratorMode(Mode.DEFAULT));
      dispatch(configureTabs({ mode: Mode.DEFAULT }));
      dispatch(setCurrentViewedPack(null));
      dispatch(resetFocusModeState());
    };
  }, []);

  return (
    <div className='w-full h-[100%] flex flex-col items-center justify-around gap-y-12 mt-8'>
      <div className='flex flex-col text-2xl text-center w-[50%]'>
        <p>{t('packs.your_pack_has_been_saved')}</p>
        <br />
        <p>{t('packs.access_it_from_list')}</p>
      </div>
    </div>
  );
}
