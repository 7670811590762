import React from 'react';
import ModalOverlay from './shared/ModalOverlay';
import ModalHeader from './shared/ModalHeader';
import { useTranslation } from 'react-i18next';
import { ACTIVITY_TEMPLATE_ID, MODAL_TYPES } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  unlockCurrentSettings,
  updateIsUnlockHabitPackModalShown,
} from 'store/reducer/setting/slice';
import _ from 'lodash';
import { THEME_OPTION } from 'constants/enum';

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`relative top-1/4 -translate-y-1/4 left-1/2 -translate-x-1/2 w-5/6 sm:w-2/3 md:w-3/5 h-fit flex flex-col rounded-lg ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-700' : 'bg-white'
      }`}
    >
      {children}
    </div>
  );
};

const UnlockHabitPackModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentSettings } = useAppSelector((state) => state.setting);

  const handleClick = () => {
    const unlockedMorningActivities = (
      currentSettings?.morning_activities ?? []
    ).map((activity) => {
      return _.omit(activity, [ACTIVITY_TEMPLATE_ID]);
    });
    const unlockedBreakingActivities = (
      currentSettings?.break_activities ?? []
    ).map((activity) => {
      return _.omit(activity, [ACTIVITY_TEMPLATE_ID]);
    });
    const unlockedEveningActivities = (
      currentSettings?.evening_activities ?? []
    ).map((activity) => {
      return _.omit(activity, [ACTIVITY_TEMPLATE_ID]);
    });

    dispatch(
      unlockCurrentSettings({
        morningActivities: unlockedMorningActivities,
        breakingActivities: unlockedBreakingActivities,
        eveningActivities: unlockedEveningActivities,
      })
    );
  };

  return (
    <ModalOverlay zIndex='z-[70]'>
      <Wrapper>
        <ModalHeader
          title={t('unlock_habit_pack')}
          modalType={MODAL_TYPES.UNLOCK_HABIT_PACK}
        />
        <div className='w-full h-fit flex flex-col px-6 py-4 gap-4'>
          <div className='text-sm sm:text-base md:text-lg font-semibold text-justify'>
            {t('unlock_habit_pack_description')}
          </div>
          <div className='w-fit h-fit flex items-center gap-6 self-end pb-1 text-xs sm:text-sm font-semibold'>
            <button
              onClick={() => {
                dispatch(updateIsUnlockHabitPackModalShown(false));
              }}
              className='w-fit h-fit px-4 py-1 self-end rounded-md text-white bg-gray-600 hover:bg-gray-800 cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300'
            >
              {t('btn_cancel')}
            </button>
            <button
              onClick={handleClick}
              className='w-fit h-fit px-4 py-1 rounded-md text-white bg-blue-600 hover:bg-blue-800 cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300'
            >
              {t('btn_unlock_editing')}
            </button>
          </div>
        </div>
      </Wrapper>
    </ModalOverlay>
  );
};

export default UnlockHabitPackModal;
