import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { generateSubTasks } from 'store/reducer/modal/extra';
import { updateTodoSubTasks } from 'store/reducer/modal/slice';

const SubTasksModalActions = () => {
  const dispatch = useAppDispatch();
  const {
    setting: { language },
    modal: {
      toDoSubTasks: {
        isGeneratingSubtasks,
        subTasks,
        task: title,
        isNewTask,
        taskId,
        areSubTasksGenerated
      }
    }
  } = useAppSelector((state) => state);

  return (
    <div className='w-fit flex items-center gap-4 self-end px-4 py-2'>
      {isNewTask && (
        <>
          <ButtonFocusBear
            disabled={isGeneratingSubtasks}
            onClick={() =>
              dispatch(
                updateTodoSubTasks({
                  subTasks: [...subTasks, { name: '', is_completed: false }],
                  isNewTask: true,
                  taskId
                })
              )
            }
            title={t('add')}
            size={BTN_FB_SIZE.SMALL}
            variant={BTN_FB_VARIANT.SECONDARY}
          />
          <ButtonFocusBear
            title={
              areSubTasksGenerated
                ? t('to_do_procrastinate.regenerate_sub_task')
                : t('to_do_procrastinate.generate_sub_task')
            }
            disabled={isGeneratingSubtasks}
            onClick={() =>
              dispatch(generateSubTasks({ task: title, language }))
            }
            additionalStyles={
              isGeneratingSubtasks ? 'animate-pulse' : 'animate-none'
            }
            size={BTN_FB_SIZE.SMALL}
          />
        </>
      )}
    </div>
  );
};
export default SubTasksModalActions;
