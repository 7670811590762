import type React from 'react';
import { AXE_CORE, NODE_ENV } from 'constants/general';

export const reportAccessibility = async (
  App: typeof React,
  config?: Record<string, unknown>
): Promise<void> => {
  const shouldClientAppRendered =
    typeof window !== undefined && process.env.NODE_ENV !== NODE_ENV.PRODUCTION;
  if (shouldClientAppRendered) {
    const axe = await import('@axe-core/react');
    const ReactDOM = await import('react-dom');
    axe.default(App, ReactDOM, AXE_CORE.TIMEOUT, config);
  }
};

export default reportAccessibility;
