import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import CloseCircle from 'assets/icons/CloseCircle';
import DropDown from 'components/common/DropDown';
import COLOR from 'constants/color';
import { REACT_SELECT_DROPDOWN, TO_DO_SUB_TASK_WHERE } from 'constants/general';
import { t } from 'i18next';
import { DropDownSelectedValue, SubTask } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateSubTaskStatus } from 'store/reducer/modal/extra';
import {
  deleteToDoSubTask,
  updateToDoSubTaskName,
  updateTodoSubTasks
} from 'store/reducer/modal/slice';
import { increment } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';

const subTaskStatusOptions = [
  { label: t('completed'), value: t('completed') },
  { label: t('uncompleted'), value: t('uncompleted') }
];

const isCompleted = (value: boolean) => {
  if (value) {
    return { label: t('completed'), value: t('completed') };
  } else {
    return { label: t('uncompleted'), value: t('uncompleted') };
  }
};

interface SubTaskItemProps {
  subtask: SubTask;
  pos: number;
  provided: DraggableProvided;
}

const SubTaskItem = ({ subtask, pos, provided }: SubTaskItemProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isStatusUpdated, setIsStatusUpdated] = useState(false); //@Description: it has local scope & helps to control the loading indicator of the active dropdown
  const {
    modal: {
      toDoSubTasks: {
        subTasks,
        isNewTask,
        taskId,
        isUpdatingSubTaskStatus,
        where
      }
    },
    toDo: { tasks },
    setting: { themeMode }
  } = useAppSelector((state) => state);

  const task = tasks.find((task) => task.id === taskId);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  useEffect(() => {
    !isUpdatingSubTaskStatus && setIsStatusUpdated(false);
  }, [isUpdatingSubTaskStatus]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className='w-full flex items-center justify-between py-1 text-sm xl:text-base'
    >
      <div className='w-full flex items-center gap-4 mr-4'>
        <p
          className={`w-4 text-right text-sm ${
            shouldActivateDarkMode ? 'text-white' : 'text-black'
          }`}
        >{`${increment(pos)})`}</p>
        <input
          className={`w-full px-2.5 py-2 rounded text-sm outline-none ${
            shouldActivateDarkMode ? 'bg-gray-400' : 'bg-white'
          }`}
          type='text'
          value={subtask.name}
          onChange={({ target: { value } }) =>
            dispatch(updateToDoSubTaskName({ pos, value }))
          }
          {...DEFAULT_HTML_ATTRIBUTES}
        />
      </div>
      <DropDown
        options={subTaskStatusOptions}
        handleChange={(data) => {
          setIsStatusUpdated(true);
          const result = data as DropDownSelectedValue;
          const subtasks = subTasks.map((sub, idx) =>
            pos === idx
              ? {
                  ...sub,
                  is_completed: result?.value === t('completed')
                }
              : sub
          );
          dispatch(updateTodoSubTasks({ subTasks: subtasks }));
          task &&
            dispatch(
              updateSubTaskStatus({
                ...task,
                subtasks
              })
            );
        }}
        value={isCompleted(subtask.is_completed)}
        isClearable={false}
        containerStyles='min-w-max w-1/2 sm:w-1/4'
        isDisabled={
          where === TO_DO_SUB_TASK_WHERE.DASHBOARD ? isNewTask : false
        }
        zIndex={REACT_SELECT_DROPDOWN.Z_INDEX.SUB_TASKS}
        isLoading={isUpdatingSubTaskStatus && isStatusUpdated}
      />
      {isNewTask && (
        <button
          onClick={() => dispatch(deleteToDoSubTask(pos))}
          className='ml-2.5'
        >
          <CloseCircle fill={COLOR.BLACK} styles='w-5 md:w-6 h-5 md:h-6' />
        </button>
      )}
    </div>
  );
};

const ListOfSubTasks = () => {
  const { t } = useTranslation();
  const {
    modal: {
      toDoSubTasks: { subTasks }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const dummyDiv = useRef<HTMLDivElement>(null);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  useEffect(() => {
    dummyDiv.current?.scrollIntoView({ behavior: 'smooth' });
  }, [subTasks.length]);

  return (
    <div
      className={`w-full h-fit max-h-[50vh] ${
        shouldActivateDarkMode ? 'bg-gray-500' : 'bg-gray-200'
      } overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300`}
    >
      {subTasks?.length ? (
        <Droppable droppableId='subtasks-container'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='flex flex-col gap-2 py-2 px-4'
            >
              {subTasks
                ?.map((task, index) => ({ id: index, task }))
                ?.map(({ id, task }) => (
                  <Draggable key={id} draggableId={id.toString()} index={id}>
                    {(draggableProvided) => (
                      <SubTaskItem
                        subtask={task}
                        pos={id}
                        provided={draggableProvided}
                      />
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ) : (
        <p
          className={`w-fit p-4 text-xs sm:text-sm mx-auto ${
            shouldActivateDarkMode ? 'text-white' : 'text-black'
          }`}
        >
          {t('no_subtasks_found')}
        </p>
      )}
    </div>
  );
};
export default ListOfSubTasks;
