import Tooltip from 'components/common/Tooltip';
import COLOR from 'constants/color';
import { SWITCH } from 'constants/tag';
import { ATTRIB } from 'constants/test';
import { t } from 'i18next';
import Switch from 'react-switch';
import { createAppSelector, useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionIncludeEveryBreak } from 'store/reducer/modal/slice';

const IncludeInEveryBreak = () => {
  const dispatch = useAppDispatch();
  const activity = useAppSelector(
    createAppSelector(
      [(state) => state.modal.moreOption.activity],
      (activity) => activity
    )
  );

  return (
    <label
      data-test={ATTRIB.TEST.SWITCH_INCLUDE_IN_EVERY_BREAK}
      className='w-fit flex gap-2 text-sm pb-0.5 font-semibold cursor-pointer'
    >
      <Switch
        width={SWITCH.WIDTH.INCLUDE_IN_EVERY_BREAK}
        height={SWITCH.HEIGHT.INCLUDE_IN_EVERY_BREAK}
        onChange={(value) => {
          dispatch(updateMoreOptionIncludeEveryBreak(value));
        }}
        checked={activity?.include_in_every_break ?? false}
        offColor={COLOR.SWITCH_OFF}
      />

      {t('include_in_every_break')}
      <Tooltip message={t('include_in_every_break_tooltip')} place='top' />
    </label>
  );
};

export default IncludeInEveryBreak;
