import { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LogQuantityQuestion } from 'interfaces';
import {
  EMPTY_STRING,
  LOG_QUANTITY_QUESTION_FEATURES,
  MODAL_TYPES
} from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateChoicesLogQuantityQuestion,
  updateMoreOptionLogQuantityDetails,
  updateMoreOptionLogQuantityQuestionFeatures
} from 'store/reducer/modal/slice';
import Option from 'assets/icons/Option';
import { LogQuantityQuestionContext } from './LogQuantityQuestions';
import { ChoiceItemContext } from 'components/setting-generator/tabs/ChoicesModal/ChoiceItem';
import { ATTRIB } from 'constants/test';
import { THEME_OPTION } from 'constants/enum';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';

interface LogQuestionInputProps {
  position: number;
  logQuestion: LogQuantityQuestion;
  handleDeleteQuestion: (arg: string) => void;
}

const LogQuestionInput = ({
  position,
  logQuestion,
  handleDeleteQuestion
}: LogQuestionInputProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [question, setQuestion] = useState(
    logQuestion.question ?? EMPTY_STRING
  );
  const isValidQuestion = question !== EMPTY_STRING;
  const { modalType } = useContext(LogQuantityQuestionContext);
  const { position: choicePosition } = useContext(ChoiceItemContext);
  const { themeMode } = useAppSelector((state) => state.setting);

  return (
    <div className='w-full flex items-center gap-1 relative'>
      <input
        data-test={
          modalType === MODAL_TYPES.MORE_OPTION
            ? ATTRIB.TEST.MORE_OPTION_INPUT_LOG_QUESTION
            : ATTRIB.TEST.CHOICES_INPUT_LOG_QUESTION
        }
        type='text'
        value={question}
        onChange={({ target: { value } }) => {
          setQuestion(value);
          modalType === MODAL_TYPES.MORE_OPTION
            ? dispatch(
                updateMoreOptionLogQuantityQuestionFeatures({
                  feature: LOG_QUANTITY_QUESTION_FEATURES.QUESTION,
                  position,
                  value
                })
              )
            : dispatch(
                updateChoicesLogQuantityQuestion({
                  choicePosition,
                  questionPosition: position,
                  value,
                  feature: LOG_QUANTITY_QUESTION_FEATURES.QUESTION
                })
              );
        }}
        className={`w-full py-1.5 pl-2 pr-8 text-xs xl:text-sm font-medium xl:font-medium tracking-wide outline-none border-[0.5px] rounded text-black ${
          isValidQuestion ? 'border-0' : 'border-red-400'
        } ${themeMode === THEME_OPTION.DARK ? 'bg-gray-400 focus:bg-white' : 'bg-white focus:bg-gray-50'} `}
        placeholder={t('log_question')}
      />
      <button
        onClick={() => {
          handleDeleteQuestion(logQuestion.id);
        }}
      >
        <CloseCircle fill={COLOR.GRAY_700} styles='w-5 md:w-6 h-auto' />
      </button>
      {isValidQuestion && (
        <button
          onClick={() => {
            dispatch(
              updateMoreOptionLogQuantityDetails({ position, logQuestion })
            );
          }}
          className='absolute top-1/2 -translate-y-1/2 right-10 w-fit h-fit'
        >
          <Option />
        </button>
      )}
    </div>
  );
};

export default memo(LogQuestionInput);
