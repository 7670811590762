import COLOR from 'constants/color';
import { IconProps } from 'interfaces';

const CloseFilled = ({
  styles = 'w-4 md:w-5 h-auto',
  fill = COLOR.RED
}: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      fill={fill}
      className={styles}
    >
      <g id='SVGRepo_iconCarrier' strokeWidth='0'>
        <path d='M20,2H4C2.9,2,2,2.9,2,4v16c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M16.9,15.6L15.5,17l-3.5-3.5L8.4,17 L7,15.6l3.5-3.5L7,8.5l1.4-1.4l3.5,3.5l3.5-3.5l1.4,1.4L13.4,12L16.9,15.6z'></path>
      </g>
    </svg>
  );
};

export default CloseFilled;
