import { PAGINATE, TO_DO_STATUS } from 'constants/enum';
import { COMPLETED_TAB, DEFAULT_TASKS_PER_PAGE } from 'constants/general';
import { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { initPagination } from 'store/reducer/pagination/slice';
import { getToDos } from 'store/reducer/to-do/extra';
import { taskContainerSelector } from 'store/reducer/to-do/selectors';
import { getPageBoundaries } from 'utils/support';
import { ToDoContext } from '..';
import EisenhowerMatrixModal from '../EisenhowerMatrixModal';
import NewTask from '../NewTask';
import Tasks from '../tasks/index';
import TasksPageActions from './TasksPageActions';

export const TodoListOfTasksContainer = () => {
  const dispatch = useAppDispatch();
  const {
    isEisenhowerMatrixModalOpened,
    tasks,
    pagination: { itemsPerPage, currentPage },
    activeTab,
    completedTasks
  } = useAppSelector(taskContainerSelector);

  const { shouldEmbedTodoList, shouldToolsEmbedTodo } = useContext(ToDoContext);
  const { pageStartIndex } = getPageBoundaries(currentPage, itemsPerPage);

  useEffect(() => {
    (activeTab === COMPLETED_TAB
      ? pageStartIndex >= completedTasks.length
      : pageStartIndex >= tasks.length) &&
      dispatch(
        getToDos({
          page: currentPage,
          itemsPerPage,
          status:
            activeTab === COMPLETED_TAB ? TO_DO_STATUS.COMPLETED : undefined
        })
      );
  }, [currentPage, activeTab]);

  useEffect(() => {
    if (activeTab === COMPLETED_TAB) {
      dispatch(
        initPagination({
          type: PAGINATE.TO_DO_COMPLETED_TASKS,
          itemsPerPage: DEFAULT_TASKS_PER_PAGE
        })
      );
    }
  }, [activeTab]);

  return (
    <>
      {!shouldToolsEmbedTodo && <TasksPageActions />}
      <Tasks />
      {!shouldEmbedTodoList && <NewTask />}
      {isEisenhowerMatrixModalOpened && <EisenhowerMatrixModal />}
    </>
  );
};
export default TodoListOfTasksContainer;
