import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  uninstallingFocusTemplate,
  installFocusTemplate
} from 'store/reducer/focus-mode/extra';
import {
  installHabitPack,
  uninstallHabitPack,
  upsertHabitPack
} from 'store/reducer//habit-pack/extra';
import {
  setCurrentViewedFocusTemplate,
  updateInitialCurrentViewedFocusTemplate
} from 'store/reducer/focus-mode/slice';
import {
  setCurrentViewedPack,
  updateInitialCurrentViewedPack,
  updateIsHabitPackPdfShowed
} from 'store/reducer/habit-pack/slice';
import {
  changeCurrentSettings,
  configureTabs,
  setGeneratorMode,
  resetActiveTabIndex,
  updateRoutinePlayerActivities,
  updateRoutinePlayingActivity,
  updateIsSettingEditingFinished,
  updateAreSettingsValid
} from 'store/reducer/setting/slice';
import {
  ACTIVITY_TYPES,
  MODAL_TYPES,
  ROUTINE_PLAYING_TYPE
} from 'constants/general';
import { PAGES, ROUTES } from 'constants/routes';
import { DEFAULT_FOCUS_TEMPLATE } from 'assets/data';
import { HabitPackFormat, Mode } from 'constants/enum';
import { v4 as uuid } from 'uuid';
import { DetailsPageWrapperContext } from '../../dashboard/wrappers/DetailsPageWrapper';
import _ from 'lodash';
import { updateConfirmActionModal } from 'store/reducer/modal/slice';

const PlaceholderSpinner = () => (
  <div className='w-5 h-5 border-t border-white rounded-full animate-spin mx-auto my-0.5'></div>
);

export default function HabitPackActionButton({
  text
}: {
  readonly text: string;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    habitPack: { currentViewedPack, installingPack, uninstallingPack },
    focusMode: {
      currentViewedFocusTemplate,
      isFocusTemplateInstalling,
      isFocusTemplateUninstalling
    },
    user: {
      details: { focus_modes }
    },
    setting: { language }
  } = useAppSelector((state) => state);
  const { page } = useContext(DetailsPageWrapperContext);
  const isEitherInstallOrUninstallInAction =
    (installingPack ||
      uninstallingPack ||
      isFocusTemplateInstalling ||
      isFocusTemplateUninstalling) &&
    (t('market.install') === text || t('market.uninstall') === text);
  const packMode =
    currentViewedPack?.pack_type === HabitPackFormat.ROUTINE
      ? Mode.ROUTINE
      : Mode.STANDALONE;

  const handleActionClick = () => {
    switch (text) {
      case t('back'):
        if (page === PAGES.HABIT_PACK) {
          !pathname.includes(ROUTES.SETTINGS) && navigate(ROUTES.BACK_ONE_STEP);
          dispatch(setCurrentViewedPack(null));
        } else {
          dispatch(setCurrentViewedFocusTemplate(DEFAULT_FOCUS_TEMPLATE));
          navigate(ROUTES.BACK_ONE_STEP);
        }
        break;
      case t('market.install'):
        if (page === PAGES.HABIT_PACK) {
          currentViewedPack?.id &&
            dispatch(installHabitPack(currentViewedPack?.id));
        } else {
          currentViewedFocusTemplate?.id &&
            dispatch(installFocusTemplate(currentViewedFocusTemplate.id));
        }
        break;
      case t('market.uninstall'):
        if (page === PAGES.HABIT_PACK) {
          currentViewedPack?.id &&
            dispatch(uninstallHabitPack(currentViewedPack.id));
        } else {
          const focusMode = focus_modes.find(
            (focusMode) =>
              focusMode.focus_mode_template_id ===
                currentViewedFocusTemplate?.id ||
              focusMode.id === currentViewedFocusTemplate?.id
          );
          focusMode && dispatch(uninstallingFocusTemplate(focusMode.id));
        }
        break;
      case t('market.delete'):
        if (page === PAGES.HABIT_PACK) {
          dispatch(
            updateConfirmActionModal({
              modalType: MODAL_TYPES.CONFIRM_ACTION_DELETE_HABIT_PACK,
              confirmTitle: t('market.delete'),
              description: t('packs.delete_habit_pack'),
              showConfirmModal: true
            })
          );
        } else {
          dispatch(
            updateConfirmActionModal({
              modalType: MODAL_TYPES.CONFIRM_ACTION_DELETE_FOCUS_TEMPLATE,
              cancelTitle: t('btn_cancel'),
              confirmTitle: t('market.delete'),
              description: t('focus_mode.delete_focus_template'),
              showConfirmModal: true
            })
          );
        }
        break;
      case t('market.edit'):
        if (page === PAGES.HABIT_PACK) {
          if (currentViewedPack) {
            dispatch(resetActiveTabIndex());
            // assign current lang to the pack
            dispatch(updateInitialCurrentViewedPack(currentViewedPack));
            dispatch(changeCurrentSettings({ ...currentViewedPack, language }));
            dispatch(setGeneratorMode(packMode));
            dispatch(configureTabs({ mode: packMode }));
            // Reset the settings page and its validation if any operations have been performed
            dispatch(updateIsSettingEditingFinished(false));
            dispatch(updateAreSettingsValid(false));
          }
        } else {
          dispatch(resetActiveTabIndex());
          dispatch(setGeneratorMode(Mode.FOCUS_MODE));
          dispatch(configureTabs({ mode: Mode.FOCUS_MODE }));
          dispatch(
            changeCurrentSettings({ ...currentViewedFocusTemplate, language })
          );
          dispatch(
            updateInitialCurrentViewedFocusTemplate({
              ...currentViewedFocusTemplate,
              language
            })
          );
          // Reset the settings page and its validation if any operations have been performed
          dispatch(updateIsSettingEditingFinished(false));
          dispatch(updateAreSettingsValid(false));
        }
        navigate(`../${ROUTES.SETTINGS}`);
        break;
      case t('market.play'):
        if (currentViewedPack?.standalone_activities?.length === 0) {
          toast.error(t('habit_pack_not_having_an_activity'));
        } else {
          if (currentViewedPack?.standalone_activities) {
            const activity_sequence_id = uuid();
            const activities = currentViewedPack?.standalone_activities.map(
              (activity) => ({ ...activity, activity_sequence_id })
            );
            dispatch(
              updateRoutinePlayerActivities({
                type: ACTIVITY_TYPES.STANDALONE,
                activities
              })
            );
            dispatch(
              updateRoutinePlayingActivity({
                type: ROUTINE_PLAYING_TYPE.CURRENT,
                activity: activities[0]
              })
            );
            navigate(`../${ROUTES.ROUTINE_PLAYER}`, {
              state: {
                routine_mode: HabitPackFormat.STANDALONE
              }
            });
          }
        }
        break;
      case t('generate_pdf'):
        dispatch(updateIsHabitPackPdfShowed(true));
        break;
      case t('duplicate'):
        if (currentViewedPack) {
          let clonedPack = _.cloneDeep(currentViewedPack);
          clonedPack.id = uuid();
          const morning_activities = clonedPack?.morning_activities?.map(
            (activity) => ({
              ...activity,
              id: uuid()
            })
          );
          const break_activities = clonedPack?.break_activities?.map(
            (activity) => {
              const choices = activity?.choices ?? [];
              if (choices.length > 0) {
                choices.map((choice) => ({ ...choice, id: uuid() }));
              }
              return {
                ...activity,
                id: uuid(),
                choices
              };
            }
          );
          const evening_activities = clonedPack?.evening_activities?.map(
            (activity) => ({
              ...activity,
              id: uuid()
            })
          );
          const standalone_activities = clonedPack?.standalone_activities?.map(
            (activity) => ({
              ...activity,
              id: uuid()
            })
          );
          clonedPack = {
            ...clonedPack,
            morning_activities,
            break_activities,
            evening_activities,
            standalone_activities
          };
          dispatch(upsertHabitPack(clonedPack));
        }
    }
  };

  return (
    <button
      className={`w-3/5 sm:w-2/3 md:w-full lg:w-3/4 xl:w-[50%] px-2 py-1 md:px-2 md:py-1 text-xs sm:text-sm md:text-base rounded-xl border-2 font-bold ${
        isEitherInstallOrUninstallInAction
          ? 'text-white bg-blue-500'
          : 'border-blue-500 text-blue-500  hover:text-white hover:bg-blue-500'
      }`}
      onClick={handleActionClick}
    >
      {isEitherInstallOrUninstallInAction ? <PlaceholderSpinner /> : text}
    </button>
  );
}
