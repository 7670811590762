import InputTime from 'components/common/inputs/InputTime';
import { SCREEN } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { timingSelector } from 'store/reducer/setting/selectors';
import { updateStartupTime } from 'store/reducer/setting/slice';

const StartUpTime = () => {
  const dispatch = useAppDispatch();
  const { startup_time, shutdown_time } = useAppSelector(timingSelector);

  return (
    <InputTime
      selectedDate={startup_time ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateStartupTime(value));
      }}
      errorMessages={[t('errors.empty_time')]}
      label={t('what_time_do_you_wake_up')}
      tooltipMessage={t('explanation_starting_time', {
        startup_time: startup_time
          ? moment(startup_time, 'HH:mm').format('hh:mm a')
          : t('wake_up_time'),
        shutdown_time: shutdown_time
          ? moment(shutdown_time, 'HH:mm').format('hh:mm a')
          : t('finish_work_time')
      })}
      wrapperStyles='w-full flex gap-x-10 gap-y-2 flex-wrap py-2'
      labelStyles='text-sm xs:text-base sm:text-lg'
    />
  );
};

export default StartUpTime;
