import Logo from 'assets/icons/Logo';
import { THEME_OPTION } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';

interface Props {
  readonly title?: string;
}

function Loading({ title }: Props) {
  const { t } = useTranslation();
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`w-screen h-screen flex flex-col justify-center items-center gap-8 sm:gap-12 md:gap-16 2xl:gap-20 animate-fadeIn ${
        themeMode === THEME_OPTION.DARK
          ? 'bg-gray-600 text-white'
          : 'bg-main text-focusBearText'
      }`}
    >
      <Logo />
      <h1 className='w-fit font-semibold text-xl sm:text-2xl md:text-4xl 2xl:text-5xl animate-pulse'>
        {title ?? t('loading')}
      </h1>
    </div>
  );
}

export default Loading;
