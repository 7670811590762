export function registerServiceWorks() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(
        (registration) => {
          console.log(
            'Caching serviceWorker registration successful with scope: ',
            registration.scope
          );
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
}
