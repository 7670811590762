import DropdownIcon from 'assets/icons/Dropdown';
import DropDown from 'components/common/DropDown';
import { NUMBER_OF_DECIMAL_PLACES } from 'constants/general';
import { DropDownSelectedValue, SubmitTaskPayload } from 'interfaces';
import { ToDoFocusEndContext } from 'pages/to-do/toDoFocusEnd';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { useAppSelector } from 'store/hooks';
import { toDoFocusEndTaskSelector } from 'store/reducer/user/selectors';
import {
  convertToMinutes,
  getToDoDefaultStatusLabel,
  getTodoTaskStatus
} from 'utils/support';
import { hasTaskValidDuration } from 'utils/todoUtil';
import { isDarkModeActivated } from 'utils/validation';

interface TodoFocusEndTaskProps {
  task: SubmitTaskPayload;
  handleDuration: (
    id: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNote: (
    id: string
  ) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TodoFocusEndTask = ({
  task,
  handleDuration,
  handleNote
}: TodoFocusEndTaskProps) => {
  const { t } = useTranslation();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [status, setStatus] = useState<DropDownSelectedValue | null>({
    label: getToDoDefaultStatusLabel(task.status, task.external_statuses),
    value: task.status
  });
  const { isUpdatingStatus, isTaskSubmitting, themeMode } = useAppSelector(
    toDoFocusEndTaskSelector
  );
  const { setIncomingTasks } = useContext(ToDoFocusEndContext);

  const [isDurationValid, toDoStatusOptions, shouldActivateDarkMode] = useMemo(
    () => [
      hasTaskValidDuration(task),
      getTodoTaskStatus(task),
      isDarkModeActivated(themeMode)
    ],
    [task, themeMode]
  );

  return (
    <div
      className={`w-full h-fit flex flex-col rounded-md shadow-md cursor-pointer pl-8 pr-4 pt-2 pb-4 ${
        shouldActivateDarkMode ? 'bg-gray-500' : 'bg-gray-100'
      }`}
    >
      <div className='w-full h-fit flex gap-4 pt-2 justify-between relative'>
        <button
          onClick={() => setToggleDropdown((prev) => !prev)}
          className={`absolute top-1.5 -left-6 ${
            toggleDropdown ? 'rotate-0' : '-rotate-90'
          } transition-[height] ease-in duration-200`}
        >
          <DropdownIcon />
        </button>
        <DropDown
          isDisabled={isTaskSubmitting}
          title={t('to_do_procrastinate.status')}
          value={status ?? { label: '', value: '' }}
          options={toDoStatusOptions}
          handleChange={(data) => {
            const result = data as SingleValue<DropDownSelectedValue>;
            result &&
              setStatus(() => {
                setIncomingTasks?.((prevTask) =>
                  (prevTask ?? []).map((item) =>
                    item.id === task.id
                      ? { ...item, status: result.value }
                      : item
                  )
                );
                return result;
              });
          }}
          containerStyles='min-w-[180px] flex flex-col text-xs self-start gap-1.5'
        />
        <p className='w-full max-w-full truncate text-sm lg:text-base'>
          <span className='flex flex-col text-xs'>
            {t('to_do_procrastinate.title')}
          </span>
          {task.title}
        </p>
        <div className='w-fit flex flex-col'>
          <p className='w-fit text-xs'>{t('duration')}</p>
          <input
            disabled={isUpdatingStatus || isTaskSubmitting}
            type='number'
            value={
              +convertToMinutes(
                parseInt(task?.focusedDuration?.toString() ?? ''),
                0,
                true
              )?.toFixed(NUMBER_OF_DECIMAL_PLACES)
            }
            onChange={handleDuration(task.id)}
            className={`w-[100px] lg:w-[150px] outline-none px-1.5 py-0.5 rounded border ${
              isDurationValid ? 'border-gray-400' : 'border-red-400'
            } text-center ${
              shouldActivateDarkMode ? 'bg-gray-400' : 'focus:bg-gray-50'
            } text-sm text-black`}
            min={0}
            pattern='^\d*(\.\d{0,2})?$'
          />
          <p className='text-xs self-end'>{t('minutes')}</p>
        </div>
      </div>
      <div
        className={`w-full flex flex-col justify-between ${
          toggleDropdown ? 'h-36' : 'h-0 overflow-hidden'
        } transition-[height] ease-in duration-100 mt-4 relative`}
      >
        <textarea
          value={task.note}
          onChange={handleNote(task.id)}
          placeholder={`${t('to_do_procrastinate.write_a_note')}...`}
          className='w-full h-full outline-none resize-none rounded shadow p-2 text-xs md:text-sm'
        ></textarea>
      </div>
    </div>
  );
};

export default TodoFocusEndTask;
