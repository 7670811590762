import classNames from 'classnames';
import { TO_DO_PLAYER } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { playerTotalDurationSelector } from 'store/reducer/to-do/selectors';
import { updateFocusPlayerTotalDuration } from 'store/reducer/to-do/slice';
import { decrement } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';

const TotalDurationCounter = () => {
  const dispatch = useAppDispatch();
  const { total_duration, themeMode } = useAppSelector(
    playerTotalDurationSelector
  );
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  useEffect(() => {
    setTimeout(() => {
      total_duration &&
        dispatch(updateFocusPlayerTotalDuration(decrement(total_duration)));
    }, TO_DO_PLAYER.TIMEOUT_DURATION);
  }, [total_duration]);

  return (
    <p
      className={classNames(
        'min-w-full text-center text-sm this_time_shows_you_how_much_time_left py-2 my-4',
        {
          'bg-gray-600 text-white': shouldActivateDarkMode,
          'bg-white text-black': !shouldActivateDarkMode
        }
      )}
    >
      {t('to_do_player.total_time_remaining')}
      <span className='pl-2'>
        {moment()
          .startOf('date')
          .add(total_duration, 'seconds')
          .format('HH[h]:mm[m]:ss[s]')}
      </span>
    </p>
  );
};

export default TotalDurationCounter;
