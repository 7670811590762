import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import CheckBox from 'components/common/CheckBox';
import { BTN_FB_SIZE, THEME_OPTION } from 'constants/enum';
import { FIRST_LESSON_OFFSET } from 'constants/general';
import { t } from 'i18next';
import { Fragment, useContext, useEffect, useRef } from 'react';
import { useAppSelector } from 'store/hooks';
import { courseLessonsModalSelector } from 'store/reducer/course/selectors';
import { themeSelector } from 'store/reducer/setting/selectors';
import { CourseLessonsModalContext } from '.';

const ListOfLessons = () => {
  const themeMode = useAppSelector(themeSelector);
  const divRef = useRef<HTMLDivElement>(null);
  const {
    lessonInfo: { currentLesson },
    setLessonInfo
  } = useContext(CourseLessonsModalContext);
  const { selectedCourse } = useAppSelector(courseLessonsModalSelector);
  const lessons = selectedCourse?.lessons ?? [];
  const btnTitle =
    currentLesson === FIRST_LESSON_OFFSET
      ? t('course.start')
      : t('course.next_lesson');

  useEffect(() => {
    divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [currentLesson]);

  return (
    <div
      className={classNames(
        'h-[92%] flex flex-col gap-3 justify-between py-2 pr-2',
        {
          'bg-transparent': themeMode === THEME_OPTION.LIGHT,
          'bg-gray-600': themeMode === THEME_OPTION.DARK
        }
      )}
    >
      {lessons?.length ? (
        <ul className='w-full h-fit max-h-[91%] overflow-y-auto px-2 scrollbar-thin scroll-mb-10'>
          {lessons.map((lesson, idx) => (
            <Fragment key={lesson.id}>
              <li className='w-full flex gap-2 p-2 hover:bg-gray-500/50 hover:rounded'>
                <CheckBox
                  checked={selectedCourse?.lessonCompletions.some(
                    (completion) => completion.lesson_id === lesson.id
                  )}
                  readOnly
                  inputStyles='w-4 sm:w-5 h-4 sm:h-5 cursor-pointer outline-none accent-sunRayFirst'
                  onClick={() =>
                    setLessonInfo?.((prev) => ({
                      ...prev,
                      currentLesson: idx,
                      showContents: true
                    }))
                  }
                  label={
                    <span className='w-full truncate text-base xs:text-lg md:text-xl'>
                      {lesson.title}
                    </span>
                  }
                  labelStyles='w-full flex items-center gap-1.5 cursor-pointer'
                />
              </li>
              {currentLesson === idx && (
                <div ref={divRef} className='float-left clear-both'></div>
              )}
            </Fragment>
          ))}
        </ul>
      ) : (
        <p className='text-sm mx-auto py-6'>{t('course.no_lesson_found')}</p>
      )}
      <ButtonFocusBear
        title={btnTitle}
        size={BTN_FB_SIZE.SMALL}
        additionalStyles='self-end'
        onClick={() =>
          setLessonInfo?.((prev) => ({ ...prev, showContents: true }))
        }
        disableDarkMode
      />
    </div>
  );
};

export default ListOfLessons;
