import { useAppSelector } from 'store/hooks';
import WeeklySummaryRow from './WeeklySummaryRow';
import WeeklySummaryColumns from './WeeklySummaryColumns';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <section className='w-[95%] xl:w-fit flex flex-col rounded-tr-2xl rounded-b-2xl shadow-md p-4 overflow-x-auto scrollbar-thin scrollbar-thumb-orange-200'>
    {children}
  </section>
);

const WeeklyStatistics = () => {
  const { isWeeklyStatsUpdating, weeklySummaryStats } = useAppSelector(
    (state) => state.user
  );

  return (
    <Wrapper>
      <WeeklySummaryColumns />
      {isWeeklyStatsUpdating ? (
        <div className='w-4 h-4 rounded-full border-b-2 border-orange-400 animate-spin mx-auto my-20'></div>
      ) : (
        <div className='w-fit flex flex-col py-4'>
          {weeklySummaryStats.map((stats) => (
            <WeeklySummaryRow key={stats.date} stats={stats} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default WeeklyStatistics;
