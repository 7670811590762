import NerdFace from 'assets/icons/NerdFace';
import SmilingFaceWithSunglasses from 'assets/icons/SmilingFaceWithSunglasses';
import classNames from 'classnames';
import UnlockHabitPackModal from 'components/UnlockHabitPackModal';
import { FLAG, THEME_OPTION } from 'constants/enum';
import { PLATFORMS, SCREEN, SETTING_TAB_INDEX, TAB } from 'constants/general';
import { t } from 'i18next';
import { useContext, useEffect, useMemo } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCurrentViewedPack } from 'store/reducer//habit-pack/slice';
import {
  resetFilters,
  updateFilterIsSettingToolbarShow
} from 'store/reducer/filter/slice';
import { getUserSettings } from 'store/reducer/setting/extra';
import { tabBodySelector } from 'store/reducer/setting/selectors';
import { updateActiveTabIndex } from 'store/reducer/setting/slice';
import { useWindowSize } from 'usehooks-ts';
import {
  getRoutineSuggestionsTabIndex,
  getRoutineTabs,
  getSettingPageTabContent,
  getSettingTabIcon
} from 'utils/settingsUtil';
import { SettingsPageContext } from '..';
import MoreOptions from './MoreOptions';
import MorningAdvancedModal from './routine/MorningAdvancedModal';
import { isRouteEmbedded } from 'utils/validation';

const TabBody = () => {
  const {
    mode,
    tabs,
    isShowMoreOptionsOpened,
    type,
    position,
    isShowRoutineModal,
    activeTabIndex,
    isUnlockHabitPackModalShown,
    platform,
    themeMode,
    isSettingLoaded,
    customRoutine,
    flags,
    routineSuggestionRoutines
  } = useAppSelector(tabBodySelector);

  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const settingPageContext = useContext(SettingsPageContext);

  const [isGeekMode, shouldActiveMobileView] = useMemo(
    () => [
      flags.includes(FLAG.GEEK_MODE),
      (settingPageContext.width ?? width) <= SCREEN.WIDTH.SMALL
    ],
    [flags, width, settingPageContext.width]
  );
  const routine_tabs = useMemo(
    () => getRoutineTabs(tabs, platform, isGeekMode, customRoutine),
    [tabs, platform, flags]
  );

  useEffect(() => {
    const isHabitPackInstallOrUninstalled =
      [
        SETTING_TAB_INDEX.EVENING_ROUTINE,
        SETTING_TAB_INDEX.HABIT_PACKS
      ].includes(activeTabIndex) && !isSettingLoaded; //@Description: this change needs to be reverted back once the mobile apps have the micro-break tab active
    isHabitPackInstallOrUninstalled && dispatch(getUserSettings());
  }, [isSettingLoaded, activeTabIndex]);

  return (
    <>
      <Tabs
        selectedIndex={activeTabIndex}
        className={classNames('w-full h-[93%] flex flex-col', {
          'py-2 md:py-4': !customRoutine,
          'px-2 pt-2': customRoutine
        })}
        onSelect={(selectedIndex) => {
          dispatch(updateActiveTabIndex(selectedIndex));
          dispatch(updateFilterIsSettingToolbarShow(false));
          dispatch(resetFilters());
          routine_tabs[selectedIndex] === TAB.HABIT_PACK &&
            dispatch(setCurrentViewedPack(null));
        }}
        selectedTabClassName={classNames(
          {
            'bg-gray-700': themeMode === THEME_OPTION.DARK,
            'bg-white': themeMode === THEME_OPTION.LIGHT
          },
          'border-t rounded-t-md'
        )}
      >
        <TabList
          className={classNames(
            'w-full min-h-[2rem] h-[7%] flex',
            {
              ' items-start gap-4': shouldActiveMobileView,
              'items-center gap-2': !shouldActiveMobileView
            },
            'overflow-x-auto scrollbar-thin scrollbar-thumb-gray-700'
          )}
        >
          {routine_tabs.map((tab: string, index: number) => (
            <Tab
              data-test={
                getSettingPageTabContent(mode, index, customRoutine, platform)
                  .testIds?.[index]
              }
              className={classNames(
                'min-w-max text-sm md:text-base px-4 py-1 cursor-pointer outline-none border-gray-400',
                {
                  'tracking-wider': platform !== PLATFORMS.WEB,
                  'tracking-normal': platform === PLATFORMS.WEB,
                  'border-b': activeTabIndex !== index
                },
                'flex items-center gap-2 select-none'
              )}
              key={tab}
            >
              {getSettingTabIcon(tab)}
              {(!shouldActiveMobileView ||
                (isRouteEmbedded &&
                  activeTabIndex ===
                    getRoutineSuggestionsTabIndex(
                      tab,
                      routineSuggestionRoutines
                    ))) &&
                t(`${tab}`)}
            </Tab>
          ))}
          {platform !== PLATFORMS.WEB && (
            <span className='px-4'>
              {!customRoutine &&
                (isGeekMode ? <NerdFace /> : <SmilingFaceWithSunglasses />)}
            </span>
          )}
        </TabList>
        {routine_tabs.map((tab: string, index: number) => (
          <TabPanel
            key={tab}
            className={`${shouldActiveMobileView ? 'h-full' : 'h-[93%]'} ${index !== activeTabIndex && 'hidden'} relative p-2 animate-fadeIn`}
          >
            {
              getSettingPageTabContent(mode, index, customRoutine, platform)
                .body
            }
          </TabPanel>
        ))}
      </Tabs>
      {isShowRoutineModal && <MorningAdvancedModal />}
      {isShowMoreOptionsOpened && position !== -1 && type && <MoreOptions />}
      {isUnlockHabitPackModalShown && <UnlockHabitPackModal />}
    </>
  );
};

export default TabBody;
