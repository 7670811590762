import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProcrastinateWebsite, SaveWebsite } from 'interfaces';
import { getSavedWebsites } from './extra';
import { initialProcrastinateState } from 'store/initial-states';

const Procrastinate = createSlice({
  name: 'procrastinate',
  initialState: initialProcrastinateState,
  reducers: {
    updateWebsite: (
      state,
      { payload }: PayloadAction<ProcrastinateWebsite>
    ) => {
      state.website = payload;
    },
    updateSavedWebsites: (state, { payload }: PayloadAction<SaveWebsite[]>) => {
      state.savedWebsites = payload;
    },
    updateIsSavedWebsitesFetching: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavedWebsitesFetching = payload;
    },
    updateIsSavedWebsitesCreating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavedWebsitesCreating = payload;
    },
    updateIsSavedWebsitesUpdating: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavedWebsitesUpdating = payload;
    },
    updateIsSavedWebsitesDeleting: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavedWebsitesDeleting = payload;
    },
    updateSelectedWebsiteIDs: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedWebsiteIDs = payload;
    },
    resetSelectedWebsiteIDs: (state) => {
      state.selectedWebsiteIDs = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getSavedWebsites.fulfilled, (state, { payload }) => {
        state.savedWebsites = payload;
        state.isSavedWebsitesFetching = false;
      })
      .addCase(getSavedWebsites.rejected, (state, { payload }) => {
        state.isSavedWebsitesFetching = false;
      });
  }
});

export const {
  updateSavedWebsites,
  updateIsSavedWebsitesFetching,
  updateIsSavedWebsitesUpdating,
  updateIsSavedWebsitesDeleting,
  updateIsSavedWebsitesCreating,
  updateWebsite,
  updateSelectedWebsiteIDs,
  resetSelectedWebsiteIDs
} = Procrastinate.actions;

export default Procrastinate.reducer;
