import { PropsWithChildren, useEffect } from 'react';
import DocumentMetaData from 'components/DocumentMetaData';
import Spinner from 'components/common/Spinner';
import TeamOwnerActionModal from 'components/dashboard/team/teamOwnerActions/TeamOwnerActionModal';
import TeamMembers from 'components/dashboard/team/teamMembers';
import { PAGES, ROUTES } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAdminTeams } from 'store/reducer/team/extra';
import { TEAM_OWNER_ACTION } from 'constants/enum';
import TeamOwnerMassUpdateModal from 'components/dashboard/team/teamOwnerActions/TeamOwnerMassUpdateModal';
import { isTeamOwnerOrAdmin } from 'utils/teamUtil';
import { useNavigate } from 'react-router-dom';
import { teamPageSelector } from 'store/reducer/team/selectors';

function Wrapper({ children }: PropsWithChildren<object>) {
  return (
    <div className='py-5 md:py-4 px-7 md:px-14 flex flex-col justify-start items-center w-full min-h-[70vh] h-fit animate-fadeIn'>
      <DocumentMetaData pageName={PAGES.TEAM} />
      {children}
    </div>
  );
}

const TeamPage = () => {
  const dispatch = useAppDispatch();
  const {
    areTeamsLoading,
    showTeamOwnerActionModal,
    teamOwnerAction,
    showTeamOwnerMassUpdateModal,
    subscriptionInfo
  } = useAppSelector(teamPageSelector);
  const navigate = useNavigate();

  useEffect(() => {
    isTeamOwnerOrAdmin(subscriptionInfo?.activeEntitlements ?? [])
      ? dispatch(fetchAdminTeams())
      : navigate(`../${ROUTES.NOT_FOUND}`);
  }, []);

  return (
    <Wrapper>
      {areTeamsLoading ? <Spinner /> : <TeamMembers />}
      {[
        TEAM_OWNER_ACTION.INVITE_MEMBER,
        TEAM_OWNER_ACTION.UPDATE_NAME
      ].includes(teamOwnerAction ?? ('' as TEAM_OWNER_ACTION)) &&
        showTeamOwnerActionModal && <TeamOwnerActionModal />}
      {showTeamOwnerMassUpdateModal && <TeamOwnerMassUpdateModal />}
    </Wrapper>
  );
};

export default TeamPage;
