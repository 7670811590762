import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

const Option = ({
  styles = 'w-3 md:w-4 h-auto',
  fill = COLOR.BLUE_600
}: IconProps) => {
  return (
    <svg
      viewBox='0 0 32 32'
      enableBackground='new 0 0 32 32'
      id='Stock_cut'
      version='1.1'
      xmlSpace='preserve'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      fill={fill}
      className={styles}
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <desc></desc>
        <g>
          <path
            d='M31,19v-6h-4.425 c-0.252-0.888-0.611-1.729-1.065-2.51L29,7l-4-4l-3.49,3.49C21.028,6.21,20.525,5.967,20,5.761V1h-8v4.761 c-0.525,0.205-1.028,0.449-1.51,0.728L7,3L3,7l3.49,3.49C6.036,11.271,5.676,12.112,5.425,13H1v6h4.425 c0.252,0.888,0.611,1.729,1.065,2.51L3,25l4,4l3.49-3.49c0.482,0.28,0.986,0.523,1.51,0.728V31h8v-4.761 c0.525-0.205,1.028-0.449,1.51-0.728L25,29l4-4l-3.49-3.49c0.454-0.781,0.813-1.622,1.065-2.51H31z'
            fill='none'
            stroke='#000000'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='2'
          ></path>
          <circle
            cx='16'
            cy='16'
            fill='none'
            r='5'
            stroke='#000000'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='2'
          ></circle>
        </g>
      </g>
    </svg>
  );
};

export default Option;
