import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ATTRIB } from 'constants/test';

export default function SignUpButton() {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  return (
    <button
      data-test={ATTRIB.TEST.SIGN_UP}
      onClick={() => {
        loginWithRedirect({ screen_hint: 'signup' });
      }}
      className='underline text-black hover:text-[#dd9542] font-semibold cursor-pointer'
    >
      {t('sign_up')}
    </button>
  );
}
