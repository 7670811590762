import { THEME_OPTION } from 'constants/enum';
import React, { useEffect, useRef } from 'react';
import { DroppableProvided } from 'react-beautiful-dnd';
import { useAppSelector } from 'store/hooks';

interface Props {
  provided: DroppableProvided;
  children: React.ReactNode;
}

const RoutineActivitiesWrapper = ({ provided, children }: Props) => {
  const dummyDiv = useRef<HTMLDivElement>(null);
  const {
    setting: {
      currentSettings: {
        morning_activities,
        break_activities,
        evening_activities
      },
      themeMode,
      isNewHabitAppended
    }
  } = useAppSelector((state) => state);

  useEffect(() => {
    isNewHabitAppended &&
      dummyDiv.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
  }, [
    morning_activities?.length,
    break_activities?.length,
    evening_activities?.length,
    isNewHabitAppended
  ]);
  return (
    <div
      className={`w-full h-full max-h-full py-2.5 pr-3 flex flex-col items-center gap-10 overflow-y-auto scrollbar-thin ${
        themeMode === THEME_OPTION.DARK
          ? 'scrollbar-thumb-gray-700 scrollbar-track-gray-500'
          : 'scrollbar-thumb-gray-300 scrollbar-track-gray-100'
      }`}
      {...provided.droppableProps}
      ref={provided.innerRef}
    >
      {children}
      <div className='float-left clear-both' ref={dummyDiv}></div>
    </div>
  );
};

export default RoutineActivitiesWrapper;
