import DropDown from 'components/common/DropDown';
import { THEME_OPTION } from 'constants/enum';
import { EMPTY_STRING, PRIORITY_OPTION } from 'constants/general';
import { t } from 'i18next';
import { DropDownSelectedValue } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateFilterPriority } from 'store/reducer/filter/slice';

const FilterItemPriority = () => {
  const dispatch = useAppDispatch();
  const {
    filter: { priority },
    setting: { themeMode }
  } = useAppSelector((state) => state);

  const priorityOptions: { label: string; value: string }[] = [
    { label: t('must_do'), value: PRIORITY_OPTION.HIGH },
    { label: t('optional'), value: PRIORITY_OPTION.STANDARD }
  ];

  const selectedPriority = () => {
    if (priority === PRIORITY_OPTION.HIGH) {
      return { label: t('must_do'), value: PRIORITY_OPTION.HIGH };
    } else if (priority === PRIORITY_OPTION.STANDARD) {
      return { label: t('optional'), value: PRIORITY_OPTION.STANDARD };
    } else {
      return { label: EMPTY_STRING, value: EMPTY_STRING };
    }
  };

  return (
    <DropDown
      title={t('priority')}
      value={selectedPriority()}
      options={priorityOptions}
      handleChange={(data) => {
        const result = data as DropDownSelectedValue;
        dispatch(updateFilterPriority(result?.value ?? EMPTY_STRING));
      }}
      isClearable={true}
      containerStyles='min-w-[150px] w-fit relative'
      labelStyles={`absolute -top-2 left-1/2 -translate-x-1/2 bg-gray-600 text-[10px] px-2 pb-0.5 rounded-b-md ${
        themeMode === THEME_OPTION.LIGHT && 'text-white'
      } z-10`}
    />
  );
};
export default FilterItemPriority;
