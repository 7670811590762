import { SENTRY } from 'constants/general';
import * as Sentry from '@sentry/react';

export const SENTRY_CONFIG = {
  dsn: SENTRY.DNS,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({ maskAllInputs: true, blockAllMedia: true }),
  ],
  tracesSampleRate: SENTRY.TRACE_SAMPLE_RATE,
  replaysSessionSampleRate: SENTRY.REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: SENTRY.REPLAYS_ON_ERROR_SAMPLE_RATE,
};
