import { IconProps } from 'interfaces';

const Goal = ({ styles = 'w-5 sm:w-6 h-auto' }: IconProps) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      fill='#000000'
      className={styles}
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path
          style={{ fill: '#FE5F1A' }}
          d='M222.242,67.516V512c122.74,0,222.242-99.501,222.242-222.242S344.982,67.516,222.242,67.516z'
        ></path>{' '}
        <path
          style={{ fill: '#FE834D' }}
          d='M410.725,289.758c0-122.74-84.387-222.242-188.484-222.242C99.501,67.516,0,167.018,0,289.758 S99.501,512,222.242,512C326.339,512,410.725,412.499,410.725,289.758z'
        ></path>{' '}
        <circle
          style={{ fill: '#FFFFFF' }}
          cx='222.242'
          cy='289.758'
          r='163.165'
        ></circle>{' '}
        <circle
          style={{ fill: '#FEA680' }}
          cx='222.242'
          cy='289.758'
          r='104.088'
        ></circle>{' '}
        <g>
          {' '}
          <circle
            style={{ fill: '#FE5F1A' }}
            cx='222.242'
            cy='289.758'
            r='45.011'
          ></circle>{' '}
          <path
            style={{ fill: '#FE5F1A' }}
            d='M498.816,13.184l-67.634,35.805l55.698,39.784l11.936-11.936 C516.394,59.261,516.394,30.76,498.816,13.184z'
          ></path>{' '}
        </g>{' '}
        <path
          style={{ fill: '#FE834D' }}
          d='M435.162,13.184l-11.935,11.935l23.87,39.784l51.72-51.72C481.238-4.394,452.74-4.394,435.162,13.184 z'
        ></path>{' '}
        <polygon
          style={{ fill: '#FEDC3D' }}
          points='265.548,246.452 311.359,264.295 463.01,112.644 431.183,64.903 '
        ></polygon>{' '}
        <polygon
          style={{ fill: '#FFE777' }}
          points='399.356,48.99 247.705,200.641 265.548,246.452 439.14,72.86 '
        ></polygon>{' '}
        <polygon
          style={{ fill: '#B9B9B9' }}
          points='455.054,56.946 435.162,60.924 431.183,80.817 463.01,112.644 486.882,88.774 '
        ></polygon>{' '}
        <rect
          x='410.318'
          y='30.472'
          transform='matrix(-0.7071 0.7071 -0.7071 -0.7071 766.7312 -211.6355)'
          style={{ fill: '#DCDCDC' }}
          width='33.758'
          height='45.011'
        ></rect>{' '}
        <polygon
          style={{ fill: '#FFC1A6' }}
          points='279.532,232.468 234.178,261.909 265.563,277.381 311.359,264.297 '
        ></polygon>{' '}
        <polygon
          style={{ fill: '#FFDBCC' }}
          points='247.705,200.641 234.62,246.437 242.134,269.866 279.532,232.468 '
        ></polygon>{' '}
        <polygon
          style={{ fill: '#272727' }}
          points='250.091,261.909 235.764,263.505 222.242,289.758 265.563,277.381 '
        ></polygon>{' '}
        <polygon
          style={{ fill: '#4D4D4D' }}
          points='234.62,246.437 222.242,289.758 250.091,261.909 '
        ></polygon>{' '}
      </g>
    </svg>
  );
};

export default Goal;
