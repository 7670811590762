import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialCourseState } from 'store/initial-states';
import {
  createLessonCompletion,
  getCourseDetails,
  getOtherCourses,
  getEnrolledCourses,
  getTutorials
} from './extra';
import { Course, Tutorial } from 'interfaces';

const course = createSlice({
  name: 'course',
  initialState: initialCourseState,
  reducers: {
    updateIsLoadingCourseDetails: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingCourseDetails = payload;
    },
    updateShowCourseLessonsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showCourseLessonsModal = payload;
    },
    updateIsCreatingLessonCompletion: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingLessonCompletion = payload;
    },
    updateIsLessonCompletionSuccess: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLessonCompletionSuccess = payload;
    },
    updateIsFetchingTutorials: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingTutorials = payload;
    },
    updateTutorials: (state, { payload }: PayloadAction<Tutorial[]>) => {
      state.tutorials = payload;
    },
    updateIsFetchingOtherCourses: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingOtherCourses = payload;
    },
    updateIsFetchingEnrolledCourses: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingEnrolledCourses = payload;
    },
    updateIsCreatingCourseEnrolment: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingCourseEnrolment = payload;
    },
    updateCourseDetails: (state, { payload }: PayloadAction<Course>) => {
      state.details = payload;
    },
    updateShowCourseDetailsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showCourseDetailsModal = payload;
    },
    updateIsUpdatingCourseCompletion: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingCourseCompletion = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCourseDetails.fulfilled, (state, { payload }) => {
        state.details = payload;
        state.isLoadingCourseDetails = false;
      })
      .addCase(createLessonCompletion.fulfilled, (state) => {
        state.isLessonCompletionSuccess = true;
      })
      .addCase(createLessonCompletion.rejected, (state) => {
        state.isLessonCompletionSuccess = false;
      })
      .addCase(getTutorials.fulfilled, (state, { payload }) => {
        state.tutorials = payload;
        state.isFetchingTutorials = false;
      })
      .addCase(getEnrolledCourses.fulfilled, (state, { payload }) => {
        state.enrolled_courses = payload;
        state.isFetchingEnrolledCourses = false;
      })
      .addCase(getOtherCourses.fulfilled, (state, { payload }) => {
        state.other_courses = payload;
        state.isFetchingOtherCourses = false;
      });
  }
});

export const {
  updateIsLoadingCourseDetails,
  updateShowCourseLessonsModal,
  updateIsCreatingLessonCompletion,
  updateIsLessonCompletionSuccess,
  updateIsFetchingTutorials,
  updateTutorials,
  updateIsFetchingOtherCourses,
  updateIsFetchingEnrolledCourses,
  updateIsCreatingCourseEnrolment,
  updateCourseDetails,
  updateShowCourseDetailsModal,
  updateIsUpdatingCourseCompletion
} = course.actions;
export default course.reducer;
