import Edit from 'assets/icons/Edit';
import Tooltip from 'components/common/Tooltip';
import { TeamMember } from 'interfaces';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMemberExpiryDate } from 'store/reducer/team/extra';
import { updateSelectedMemberId } from 'store/reducer/team/slice';

const MemberActions = ({ member }: { member: TeamMember }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const {
    team: {
      selectedTeam,
      isUpdatingExpiryDate,
      teamMembers: { admins }
    },
    user: { details: userInfo }
  } = useAppSelector((state) => state);
  const isOwner = admins.some(
    (admin) =>
      admin.email === userInfo?.email && member.email === userInfo?.email
  );

  if (isEditing) {
    return member.id && isUpdatingExpiryDate ? (
      <div className='w-4 h-4 border-t-2 border-gray-500 rounded-full animate-spin'></div>
    ) : (
      <input
        type='date'
        value={moment(member.member_expiry_date).format('YYYY-MM-DD')}
        onChange={({ target: { value } }) => {
          dispatch(updateSelectedMemberId(member.id));
          selectedTeam &&
            dispatch(
              updateMemberExpiryDate({
                member_id: member.id,
                team_id: selectedTeam?.id,
                expiry_date: moment(value).endOf('day').format()
              })
            );
        }}
        className='px-1.5 py-0.5 rounded outline-none cursor-pointer bg-gray-200 focus:bg-gray-200/70 text-center'
        min={moment().format('YYYY-MM-DD')}
      />
    );
  }
  const isMemberAccessExpired = moment(member.member_expiry_date).isAfter(
    moment()
  );

  return (
    <>
      <p>
        {isMemberAccessExpired
          ? t('admin_team.yes_until', {
              date: moment(member.member_expiry_date).format('YYYY-MM-DD')
            })
          : t('no')}
      </p>
      {!isOwner ? (
        <button
          onClick={() => setIsEditing(true)}
          className='w-fit h-fit border-2 border-gray-600 p-0.5 rounded-md hover:bg-gray-100'
        >
          <Tooltip icon={<Edit styles='w-3 h-auto' />} />
        </button>
      ) : null}
    </>
  );
};

export default MemberActions;
