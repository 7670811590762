import {
  EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER,
  FILENAME,
  PLATFORMS
} from 'constants/general';
import { ToDoTask } from 'interfaces/commonInterface';
import { WeeklyFocusBlockSummary } from 'interfaces/userInterface';
import { STATS } from 'constants/table';
import { t } from 'i18next';
import moment from 'moment';
import i18n from 'services/i18n';
import {
  getEisenhowerQuadrantInfo,
  getToDoDefaultStatusLabel,
  removeHtmlTags
} from './support';
import { decode } from 'html-entities';

export const downloadFocusSummaryCSVFile = (
  weeklyFocusBlockSummary: WeeklyFocusBlockSummary[],
  platform: string
) => {
  const headers = STATS.FOCUS_SUMMARY.WEEKLY.COLUMN.map((column) =>
    i18n.t(column)
  );
  const contents = weeklyFocusBlockSummary.reduce((row: string[], summary) => {
    const {
      focus_mode,
      focus_duration_seconds,
      start_time,
      finish_time,
      intention,
      achievements,
      distractions
    } = summary;
    row.push(
      [
        focus_mode.name,
        focus_duration_seconds,
        start_time,
        finish_time,
        intention,
        achievements,
        distractions
      ].join(',')
    );
    return row;
  }, []);
  const encoded_data = [headers.join(','), ...contents].join('\n');
  if ([PLATFORMS.MAC, PLATFORMS.IOS].includes(platform)) {
    window.webkit.messageHandlers.download_focus_summaries.postMessage(
      encoded_data
    );
  } else {
    createCSVfile(encoded_data, FILENAME.FOCUS_SUMMARY);
  }
};

export const downloadEisenhowerMatrixCSVFile = (
  tasks: ToDoTask[],
  platform: string
) => {
  const headers = [
    t('to_do_procrastinate.title'),
    t('to_do_procrastinate.eisenhower_quadrant'),
    t('to_do_procrastinate.category'),
    t('to_do_procrastinate.decision')
  ];
  const contents = tasks.reduce(
    (row: string[], { title, eisenhower_quadrant }: ToDoTask) => {
      const { category, decision } = getEisenhowerQuadrantInfo(
        eisenhower_quadrant.toString()
      );
      row.push([title, eisenhower_quadrant, category, decision].join(','));
      return row;
    },
    []
  );
  const encoded_data = [headers.join(','), ...contents].join('\n');
  if ([PLATFORMS.MAC, PLATFORMS.IOS].includes(platform)) {
    window.webkit.messageHandlers.download_eisenhower_matrix.postMessage(
      encoded_data
    );
  } else {
    createCSVfile(encoded_data, FILENAME.EISENHOWER_MATRIX_SUMMARY);
  }
};

export const downloadToDoTasksCSVFile = (
  tasks: ToDoTask[],
  platform: string
) => {
  const headers = [
    t('to_do_procrastinate.title'),
    t('to_do_procrastinate.description'),
    t('to_do_procrastinate.objective'),
    t('to_do_procrastinate.status'),
    t('to_do_procrastinate.eisenhower_quadrant'),
    t('to_do_procrastinate.project'),
    t('to_do_procrastinate.sub_tasks'),
    t('to_do_procrastinate.due_date'),
    t('to_do_procrastinate.created_at')
  ];
  const contents = tasks.reduce((row: string[], task) => {
    const {
      title,
      details,
      objective,
      status,
      eisenhower_quadrant,
      tags,
      subtasks,
      due_date,
      created_at
    } = task;
    const { category } = getEisenhowerQuadrantInfo(
      eisenhower_quadrant?.toString()
    );
    row.push(
      [
        `"${escapeCommas(title)}"`,
        `"${decode(escapeCommas(removeHtmlTags(details ?? EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER)))}"`,
        `"${decode(escapeCommas(removeHtmlTags(objective ?? EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER)))}"`,
        status
          ? getToDoDefaultStatusLabel(status)
          : EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER,
        category ?? EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER,
        `"${tags.map((tag) => tag.text).join(',')}"`,
        `"${subtasks ? subtasks.map((subtask) => subtask.name).join(',') : EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER}"`,
        due_date
          ? moment(due_date).format('MMM DD YYYY')
          : EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER,
        created_at
          ? moment(created_at).format('MMM DD YYYY')
          : EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER
      ].join(',')
    );
    return row;
  }, []);
  const encoded_data = [headers.join(','), ...contents].join('\n');
  if ([PLATFORMS.MAC, PLATFORMS.IOS].includes(platform)) {
    window.webkit.messageHandlers.download_todo_tasks.postMessage(encoded_data);
  } else {
    createCSVfile(encoded_data, FILENAME.TO_DO_TASKS_SUMMARY);
  }
};

const createCSVfile = (encoded_data: string, filename: string) => {
  const url = window.URL.createObjectURL(
    new Blob([encoded_data], { type: 'text/csv' })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const escapeCommas = (value?: string) =>
  value ? value.replaceAll(',', '/,') : '';
