import { useContext } from 'react';
import { useAppSelector } from 'store/hooks';
import { courseLessonsModalSelector } from 'store/reducer/course/selectors';
import { CourseLessonsModalContext } from '.';
import CourseLessonItem from './CourseLessonItem';
import CourseModalActions from './CourseModalActions';
import CourseSpinner from './CourseSpinner';
import CourseSummary from './CourseSummary';

const CourseLessonsSlider = () => {
  const { lessonInfo } = useContext(CourseLessonsModalContext);
  const {
    selectedCourse,
    isCreatingLessonCompletion,
    isUpdatingCourseCompletion
  } = useAppSelector(courseLessonsModalSelector);
  const lessons = selectedCourse?.lessons ?? [];

  return (
    <div className='w-full h-full flex relative overflow-hidden'>
      {lessonInfo.isLastLesson ? (
        <CourseSummary />
      ) : (
        <>
          {lessons?.map((lesson) => (
            <CourseLessonItem key={lesson.id} lesson={lesson} />
          ))}
          <CourseModalActions />
        </>
      )}
      {(isCreatingLessonCompletion || isUpdatingCourseCompletion) && (
        <CourseSpinner />
      )}
    </div>
  );
};

export default CourseLessonsSlider;
