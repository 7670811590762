import COLOR from 'constants/color';
import { IconProps } from 'interfaces';

export default function GooglePlayLogo({
  fill = COLOR.WHITE,
  styles = 'w-4 md:w-5 h-auto'
}: IconProps) {
  return (
    <svg
      className={styles}
      fill={fill}
      version='1.1'
      id='Icons'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <g>
        <path d='M17,14.5l4.2-4.5L4.9,1.2C4.8,1.1,4.6,1.1,4.3,1L17,14.5z' />
        <path d='M23,21l5.9-3.2c0.7-0.4,1.1-1,1.1-1.8s-0.4-1.5-1.1-1.8L23,11l-4.7,5L23,21z' />
        <path d='M2.4,1.9C2.1,2.2,2,2.6,2,3V29c0,0.4,0.1,0.8,0.4,1.2L15.6,16L2.4,1.9z' />
        <path d='M17,17.5L4.3,31c0.2,0,0.4-0.1,0.6-0.2L21.2,22L17,17.5z' />
      </g>
    </svg>
  );
}
