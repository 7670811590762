import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import {
  changeShowChoice,
  changeShowDropProcessor,
  changeShowFreeStyle,
} from 'store/reducer/setting/slice';

const Questioner = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChoice = (choice: string) => {
    choice === 'no'
      ? dispatch(changeShowFreeStyle(true))
      : dispatch(changeShowDropProcessor(true));
    dispatch(changeShowChoice(false));
  };

  return (
    <div className='w-full h-full flex flex-col justify-center items-center gap-8'>
      <h1 className='px-3 sm:px-0 font-bold text-xl sm:text-2xl md:text-3xl'>
        {t('do_you_have_settings')}
      </h1>
      <div className='w-full flex items-center justify-center gap-14'>
        <button
          className='w-fit h-fit text-base sm:text-xl font-semibold px-8 sm:px-10 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md'
          onClick={() => handleChoice('yes')}
        >
          {t('yes')}
        </button>
        <button
          className='w-fit h-fit text-base sm:text-xl font-semibold px-8 sm:px-10 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md'
          onClick={() => handleChoice('no')}
        >
          {t('no')}
        </button>
      </div>
    </div>
  );
};

export default Questioner;
