import { useAppSelector } from 'store/hooks';
import { ACTIVITY_TYPES, PRIORITY_OPTION } from 'constants/general';
import Category from './Category';
import MoveActivity from './MoveActivity';
import CopyActivity from './CopyActivity';
import { Mode } from 'constants/enum';
import ImpactMeasurement from './ImpactMeasurement';
import Tutorials from './Tutorial';
import ActivityCutoffTime from 'components/setting-generator/tabs/MoreOptions/Advanced/ActivityCutoffTime';

const Advanced = () => {
  const {
    setting: {
      moreOptions: { type },
      mode
    },
    modal: {
      moreOption: { activity, isNewActivity }
    },
    user: { isUserAdmin }
  } = useAppSelector((state) => state);
  const shouldIncludeRoutineAndStandalonePack =
    mode === Mode.ROUTINE || mode === Mode.STANDALONE;
  const isActivityFromLibrary = type === ACTIVITY_TYPES.LIBRARY;
  const shouldShowCutoffTime =
    type === ACTIVITY_TYPES.EVENING &&
    activity.priority === PRIORITY_OPTION.HIGH;

  return (
    <div className='w-full flex flex-wrap p-2 gap-8'>
      <div className='w-full flex flex-wrap-reverse gap-5 sm:gap-x-10 sm:gap-y-5'>
        {shouldShowCutoffTime && <ActivityCutoffTime />}
        {!(
          isNewActivity ||
          [ACTIVITY_TYPES.LIBRARY, ACTIVITY_TYPES.BREAKING].includes(type)
        ) && <Tutorials />}
        {type !== ACTIVITY_TYPES.LIBRARY && activity.name && <CopyActivity />}
        {isUserAdmin && shouldIncludeRoutineAndStandalonePack && (
          <ImpactMeasurement />
        )}
        {isActivityFromLibrary ? <Category /> : null}
      </div>
      <div className='w-full flex flex-col md:flex-row gap-2 md:gap-8'>
        {!isActivityFromLibrary ? <MoveActivity /> : null}
      </div>
    </div>
  );
};

export default Advanced;
