import { MODAL_TYPES, NUMBERS } from 'constants/general';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateIsHabitPackPdfShowed } from 'store/reducer//habit-pack/slice';

interface Props {
  children: React.ReactNode;
  zIndex?: string;
  setIsPdfShown?: (arg: boolean) => void;
  styles?: string;
  modalType?: string;
}

const ModalOverlay = ({
  children,
  zIndex = 'z-40',
  setIsPdfShown,
  styles = 'w-full h-full bg-gray-600/70',
  modalType,
}: Props) => {
  const dispatch = useAppDispatch();
  return (
    <div
      role='button'
      onClick={() => {
        if (modalType && modalType === MODAL_TYPES.PDF) {
          dispatch(updateIsHabitPackPdfShowed(false));
        } else if (setIsPdfShown) {
          setIsPdfShown(false);
        }
      }}
      className={`fixed inset-0 ${styles} ${zIndex} cursor-default`}
      onKeyDown={() => {
        //Visible, non-interactive elements with click handlers must have at least one keyboard listener
      }}
      tabIndex={NUMBERS.ZERO}
    >
      {children}
    </div>
  );
};

export default ModalOverlay;
