import MobileNavigation from '../layout/MobileNavigation';
import DesktopNavigation from '../layout/DesktopNavigation';
import { SCREEN } from 'constants/general';
import { useContext, createContext, useMemo } from 'react';
import { LayoutContext } from '../layout';
import { useAppSelector } from 'store/hooks';
import { NavBarMenuOption } from 'interfaces';
import { getAccountDropDownMenus } from 'utils/support';

export const NavBarContext = createContext<{
  accountMenuOptions: NavBarMenuOption[];
  extraMenuOptions: NavBarMenuOption[];
}>({ accountMenuOptions: [], extraMenuOptions: [] });

const NavBar = () => {
  const {
    user: { subscriptionInfo },
    setting: { language }
  } = useAppSelector((state) => state);
  const { width } = useContext(LayoutContext);
  const { accountMenuOptions, extraMenuOptions } = useMemo(
    () => getAccountDropDownMenus(subscriptionInfo?.activeEntitlements ?? []),
    [subscriptionInfo, language]
  );

  return (
    <NavBarContext.Provider
      value={useMemo(
        () => ({ accountMenuOptions, extraMenuOptions }),
        [subscriptionInfo, language]
      )}
    >
      {width >= SCREEN.WIDTH.LARGE ? (
        <DesktopNavigation />
      ) : (
        <MobileNavigation />
      )}
    </NavBarContext.Provider>
  );
};

export default NavBar;
