import { useTranslation } from 'react-i18next';
import { HabitPackType } from 'interfaces/commonInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCurrentViewedPack } from 'store/reducer//habit-pack/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import CloneHabitPackButton from '../dashboard/marketplace/CloneHabitPackButton';
import { NUMBERS } from 'constants/general';
import { useAuth0 } from '@auth0/auth0-react';
import { THEME_OPTION } from 'constants/enum';

export interface HabitPackCardProps {
  habitPack: HabitPackType;
}

const Wrapper = ({
  habitPack,
  children
}: {
  habitPack: HabitPackType;
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const { isOnHabitPacksTab } = useAppSelector((state) => state.habitPack);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  return (
    <div
      role='button'
      onClick={() => {
        const pathname = `/habit-packs/${habitPack.id}`;
        if (isOnHabitPacksTab) {
          dispatch(setCurrentViewedPack(habitPack));
        } else {
          isAuthenticated
            ? navigate(pathname)
            : (window.location.pathname = pathname);
        }
      }}
      onKeyDown={() => {
        //Visible, non-interactive elements with click handlers must have at least one keyboard listener
      }}
      className='w-full h-fit rounded-lg shadow-md cursor-pointer border-t border-r border-l border-gray-200 relative'
      tabIndex={NUMBERS.ZERO}
    >
      {children}
    </div>
  );
};

export default function HabitPackCard({ habitPack }: HabitPackCardProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { themeMode } = useAppSelector((state) => state.setting);

  return (
    <Wrapper habitPack={habitPack}>
      {pathname.includes(ROUTES.MY_PACKS_AND_FOCUS_TEMPLATES) && (
        <CloneHabitPackButton habitPack={habitPack} />
      )}
      <p
        className={`w-full flex pl-2 pr-10 py-1 text-left capitalize ${
          themeMode === THEME_OPTION.DARK ? 'bg-gray-700' : 'bg-white'
        }  rounded-t-lg text-xs sm:text-sm line-clamp-1`}
      >
        {habitPack?.pack_name ?? ''}
      </p>
      <p
        className={`w-full flex items-center gap-1.5 rounded-b-md px-2 py-2 ${
          themeMode === THEME_OPTION.DARK ? 'bg-gray-800' : 'bg-gray-300'
        } border-black-300 text-xs`}
      >
        {t('market.made_by')}
        <span className='font-semibold truncate'>
          {habitPack?.creator_name ?? ''}
        </span>
      </p>
    </Wrapper>
  );
}
