import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import {
  DESKTOP_APP_CLOSE,
  MAC_APP_DATA_LOCATION,
  PLATFORMS
} from 'constants/general';
import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { pageActionsSelector } from 'store/reducer/to-do/selectors';
import { updateIsTaskSubmitting } from 'store/reducer/to-do/slice';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { ToDoContext } from '..';

const TasksPageActions = () => {
  const dispatch = useAppDispatch();
  const { isTaskSubmitting, tasks, selectedTaskIDs, platform } =
    useAppSelector(pageActionsSelector);
  const { shouldEmbedTodoList } = useContext(ToDoContext);

  return shouldEmbedTodoList ? (
    <button
      disabled={isTaskSubmitting}
      className={`absolute top-2 lg:top-1  ${
        platform === PLATFORMS.MAC ? 'left-2 lg:left-1' : 'right-2 lg:right-1'
      } z-10`}
      onClick={() => {
        dispatch(updateIsTaskSubmitting(true));
        const selectedTasks = tasks.filter((task) =>
          selectedTaskIDs.includes(task.id)
        );
        sendDataToPlatform(
          platform,
          selectedTasks?.length
            ? selectedTasks
            : DESKTOP_APP_CLOSE.TO_DO_TASK_UI,
          MAC_APP_DATA_LOCATION.CLOSE_TODO_TASK_UI
        );
      }}
    >
      <CloseCircle fill={isTaskSubmitting ? COLOR.GRAY_4OO : COLOR.GRAY_600} />
    </button>
  ) : null;
};

export default TasksPageActions;
