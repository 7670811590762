import Endpoints from 'constants/endpoints';
import { INTEGRATION_PLATFORM } from 'constants/general';

export const getPlatformEndpoint = (platform: string | undefined) => {
  switch (platform) {
    case INTEGRATION_PLATFORM.CLICK_UP.toLowerCase():
      return Endpoints.CLICK_UP_AUTH;
    case INTEGRATION_PLATFORM.ZOHO.toLowerCase():
      return Endpoints.ZOHO_AUTH;
    case INTEGRATION_PLATFORM.ASANA.toLowerCase():
      return Endpoints.ASANA_AUTH;
    case INTEGRATION_PLATFORM.JIRA.toLowerCase():
      return Endpoints.JIRA_AUTH;
    case INTEGRATION_PLATFORM.TRELLO.toLowerCase():
      return Endpoints.TRELLO_AUTH;
    case INTEGRATION_PLATFORM.MONDAY.toLowerCase():
      return Endpoints.MONDAY_AUTH;
    case INTEGRATION_PLATFORM.GOOGLE.toLowerCase():
      return Endpoints.GOOGLE_AUTH;
    case INTEGRATION_PLATFORM.MICROSOFT.toLowerCase():
      return Endpoints.MICROSOFT_AUTH;
    default:
      return null;
  }
};
