export const STATS = {
  DASHBOARD_TABS: [
    { id: '0', value: 'stats_page.routines_blocks' },
    { id: '1', value: 'stats_page.goals' },
    { id: '2', value: 'stats_page.leader_board' },
    { id: '3', value: 'stats_page.focus_summary' },
    { id: '5', value: 'stats_page.activity_stats' }
  ],
  EMBEDDED_TABS: [
    { id: '4', value: 'stats_page.routines_blocks' },
    { id: '5', value: 'stats_page.goals' },
    { id: '6', value: 'stats_page.get_motivated' },
    { id: '7', value: 'stats_page.leader_board' },
    { id: '8', value: 'stats_page.focus_summary' },
    { id: '9', value: 'stats_page.activity_stats' }
  ],
  FOCUS_SUMMARY: {
    WEEKLY: {
      COLUMN: [
        'weekly_focus_block_summary.name',
        'weekly_focus_block_summary.duration',
        'weekly_focus_block_summary.start_time',
        'weekly_focus_block_summary.finish_time',
        'weekly_focus_block_summary.intention',
        'weekly_focus_block_summary.achievements',
        'weekly_focus_block_summary.distractions',
        ''
      ],
      KEY: [
        'focus_mode.name',
        'focus_duration_seconds',
        'start_time',
        'finish_time',
        'intention',
        'achievements',
        'distractions'
      ]
    }
  }
};

export const LEADER_BOARD = {
  COLUMNS: [
    'leader_board.rank',
    'leader_board.name',
    'leader_board.morning_streak',
    'leader_board.evening_streak',
    'leader_board.focus_mode'
  ],
  SORT: ['leader_board.morning_streak', 'leader_board.evening_streak'],
  STAT_LIMIT: 20
};

export const ADMIN_SURVEY = {
  COLUMNS: ['admin.question', 'admin.category', 'admin.choices'],
  SORT: []
};
