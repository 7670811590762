import { store } from 'store';
import { updateYouTubeURLMetadata } from 'store/reducer/cache/slice';
import * as Sentry from '@sentry/react';

export const CACHE_NAME = 'focusBear';
export const CACHE_CATEGORY = {
  YOUTUBE_URL: 'youtube_url'
};

export const addYouTubeURLMetaDataIntoCache = (
  cacheCategory: string,
  response: Response
) => {
  if ('caches' in window) {
    caches.open(CACHE_NAME).then((cache) => {
      cache.put(cacheCategory, response);
    });
  }
};

export const getYouTubeURLMetaCacheData = async () => {
  try {
    await caches
      .match(CACHE_CATEGORY.YOUTUBE_URL)
      .then((response) => response?.json())
      .then((data) => {
        if (data) {
          store.dispatch(updateYouTubeURLMetadata(data));
        }
      });
  } catch (error) {
    Sentry.captureException(JSON.stringify(error));
  }
};
