import { MouseEvent } from 'react';

interface Props {
  label: string;
  onClickButton?: (event: MouseEvent<HTMLButtonElement>) => void;
  isButtonDisabled?: boolean;
  style?: string;
}

const ConfirmButton = ({
  label,
  onClickButton,
  isButtonDisabled = false,
  style = 'w-full sm:w-fit bg-black hover:bg-orange-400 disabled:bg-gray-500 disabled:hover:bg-gray-500 transition-all text-white text-xs sm:text-sm md:text-base lg:text-xl rounded-lg px-2 sm:px-4 md:px-6 py-2 outline-none shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)]'
}: Props) => {
  return (
    <button
      onClick={onClickButton}
      disabled={isButtonDisabled}
      className={style}
    >
      {label}
    </button>
  );
};

export default ConfirmButton;
