import PlusCircle from 'assets/icons/PlusCircle';
import COLOR from 'constants/color';
import { DetailedHTMLProps, ButtonHTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { newHabit } from 'store/reducer/setting/slice';
import { getNewHabitProps } from 'utils/support';
import _ from 'lodash';
import { addHabitButtonSelector } from 'store/reducer/setting/selectors';
import { t } from 'i18next';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';

interface AddHabitButtonForNonEmptyRoutineProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  routine: string;
  styles?: string;
}

const AddHabitButtonForNonEmptyRoutine: React.FC<
  AddHabitButtonForNonEmptyRoutineProps
> = ({
  routine,
  styles = 'bg-focusBearText/60 hover:scale-105 duration-150 ease-in-out shadow-md',
  ...rest
}: AddHabitButtonForNonEmptyRoutineProps) => {
  const dispatch = useAppDispatch();
  const { tabs, currentSettings, activeTabIndex, customRoutine, routines } =
    useAppSelector(addHabitButtonSelector);

  const [activity_type, sequence_id] = useMemo(
    () =>
      getNewHabitProps(
        tabs,
        activeTabIndex,
        _.cloneDeep(currentSettings),
        customRoutine,
        routines
      ),
    [tabs, activeTabIndex, currentSettings, customRoutine, routines]
  );

  return (
    <ButtonFocusBear
      onClick={() =>
        dispatch(
          newHabit({
            type: activity_type,
            sequence_id
          })
        )
      }
      title={t('add_a_habit')}
      additionalStyles='shadow-md'
    />
  );
};

export default AddHabitButtonForNonEmptyRoutine;
