import { TextEditorWithLabelProps } from 'interfaces';
import TextEditor from 'components/common/TextEditor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpFilled from 'assets/icons/HelpFilled';
import Tooltip from './Tooltip';
import { RICH_TEXT_EDITOR_HEIGHT } from 'constants/general';

export default function RichTextEditorWithLabel({
  label,
  tooltip,
  handleChange,
  value,
  required = false
}: TextEditorWithLabelProps) {
  const { t } = useTranslation();
  const [hasTextLimitBeenReached, setHasTextLimitBeenReached] = useState(false);

  return (
    <>
      <div className='flex'>
        <h6 className='text-xs pb-0.5 text-gray-600 font-semibold mr-2'>
          {label}
        </h6>
        <Tooltip message={tooltip} icon={<HelpFilled />} />
        {hasTextLimitBeenReached && (
          <p className='text-red-400 text-xs ml-2'>{t('text_limit_reached')}</p>
        )}
      </div>
      <TextEditor
        textMarkup={value}
        onTextChange={handleChange}
        setHasTextLimitBeenReached={setHasTextLimitBeenReached}
        required={required}
        height={RICH_TEXT_EDITOR_HEIGHT.ROUTINE}
      />
    </>
  );
}
