import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INQUIRY_TYPE } from 'constants/general';
import { initialInquiryState } from 'store/initial-states';

const inquiry = createSlice({
  name: 'inquiry',
  initialState: initialInquiryState,
  reducers: {
    updateIsInquiryModalShown: (state, { payload }: PayloadAction<boolean>) => {
      state.is_inquiry_modal_shown = payload;
    },
    updateInquiry: (
      state,
      {
        payload: { type, value },
      }: PayloadAction<{ type: string; value: boolean }>
    ) => {
      switch (type) {
        case INQUIRY_TYPE.DESKTOP:
          state.installation_path.desktop = value;
          break;
        case INQUIRY_TYPE.MOBILE:
          state.installation_path.mobile = value;
          break;
        case INQUIRY_TYPE.CALENDAR:
          state.reminder.calendar = value;
          break;
        case INQUIRY_TYPE.DESKTOP_APP:
          state.reminder.desktop_app = value;
      }
    },
  },
});

export const { updateIsInquiryModalShown, updateInquiry } = inquiry.actions;

export default inquiry.reducer;
