import * as Sentry from '@sentry/react';
import { DEFAULT_PLAYER_CONFIG } from 'assets/data';
import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import PlayerContents from 'components/todo-player/PlayerContents';
import { POST_HOG_EVENT } from 'constants/events';
import {
  DEFAULT_TO_DO_PLAYER_TOAST_AUTO_CLOSE,
  LOCAL_STORAGE,
  MAC_APP_DATA_LOCATION,
  NUMBER_OF_TOAST_VISIBLE,
  PLATFORMS
} from 'constants/general';
import { PlayerDataType } from 'interfaces';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ToastContainer } from 'react-toastify';
import { addAccessTokenToInterceptor } from 'services/axios-config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsTokenAddedToInterceptor } from 'store/reducer/setting/slice';
import { getRecentToDos } from 'store/reducer/to-do/extra';
import { todoPlayerSelector } from 'store/reducer/to-do/selectors';
import {
  updatePlayerIsReadyToSendUpdates,
  updatePlayerTasks
} from 'store/reducer/to-do/slice';
import { getUserDetails } from 'store/reducer/user/extra';
import { captureEvents } from 'utils/events';
import {
  getToDosFromPlayer,
  loadMetaDataForToDoPlayer,
  sendDataToPlatform,
  stopToDoPlayer
} from 'utils/focusBear_apps';
import { getLocalStorage, updateLocalStorage } from 'utils/support';

export const ToDoPlayerContext = createContext<{
  playerData: PlayerDataType;
  setPlayerData?: Dispatch<SetStateAction<PlayerDataType>>;
}>({ playerData: DEFAULT_PLAYER_CONFIG });

const ToDoPlayer = () => {
  const dispatch = useAppDispatch();
  const [playerData, setPlayerData] = useState<PlayerDataType>(
    DEFAULT_PLAYER_CONFIG
  );
  const { isTokenAddedToInterceptor, platform, player, userId, userEmail } =
    useAppSelector(todoPlayerSelector);

  useEffect(() => {
    const token = getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN);
    if (token) {
      addAccessTokenToInterceptor();
      dispatch(updateIsTokenAddedToInterceptor(true));
    }
    loadMetaDataForToDoPlayer();
    stopToDoPlayer();
  }, []);

  useEffect(() => {
    getToDosFromPlayer();
  }, [player.tasks]);

  useEffect(() => {
    if (player.isReadyToSendUpdates) {
      sendDataToPlatform(
        platform,
        platform === PLATFORMS.MAC
          ? [...player.tasks]
          : { tasks: [...player.tasks] },
        MAC_APP_DATA_LOCATION.SEND_NEW_TASK_FROM_TO_DO_PLAYER
      );
      updateLocalStorage(
        LOCAL_STORAGE.TO_DO_PLAYER_TASKS,
        JSON.stringify(player.tasks)
      );
      dispatch(updatePlayerIsReadyToSendUpdates(false));
    }
  }, [player.isReadyToSendUpdates]);

  useEffect(() => {
    isTokenAddedToInterceptor &&
      Promise.allSettled([
        dispatch(
          getRecentToDos(moment().subtract(7, 'days').format('YYYY-MM-DD'))
        ),
        dispatch(getUserDetails())
      ]);
  }, [isTokenAddedToInterceptor]);

  useEffect(() => {
    isTokenAddedToInterceptor &&
      playerData.isPlayingStarted &&
      captureEvents(
        POST_HOG_EVENT.TODO_PLAYER_TASK_PLAY_BUTTON_CLICKED,
        userEmail,
        { userId }
      );
  }, [isTokenAddedToInterceptor, playerData.isPlayingStarted]);

  useEffect(() => {
    calculateFocusDuration();
  }, [playerData.isPlaying, playerData.counter]);

  const calculateFocusDuration = () => {
    if (playerData.isPlayingStarted) {
      const latestTodoTasks = player.tasks?.map((task) => {
        const focusedDuration =
          task.focusedDuration ?? DEFAULT_PLAYER_CONFIG.counter;
        return task.id === player.selectedTask?.id
          ? {
              ...task,
              focusedDuration: focusedDuration + 1
            }
          : task;
      });
      /****
       * @Issue: "dispatch(updateTasks(latestTodoTasks))" will cause a lot of re-render
       * @Solution:
       *  updateLocalStorage(LOCAL_STORAGE.TO_DO_PLAYER_TASKS,JSON.stringify(latestTodoTasks));
       *  call window.getToDosFromPlayer() when the desktop form close event called
       * ***/
      dispatch(updatePlayerTasks(latestTodoTasks));
      dispatch(updatePlayerIsReadyToSendUpdates(true));
    }
  };

  return (
    <ToDoPlayerContext.Provider
      value={useMemo(() => ({ playerData, setPlayerData }), [playerData])}
    >
      {isTokenAddedToInterceptor ? (
        <PlayerContents />
      ) : (
        <InitEmbeddedServices />
      )}
      <ToastContainer
        limit={NUMBER_OF_TOAST_VISIBLE}
        draggable={false}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        autoClose={DEFAULT_TO_DO_PLAYER_TOAST_AUTO_CLOSE}
      />
    </ToDoPlayerContext.Provider>
  );
};

export default Sentry.withProfiler(ToDoPlayer);
