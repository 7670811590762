import Endpoints from 'constants/endpoints';
import focusBearApi from './axios-config';
import { Uploader } from 'uploader';

export const uploader = Uploader({
  apiKey: process.env.REACT_APP_UPLOAD_IO_API_KEY!,
});

export const deleteActivityImage = async (filePath: string) => {
  const response = await focusBearApi.delete(
    Endpoints.DELETE_ACTIVITY_IMAGE.replace('{path_file}', filePath)
  );
  return response;
};
