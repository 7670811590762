import React from 'react';

interface Props {
  children: React.ReactNode;
}

const PackAndFocusModeInfoRightContainer = ({ children }: Props) => (
  <div className='sm:w-1/2 w-full flex flex-col justify-end gap-1'>
    {children}
  </div>
);

export default PackAndFocusModeInfoRightContainer;
