import Edit from 'assets/icons/Edit';
import classNames from 'classnames';
import { TO_DO_STATUS } from 'constants/enum';
import { EISENHOWER_QUADRANT } from 'constants/general';
import { t } from 'i18next';
import { useContext } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateTodoSubTasks } from 'store/reducer/modal/slice';
import {
  updateEditing,
  updateNewTask,
  updateShouldUpdateTextEditorContent,
  updateShowEmbeddedCreateToDoModal,
  updateShowTodoSubTasksModal
} from 'store/reducer/to-do/slice';
import { createHtml } from 'utils/support';
import { TaskItemContext } from '.';
import { ToDoContext } from '../..';

const TaskItemBody = () => {
  const {
    taskItemState: { task, showTaskContents }
  } = useContext(TaskItemContext);
  const dispatch = useAppDispatch();
  const { shouldEmbedTodoList } = useContext(ToDoContext);

  const handleTaskUpdate = () => {
    if (task) {
      dispatch(updateEditing({ active: true, task }));
      shouldEmbedTodoList
        ? dispatch(updateShowEmbeddedCreateToDoModal(true))
        : dispatch(updateShouldUpdateTextEditorContent(true));
      dispatch(
        updateTodoSubTasks({
          title: task.title,
          subTasks: task.subtasks ?? [],
          isNewTask: false,
          taskId: task.id
        })
      );
      dispatch(updateNewTask(task));
    }
  };

  const handleViewSubTasks = () => {
    if (task) {
      dispatch(
        updateTodoSubTasks({
          title: task.title,
          subTasks: task.subtasks ?? [],
          isNewTask: false,
          taskId: task.id
        })
      );
      dispatch(updateShowTodoSubTasksModal(true));
    }
  };

  return (
    <div
      className={`w-full flex flex-col justify-between pl-3 ${
        showTaskContents ? 'h-fit max-h-[9rem]' : 'h-0 overflow-hidden'
      } transition-[height] ease-in duration-100`}
    >
      <div
        className={classNames(
          'w-full text-xs md:text-sm my-2 h-fit max-h-24 overflow-y-auto scrollbar-thin pt-1 lg:pt-2',
          {
            'scrollbar-thumb-red-200':
              String(task?.eisenhower_quadrant) === EISENHOWER_QUADRANT.DO,
            'scrollbar-thumb-orange-200':
              String(task?.eisenhower_quadrant) === EISENHOWER_QUADRANT.DECIDE,
            'scrollbar-thumb-green-200':
              String(task?.eisenhower_quadrant) ===
              EISENHOWER_QUADRANT.DELEGATE,
            'scrollbar-thumb-blue-200':
              String(task?.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELETE
          }
        )}
      >
        {task?.objective ? (
          <>
            <p className='text-sm italic'>
              {t('to_do_procrastinate.objective')}
            </p>
            {createHtml(task.objective)}
          </>
        ) : null}
        <p className='text-sm italic mt-2.5'>
          {t('to_do_procrastinate.description')}
        </p>
        {createHtml(task?.details)}
      </div>
      <div className='w-full flex items-center justify-end gap-2 mt-2'>
        {task?.title && task?.subtasks?.length ? (
          <button
            onClick={handleViewSubTasks}
            className='w-fit h-fit px-2 py-1 rounded-md text-xs bg-gray-500 hover:bg-gray-600 animate-none text-white'
          >
            {t('to_do_procrastinate.view_sub_tasks')}
          </button>
        ) : null}
        {task?.status !== TO_DO_STATUS.COMPLETED && (
          <button onClick={handleTaskUpdate} className='w-fit h-fit z-50'>
            <Edit />
          </button>
        )}
      </div>
    </div>
  );
};

export default TaskItemBody;
