import {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalHeader from 'components/shared/ModalHeader';
import { ModalProps } from 'interfaces';
import InputCheckItem from './InputCheckItem';
import { EMPTY_STRING } from 'constants/general';
import _ from 'lodash';
import { ATTRIB } from 'constants/test';
import {
  updateMoreOptionCheckLists,
  updateMoreOptionShowCheckListModal
} from 'store/reducer/modal/slice';
import { THEME_OPTION } from 'constants/enum';

interface WrapperProps {
  children: ReactNode;
  todoItems: string[];
}

const CheckListContext = createContext<{
  checkLists: { id: number; value: string }[];
  setCheckLists?: Dispatch<SetStateAction<{ id: number; value: string }[]>>;
}>({ checkLists: [] });

const Wrapper = ({ todoItems, children }: WrapperProps) => {
  const [checkLists, setCheckLists] = useState(
    todoItems.map((value, index) => ({ id: index, value }))
  );
  const { themeMode } = useAppSelector((state) => state.setting);

  return (
    <div
      className={`relative top-[40%] -translate-y-[40%] mx-auto w-[95%] sm:w-3/5 md:w-2/5 shadow-lg ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-800' : 'bg-gray-200'
      } flex flex-col gap-2 md:gap-4 rounded-md`}
    >
      <CheckListContext.Provider
        value={useMemo(() => ({ checkLists, setCheckLists }), [checkLists])}
      >
        {children}
      </CheckListContext.Provider>
    </div>
  );
};

const ModalActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const { checkLists, setCheckLists } = useContext(CheckListContext);

  const shouldURLsEdited = () => {
    const oldCheckList: string[] = activity?.check_list ?? [];
    return !_.isEqual(oldCheckList, checkLists);
  };

  const shouldURLsNotValid = () =>
    checkLists.some((item) => item.value === EMPTY_STRING);

  return (
    <div className='w-fit h-fit flex items-center gap-2 self-end pr-3 pb-2'>
      <button
        data-test={ATTRIB.TEST.ADD_CHECK_LIST}
        onClick={() => {
          setCheckLists?.((prev) => [
            ...prev,
            { id: prev.length, value: EMPTY_STRING }
          ]);
        }}
        className='w-24 h-fit text-xs font-medium tracking-wider py-1.5 self-end rounded-md text-white mr-2 mb-2 bg-gray-500 hover:bg-gray-700 cursor-pointer transition ease-in-out delay-150 hover:scale-110  duration-300'
      >
        {t('add')}
      </button>
      {shouldURLsEdited() && (
        <button
          data-test={ATTRIB.TEST.SAVE_CHECK_LISTS}
          disabled={shouldURLsNotValid()}
          onClick={() => {
            dispatch(
              updateMoreOptionCheckLists(checkLists.map((item) => item.value))
            );
            dispatch(updateMoreOptionShowCheckListModal(false));
          }}
          className={`w-24 h-fit text-xs font-medium tracking-wider self-end rounded-md mr-2 mb-2 ${
            shouldURLsNotValid()
              ? 'py-1 border-2 border-gray-500 text-gray-500'
              : 'py-1.5 text-white bg-blue-600 hover:bg-blue-800 cursor-pointer transition ease-in-out delay-150 hover:scale-110 duration-300'
          }`}
        >
          {t('save')}
        </button>
      )}
    </div>
  );
};

const ModalBody = () => {
  const { t } = useTranslation();
  const { checkLists, setCheckLists } = useContext(CheckListContext);
  const dummyDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dummyDiv.current?.scrollIntoView({ behavior: 'smooth' });
  }, [checkLists.length]);

  const handleDelete = (position: number) => {
    setCheckLists?.((prev) =>
      prev.filter((_, index: number) => index !== position)
    );
  };

  const handleInputCheckItem = (position: number, value: string) => {
    setCheckLists?.((prev) =>
      prev.map((item, index) =>
        index === position ? { ...item, value } : item
      )
    );
  };

  return (
    <div className='w-full h-fit max-h-56 flex flex-col gap-2.5 px-4 py-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
      {!checkLists.length ? (
        <div className='w-full text-center py-4 font-medium text-blue-500 text-sm'>
          {t('no_check_list_found')}
        </div>
      ) : (
        checkLists.map((item, index: number) => (
          <InputCheckItem
            key={item.id}
            position={index}
            item={item.value}
            handleInputCheckItem={handleInputCheckItem}
            handleDelete={handleDelete}
          />
        ))
      )}
      <div className='float-left clear-both' ref={dummyDiv}></div>
    </div>
  );
};

const CheckListsModal = ({ modalType }: ModalProps) => {
  const { t } = useTranslation();
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const todoItems = activity?.check_list ?? [];

  return (
    <ModalOverlay zIndex='z-[100]'>
      <Wrapper todoItems={todoItems}>
        <ModalHeader title={t('check_list')} modalType={modalType} />
        <ModalBody />
        <ModalActions />
      </Wrapper>
    </ModalOverlay>
  );
};

export default CheckListsModal;
