import { useAppSelector } from 'store/hooks';
import { ACTIVITY_TYPES } from 'constants/general';
import ChoiceType from './ChoiceType';
import IsOfficeFriendly from './IsOfficeFriendly';

const Settings = () => {
  const {
    moreOptions: { type },
  } = useAppSelector((state) => state.setting);
  const shouldAllowedForMicroBreaks = type === ACTIVITY_TYPES.BREAKING;

  return (
    <div className='w-full sm:w-fit flex flex-wrap items-start gap-x-10 gap-y-5 py-2'>
      {shouldAllowedForMicroBreaks && <IsOfficeFriendly />}
      <ChoiceType />
    </div>
  );
};

export default Settings;
