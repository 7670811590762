import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

function Auth0ProviderWithHistory({
  children
}: {
  readonly children: JSX.Element;
}) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined) => {
    if (appState?.returnTo) {
      navigate(appState?.returnTo);
    }
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation='localstorage'
      scope='offline_access openid profile email'
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
