import { createAsyncThunk } from '@reduxjs/toolkit';
import { incrementGeneralCounter, updateShouldStopCounter } from './slice';
import { RootState } from 'store';
import { NUMBERS } from 'constants/general';

export const startGeneralCounter = createAsyncThunk(
  'general/start_general_counter',
  async (_, { dispatch, getState }) => {
    dispatch(updateShouldStopCounter(false));
    const intervalId = setInterval(() => {
      const {
        general: { counter: motivationalWaitingCounter, shouldStopCounter },
      } = getState() as RootState;
      dispatch(incrementGeneralCounter());
      if (shouldStopCounter || motivationalWaitingCounter >= NUMBERS.EIGHT) {
        clearInterval(intervalId);
      }
    }, 1000);
  }
);
