import InputTime from 'components/common/inputs/InputTime';
import { SCREEN } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cutoffTimeForNonHighPriorityActivitiesSelector } from 'store/reducer/setting/selectors';
import { updateCutoffTimeForNonHighPriority } from 'store/reducer/setting/slice';
import { useWindowSize } from 'usehooks-ts';

const CutoffTimeForNonHighPriorityActivities = () => {
  const dispatch = useAppDispatch();
  const {
    shutdown_time,
    evening_activities,
    cutoff_time_for_non_high_priority_activities
  } = useAppSelector(cutoffTimeForNonHighPriorityActivitiesSelector);
  const { width: screenWidth } = useWindowSize();

  const [advancedData, setAdvancedData] = useState({
    isCutoffTimeCollapsed: false,
    errorMessages: []
  });

  const validateCutoffTime = useCallback(() => {
    const invalidGlobalCutoffTime = moment(
      cutoff_time_for_non_high_priority_activities,
      'HH:mm'
    ).isSameOrBefore(moment(shutdown_time, 'HH:mm'));
    const foundInvalidActivityTime = (evening_activities ?? []).some(
      (activity) =>
        cutoff_time_for_non_high_priority_activities &&
        activity.cutoff_time_for_doing_activity
          ? moment(activity.cutoff_time_for_doing_activity, 'HH:mm').isBefore(
              moment(cutoff_time_for_non_high_priority_activities, 'HH:mm')
            )
          : false
    );
    if (invalidGlobalCutoffTime && foundInvalidActivityTime) {
      setAdvancedData((prev) => ({
        ...prev,
        errorMessages: [
          t('errors.cutoff_time_must_be_after_shutdown_time'),
          t(
            'errors.the_time_should_be_before_all_of_the_cutoff_times_for_evening_activities'
          )
        ]
      }));
    } else {
      invalidGlobalCutoffTime &&
        setAdvancedData((prev) => ({
          ...prev,
          errorMessages: [t('errors.cutoff_time_must_be_after_shutdown_time')]
        }));
      foundInvalidActivityTime &&
        setAdvancedData((prev) => ({
          ...prev,
          errorMessages: [
            t(
              'errors.the_time_should_be_before_all_of_the_cutoff_times_for_evening_activities'
            )
          ]
        }));
    }
    return invalidGlobalCutoffTime || foundInvalidActivityTime;
  }, [
    cutoff_time_for_non_high_priority_activities,
    evening_activities,
    shutdown_time
  ]);

  return (
    <InputTime
      selectedDate={cutoff_time_for_non_high_priority_activities ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateCutoffTimeForNonHighPriority(value));
      }}
      errorMessages={advancedData.errorMessages}
      validateTime={validateCutoffTime}
      label={t('what_time_do_you_get_off_tech_in_the_evening')}
      tooltipMessage={t(
        'explanation_cutoff_time_for_standard_priority_activities',
        {
          cutoff_time: cutoff_time_for_non_high_priority_activities
            ? moment(
                cutoff_time_for_non_high_priority_activities,
                'HH:mm'
              ).format('h:mm a')
            : t('start_winding_down_time')
        }
      )}
      wrapperStyles='w-full flex gap-x-10 gap-y-2 flex-wrap py-2'
      toolTipAlignment={screenWidth < SCREEN.WIDTH.SMALL ? 'right' : 'left'}
      labelStyles='text-sm xs:text-base sm:text-lg'
    />
  );
};

export default CutoffTimeForNonHighPriorityActivities;
