import { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ACTIVITY_TYPES, ACTIVITY_SEQUENCE_TYPE } from 'constants/general';
import { ROUTES } from 'constants/routes';
import { updateCompletedRoutines } from 'store/reducer/user/slice';

const RoutineAnalyzer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentActivityProps, isCurrentActivityPropsUpdating } =
    useAppSelector((state) => state.user);

  const isLastCompletedSequenceStartedFinishedToday =
    moment(currentActivityProps?.last_completed_sequence_started_at).isSame(
      moment(),
      'day'
    ) &&
    moment(currentActivityProps?.last_completed_sequence_at).isSame(
      moment(),
      'day'
    );

  const areAllRoutinesCompleted =
    currentActivityProps?.last_completed_sequence?.type ===
      ACTIVITY_SEQUENCE_TYPE.EVENING &&
    isLastCompletedSequenceStartedFinishedToday;

  useEffect(() => {
    checkCompletedRoutines();
  }, [currentActivityProps]);

  // we need only check whether today's evening routine has been completed
  const checkCompletedRoutines = useCallback(() => {
    if (areAllRoutinesCompleted) {
      dispatch(
        updateCompletedRoutines([
          ACTIVITY_TYPES.MORNING,
          ACTIVITY_TYPES.BREAKING,
          ACTIVITY_TYPES.EVENING,
        ])
      );
    }
  }, [currentActivityProps]);

  return (
    <button
      onClick={() => {
        navigate(ROUTES.ROUTINE_PLAYER);
      }}
      disabled={areAllRoutinesCompleted}
      className='w-fit h-fit text-sm sm:text-lg font-medium px-4 py-2 rounded-md text-orange-400 bg-orange-100 hover:bg-orange-500 hover:text-white'
    >
      {isCurrentActivityPropsUpdating && (
        <div className='w-4 h-4 my-1.5 rounded-full border-t border-orange-400 animate-spin mx-auto'></div>
      )}
      {!isCurrentActivityPropsUpdating &&
        (!areAllRoutinesCompleted
          ? t('routine_player.next_activity')
          : t('routine_player.all_routines_completed'))}
    </button>
  );
};

export default RoutineAnalyzer;
