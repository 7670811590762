import { Mode } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import {
  changeShowFreeStyle,
  changeShowTabs,
  configureTabs,
  setGeneratorMode,
} from 'store/reducer/setting/slice';

const FreeStyle = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleFreeStyleChoice = (mode?: Mode) => {
    if (mode) {
      dispatch(setGeneratorMode(mode));
      dispatch(configureTabs({ mode }));
    }
    dispatch(changeShowTabs(true));
    dispatch(changeShowFreeStyle(false));
  };

  return (
    <div className='w-full h-full flex flex-col justify-center items-center gap-8'>
      <h1 className='w-full sm:w-11/12 px-3 sm:px-0 font-bold text-xl sm:text-2xl md:text-3xl'>
        {t('do_you_wanna_go_freestyle')}
      </h1>
      <div className='w-full flex items-center justify-center gap-14'>
        <button
          className='w-1/2 sm:w-2/5 text-xs sm:text-base font-normal sm:font-semibold p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md'
          onClick={() => handleFreeStyleChoice()}
        >
          {t('choice_healthy')}
        </button>
        <button
          className='w-1/2 sm:w-1/4 text-xs sm:text-base font-normal sm:font-semibold p-2 bg-orange-500 hover:bg-orange-600 text-white rounded-md'
          onClick={() => handleFreeStyleChoice(Mode.FREESTYLE)}
        >
          {t('choice_freestyle')}
        </button>
      </div>
    </div>
  );
};

export default FreeStyle;
