import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { DecodedTokenDataType, SubscriberType } from 'interfaces';
import { Entitlement } from 'constants/enum';
import focusBearApi from './axios-config';
import * as Sentry from '@sentry/react';
import { HTTP_STATS_CODE, LOCAL_STORAGE } from 'constants/general';
import { SUBSCRIPTION_INFO_KEY } from 'constants/routes';
import { getLocalStorage } from 'utils/support';
import Endpoints from 'constants/endpoints';
import { store } from 'store';
import {
  updateIsCancelingSubscription,
  updateShowCancelReasonModal
} from 'store/reducer/user/slice';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { getUserSubscriptionInfo } from 'store/reducer/user/extra';

const NO_PLAN = 'no_plan';

export const getStripeCheckoutURL = async (
  price_id: string,
  team_name?: string
) => {
  const response = await focusBearApi.post(
    Endpoints.STRIPE.CREATE_CHECKOUT_SESSION,
    { price_id, team_name }
  );
  window.location.href = response.data.url;
};

export const getSubscriptionManagementPortal = async () => {
  const response = await focusBearApi.post(
    Endpoints.STRIPE.CREATE_PORTAL_SESSION
  );
  window.location.href = response.data.url;
};

const getRevenueCatSubscriber = async (
  decodedToken: DecodedTokenDataType,
  RCStripeApiKey: string
) => {
  const app_user_id = decodedToken[SUBSCRIPTION_INFO_KEY].id;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'X-Platform': 'web',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${RCStripeApiKey}`,
      'Cache-Control': 'private, max-age=86400, must-revalidate' //@Description: 1 day == 86400 seconds
    }
  };

  try {
    const res = await axios.get(
      `${Endpoints.REVENUE_CAT}${app_user_id}`,
      options
    );

    return res.data.subscriber;
  } catch (error) {
    Sentry.captureException(error);
  }
};

const getUserPlan = (entitlementKeysArray: string[]) => {
  if (entitlementKeysArray.includes(Entitlement.TEAM_OWNER)) {
    return Entitlement.TEAM_OWNER;
  } else if (entitlementKeysArray.includes(Entitlement.PERSONAL)) {
    return Entitlement.PERSONAL;
  } else if (entitlementKeysArray.includes(Entitlement.TEAM_MEMBER)) {
    return Entitlement.TEAM_MEMBER;
  } else if (entitlementKeysArray.includes(Entitlement.TRIAL)) {
    return Entitlement.TRIAL;
  } else {
    return NO_PLAN;
  }
};

export const getSubscriptionInfo = async () => {
  const token = getLocalStorage(LOCAL_STORAGE.BEARER_TOKEN);
  if (!token) {
    throw new Error(
      "Can't fetch subscription info because there is no access token"
    );
  } else {
    const decodedToken: DecodedTokenDataType = await jwt_decode(token);
    const RCResponse: SubscriberType = await getRevenueCatSubscriber(
      decodedToken,
      process.env.REACT_APP_RC_STRIPE_API_KEY as string
    );
    const userId = decodedToken[SUBSCRIPTION_INFO_KEY].id;
    const stripeCusId = decodedToken[SUBSCRIPTION_INFO_KEY].stripeCustomerId;
    const userHasActiveSubscription =
      decodedToken[SUBSCRIPTION_INFO_KEY].subscriptionStatus
        .hasActiveSubscription;
    const entitlementsArray =
      decodedToken[SUBSCRIPTION_INFO_KEY].subscriptionStatus
        ?.activeEntitlements ?? [];
    const entitlements = RCResponse.entitlements;

    if (!RCResponse.entitlements) {
      throw new Error('Could not fetch subscription info from RevenueCat API.');
    }

    if (userHasActiveSubscription) {
      const userPlan = getUserPlan(entitlementsArray);
      const prodId = RCResponse.entitlements[userPlan]?.product_identifier;

      return {
        daysLeftInTrial:
          decodedToken[SUBSCRIPTION_INFO_KEY].subscriptionStatus.expirations[
            userPlan
          ]?.days_left,
        planType: userPlan,
        store: RCResponse?.subscriptions[prodId]?.store,
        hasActiveSubscription: true,
        unsubscribeDetectedAt:
          decodedToken[SUBSCRIPTION_INFO_KEY].subscriptionStatus.expirations[
            userPlan
          ]?.expires_date,
        stripeCustomerId: stripeCusId,
        userId: userId,
        activeEntitlements: entitlementsArray,
        entitlements
      };
    } else {
      return {
        daysLeftInTrial: 0,
        planType: 'no_plan',
        store: 'stripe',
        hasActiveSubscription: false,
        unsubscribeDetectedAt: null,
        stripeCustomerId: stripeCusId,
        userId: userId,
        activeEntitlements: entitlementsArray,
        entitlements
      };
    }
  }
};

export const cancelSubscription = async ({
  cancel_subscription_reason,
  entitlement_id
}: {
  cancel_subscription_reason: string;
  entitlement_id: string;
}) => {
  try {
    store.dispatch(updateIsCancelingSubscription(true));
    const { status } = await focusBearApi.post(
      Endpoints.STRIPE.CANCEL_SUBSCRIPTION_SESSION,
      {
        cancel_subscription_reason,
        entitlement_id
      }
    );
    store.dispatch(updateIsCancelingSubscription(false));
    if (status === HTTP_STATS_CODE.CREATED) {
      store.dispatch(updateShowCancelReasonModal(false));
      store.dispatch(getUserSubscriptionInfo());
    } else {
      toast.error(t('could_not_process_the_request'));
    }
  } catch (error) {
    toast.error(t('could_not_process_the_request'));
    store.dispatch(updateIsCancelingSubscription(false));
  }
};
