import { createAsyncThunk } from '@reduxjs/toolkit';
import Endpoints from 'constants/endpoints';
import focusBearApi from 'services/axios-config';
import {
  resetSelectedWebsiteIDs,
  updateIsSavedWebsitesCreating,
  updateIsSavedWebsitesDeleting,
  updateIsSavedWebsitesFetching,
  updateWebsite
} from './slice';
import { SaveWebsite } from 'interfaces';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import i18n from 'services/i18n';
import { EMPTY_STRING, HTTP_STATS_CODE } from 'constants/general';

export const getSavedWebsites = createAsyncThunk(
  'procrastinate/get_saved_websites',
  async (_, { dispatch }) => {
    try {
      dispatch(updateIsSavedWebsitesFetching(true));
      const { data } = await focusBearApi.get(Endpoints.SAVED_WEBSITE);
      return data ?? [];
    } catch (error) {
      toast.warning(i18n.t('guilt_free_play.could_not_fetch_saved_websites'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const addWebsite = createAsyncThunk(
  'procrastinate/add_website',
  async (data: SaveWebsite[], { dispatch }) => {
    try {
      dispatch(updateIsSavedWebsitesCreating(true));
      const { status } = await focusBearApi.post(Endpoints.SAVED_WEBSITE, data);
      dispatch(updateIsSavedWebsitesCreating(false));
      if (status === HTTP_STATS_CODE.CREATED) {
        toast.success(i18n.t('guilt_free_play.websites_updated_successfully'));
        dispatch(updateWebsite({ data: EMPTY_STRING, isValid: false }));
        dispatch(getSavedWebsites());
      } else {
        toast.warning(
          i18n.t('guilt_free_play.could_not_update_saved_websites')
        );
      }
    } catch (error) {
      dispatch(updateIsSavedWebsitesCreating(false));
      toast.warning(i18n.t('guilt_free_play.could_not_update_saved_websites'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const deleteWebsite = createAsyncThunk(
  'procrastinate/delete_website',
  async (website_id: string, { dispatch }) => {
    try {
      dispatch(updateIsSavedWebsitesDeleting(true));
      const { data } = await focusBearApi.delete(Endpoints.SAVED_WEBSITE, {
        params: {
          website_id
        }
      });
      dispatch(updateIsSavedWebsitesDeleting(false));
      if (data?.success) {
        toast.success(i18n.t('guilt_free_play.websites_deleted_successfully'));
        dispatch(getSavedWebsites());
      } else {
        toast.warning(
          i18n.t('guilt_free_play.could_not_delete_saved_websites')
        );
      }
    } catch (error) {
      dispatch(updateIsSavedWebsitesDeleting(false));
      toast.warning(i18n.t('guilt_free_play.could_not_delete_saved_websites'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const deleteWebsites = createAsyncThunk(
  'procrastinate/delete_websites',
  async (websiteIds: string[], { dispatch }) => {
    try {
      dispatch(updateIsSavedWebsitesDeleting(true));
      const { data } = await focusBearApi.put(
        Endpoints.SAVED_WEBSITE_DELETE,
        websiteIds
      );
      dispatch(updateIsSavedWebsitesDeleting(false));
      if (data?.success) {
        toast.success(i18n.t('guilt_free_play.websites_deleted_successfully'));
        dispatch(resetSelectedWebsiteIDs());
        dispatch(getSavedWebsites());
      } else {
        toast.warning(
          i18n.t('guilt_free_play.could_not_delete_saved_websites')
        );
      }
    } catch (error) {
      dispatch(updateIsSavedWebsitesDeleting(false));
      toast.warning(i18n.t('guilt_free_play.could_not_delete_saved_websites'));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
