import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { isDarkModeActivated } from 'utils/validation';

interface WrapperProps {
  readonly children: React.ReactNode;
  readonly mainStyles?: string;
  readonly additionalStyles?: string;
}

export default function ModalWrapper({
  children,
  additionalStyles = 'gap-y-6',
  mainStyles = 'relative top-1/2 -translate-y-1/2 w-[90%] sm:w-[80%] md:w-[65%] xl:w-[50%] h-fit max-h-[90vh] overflow-y-auto scrollbar-thin scrollbar-track-orange-100 scrollbar-thumb-orange-300 flex flex-col items-center text-center text-xl border-2 border-orange-300 rounded-lg p-3 shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] mx-auto'
}: WrapperProps) {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  return (
    <div
      className={`${mainStyles} ${mainStyles && shouldActivateDarkMode ? 'bg-gray-500' : 'bg-white'} ${additionalStyles}`}
    >
      {children}
    </div>
  );
}
