import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionTakeNotes } from 'store/reducer/modal/slice';
import { EMPTY_STRING, TAKE_NOTE_OPTION } from 'constants/general';
import DropDown from 'components/common/DropDown';
import { SingleValue } from 'react-select';
import { DropDownSelectedValue } from 'interfaces';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className='min-w-[20%] w-[95%] md:w-[30%] lg:w-fit h-fit flex flex-col gap-1 py-3 px-4 border-[0.5px] border-gray-100 rounded-md'>
    {children}
  </div>
);

const TakeNote = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { activity } = useAppSelector((state) => state.modal.moreOption);

  const options: { label: string; value: string }[] = [
    { label: t('notes.dont_take_notes'), value: TAKE_NOTE_OPTION.NO_NOTE },
    {
      label: t('notes.take_notes_during_activity'),
      value: TAKE_NOTE_OPTION.DURING_ACTIVITY
    },
    {
      label: t('notes.take_notes_at_end_of_activity'),
      value: TAKE_NOTE_OPTION.END_OF_ACTIVITY
    }
  ];

  const note = () => {
    if (activity?.take_notes === TAKE_NOTE_OPTION.NO_NOTE) {
      return {
        label: t('notes.dont_take_notes'),
        value: TAKE_NOTE_OPTION.NO_NOTE
      };
    } else if (activity?.take_notes === TAKE_NOTE_OPTION.DURING_ACTIVITY) {
      return {
        label: t('notes.take_notes_during_activity'),
        value: TAKE_NOTE_OPTION.DURING_ACTIVITY
      };
    } else if (activity?.take_notes === TAKE_NOTE_OPTION.END_OF_ACTIVITY) {
      return {
        label: t('notes.take_notes_at_end_of_activity'),
        value: TAKE_NOTE_OPTION.END_OF_ACTIVITY
      };
    } else {
      return { label: EMPTY_STRING, value: EMPTY_STRING };
    }
  };

  return (
    <Wrapper>
      <DropDown
        title={t('notes.takes_notes')}
        value={note()}
        options={options}
        handleChange={(data) => {
          const result = data as SingleValue<DropDownSelectedValue>;
          result && dispatch(updateMoreOptionTakeNotes(result.value));
        }}
        containerStyles='w-full text-xs lg:text-sm font-medium'
      />
    </Wrapper>
  );
};

export default React.memo(TakeNote);
