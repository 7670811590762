import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import focusBearApi from 'services/axios-config';
import {
  updateAreProjectsLoading,
  updateAreExternalStatusesSyncing,
  updateIsLoadingProjectTasks,
  updateIsProjectSynching,
  updateIsSynchingProjectTasks,
  updateShowPlatformProjects
} from './slice';
import { HTTP_STATS_CODE } from 'constants/general';
import { toast } from 'react-toastify';
import Endpoints from 'constants/endpoints';
import {
  ProjectTasksPayload,
  SyncProjectExternalStatusPayload,
  SyncProjectsPayload
} from 'interfaces';
import i18n from 'services/i18n';

export const getProjects = createAsyncThunk(
  'platform_integration/get_projects',
  async (platform: string, { dispatch }) => {
    try {
      dispatch(updateAreProjectsLoading(true));
      const { data } = await focusBearApi.get(
        Endpoints.USER_PROJECTS(platform.toLowerCase())
      );
      return data ?? [];
    } catch (error) {
      dispatch(updateShowPlatformProjects(true));
      dispatch(updateAreProjectsLoading(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const syncProjects = createAsyncThunk(
  'platform_integration/sync_projects',
  async (
    {
      portal_id,
      project_id,
      platform,
      are_multiple_projects_being_synced,
      only_assigned
    }: SyncProjectsPayload,
    { dispatch }
  ) => {
    try {
      !are_multiple_projects_being_synced &&
        dispatch(updateIsProjectSynching(true));
      const { status } = await focusBearApi.post(
        `${Endpoints.SYNC_PROJECT(platform.toLowerCase())}`,
        { only_assigned },
        {
          params: {
            portal_id,
            project_id
          }
        }
      );
      if (!are_multiple_projects_being_synced) {
        dispatch(updateIsProjectSynching(false));
        status === HTTP_STATS_CODE.CREATED
          ? dispatch(getProjects(platform))
          : toast.error('to_do_procrastinate.could_not_sync_the_project');
      }

      return project_id;
    } catch (error) {
      dispatch(updateIsProjectSynching(false));
      Sentry.captureException(JSON.stringify(error));
      return null;
    }
  }
);

export const syncProjectExternalStatus = createAsyncThunk(
  'to_do/sync_project_external_status',
  async (
    {
      project_id,
      external_statuses,
      is_apply_to_all_projects
    }: SyncProjectExternalStatusPayload,
    { dispatch }
  ) => {
    try {
      !is_apply_to_all_projects &&
        dispatch(updateAreExternalStatusesSyncing(true));
      const { status } = await focusBearApi.put(
        Endpoints.SYNCED_PROJECTS_EXTERNAL_STATUS,
        {
          project_id,
          external_statuses
        }
      );
      !is_apply_to_all_projects &&
        dispatch(updateAreExternalStatusesSyncing(false));
      status !== HTTP_STATS_CODE.SUCCESS &&
        toast.error(i18n.t('to_do_procrastinate.could_not_update_statuses'));
    } catch (error) {
      !is_apply_to_all_projects &&
        dispatch(updateAreExternalStatusesSyncing(false));
      Sentry.captureException(JSON.stringify(error));
      return null;
    }
  }
);

export const syncProjectTasks = createAsyncThunk(
  'platform_integration/sync_project_tasks',
  async (
    { platform, portalId, projectId }: ProjectTasksPayload,
    { dispatch }
  ) => {
    try {
      dispatch(updateIsSynchingProjectTasks(true));
      const { status } = await focusBearApi.post(
        Endpoints.SYNC_TASKS(platform.toLowerCase())
      );
      dispatch(updateIsSynchingProjectTasks(false));
      if (status === HTTP_STATS_CODE.CREATED) {
        dispatch(getProjectTasks({ platform, portalId, projectId }));
      } else {
        toast.error(i18n.t('could_not_process_the_request'));
      }
    } catch (error) {
      dispatch(updateIsSynchingProjectTasks(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);

export const getProjectTasks = createAsyncThunk(
  'platform_integration/get_project_tasks',
  async (
    { platform, portalId, projectId }: ProjectTasksPayload,
    { dispatch }
  ) => {
    try {
      dispatch(updateIsLoadingProjectTasks(true));
      const { data } = await focusBearApi.get(
        Endpoints.GET_TASKS(platform.toLowerCase(), portalId, projectId)
      );
      return data ?? [];
    } catch (error) {
      dispatch(updateIsLoadingProjectTasks(false));
      Sentry.captureException(JSON.stringify(error));
    }
  }
);
