import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { HabitPackType } from 'interfaces/commonInterface';
import { upsertHabitPack } from 'store/reducer//habit-pack/extra';
import Clone from 'assets/icons/Clone';
import { CLONE_ICON } from 'constants/general';
import { useTranslation } from 'react-i18next';

interface CloneHabitPackButtonProps {
  habitPack: HabitPackType;
}

const CloneHabitPackButton = ({ habitPack }: CloneHabitPackButtonProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClone = useCallback(() => {
    let clonedPack = _.cloneDeep(habitPack);
    clonedPack.id = uuid();
    const morning_activities = clonedPack?.morning_activities?.map(
      (activity) => ({
        ...activity,
        id: uuid()
      })
    );
    const break_activities = clonedPack?.break_activities?.map((activity) => {
      const choices = activity?.choices ?? [];
      if (choices.length > 0) {
        choices.map((choice) => ({ ...choice, id: uuid() }));
      }
      return {
        ...activity,
        id: uuid(),
        choices
      };
    });
    const evening_activities = clonedPack?.evening_activities?.map(
      (activity) => ({
        ...activity,
        id: uuid()
      })
    );
    const standalone_activities = clonedPack?.standalone_activities?.map(
      (activity) => ({
        ...activity,
        id: uuid()
      })
    );
    clonedPack = {
      ...clonedPack,
      morning_activities,
      break_activities,
      evening_activities,
      standalone_activities
    };
    dispatch(upsertHabitPack(clonedPack));
  }, []);

  return (
    <button
      onClick={(event) => {
        event.stopPropagation();
        handleClone();
      }}
      className='w-fit h-fit absolute -top-0.5 right-1.5 group'
    >
      <Clone
        width={CLONE_ICON.HABIT_PACKS.WIDTH}
        height={CLONE_ICON.HABIT_PACKS.HEIGHT}
      />
      <span className='w-[150px] bg-black text-white text-xs text-center rounded-md py-1 absolute -top-5 right-0 hidden group-hover:inline-block z-50'>
        {t('clone_the_habit_pack')}
      </span>
    </button>
  );
};

export default CloneHabitPackButton;
