import AppStore from 'assets/icons/AppStore';
import AppleLogo from 'assets/icons/AppleLogo';
import GooglePlayLogo from 'assets/icons/GooglePlayLogo';
import WindowsLogo from 'assets/icons/WindowsLogo';
import { DOWNLOAD_LINK } from 'constants/routes';
import { BackToHomePageBtn } from 'pages/team/TeamAcceptInvitation';
import { useTranslation } from 'react-i18next';

const downloadOptions = [
  { icon: <AppleLogo />, link: DOWNLOAD_LINK.MAC_APP },
  { icon: <WindowsLogo />, link: DOWNLOAD_LINK.WINDOWS_APP },
  { icon: <GooglePlayLogo />, link: DOWNLOAD_LINK.MOBILE.ANDROID },
  { icon: <AppStore />, link: DOWNLOAD_LINK.MOBILE.IOS }
];

const TeamWelcomeModal = ({ teamName }: { teamName: string }) => {
  const { t } = useTranslation();

  return (
    <div className='w-fit flex flex-col items-center gap-8'>
      <div className='w-44 h-44 bg-bear bg-center bg-contain bg-no-repeat'></div>
      <p className='text-xl md:text-2xl lg:text-3xl py-1 sm:py-2'>
        {t('welcome_to_team', { teamName })}
      </p>
      <p className='text-sm sm:text-base md:text-lg lg:text-xl pt-1 sm:pt-3 md:pt-6 pb-1 sm:pb-2'>
        {t('select_your_device_to_download_the_app')}
      </p>
      <div className='w-full flex items-center justify-around pb-10'>
        {downloadOptions.map(({ link, icon }) => (
          <a
            target='_blank'
            key={link}
            href={link}
            rel='noreferrer'
            className='bg-focusBearText px-2 sm:px-3 md:px-4 py-1.5 sm:py-2 md:py-3 rounded-md sm:rounded-lg md:rounded-xl shadow-md'
          >
            {icon}
          </a>
        ))}
      </div>
      <BackToHomePageBtn />
    </div>
  );
};

export default TeamWelcomeModal;
