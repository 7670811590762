import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

const CheckMark = ({
  fill = COLOR.BLACK,
  styles = 'w-4 h-auto'
}: IconProps) => {
  return (
    <svg
      className={styles}
      version='1.1'
      id='_x32_'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      fill={fill}
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <g>
          <path d='M469.402,35.492C334.09,110.664,197.114,324.5,197.114,324.5L73.509,184.176L0,254.336l178.732,222.172 l65.15-2.504C327.414,223.414,512,55.539,512,55.539L469.402,35.492z'></path>{' '}
        </g>
      </g>
    </svg>
  );
};

export default CheckMark;
