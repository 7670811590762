import { ReactNode, useContext } from 'react';
import logo from 'assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import LanguageDropDown from './LanguageDropDown';
import NavButton from '../navBar/NavButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import NavDropDown from '../navBar/NavDropDown';
import Option from 'assets/icons/Option';
import { updateIsInquiryModalShown } from 'store/reducer/inquiry/slice';
import { getPackIds } from 'utils/support';
import { ATTRIB } from 'constants/test';
import { NavBarContext } from '../navBar';

function NavWrapper({ children }: { readonly children: ReactNode }) {
  return (
    <div className='w-full min-h-[80px] px-4 bg-transparent flex justify-between items-center sticky top-0 shadow-md backdrop-blur-md z-[60] lg:text-sm xl:text-lg'>
      {children}
    </div>
  );
}

const DesktopNavigationMiddle = () => {
  const { t } = useTranslation();
  const { isUserAdmin } = useAppSelector((state) => state.user);
  const { accountMenuOptions, extraMenuOptions } = useContext(NavBarContext);

  return (
    <div className='w-fit flex items-center gap-4'>
      <NavDropDown
        data={{
          parent: t('account'),
          children: accountMenuOptions
        }}
      />
      <NavButton
        path={ROUTES.STATS}
        text={t('stats')}
        attribAction={ATTRIB.TEST.STATS}
      />
      <NavButton
        path={ROUTES.SETTINGS}
        text={t('settings')}
        attribAction={ATTRIB.TEST.SETTINGS}
      />
      <NavButton
        path={ROUTES.TODO}
        text={t('to_do_procrastinate.to_do')}
        attribAction={ATTRIB.TEST.TODO}
      />
      {isUserAdmin && (
        <NavButton
          path={ROUTES.ADMIN}
          text={t('admin.admin')}
          attribAction={ATTRIB.TEST.ADMIN}
        />
      )}
      <NavDropDown
        data={{
          parent: t('extra'),
          children: extraMenuOptions
        }}
      />
    </div>
  );
};

const DesktopNavigationRight = () => {
  const dispatch = useAppDispatch();
  const { currentViewedPack, installedHabitPacks } = useAppSelector(
    (state) => state.habitPack
  );
  const { pathname } = useLocation();
  const installedPacksIds = getPackIds(installedHabitPacks);
  const userHasPackInstalled =
    currentViewedPack?.id && installedPacksIds.includes(currentViewedPack?.id);

  return (
    <div className='w-fit flex items-center gap-4'>
      {pathname.includes('habit-packs/') && userHasPackInstalled && (
        <button
          className='w-fit h-fit'
          onClick={() => {
            dispatch(updateIsInquiryModalShown(true));
          }}
        >
          <Option />
        </button>
      )}
      <LanguageDropDown />
    </div>
  );
};

const FocusBearLogo = () => {
  return (
    <Link to='/' className='w-[10%]' data-test={ATTRIB.TEST.HOME}>
      <img src={logo} className='w-full object-cover' alt='focus bear' />
    </Link>
  );
};

const DesktopNavigation = () => (
  <NavWrapper>
    <FocusBearLogo />
    <DesktopNavigationMiddle />
    <DesktopNavigationRight />
  </NavWrapper>
);

export default DesktopNavigation;
