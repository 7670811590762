import Edit from 'assets/icons/Edit';
import classNames from 'classnames';
import Button from 'components/common/buttons/Button';
import TextInput from 'components/common/inputs/TextInput';
import COLOR from 'constants/color';
import { MAC_APP_DATA_LOCATION, PLATFORMS } from 'constants/general';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { playerIntentionSelector } from 'store/reducer/to-do/selectors';
import { updatePlayerIntention } from 'store/reducer/to-do/slice';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { createHtml, isAlphaNumericInput } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';

const PlayerIntention = ({
  intentionTextStyles
}: {
  intentionTextStyles?: string;
}) => {
  const dispatch = useAppDispatch();
  const [isIntentionEditable, setIsIntentionEditable] = useState(false);
  const { intention, themeMode, platform } = useAppSelector(
    playerIntentionSelector
  );
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  useEffect(() => {
    sendDataToPlatform(
      platform,
      platform === PLATFORMS.MAC ? intention : { intention },
      MAC_APP_DATA_LOCATION.SEND_INTENTION
    );
  }, [intention]);

  return (
    <div className='w-full flex items-center justify-center gap-2'>
      {isIntentionEditable ? (
        <TextInput
          value={intention}
          onChange={({ target: { value } }) =>
            isAlphaNumericInput(value) && dispatch(updatePlayerIntention(value))
          }
          additionalStyles={classNames('w-full shadow animate-fadeIn', {
            'bg-gray-500': shouldActivateDarkMode,
            'bg-white': !shouldActivateDarkMode
          })}
          onBlur={() => setIsIntentionEditable((prev) => !prev)}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={isIntentionEditable}
        />
      ) : (
        <p className={intentionTextStyles ?? 'w-fit text-center text-gray-700'}>
          {t('to_do_player.your_intention_is', {
            intention: createHtml(intention)
          })}
        </p>
      )}
      {!isIntentionEditable ? (
        <Button
          icon={
            <Edit
              styles={`${intentionTextStyles ? 'w-4' : 'w-3'} h-auto`}
              fill={COLOR.WHITE}
            />
          }
          onClick={() => {
            setIsIntentionEditable((prev) => !prev);
          }}
          className='h-fit bg-gray-500 hover:bg-gray-600 rounded-md shadow p-1 disabled:bg-gray-400 disabled:hover:bg-gray-400 animate-fadeIn'
        />
      ) : null}
    </div>
  );
};

export default PlayerIntention;
