import CloseCircle from 'assets/icons/CloseCircle';
import { t } from 'i18next';
import COLOR from 'constants/color';
import { useAppSelector } from 'store/hooks';
import { FC } from 'react';
import Tooltip from 'components/common/Tooltip';
import { selectedGoalSelector } from 'store/reducer/setting/selectors';

interface Props {
  position: number;
  goal: string;
  handleDeleted: (position: number) => void;
}

const SelectedGoal: FC<Props> = ({ goal, position, handleDeleted }: Props) => {
  const { details: userInfo, isLogTermGoalUpdating } =
    useAppSelector(selectedGoalSelector);
  const long_term_goals = userInfo?.long_term_goals ?? [];

  return (
    <div className='min-w-max h-fit flex items-center justify-center rounded-md bg-gray-600 pl-2 pr-3 py-1 my-2 relative shadow-md'>
      <button
        disabled={isLogTermGoalUpdating}
        onClick={() => handleDeleted(position)}
        className='absolute -top-1.5 -right-1.5 bg-white rounded-full'
      >
        <CloseCircle
          styles='w-4 h-auto'
          fill={COLOR.RED}
          fillDarkMode={COLOR.RED}
        />
      </button>
      <div className='text-sm font-medium text-white flex items-center gap-1.5'>
        {long_term_goals.includes(goal) && (
          <Tooltip
            message={t('routine_suggestion.custom_goal_you_added')}
            iconStyles='w-4 h-auto bg-sunRay rounded-full'
            iconFillDarkMode={COLOR.BLACK}
          />
        )}
        {goal}
      </div>
    </div>
  );
};

export default SelectedGoal;
