import logo from 'assets/images/logo.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <section className='container mx-auto flex flex-col items-center gap-4 py-6'>
      <div className='w-full h-fit flex flex-col justify-center items-center gap-1'>
        <p className='w-full text-gray-500 text-sm text-center '>
          {t('all_rights_reserved', {
            year: new Date().getFullYear().toString(),
          })}
        </p>
        <div className='w-fit flex items-center gap-1'>
          <a
            className='mr-6 text-sm text-gray-500 hover:text-black'
            href='https://www.focusbear.io/terms-of-service'
          >
            {t('terms_of_service')}
          </a>
          <a
            className='text-sm text-gray-500 hover:text-black'
            href='https://www.focusbear.io/privacy-policy'
          >
            {t('privacy_policy')}
          </a>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center gap-1'>
        <img className=' w-[120px]' src={logo} alt='focus bear logo' />
        <a className='italic' href='https://www.focusbear.io'>
          www.focusbear.io
        </a>
      </div>
    </section>
  );
};

export default Footer;
