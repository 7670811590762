import {
  Page,
  Document,
  StyleSheet,
  Text,
  View,
  Image
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import PageContent from './PageContent';
import logo from 'assets/images/logo.png';
import { SettingsType } from 'interfaces/settingInterface';
import { EMPTY_STRING, FOCUS_BEAR, PDF_PAPER_TYPE } from 'constants/general';

interface PdfDocumentProps {
  settings: SettingsType;
  metadata: {
    profile_image: { url: string; file_path?: string };
    description: string;
  };
}

const PdfDocument = ({ settings, metadata }: PdfDocumentProps) => {
  const { t } = useTranslation();

  return (
    <Document
      creator={FOCUS_BEAR}
      author={settings?.creator_name ?? EMPTY_STRING}
    >
      <Page size={PDF_PAPER_TYPE} style={styles.pageWrapper}>
        <View style={styles.header} fixed>
          <View style={styles.info}>
            {metadata?.profile_image ? (
              <Image
                style={styles.authorImage}
                src={metadata?.profile_image?.url}
              />
            ) : (
              <Image src={logo} style={styles.logo} />
            )}
            {metadata?.description && (
              <Text style={styles.authorInfo}>{metadata?.description}</Text>
            )}
          </View>
          <View style={styles.timeWrapper}>
            <View style={styles.timeWrapperLeft}>
              <Text style={styles.textTime}>
                {t('starting_time', { time: settings.startup_time })}
              </Text>
              <Text style={styles.textTime}>
                {t('ending_time', { time: settings.shutdown_time })}
              </Text>
            </View>
            <View style={styles.timeWrapperRight}>
              <Text style={styles.textTime}>
                {t('break_every', { time: settings.break_after_minutes })}
              </Text>
              {Boolean(
                settings.cutoff_time_for_non_high_priority_activities
              ) && (
                <Text style={styles.textTime}>
                  {t(
                    'what_time_do_you_want_to_start_winding_down_to_sleep_time',
                    {
                      time: settings?.cutoff_time_for_non_high_priority_activities
                    }
                  )}
                </Text>
              )}
            </View>
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <Text style={styles.activityHeader}>{t('morning_habits')}</Text>
          <PageContent activities={settings?.morning_activities ?? []} />
        </View>
        <View style={styles.contentWrapper} break>
          <Text style={styles.activityHeader}>{t('break_activities')}</Text>
          <PageContent activities={settings?.break_activities ?? []} />
        </View>
        <View style={styles.contentWrapper} break>
          <Text style={styles.activityHeader}>{t('evening_habits')}</Text>
          <PageContent activities={settings?.evening_activities ?? []} />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  info: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderColor: '2px solid #FFA500',
    justifyContent: 'space-between'
  },
  authorInfo: {
    width: '200px',
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right'
  },
  authorImage: {
    width: '50px',
    marginLeft: '5px'
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 0'
  },
  contentWrapper: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '16px 4px'
  },
  activityHeader: {
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'left',
    marginBottom: '8px',
    fontStyle: 'bold'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  header: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px'
  },
  logo: {
    width: '200px',
    height: '33px',
    marginBottom: '14px'
  },
  timeWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '15px 0',
    borderBottom: '1px solid #000',
    borderTop: '1px solid #000'
  },
  timeWrapperLeft: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column'
  },
  timeWrapperRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column'
  },
  textTime: {
    color: '#000',
    fontSize: '16px',
    paddingBottom: '2px'
  }
});

export default PdfDocument;
