import InputTime from 'components/common/inputs/InputTime';
import { SCREEN } from 'constants/general';
import { t } from 'i18next';
import moment from 'moment';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { timingSelector } from 'store/reducer/setting/selectors';
import { updateShutdownTime } from 'store/reducer/setting/slice';

const ShutDownTime = () => {
  const dispatch = useAppDispatch();
  const { cutoff_time_for_non_high_priority_activities, shutdown_time } =
    useAppSelector(timingSelector);

  const [isShutdownTimeInvalid, errorMessages] = useMemo(() => {
    const isCutoffTimeInvalid = cutoff_time_for_non_high_priority_activities
      ? moment(shutdown_time, 'HH:mm').isSameOrAfter(
          moment(cutoff_time_for_non_high_priority_activities, 'HH:mm')
        )
      : false;
    let errorMessages: string[] = [];
    if (isCutoffTimeInvalid) {
      errorMessages = [
        t('errors.the_time_should_be_after_start_winding_down_time', {
          winding_down_time: cutoff_time_for_non_high_priority_activities
            ? moment(
                cutoff_time_for_non_high_priority_activities,
                'HH:mm'
              ).format('hh:mm a')
            : t('start_winding_down_time')
        })
      ];
    } else if (!shutdown_time) {
      errorMessages = [t('errors.empty_time')];
    }
    return [isCutoffTimeInvalid || !shutdown_time, errorMessages];
  }, [cutoff_time_for_non_high_priority_activities, shutdown_time]);

  return (
    <InputTime
      selectedDate={shutdown_time ?? ''}
      setSelectedDate={(value: string) => {
        dispatch(updateShutdownTime(value));
      }}
      validateTime={() => isShutdownTimeInvalid}
      errorMessages={errorMessages}
      label={t('what_time_do_you_finish_work')}
      tooltipMessage={t('explanation_shutdown_time', {
        shutdown_time: shutdown_time
          ? moment(shutdown_time, 'HH:mm').format('hh:mm a')
          : t('finish_work_time'),
        cutoff_time: cutoff_time_for_non_high_priority_activities
          ? moment(
              cutoff_time_for_non_high_priority_activities,
              'HH:mm'
            ).format('hh:mm a')
          : moment(shutdown_time, 'HH:mm').format('hh:mm a')
      })}
      wrapperStyles='w-full flex gap-x-10 gap-y-2 flex-wrap py-2'
      labelStyles='text-sm xs:text-base sm:text-lg'
    />
  );
};

export default ShutDownTime;
