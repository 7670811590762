import Dropdown from 'assets/icons/Dropdown';
import { useEffect, useState } from 'react';
import { createAppSelector, useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';
import COLOR from 'constants/color';
import { THEME_OPTION } from 'constants/enum';
import classNames from 'classnames';

interface AccordionProps {
  title?: string;
  children: React.ReactNode;
  value?: boolean;
  contentWrapperPadding?: string;
  contentPadding?: string;
  wrapperStyles?: string;
  headerBackground?: string;
  titleBackground?: string;
}

const Accordion = ({
  title,
  children,
  value = false,
  contentWrapperPadding = 'py-2 px-3',
  contentPadding = 'p-3',
  wrapperStyles = 'w-full h-fit flex flex-col rounded-md',
  headerBackground = 'bg-gray-500',
  titleBackground = 'text-white'
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(value);
  const shouldActiveDarkMode = isDarkModeActivated(
    useAppSelector(
      createAppSelector([(state) => state.setting.themeMode], (theme) =>
        theme.slice()
      )
    ) as THEME_OPTION
  );

  useEffect(() => {
    setIsOpen(value);
  }, [value]);

  return (
    <div className={wrapperStyles}>
      <div
        className={classNames(
          'w-full h-fit flex items-center justify-between rounded-t-md text-xs',
          {
            'bg-gray-800': shouldActiveDarkMode,
            [headerBackground]: !shouldActiveDarkMode,
            [contentWrapperPadding]: true,
            [titleBackground]: true
          }
        )}
      >
        <p>{title}</p>
        <button
          className={`${isOpen ? 'rotate-180' : 'rotate-0'} duration-200`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <Dropdown fill={COLOR.WHITE} />
        </button>
      </div>
      <div
        className={`w-full ${
          isOpen
            ? `h-fit overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 ${contentPadding}`
            : 'h-0 p-0 overflow-hidden'
        } ${
          shouldActiveDarkMode ? 'bg-gray-600' : 'bg-gray-200'
        } transition-all duration-200 rounded-b-md`}
      >
        {isOpen && children}
      </div>
    </div>
  );
};

export default Accordion;
