import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useAppSelector } from 'store/hooks';

interface Props {
  children: React.ReactNode;
}

const DetailsPageBodyWrapper = ({ children }: Props) => {
  const { isAuthenticated } = useAuth0();
  const { isOnHabitPacksTab } = useAppSelector((state) => state.habitPack);
  return (
    <div
      className={`w-full h-full flex flex-col md:flex-row gap-0 md:gap-6 lg:gap-0 ${
        isAuthenticated || isOnHabitPacksTab ? 'bg-transparent' : 'bg-white/90'
      }`}
    >
      {children}
    </div>
  );
};

export default DetailsPageBodyWrapper;
