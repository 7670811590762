import { configureStore } from '@reduxjs/toolkit';
import setting from './reducer/setting/slice';
import user from './reducer/user/slice';
import habitPack from './reducer/habit-pack/slice';
import admin from './reducer/admin/slice';
import cache from './reducer/cache/slice';
import focusMode from './reducer/focus-mode/slice';
import inquiry from './reducer/inquiry/slice';
import modal from './reducer/modal/slice';
import filter from './reducer/filter/slice';
import chatBot from './reducer/chat-bot/slice';
import general from './reducer/general/slice';
import procrastinate from './reducer/procrastinate/slice';
import toDo from './reducer/to-do/slice';
import platformIntegration from './reducer/platform-integration/slice';
import team from './reducer/team/slice';
import pagination from './reducer/pagination/slice';
import course from './reducer/course/slice';
import { isProductionEnvironment } from 'utils/support';

export const store = configureStore({
  devTools: !isProductionEnvironment,
  reducer: {
    setting,
    user,
    habitPack,
    admin,
    cache,
    focusMode,
    inquiry,
    modal,
    filter,
    chatBot,
    general,
    procrastinate,
    toDo,
    platformIntegration,
    team,
    pagination,
    course
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
