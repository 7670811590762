import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ToDoContext } from '..';
import { isDarkModeActivated } from 'utils/validation';
import { updateIsTaskSubmitting } from 'store/reducer/to-do/slice';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { MAC_APP_DATA_LOCATION } from 'constants/general';
import { t } from 'i18next';
import { selectTasksBtnSelector } from 'store/reducer/to-do/selectors';

const SelectTasksBtn = () => {
  const dispatch = useAppDispatch();
  const { selectedTaskIDs, tasks, isTaskSubmitting, platform, themeMode } =
    useAppSelector(selectTasksBtnSelector);
  const { shouldEmbedTodoList } = useContext(ToDoContext);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return shouldEmbedTodoList && selectedTaskIDs.length ? (
    <button
      disabled={isTaskSubmitting}
      className={`w-[97%] h-fit px-2 py-1.5 rounded-md ${
        isTaskSubmitting
          ? 'cursor-not-allowed'
          : 'hover:bg-gray-600 cursor-pointer'
      } animate-fadeIn ${
        shouldActivateDarkMode
          ? 'text-focusBearText bg-gray-700 hover:bg-gray-700/80'
          : 'bg-gray-500  text-white'
      } text-xs`}
      onClick={() => {
        dispatch(updateIsTaskSubmitting(true));
        sendDataToPlatform(
          platform,
          tasks.filter((task) => selectedTaskIDs.includes(task.id)),
          MAC_APP_DATA_LOCATION.START_FOCUS_MODE
        );
      }}
    >
      {isTaskSubmitting ? (
        <span className='w-4 h-4 rounded-full border-b-2 border-gray-950 animate-spin'></span>
      ) : (
        t('to_do_procrastinate.select_tasks')
      )}
    </button>
  ) : null;
};

export default SelectTasksBtn;
