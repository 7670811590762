import Logo from 'assets/icons/Logo';
import { ROUTES } from 'constants/routes';

const FocusBearLogo = () => (
  <a href={ROUTES.HOME} className='py-10'>
    <Logo styles='cursor-pointer w-56 lg:w-72 2xl:w-[400px] h-auto' />
  </a>
);

export default FocusBearLogo;
