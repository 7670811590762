import React from 'react';

interface Props {
  text: string;
}

export default function PageSubHeading({ text }: Props) {
  return (
    <div className='text-orange-500 text-base lg:text-lg xl:text-xl'>
      {text}
    </div>
  );
}
