import { useContext, useEffect, useMemo, useState } from 'react';
import DropDown from 'components/common/DropDown';
import { EISENHOWER_QUADRANT_OPTIONS, PAGE } from 'constants/general';
import { DropDownSelectedValue } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updatePaginationCurrentPage } from 'store/reducer/pagination/slice';
import { TasksContext } from '..';
import { getTodoTaskStatus } from 'utils/support';
import { DEFAULT_TO_DO_TASK_STATUS } from 'assets/data';
import FiltersActions from './FiltersActions';
import { tasksToolbarSelector } from 'store/reducer/to-do/selectors';

export interface FilterDataProps {
  taskName: string;
  quadrant: DropDownSelectedValue | null;
  project: DropDownSelectedValue | null;
  focusMode: DropDownSelectedValue | null;
  status: DropDownSelectedValue | null;
}

const TasksToolbar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showFilters, setShowFilters] = useState(false);
  const { filterData, setFilterData, shouldActivateDarkMode } =
    useContext(TasksContext);

  const { tags, details, tasks } = useAppSelector(tasksToolbarSelector);

  useEffect(() => {
    dispatch(updatePaginationCurrentPage(PAGE.FIRST));
  }, [filterData]);

  const focusTypeOptions: DropDownSelectedValue[] = useMemo(
    () =>
      details?.focus_modes?.map((focus_mode) => ({
        label: focus_mode.name,
        value: focus_mode.id
      })),
    [details?.focus_modes]
  );

  const projectOptions: DropDownSelectedValue[] = useMemo(
    () => tags?.map((tag) => ({ label: tag.text, value: tag.id })),
    [tags]
  );

  const statusOptions = () => {
    const statuses: { label: string; value: string }[] = [];
    tasks?.forEach((task) => {
      const taskStatuses = task.external_statuses
        ? getTodoTaskStatus(task)
        : [];
      const found = statuses.some((status) =>
        taskStatuses.map((status) => status.label).includes(status.label)
      );
      if (!found) {
        statuses.push(...taskStatuses);
      }
    });
    return [...DEFAULT_TO_DO_TASK_STATUS, ...statuses];
  };

  return (
    <div
      className={`w-full flex flex-col gap-2 p-3 rounded shadow-md ${
        shouldActivateDarkMode ? 'bg-gray-700' : 'bg-gray-200'
      }`}
    >
      <FiltersActions
        showFilters={showFilters}
        setShowFilters={setShowFilters}
      />
      {showFilters ? (
        <div className='w-full flex flex-col md:flex-row justify-between items-center gap-4 animate-fadeIn'>
          <DropDown
            title={t('priority')}
            value={filterData.quadrant ?? undefined}
            options={EISENHOWER_QUADRANT_OPTIONS}
            handleChange={(data) => {
              const result = data as DropDownSelectedValue;
              setFilterData?.((prev) => ({
                ...prev,
                quadrant: result
              }));
            }}
            isClearable
            labelStyles='w-full text-xs'
          />
          <DropDown
            title={t('to_do_procrastinate.focus_type')}
            value={filterData.focusMode ?? undefined}
            options={focusTypeOptions}
            handleChange={(data) => {
              const result = data as DropDownSelectedValue;
              setFilterData?.((prev) => ({
                ...prev,
                focusMode: result
              }));
            }}
            isClearable
            labelStyles='w-full text-xs'
          />
          <DropDown
            title={t('to_do_procrastinate.projects')}
            value={filterData.project ?? undefined}
            options={projectOptions}
            handleChange={(data) => {
              const result = data as DropDownSelectedValue;
              setFilterData?.((prev) => ({
                ...prev,
                project: result
              }));
            }}
            isClearable
            labelStyles='w-full text-xs'
          />
          <DropDown
            title={t('to_do_procrastinate.status')}
            value={filterData.status ?? undefined}
            options={statusOptions()}
            handleChange={(data) => {
              const result = data as DropDownSelectedValue;
              setFilterData?.((prev) => ({
                ...prev,
                status: result
              }));
            }}
            isClearable
            labelStyles='w-full text-xs'
          />
        </div>
      ) : null}
    </div>
  );
};

export default TasksToolbar;
