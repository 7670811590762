import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';

const InitEmbeddedServices = ({ message }: { readonly message?: string }) => {
  const { t } = useTranslation();
  const { themeMode } = useAppSelector((state) => state.setting);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);
  return (
    <div className='fixed w-screen h-screen bg-bear bg-no-repeat bg-center bg-fixed bg-contain'>
      <div
        className={`w-full h-full flex items-center justify-center ${
          shouldActivateDarkMode ? 'bg-gray-600/95 text-white' : 'bg-white/95'
        }`}
      >
        <p className='w-fit h-fit animate-pulse text-lg xl:text-2xl'>
          {message ?? t('initializing_embedded_services')}
        </p>
      </div>
    </div>
  );
};

export default InitEmbeddedServices;
