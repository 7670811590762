import { LAZY_LOAD_TIME } from 'constants/general';
import { lazy, ComponentType, ElementType } from 'react';

const lazyDelayedImport = <T extends ComponentType<ElementType>>(
  factory: () => Promise<{ default: T }>,
  minLoadTime = LAZY_LOAD_TIME
) =>
  lazy(() =>
    Promise.all([
      factory(),
      new Promise((resolve) => setTimeout(resolve, minLoadTime))
    ]).then(([moduleExports]) => moduleExports)
  );

export default lazyDelayedImport;
