import React from 'react';

interface Props {
  children: React.ReactNode;
}

const PackAndFocusModeInfoLeftContainer = ({ children }: Props) => (
  <div className='sm:w-1/2 w-full flex flex-col gap-1'>{children}</div>
);

export default PackAndFocusModeInfoLeftContainer;
