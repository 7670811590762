import { DEFAULT_GOAL_SUGGESTIONS } from 'assets/data';
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAppSelector } from 'store/hooks';
import { routineGoalsSelector } from 'store/reducer/setting/selectors';
import { WizardSectionWrapper } from '..';
import AvailableGoals from './AvailableGoalsModal';
import ListOfSelectedGoals from './ListOfSelectedGoals';
import { getUniqueGoals } from 'utils/routineSuggestionUtil';

interface RoutineData {
  selectedGoals: string[];
  showAvailableGoals: boolean;
  availableGoals: string[];
}

const DEFAULT_ROUTINE_DATA = {
  selectedGoals: [],
  showAvailableGoals: false,
  availableGoals: DEFAULT_GOAL_SUGGESTIONS
};

export const RoutineGoalsContext = createContext<{
  routineData: RoutineData;
  setRoutineData?: Dispatch<SetStateAction<RoutineData>>;
}>({ routineData: DEFAULT_ROUTINE_DATA });

const RoutineGoals: FC = () => {
  const { long_term_goals, user_goals } = useAppSelector(routineGoalsSelector);
  const [routineData, setRoutineData] =
    useState<RoutineData>(DEFAULT_ROUTINE_DATA);

  useEffect(() => {
    setRoutineData?.((prev) => ({
      ...prev,
      selectedGoals: user_goals
    }));
  }, []);

  useEffect(() => {
    const updateGoals = (long_term_goals ?? []).filter(
      (goal) => !routineData.availableGoals.includes(goal)
    );

    setRoutineData((prev) => ({
      ...prev,
      selectedGoals: getUniqueGoals([...prev.selectedGoals, ...updateGoals]),
      availableGoals: getUniqueGoals([...prev.availableGoals, ...updateGoals])
    }));
  }, [long_term_goals]);

  return (
    <RoutineGoalsContext.Provider
      value={useMemo(() => ({ routineData, setRoutineData }), [routineData])}
    >
      <WizardSectionWrapper>
        <ListOfSelectedGoals />
        {routineData.showAvailableGoals && <AvailableGoals />}
      </WizardSectionWrapper>
    </RoutineGoalsContext.Provider>
  );
};

export default RoutineGoals;
