import React from 'react';

interface Props {
  children: React.ReactNode;
}

const PackAndFocusModeInfoContainer = ({ children }: Props) => (
  <div className='w-full flex sm:flex-row flex-col justify-around gap-4 sm:gap-8 relative pr-4'>
    {children}
  </div>
);

export default PackAndFocusModeInfoContainer;
