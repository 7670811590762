import Logo from 'assets/icons/Logo';
import LoginButton from 'components/common/buttons/LoginButton';
import SignUpButton from 'components/common/buttons/SignUpButton';
import { useTranslation } from 'react-i18next';

function Login() {
  const { t } = useTranslation();
  return (
    <div className='min-h-screen text-center flex flex-col items-center justify-center gap-12 bg-main'>
      <Logo />
      <h1 className='w-[90%] sm:w-5/6 md:w-4/5 lg:w-2/3 xl:w-1/2 text-2xl sm:text-3xl md:text-4xl font-semibold text-loginText'>
        {t('make_deep_work')}
      </h1>
      <div className='w-fit h-fit flex flex-col'>
        <LoginButton />
        <p className='mt-8 font-semibold'>{t('dont_have_an_account')}</p>
        <SignUpButton />
      </div>
    </div>
  );
}

export default Login;
