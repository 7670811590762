import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

const HotBeverage = ({
  styles = 'w-5 xl:w-6 h-auto',
  fill = COLOR.GRAY_5OO
}: IconProps) => {
  return (
    <svg
      viewBox='0 0 36 36'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-hidden='true'
      preserveAspectRatio='xMidYMid meet'
      fill={fill}
      className={styles}
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <ellipse fill='#99AAB5' cx='18' cy='26' rx='18' ry='10'></ellipse>
        <ellipse fill='#CCD6DD' cx='18' cy='24' rx='18' ry='10'></ellipse>
        <path
          fill='#F5F8FA'
          d='M18 31C3.042 31 1 16 1 12h34c0 2-1.958 19-17 19z'
        ></path>
        <path
          fill='#CCD6DD'
          d='M34.385 9.644c2.442-10.123-9.781-7.706-12.204-5.799A38.063 38.063 0 0 0 18 3.611c-9.389 0-17 3.229-17 8.444C1 17.271 8.611 21.5 18 21.5s17-4.229 17-9.444c0-.863-.226-1.664-.615-2.412zm-2.503-2.692c-1.357-.938-3.102-1.694-5.121-2.25c1.875-.576 4.551-.309 5.121 2.25z'
        ></path>
        <ellipse fill='#8A4B38' cx='18' cy='13' rx='15' ry='7'></ellipse>
        <path
          fill='#D99E82'
          d='M20 17a.997.997 0 0 1-.707-.293c-2.337-2.337-2.376-4.885-.125-8.262c.739-1.109.9-2.246.478-3.377c-.461-1.236-1.438-1.996-1.731-2.077c-.553 0-.958-.443-.958-.996c0-.552.491-.995 1.043-.995c.997 0 2.395 1.153 3.183 2.625c1.034 1.933.91 4.039-.351 5.929c-1.961 2.942-1.531 4.332-.125 5.738A.999.999 0 0 1 20 17zm-6-2a.997.997 0 0 1-.707-.293c-2.337-2.337-2.376-4.885-.125-8.262c.727-1.091.893-2.083.494-2.947c-.444-.961-1.431-1.469-1.684-1.499a.99.99 0 0 1-.989-1c0-.552.458-1 1.011-1c.997 0 2.585.974 3.36 2.423c.481.899 1.052 2.761-.528 5.131c-1.961 2.942-1.531 4.332-.125 5.738a.999.999 0 0 1 0 1.414A.991.991 0 0 1 14 15z'
        ></path>
      </g>
    </svg>
  );
};

export default HotBeverage;
