import { t } from 'i18next';
import { useAppSelector } from 'store/hooks';
import Member from './member';
import DropdownIcon from 'assets/icons/Dropdown';
import COLOR from 'constants/color';
import { useCallback, useEffect, useState } from 'react';
import { SORT_OPTION } from 'constants/enum';
import { getSortedMembers, teamMembersColumns } from 'utils/teamUtil';
import { listOfMembersSelector } from 'store/reducer/team/selectors';

const MembersHeader = ({
  handleSort
}: {
  handleSort: (arg: { column: string; sortType: SORT_OPTION }) => void;
}) => {
  const [activeColumn, setActiveColumn] = useState({
    column: '',
    sortType: SORT_OPTION.DESC
  });

  useEffect(() => {
    activeColumn && handleSort(activeColumn);
  }, [activeColumn]);

  return (
    <div className='min-w-max w-full flex items-center bg-gray-500 text-white uppercase gap-2 rounded-t px-4 py-1.5 text-center text-xs sm:text-sm'>
      {teamMembersColumns.map((column, idx) =>
        idx ? (
          <button
            key={column.title}
            disabled={!column.shouldSortAllowed}
            className={`${column.styles} flex items-center justify-center gap-2 disabled:cursor-default cursor-pointer odd:border-x border-white`}
            onClick={() => {
              setActiveColumn((prev) => ({
                column: column.title,
                sortType:
                  prev.sortType === SORT_OPTION.DESC
                    ? SORT_OPTION.ASC
                    : SORT_OPTION.DESC
              }));
            }}
          >
            <p>{column.title}</p>
            {column.shouldSortAllowed &&
              activeColumn.column === column.title && (
                <DropdownIcon
                  fill={COLOR.WHITE}
                  styles={`w-5 h-auto transform ${activeColumn.sortType === SORT_OPTION.ASC ? 'rotate-180' : 'rotate-0'} duration-200 animate-fadeIn`}
                />
              )}
          </button>
        ) : (
          <div key={column.title} className={column.styles}></div>
        )
      )}
    </div>
  );
};

const ListOfMembers = () => {
  const { members, areMembersLoading, searchMember } = useAppSelector(
    listOfMembersSelector
  );
  const [sortedMembers, setSortedMembers] = useState(members);

  useEffect(() => {
    !areMembersLoading && setSortedMembers(getSortedMembers(members ?? []));
  }, [areMembersLoading]);

  useEffect(() => {
    const searchTerm = searchMember?.toLowerCase();
    setSortedMembers(
      members?.filter(
        (member) =>
          member.email?.toLowerCase()?.includes(searchTerm) ||
          member.first_name?.toLowerCase()?.includes(searchTerm) ||
          member.last_name?.toLowerCase()?.includes(searchTerm)
      )
    );
  }, [searchMember, members]);

  const handleSort = useCallback(
    ({ column, sortType }: { column: string; sortType: SORT_OPTION }) => {
      setSortedMembers((prev) => [...getSortedMembers(prev, column, sortType)]);
    },
    []
  );

  return sortedMembers?.length ? (
    <div className='w-full h-fit max-h-[70vh] pb-2 flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300'>
      <MembersHeader handleSort={handleSort} />
      {sortedMembers?.map((member) => (
        <Member key={member.id} member={member} />
      ))}
    </div>
  ) : (
    <p className='w-full text-center py-10'>
      {t('admin_team.no_members_found')}
    </p>
  );
};

export default ListOfMembers;
