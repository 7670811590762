import React from 'react';
import ActivityImages from './ActivityImages';
import TextInstructions from './TextInstructions';

const Instructions = () => {
  return (
    <div className='w-full flex flex-wrap gap-6 p-2'>
      <ActivityImages />
      <TextInstructions />
    </div>
  );
};

export default Instructions;
