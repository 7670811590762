import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useMemo
} from 'react';
import { ChoiceItemProps } from 'interfaces';
import { Mode, THEME_OPTION } from 'constants/enum';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsYouTubeURLMetadataUpdating } from 'store/reducer/cache/slice';
import { fetchYoutubeURLMetadata } from 'store/reducer/cache/extra';
import {
  ACTIVITY_TYPES,
  HABIT_CHOICE_TYPE,
  MAXIMUM_ALLOWED_MINUTES,
  MODAL_TYPES,
  NUMBERS
} from 'constants/general';
import YouTubeMetaData from 'components/shared/YouTubeMetaData';
import HabitPackAndLibraryActivitiesAutocompleteInput from 'components/setting-generator/HabitPackAndLibraryActivitiesAutocompleteInput';
import {
  updateChoices,
  updateChoicesCompetencyLevel,
  updateChoicesCompletionRequirements,
  updateChoicesDuration,
  updateChoicesName,
  updateChoicesSelectedChoicePosition,
  updateChoicesShowYouTubeURLModal
} from 'store/reducer/modal/slice';
import { increment, nextLevel } from 'utils/support';
import { SWITCH } from 'constants/tag';
import { ATTRIB } from 'constants/test';
import COLOR from 'constants/color';
import CloseCircle from 'assets/icons/CloseCircle';
import { toast } from 'react-toastify';
import LogQuantityQuestions from '../MoreOptions/LogData/LogQuantityQuestions';
import LogSummary from '../MoreOptions/LogData/LogSummary';

interface WrapperProps {
  children: React.ReactNode;
  position: number;
}

export const ChoiceItemContext = React.createContext({
  position: NUMBERS.ZERO
});

const Wrapper = ({ children, position }: WrapperProps) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`w-full h-fit flex flex-col sm:flex-row border p-4 ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-600' : 'bg-gray-100'
      } rounded-md gap-4 relative`}
    >
      <ChoiceItemContext.Provider value={useMemo(() => ({ position }), [])}>
        {children}
      </ChoiceItemContext.Provider>
    </div>
  );
};

const EmptyItems = ({ title }: { title: string }) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  return (
    <div
      className={`w-full flex justify-center py-4 text-xs font-semibold ${
        themeMode === THEME_OPTION.DARK ? 'text-focusBearText' : 'text-blue-600'
      }`}
    >
      {title}
    </div>
  );
};

const YouTubeVideos = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    cache: {
      isYouTubeURLMetadataUpdating,
      youtube_urls_metadata: { videos_metadata }
    },
    modal: {
      choice: { choices }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const { position } = useContext(ChoiceItemContext);
  const video_urls = choices[position]?.video_urls ?? [];

  return (
    <div className='w-full h-full flex flex-col justify-start border border-gray-400 rounded-md px-2 py-2'>
      <div className='w-full flex items-center gap-2 border-b py-1'>
        <div className='text-sm font-bold pl-3'>{t('youtube_videos')}</div>
        <button
          data-test={ATTRIB.TEST.OPEN_CHOICE_YOUTUBE_VIDEOS}
          className='w-fit h-fit cursor-pointer bg-gray-500 hover:bg-gray-700 text-[0.67rem] text-white rounded px-1.5 py-0.5 tracking-wide'
          onClick={() => {
            dispatch(updateChoicesSelectedChoicePosition(position));
            dispatch(updateChoicesShowYouTubeURLModal(true));
          }}
        >
          {t('manage')}
        </button>
      </div>
      <div className='w-full h-64 flex flex-col px-1 items-center justify-start gap-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100'>
        {!video_urls.length ? (
          <EmptyItems title={t('no_youtube_urls_found')} />
        ) : (
          video_urls
            .map((url: string, index: number) => ({ position: index, url }))
            .map(({ url, position }) => {
              let metadata = { title: '', duration: '' };
              if (videos_metadata.length > 0) {
                metadata = videos_metadata.find(
                  (meta) => meta.video_url === url
                ) ?? { title: '', duration: '' };
              }
              return (
                <div
                  key={position}
                  className='w-full flex flex-col py-2.5 pr-3'
                >
                  {isYouTubeURLMetadataUpdating && (
                    <div
                      className={`w-3 h-3 rounded-full border-r ${
                        themeMode === THEME_OPTION.DARK
                          ? 'border-white'
                          : 'border-gray-600'
                      } animate-spin`}
                    ></div>
                  )}
                  {!isYouTubeURLMetadataUpdating &&
                    (metadata.title && metadata.duration ? (
                      <YouTubeMetaData
                        title={metadata.title}
                        duration={metadata.duration}
                      />
                    ) : (
                      `URL ${increment(position)}`
                    ))}
                  <input
                    className='w-full outline-none border border-gray-400 rounded px-1.5 py-0.5 text-sm font-medium mt-0.5 text-black'
                    type='text'
                    value={url}
                    readOnly
                  />
                </div>
              );
            })
        )}
      </div>
    </div>
  );
};

const ActivityName = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    modal: {
      choice: { showChoicesYouTubeURLModal, choices }
    },
    setting: { mode: generatorMode, themeMode }
  } = useAppSelector((state) => state);
  const { position } = useContext(ChoiceItemContext);
  const choice = choices[position];

  return (
    <div className='w-full  flex flex-col gap-1'>
      <p className='text-left text-xs lg:text-sm  font-semibold'>{t('name')}</p>
      {generatorMode === Mode.ROUTINE || generatorMode === Mode.DEFAULT ? (
        <HabitPackAndLibraryActivitiesAutocompleteInput
          name={choice.name}
          position={position}
          type={ACTIVITY_TYPES.CHOICE}
        />
      ) : (
        <input
          data-test={ATTRIB.TEST.INPUT_ACTIVITY_CHOICE_NAME}
          className={`w-full outline-none rounded py-0.5 px-1.5 text-sm font-medium text-black ${
            choice?.name && 'border border-red-400'
          } ${themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-white'}`}
          type='text'
          value={choice?.name ?? ''}
          onChange={({
            target: { value }
          }: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(updateChoicesName({ position, value }));
          }}
          disabled={showChoicesYouTubeURLModal}
        />
      )}
    </div>
  );
};

const ChoiceCompletionUsingTimer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    modal: {
      choice: { choices }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const { position } = useContext(ChoiceItemContext);
  const [second, setSecond] = useState(
    String(
      (choices[position]?.duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY
    )
  );

  const isDurationValid = Boolean(choices[position]?.duration_seconds);

  const handleInputMinuteDuration = useCallback(
    (value: string) => {
      const minutes = parseInt(value);
      const total_minutes =
        minutes +
        ((choices[position].duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY);
      if (total_minutes > MAXIMUM_ALLOWED_MINUTES) {
        toast.error(t('validate_duration_min_max'));
      } else {
        const seconds = second ? NUMBERS.ZERO : parseInt(second);
        const total_seconds = minutes * NUMBERS.SIXTY + seconds;
        dispatch(
          updateChoicesDuration({
            position,
            value: total_seconds
          })
        );
      }
    },

    [choices[position]]
  );

  const handleInputSecondDuration = useCallback(() => {
    const seconds = second ? parseInt(second) : NUMBERS.ZERO;
    const current_duration = choices[position].duration_seconds ?? NUMBERS.ZERO;
    const duration = isNaN(current_duration)
      ? NUMBERS.ZERO
      : Math.floor(current_duration / NUMBERS.SIXTY) * NUMBERS.SIXTY;
    dispatch(
      updateChoicesDuration({
        position: position,
        value: duration + seconds
      })
    );
    const remainingSeconds = (duration + seconds) % NUMBERS.SIXTY;
    setSecond(remainingSeconds?.toString());
  }, [choices[position], second]);

  return (
    <>
      <p className='text-xs font-semibold'>{t('duration')}</p>
      <div className='w-full flex flex-col sm:flex-row items-center gap-5'>
        <div className='w-full sm:w-1/2 relative'>
          <input
            type='number'
            value={String(
              Math.floor(
                (choices[position].duration_seconds ?? NUMBERS.ZERO) /
                  NUMBERS.SIXTY
              )
            )}
            onChange={({ target: { value } }) => {
              handleInputMinuteDuration(value);
            }}
            className={`w-full px-2 py-1 text-sm font-semibold bg-white outline-none rounded text-black ${
              isDurationValid ? 'border-none' : 'border border-red-400'
            } ${themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-white'}`}
            step={NUMBERS.ONE}
            min={NUMBERS.ZERO}
          />
          <span className='absolute -bottom-[14px] right-0 text-[10px] font-semibold'>
            {t('minutes')}
          </span>
        </div>
        <div className='w-full sm:w-1/2 relative'>
          <input
            type='number'
            value={second}
            onChange={({ target: { value } }) => {
              setSecond(value);
            }}
            className={`w-full px-2 py-1 text-sm font-semibold bg-white outline-none rounded text-black ${
              isDurationValid ? 'border-none' : 'border border-red-400'
            }`}
            step={NUMBERS.ONE}
            min={NUMBERS.ZERO}
            onBlur={handleInputSecondDuration}
          />
          <span className='absolute -bottom-[14px] right-0 text-[10px] font-semibold'>
            {t('seconds')}
          </span>
        </div>
      </div>
    </>
  );
};

const ChoiceCompletionUsingLevel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { choices } = useAppSelector((state) => state.modal.choice);
  const { position } = useContext(ChoiceItemContext);

  return (
    <>
      <p className='text-xs font-semibold'>{t('completion_requirements')}</p>
      <input
        data-test={ATTRIB.TEST.INPUT_CHOICE_COMPLETION_REQUIREMENT}
        value={choices[position].completion_requirements}
        onChange={({ target: { value } }) => {
          dispatch(updateChoicesCompletionRequirements({ position, value }));
        }}
        className='w-full py-1 border px-2 rounded text-sm font-medium outline-none text-black'
        placeholder={t('read_ten_pages')}
      />
      <div className='text-[9px] lg:text-xs 2xl:text-xs font-medium text-blue-600'>
        {t('eg_how_many_sets')}
      </div>
    </>
  );
};

const ChoiceCompletionRequirements = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    modal: {
      choice: { choices }
    }
  } = useAppSelector((state) => state);
  const { position } = useContext(ChoiceItemContext);
  const doesChoiceHaveExistingRequirement = Boolean(
    choices[position].completion_requirements
  );
  const [shouldUseTimer, setShouldUseTimer] = useState(
    !doesChoiceHaveExistingRequirement
  );

  const handleSwitchUseTimer = (value: boolean) => {
    setShouldUseTimer(value);
    // clear completion requirements text if user changes to use timer
    if (value) {
      dispatch(updateChoicesCompletionRequirements({ position, value: '' }));
    }
  };

  return (
    <div className='w-full h-fit flex flex-col justify-start'>
      <div className='w-fit h-fit min-w-[5%] flex gap-2 pb-1 items-center'>
        <label
          data-test={ATTRIB.TEST.SWITCH_CHOICE_USE_TIMER}
          className='flex items-center gap-2 cursor-pointer text-xs lg:text-sm pb-0.5 font-semibold'
        >
          <Switch
            width={SWITCH.WIDTH.CHOICES_LOG_QUANTITY}
            height={SWITCH.HEIGHT.CHOICES_LOG_QUANTITY}
            onChange={handleSwitchUseTimer}
            checked={shouldUseTimer}
          />
          {t('use_timer')}
        </label>
      </div>
      <div className='w-full flex flex-col gap-1 border border-gray-300 rounded-md px-2 pt-2 pb-4'>
        {shouldUseTimer ? (
          <ChoiceCompletionUsingTimer />
        ) : (
          <ChoiceCompletionUsingLevel />
        )}
      </div>
    </div>
  );
};

const CompetencyLevel = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { position } = useContext(ChoiceItemContext);
  const level = nextLevel(position);

  const handleCompetencyLevelSave = (position: number, value: number) => {
    dispatch(updateChoicesCompetencyLevel({ position, value }));
  };

  useEffect(() => {
    handleCompetencyLevelSave(position, level);
  }, []);

  return (
    <div className='flex flex-row gap-3'>
      <h6 className='text-sm pb-0.5  font-semibold'>{t('competency_level')}</h6>
      <p className='text-sm pb-0.5 font-semibold'>{level}</p>
    </div>
  );
};

const ChoiceItemActions = () => {
  const dispatch = useAppDispatch();
  const { choices } = useAppSelector((state) => state.modal.choice);
  const { position } = useContext(ChoiceItemContext);

  const handleDeleteChoice = useCallback(() => {
    const filteredChoices = choices.filter(
      (_, index: number) => index !== position
    );
    dispatch(updateChoices(filteredChoices));
  }, [choices]);

  return (
    <button
      onClick={handleDeleteChoice}
      className='absolute -right-1 -top-2.5 w-3 h-3 cursor-pointer object-cover'
    >
      <CloseCircle fill={COLOR.RED} />
    </button>
  );
};

const ChoiceItemRight = () => (
  <div className='w-full sm:w-1/2 h-full flex-grow'>
    <YouTubeVideos />
  </div>
);

const ChoiceItemLeft = () => {
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const shouldActivityChoiceTypeToBeCompetency =
    activity?.choice_type === HABIT_CHOICE_TYPE.COMPETENCY_BASED;

  return (
    <div className='w-full sm:w-1/2 flex flex-col gap-4'>
      <ActivityName />
      <ChoiceCompletionRequirements />
      {!shouldActivityChoiceTypeToBeCompetency && (
        <LogSummary modalType={MODAL_TYPES.CHOICES} />
      )}
      <LogQuantityQuestions modalType={MODAL_TYPES.CHOICES} />
      {shouldActivityChoiceTypeToBeCompetency && <CompetencyLevel />}
    </div>
  );
};

const ChoiceItem = ({ position }: ChoiceItemProps) => {
  const dispatch = useAppDispatch();
  const {
    cache: {
      youtube_urls_metadata: { videos_metadata }
    },
    modal: {
      choice: { choices }
    }
  } = useAppSelector((state) => state);

  useEffect(() => {
    const allowedURLsWithOutMetaData = (
      choices[position]?.video_urls ?? []
    ).filter(
      (url) => !videos_metadata.find((metaData) => metaData.video_url === url)
    );
    if (
      videos_metadata.length === 0 ||
      allowedURLsWithOutMetaData.length !== 0
    ) {
      dispatch(updateIsYouTubeURLMetadataUpdating(true));
      dispatch(fetchYoutubeURLMetadata(allowedURLsWithOutMetaData));
    }
  }, []);

  return (
    <Wrapper position={position}>
      <ChoiceItemLeft />
      <ChoiceItemRight />
      <ChoiceItemActions />
    </Wrapper>
  );
};

export default ChoiceItem;
