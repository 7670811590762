import Spinner from 'components/common/Spinner';
import { useAppSelector } from 'store/hooks';
import ListOfCourses from '../list-of-courses/ListOfCourses';
import { enrolledCoursesListSelector } from 'store/reducer/course/selectors';

const EnrolledCoursesList = () => {
  const isFetchingEnrolledCourses = useAppSelector(enrolledCoursesListSelector);

  return isFetchingEnrolledCourses ? <Spinner /> : <ListOfCourses />;
};

export default EnrolledCoursesList;
