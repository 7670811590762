import { createAppSelector, useAppSelector } from 'store/hooks';
import CourseReviews from './CourseReviews';
import CourseHighlights from './CourseHighlights';
import EmptyContent from 'assets/icons/EmptyContent';
import { t } from 'i18next';

export const CourseData = () => {
  const { details } = useAppSelector(
    createAppSelector([(state) => state.course], (course) => course)
  );
  return details ? (
    <div className='w-full h-fit flex flex-col gap-4'>
      <CourseHighlights />
      <CourseReviews />
    </div>
  ) : (
    <p className='text-xl md:text-4xl font-semibold m-auto flex flex-col items-center'>
      <EmptyContent />
      {t('course.course_not_found')}
    </p>
  );
};

export default CourseData;
