import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title?: string;
  icon?: JSX.Element;
}

const Button = ({
  title,
  icon,
  className = 'btn-primary flex items-center gap-1',
  ...rest
}: Props) => {
  return (
    <button {...rest} className={className}>
      {title}
      {icon}
    </button>
  );
};

export default Button;
