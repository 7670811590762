import { useCallback } from 'react';
import { changeActivityOrder } from 'store/reducer/setting/slice';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';
import { ACTIVITY_TYPES, HABIT_QUESTIONS } from 'constants/general';
import TabBodyHeader from './TabBodyHeader';
import { ActivityType } from 'interfaces/commonInterface';
import DraggableContent from './DraggableContent';
import TabBodyWrapper from './TabBodyWrapper';
import { isActivityFilterByAll } from 'utils/validation';
import AddHabitButtonForEmptyRoutine from 'components/setting-generator/tabs/AddHabitButtonForEmptyRoutine';
import { useTranslation } from 'react-i18next';

export default function StandaloneActivities() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    setting: {
      currentSettings: { standalone_activities }
    },
    filter: { searchWord, daysOfWeek, priority }
  } = useAppSelector((state) => state);
  const sequence_id =
    standalone_activities?.[0]?.activity_sequence_id ?? uuid();

  const handleDrop = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const updateData = Array.from(standalone_activities ?? []);
      const [removed] = updateData.splice(result.source.index, 1);
      updateData.splice(result.destination.index, 0, removed);
      dispatch(
        changeActivityOrder({
          type: ACTIVITY_TYPES.STANDALONE,
          data: [...updateData]
        })
      );
    },
    [standalone_activities]
  );

  return (
    <TabBodyWrapper>
      <TabBodyHeader title={HABIT_QUESTIONS.STANDALONE_ROUTINE} />
      {standalone_activities?.length ? (
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable
            droppableId={`list-container-${ACTIVITY_TYPES.STANDALONE}`}
          >
            {(provided) => (
              <div
                className='w-full h-fit flex flex-col items-center  gap-4 lg:gap-5 xl:gap-6'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {standalone_activities?.map(
                  (activity: ActivityType, index: number) => {
                    if (
                      isActivityFilterByAll(
                        activity,
                        searchWord,
                        priority,
                        daysOfWeek
                      )
                    ) {
                      return (
                        <Draggable
                          key={activity.id}
                          draggableId={activity.id}
                          index={index}
                        >
                          {(provided) => (
                            <DraggableContent
                              position={index}
                              type={ACTIVITY_TYPES.STANDALONE}
                              sequence_id={sequence_id}
                              provided={provided}
                            />
                          )}
                        </Draggable>
                      );
                    }
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <AddHabitButtonForEmptyRoutine
          routine={t('market.standalone_activities')}
        />
      )}
    </TabBodyWrapper>
  );
}
