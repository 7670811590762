import CloseCircle from 'assets/icons/CloseCircle';
import TextInput from 'components/common/inputs/TextInput';
import { EMPTY_STRING, KEY_BOARD } from 'constants/general';
import { t } from 'i18next';
import { KeyboardEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateMoreOptionActivityTags } from 'store/reducer/modal/slice';
import COLOR from 'constants/color';

const Tags = () => {
  const dispatch = useAppDispatch();
  const [tag, setTag] = useState(EMPTY_STRING);
  const { activity } = useAppSelector((state) => state.modal.moreOption);

  const isValidTag = tag ? /^[A-Za-z][A-Za-z_ ]*$/.test(tag) : true;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEY_BOARD.ENTER.KEY) {
      const duplicateTagFound = activity.tags?.some(
        (templateTag) => templateTag === tag
      );
      if (duplicateTagFound) {
        toast.error(t('tag_has_already_added', { tag }));
      } else {
        setTag(EMPTY_STRING);
        dispatch(updateMoreOptionActivityTags([...(activity.tags ?? []), tag]));
      }
    }
  };

  const handleRemove = useCallback(
    (tag: string) => {
      dispatch(
        updateMoreOptionActivityTags(
          (activity.tags ?? []).filter((template_tag) => template_tag !== tag)
        )
      );
    },
    [activity.tags]
  );

  return (
    <div className='flex flex-col gap-0.5 p-4 rounded-md bg-gray-100 shadow mb-2'>
      <p className='text-sm'>{t('tags')}</p>
      <TextInput
        value={tag}
        onChange={({ target: { value } }) => setTag(value)}
        onKeyDown={(event) => isValidTag && handleKeyDown(event)}
        className={`w-full text-sm rounded border ${isValidTag ? 'border-gray-200' : 'border-red-300'} py-1 px-2 outline-none`}
      />
      <div className='w-full flex flex-wrap gap-3 pt-4'>
        {activity.tags?.map((tag, idx) => (
          <div
            key={`${idx}${tag}`}
            className='bg-gray-600 rounded px-2 py-0.5 shadow relative'
          >
            <p className='w-fit text-xs font-medium text-white tracking-wide'>{`#${tag}`}</p>
            <button
              className='absolute -top-2 -right-2 bg-white rounded-full'
              onClick={() => handleRemove(tag)}
            >
              <CloseCircle styles='w-4 h-auto' fill={COLOR.RED} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tags;
